import { TextFieldModule } from '@angular/cdk/text-field';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { GestureConfig } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { ErrorHandlerService } from './core/api/error-handler.service';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { EncountersModule } from './encounters/encounters.module';
import { FilterModule } from './filter/filter.module';
import { FormTemplateModule } from './form-template/form-template.module';
import { IntakeFormPdfExportComponent } from './intake-form/intake-form-pdf-export/intake-form-pdf-export.component';
import { IntakeFormComponent } from './intake-form/intake-form.component';
import { IntakeFormModule } from './intake-form/intake-form.module';
import { InvoiceExportComponent } from './invoice-export/invoice-export.component';
import { LandingPageModule } from './landing-page/landing-page.module';
import { LoggedInNavbarComponent } from './logged-in-navbar/logged-in-navbar.component';
import { LoggedInNavbarModule } from './logged-in-navbar/logged-in-navbar.module';
import { PatientsModule } from './patients/patients.module';
import { PackageCustomizerComponent } from './pricing/package-customizer/package-customizer.component';
import { PackagesComponent } from './pricing/packages/packages.component';
import { PricingTableComponent } from './pricing/pricing-table/pricing-table.component';
import { PricingComponent } from './pricing/pricing.component';
import { CreatePackageModalComponent } from './pricing/pricing/packages/create-package-modal/create-package-modal.component';
import { QuestionnairePdfExportComponent } from './questionnaires/questionnaire-pdf-export/questionnaire-pdf-export.component';
import { RejectQuestionnaireModalComponent } from './questionnaires/questionnaire-request/reject-questionnaire-modal/reject-questionnaire-modal.component';
import { ReferralPdfExportComponent } from './referral/referral-pdf-export/referral-pdf-export.component';
import { AttachmentsModule } from './shared/attachments/attachments.module';
import { ConsultFormsModule } from './shared/consult-forms/consult-forms.module';
import { DoctorAndClinicInfoWizardModalComponent } from './shared/consult-forms/doctor-and-clinic-info-wizard-modal/doctor-and-clinic-info-wizard-modal.component';
import { QuestionnaireResponseModalComponent } from './shared/consult-forms/questionnaire-response-modal/questionnaire-response-modal.component';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';
import { TreatmentRequestComponent } from './treatment-request/treatment-request.component';

@NgModule({
  declarations: [
    AppComponent,
    RejectQuestionnaireModalComponent,
    IntakeFormPdfExportComponent,
    IntakeFormComponent,
    ReferralPdfExportComponent,
    QuestionnairePdfExportComponent,
    QuestionnaireResponseModalComponent,
    PricingComponent,
    PricingTableComponent,
    InvoiceExportComponent,
    TreatmentRequestComponent,
    PackagesComponent,
    CreatePackageModalComponent,
    PackageCustomizerComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    LandingPageModule,
    SharedModule,
    LoggedInNavbarModule,
    ConsultFormsModule,
    PatientsModule,
    EncountersModule,
    HttpClientModule,
    TranslocoRootModule,
    FilterModule,
    // This must be last imported module, see https://angular.io/guide/router#module-import-order-matters
    AppRoutingModule,
    TextFieldModule,
    AttachmentsModule,
    FormTemplateModule,
    IntakeFormModule,
    DashboardModule
  ],
  entryComponents: [
    DoctorAndClinicInfoWizardModalComponent,
    RejectQuestionnaireModalComponent,
    IntakeFormPdfExportComponent,
    ReferralPdfExportComponent,
    QuestionnaireResponseModalComponent,
    QuestionnairePdfExportComponent,
    PricingComponent,
    PricingTableComponent,
    InvoiceExportComponent,
    PackagesComponent,
    CreatePackageModalComponent,
    PackageCustomizerComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    CookieService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  bootstrap: [AppComponent, LoggedInNavbarComponent]
})
export class AppModule {
  constructor() {}
}
