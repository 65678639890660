import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { reminders } from './reminder.model';

@Pipe({
  name: 'reminderList'
})
export class ReminderListPipe implements PipeTransform {
  transform(previousReminderValue, currentControl: FormControl): any {
    const deepCopyReminders = [...reminders];

    if (previousReminderValue) {
      const indexOfReminderBefore = deepCopyReminders.findIndex(
        item => item === previousReminderValue
      );

      const indexOfCurrentReminder = deepCopyReminders.findIndex(
        item => item === currentControl.value
      );

      deepCopyReminders.splice(indexOfReminderBefore);

      if (indexOfCurrentReminder >= indexOfReminderBefore) {
        currentControl.setValue(null);
      }
    }

    return deepCopyReminders;
  }
}
