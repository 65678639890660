import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAccordion, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Assessment } from 'src/app/core/api/assessment.service';
import { SignImageWizardModalComponent } from 'src/app/encounters/signs/sign-image-wizard/sign-image-wizard-modal/sign-image-wizard-modal.component';
import { AssessmentRouteData } from '../../../encounters/assessment/assessment.component';
import { QrCodeModalComponent } from '../../qr-code-modal/qr-code-modal.component';
import { SymptomModes } from '../../symptoms/symptom.model';
import { Patient } from './../../../core/api/patient.service';
import { RealtimeMediaModalComponent } from './../realtime-media-modal/realtime-media-modal.component';
import { DryEyeAssessmentFormGroup } from './dry-eye-assessment-form/dry-eye-assessment-form.model';
import { DryEyeFormGroup } from './dry-eye-form/dry-eye-form.model';
import { DryEyeExpansionPanel, DryEyeFormsService } from './dry-eye-forms.service';
import { DryEyeMedicalHistoryFormGroup } from './dry-eye-medical-history-form/dry-eye-medical-history-form.model';
import { LinkQuestionnaireModalComponent } from './dry-eye-medical-history-form/link-assessment-to-questionnaire-modal/link-assessment-to-questionnaire-modal.component';
import { DryEyePredictionService } from './dry-eye-prediction.service';
import { PreOpService } from './pre-op/pre-op.service';
import { WeatherFormGroup } from './weather/weather-form.model';

// Do not make this component OnPush or it will cause the follwing bug
// https://gitlab.com/clarity-lv/dry-eye-frontend/-/issues/325
@Component({
  selector: 'csi-dry-eye-forms',
  templateUrl: './dry-eye-forms.component.html',
  styleUrls: ['./dry-eye-forms.component.css']
})
export class DryEyeFormsComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion, { static: false }) private accordion: MatAccordion;
  @Input() medicalHistoryFormGroup: DryEyeMedicalHistoryFormGroup;
  @Input() symptomsAndSignsFormGroup: DryEyeFormGroup;
  @Input() weatherFormGroup: WeatherFormGroup;
  @Input() assessmentFormGroup: DryEyeAssessmentFormGroup;
  @Input() expansionStateFormControl: FormControl;
  @Input() enabledStateFormControl: FormControl;
  @Input() patient: Patient;
  @Input() showImageRequiredError = false;
  @Input() assessmentRouteData$: Observable<AssessmentRouteData>;
  @Input() isFirstEncounter: boolean;
  @Input() assessment: Assessment;
  @Input() printMode: boolean;
  @Input() onPatientFileSaved$: Subject<Object>;
  @Input() saveFn: () => Observable<Assessment>;

  public readonly SymptomModes = SymptomModes;
  public readonly ExpansionPanel = DryEyeExpansionPanel;
  public id: string;
  public showImpressionsWarning$: Observable<boolean>;
  private isUpdatingFormGroups = false;

  public assessmentMethod = null;
  public isNewAssessment = true;
  public questionnaires = [];
  public newlyLinkedQuestionnaires = [];
  onQuestionnaireLinked$: Subject<void> = new Subject<void>();

  constructor(
    private dryEyePredictionService: DryEyePredictionService,
    public dryEyeFormsService: DryEyeFormsService,
    public preOpService: PreOpService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.symptomsAndSignsFormGroup.controls.assessmentMethod.valueChanges.subscribe(
      assessmentMethod => {
        this.assessmentMethod = assessmentMethod;
      }
    );

    // this assumes form groups are set together at once and only once
    this.symptomsAndSignsFormGroup.assessmentMethod.subscribe(symptomMode => {
      this.medicalHistoryFormGroup.optional =
        symptomMode !== SymptomModes.Advanced && symptomMode !== SymptomModes.PreOp;
      this.updateFormGroups();
      this.showImpressionsWarning$ = this.areRecommendedFieldsValidInAdvancedMode(symptomMode);
    });

    this.dryEyePredictionService.medicalHistoryFormGroup = this.medicalHistoryFormGroup;
    this.dryEyePredictionService.symptomsAndSignsFormGroup = this.symptomsAndSignsFormGroup;
    this.dryEyePredictionService.patient = this.patient;

    this.questionnaires = this.filterQuestionnairesToThreeWeeksFromCompletion();

    const pathname = location.pathname;
    this.id = pathname.substring(pathname.indexOf(':') + 1, pathname.length - 1);

    this.isNewAssessment = this.id === 'new';
  }

  ngOnDestroy() {
    this.dryEyeFormsService.dryEyeFormsExpansionMap = {};
  }

  openLinkQuestionnaireModal() {
    const dialogRef = this.dialog.open(LinkQuestionnaireModalComponent, {
      data: { saveFn: this.saveFn }
    });

    dialogRef.afterClosed().subscribe(newlyLinkedQuestionnaires => {
      this.onQuestionnaireLinked$.next(newlyLinkedQuestionnaires);
    });
  }

  openImageWizardModal() {
    this.saveNewAssessment().subscribe(data => {
      this.id = data.id;
      SignImageWizardModalComponent.open(
        this.dialog,
        this.symptomsAndSignsFormGroup,
        this.id,
        this.saveFn
      );
    });
  }

  openMobileImageWizardModal() {
    this.saveNewAssessment().subscribe(data => {
      this.id = data.id;
      const path = `${location.origin}/${this.id}/mobile-media-wizard`;
      QrCodeModalComponent.open(this.dialog, {
        qrCodePath: path,
        modalTitle: 'Scan to open Media Wizard on Mobile'
      });
    });
  }

  saveNewAssessment() {
    return this.id === 'new' ? this.saveFn() : of({ id: this.id });
  }

  filterQuestionnairesToThreeWeeksFromCompletion() {
    const threeWeeksInMilliSeconds = 1814400000;
    const assessmentDate = new Date();
    assessmentDate.setHours(0, 0, 0, 0);
    const assessmentTime = assessmentDate.getTime();

    const maxQuestionnaireTime = assessmentTime + threeWeeksInMilliSeconds;
    const minQuestionnaireTime = assessmentTime - threeWeeksInMilliSeconds;

    if (!this.patient) return [];
    return this.patient.questionnaireRequests.items.filter(questionnaire => {
      if (!questionnaire.completedAt) return false;
      const questionnaireDate = new Date(questionnaire.completedAt);
      questionnaireDate.setHours(0, 0, 0, 0);
      const questionnaireTime = questionnaireDate.getTime();
      return questionnaireTime <= maxQuestionnaireTime && questionnaireTime >= minQuestionnaireTime;
    });
  }

  private areRecommendedFieldsValidInAdvancedMode(
    assesmentMethod: SymptomModes
  ): Observable<boolean> {
    return combineLatest([
      merge(of(this.medicalHistoryFormGroup.status), this.medicalHistoryFormGroup.statusChanges),
      merge(of(this.symptomsAndSignsFormGroup.status), this.symptomsAndSignsFormGroup.statusChanges)
    ]).pipe(map(status => status.includes('INVALID') && assesmentMethod !== SymptomModes.Simple));
  }

  private updateFormGroups() {
    if (!this.isUpdatingFormGroups) {
      const controls = this.symptomsAndSignsFormGroup.controls;
      this.isUpdatingFormGroups = true;
      Object.keys(controls).forEach(key =>
        controls[key].updateValueAndValidity({ onlySelf: true })
      );
      this.symptomsAndSignsFormGroup.updateValueAndValidity();
      this.isUpdatingFormGroups = false;
    }
  }

  accordionExpansion() {
    this.accordion.openAll();
    Object.keys(this.dryEyeFormsService.dryEyeFormsExpansionMap).forEach(key => {
      this.dryEyeFormsService.dryEyeFormsExpansionMap[key] = true;
    });

    Object.keys(this.preOpService.expansionMap).forEach(key => {
      this.preOpService.expansionMap[key] = true;
    });
  }

  accordionCollapse() {
    this.accordion.closeAll();
    Object.keys(this.dryEyeFormsService.dryEyeFormsExpansionMap).forEach(key => {
      this.dryEyeFormsService.dryEyeFormsExpansionMap[key] = false;
    });

    Object.keys(this.preOpService.expansionMap).forEach(key => {
      this.preOpService.expansionMap[key] = false;
    });
  }

  goToMobileMediaWizard() {
    this.saveNewAssessment().subscribe(({ id }) => {
      this.router.navigateByUrl(`/${id}/mobile-media-wizard?return=true`);
    });
  }

  requestMediaFromApp() {
    this.saveNewAssessment().subscribe(data => {
      console.log(data);
      this.dialog.open(RealtimeMediaModalComponent, {
        data: {
          attachmentFormControl: this.assessmentFormGroup.controls.images
        }
      });
    });
  }
}
