import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppSyncService } from 'src/app/core/appsync.service';
import {
  createPatientInvoice as rawCreatePatientInvoice,
  updatePatientInvoice as rawUpdatePatientInvoice
} from 'src/graphql/mutations';
import {
  getPatientInvoice,
  listPatientInvoiceByPatientId,
  listPatientInvoiceByClinicId as rawListPatientInvoiceByClinicId
} from 'src/graphql/queries';
import { Invoice } from '../invoice-table/invoice.model';

const createPatientInvoice = gql`
  ${rawCreatePatientInvoice}
`;
const updatePatientInvoice = gql`
  ${rawUpdatePatientInvoice}
`;
const listPatientInvoiceByClinicId = gql`
  ${rawListPatientInvoiceByClinicId}
`;

@Injectable({
  providedIn: 'root'
})
export class InvoiceExportService {
  public invoices$ = new BehaviorSubject<Invoice[]>([]);
  constructor(private appSyncService: AppSyncService) {}

  public createInvoice(input: Invoice): Observable<Invoice> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: createPatientInvoice,
          variables: { input: input }
        })
      ),
      map((result: any) => {
        const createdInvoice = result.data.createPatientInvoice;
        const updatedInvoices = [...this.invoices$.value, createdInvoice];
        this.invoices$.next(updatedInvoices);
        return createdInvoice;
      })
    );
  }

  public updateInvoice(input: any): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: updatePatientInvoice,
          variables: { input }
        })
      ),
      map((result: any) => {
        const updatedInvoice = result.data.updatePatientInvoice;
        const updatedInvoices = this.invoices$.value.map(invoice =>
          invoice.id === updatedInvoice.id ? updatedInvoice : invoice
        );
        this.invoices$.next(updatedInvoices);
        return updatedInvoice;
      })
    );
  }

  public listInvoicesByPatientId(patientId: string): Observable<any[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<any>({
          query: gql(listPatientInvoiceByPatientId),
          variables: { patientInvoicePatientId: patientId },
          fetchPolicy: 'no-cache'
        })
      ),
      map((result: any) => {
        const invoices = result.data.listPatientInvoiceByPatientId.items;
        this.invoices$.next(invoices);
        return invoices;
      })
    );
  }

  getInvoice(id: string): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<any>({
          query: gql(getPatientInvoice),
          variables: { id },
          fetchPolicy: 'no-cache'
        })
      ),
      map((result: any) => result.data.getTreatmentPlan)
    );
  }
}
