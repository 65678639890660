export const reminders = [
  '1 Day Before',
  '2 Days Before',
  '3 Days Before',
  '4 Days Before',
  '5 Days Before',
  '6 Days Before'
];

export const reminderSelectionKeys = [1, 2];
