import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PatientService } from 'src/app/core/api/patient.service';
import { AppSyncService } from 'src/app/core/appsync.service';
import {
  getTreatmentPlan,
  listTreatmentPlanByPatientId,
  listTreatmentPlans
} from 'src/graphql/queries';
import { LayoutsService } from './../../../logged-in-navbar/clinic-setup-modal/layouts/layouts.service';
import { TreatmentPlan } from './treatment-plan-table.model';

@Injectable({
  providedIn: 'root'
})
export class TreatmentPlanService {
  public treatmentPlans$ = new BehaviorSubject<TreatmentPlan[]>([]);

  constructor(
    private appSyncService: AppSyncService,
    private layoutsService: LayoutsService,
    private patientService: PatientService
  ) {}

  monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
  ];

  private createTreatmentPlanMutation = gql`
    mutation CreateTreatmentPlan($input: CreateTreatmentPlanInput!) {
      createTreatmentPlan(input: $input) {
        id
        treatmentPlanClinicId
        treatmentPlanPatientId
        treatmentPlanAssessmentId
        treatmentSpecifications {
          treatmentId
          units
          comment
        }
        commentAndStatusHistory
        createdAt
        updatedAt
      }
    }
  `;

  private updateTreatmentPlanMutation = gql`
    mutation UpdateTreatmentPlan($input: UpdateTreatmentPlanInput!) {
      updateTreatmentPlan(input: $input) {
        id
        treatmentPlanClinicId
        treatmentPlanPatientId
        treatmentPlanAssessmentId
        treatmentSpecifications {
          treatmentId
          units
          comment
        }
        commentAndStatusHistory
        createdAt
        updatedAt
      }
    }
  `;

  private deleteTreatmentPlanMutation = gql`
    mutation DeleteTreatmentPlan($input: DeleteTreatmentPlanInput!) {
      deleteTreatmentPlan(input: $input) {
        id
        treatmentPlanClinicId
        treatmentPlanPatientId
        treatmentPlanAssessmentId
        treatmentSpecifications {
          treatmentId
          units
          comment
        }
        commentAndStatusHistory
        createdAt
        updatedAt
      }
    }
  `;

  createTreatmentPlan(input: any): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: this.createTreatmentPlanMutation,
          variables: { input }
        })
      ),
      map((result: any) => {
        const newPlan = result.data.createTreatmentPlan;
        newPlan.linkedAssessmentName = this.getLinkedAssessment(newPlan);
        newPlan.commentAndStatusHistory = JSON.parse(newPlan.commentAndStatusHistory);
        const updatedPlans = [...this.treatmentPlans$.value, newPlan];
        this.treatmentPlans$.next(updatedPlans);
        return newPlan;
      })
    );
  }

  updateTreatmentPlan(input: any): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: this.updateTreatmentPlanMutation,
          variables: { input }
        })
      ),
      map((result: any) => {
        const updatedPlan = result.data.updateTreatmentPlan;
        updatedPlan.linkedAssessmentName = this.getLinkedAssessment(updatedPlan);
        updatedPlan.commentAndStatusHistory = JSON.parse(updatedPlan.commentAndStatusHistory);
        const updatedPlans = this.treatmentPlans$.value.map(plan =>
          plan.id === updatedPlan.id ? updatedPlan : plan
        );
        this.treatmentPlans$.next(updatedPlans);
        return updatedPlan;
      })
    );
  }

  listTreatmentPlansByPatientId(patientId: string): Observable<any[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<any>({
          query: gql(listTreatmentPlanByPatientId),
          variables: { treatmentPlanPatientId: patientId },
          fetchPolicy: 'no-cache'
        })
      ),
      map((result: any) => {
        const plans = result.data.listTreatmentPlanByPatientId.items;
        this.treatmentPlans$.next(plans);
        return plans;
      })
    );
  }

  getTreatmentPlan(id: string): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<any>({
          query: gql(getTreatmentPlan),
          variables: { id },
          fetchPolicy: 'no-cache'
        })
      ),
      map((result: any) => result.data.getTreatmentPlan)
    );
  }

  listTreatmentPlans(filter: any, limit: number, nextToken: string): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<any>({
          query: gql(listTreatmentPlans),
          variables: { filter, limit, nextToken },
          fetchPolicy: 'no-cache'
        })
      ),
      map((result: any) => result.data.listTreatmentPlans)
    );
  }

  deleteTreatmentPlan(input: { id: string }): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: this.deleteTreatmentPlanMutation,
          variables: { input }
        })
      ),
      map((result: any) => {
        const deletedPlanId = result.data.deleteTreatmentPlan.id;
        const updatedPlans = this.treatmentPlans$.value.filter(plan => plan.id !== deletedPlanId);
        this.treatmentPlans$.next(updatedPlans);
        return result.data.deleteTreatmentPlan;
      })
    );
  }

  //To create the linked assessment name for the treatment plan table column
  getLinkedAssessment(plan: TreatmentPlan | any) {
    let linkedAssessment = 'N/A';

    const rawAssessment = this.patientService.assessments.find(assessment => {
      try {
        const parsedBody = JSON.parse(assessment.body || '{}');
        return parsedBody && parsedBody.id === plan.treatmentPlanAssessmentId;
      } catch {
        return false;
      }
    });

    const assessmentBody = JSON.parse(rawAssessment.body);

    const assessmentDate: Date = new Date(rawAssessment.createdAt);

    const monthAndDay: string =
      this.monthNames[assessmentDate.getMonth()] + ' ' + assessmentDate.getDate();
    if (rawAssessment && assessmentBody && monthAndDay) {
      linkedAssessment =
        monthAndDay +
        ' - ' +
        this.layoutsService.assessmentMethodFriendlyName[
          assessmentBody.dryEyeForm.assessmentMethod
        ];
    }
    return linkedAssessment;
  }
}
