import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionnaireRisk'
})
export class QuestionnaireRiskPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    value = value.split('<br>')[1];
    // Angular 8 does not support nullish coalescing operator
    // return value ?? 'N/A';
    // ^ Look how much nicer this looks
    if (!value) {
      return 'N/A';
    }
    return value;
  }
}
