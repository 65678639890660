import { Component, ElementRef, HostListener } from '@angular/core';
import { AppRoutePath } from '../app-routing.module';
import { StaffService } from '../core/api/staff.service';

enum Tab {
  Home = '#home',
  What = '#what',
  Mission = '#mission',
  How = '#how',
  Report = '#report'
}

@Component({
  selector: 'csi-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {
  public readonly PatientsRoutePath = `/${AppRoutePath.patients}`;
  public readonly Tab = Tab;

  public activeTab = Tab.Home;
  public isNavbarExpanded = false;

  constructor(private hostElementRef: ElementRef, public staffService: StaffService) {}

  toggleNavbar() {
    this.isNavbarExpanded = !this.isNavbarExpanded;
  }

  navItemClicked(event: Event, tab: Tab) {
    event.preventDefault();

    this.isNavbarExpanded = false;

    window.scroll({
      top: (<HTMLElement>this.hostElement.querySelector(tab)).offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  }

  @HostListener('window:scroll') calculateActiveTab() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    this.activeTab = Object.keys(Tab)
      .map(key => ({
        id: Tab[key],
        distance: Math.abs(
          (<HTMLElement>this.hostElement.querySelector(Tab[key])).offsetTop - scrollTop
        )
      }))
      .reduce((closestTab, tab) => (tab.distance < closestTab.distance ? tab : closestTab)).id;
  }

  public get copyrightYearText(): string {
    const yearCreated = 2018;
    const currentYear = new Date().getFullYear();

    return yearCreated === currentYear ? yearCreated.toString() : `${yearCreated}-${currentYear}`;
  }

  private get hostElement(): HTMLElement {
    return this.hostElementRef.nativeElement;
  }
}
