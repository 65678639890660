import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import gql from 'graphql-tag';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppSyncService } from 'src/app/core/appsync.service';
import { emailVerification } from 'src/app/shared/shared-validators/email-validator';
import { addStaff, updateStaffType } from 'src/graphql/mutations';
import {
  AddStaffMutationVariables,
  Doctor,
  UpdateStaffTypeMutationVariables
} from './../../../../../API';
import { ClinicSetupService } from './../../clinic-setup.service';

interface StaffActionData {
  staff?: Doctor;
  action: 'update' | 'add';
}

@Component({
  selector: 'csi-staff-actions-modal',
  templateUrl: './staff-actions-modal.component.html',
  styleUrls: ['./staff-actions-modal.component.css']
})
export class StaffActionsModalComponent implements OnInit {
  public emailControl = new FormControl(null, [Validators.required, Validators.email]);
  public staffFormGroup = new FormGroup({
    email: this.emailControl,
    verifyEmail: new FormControl(null, [emailVerification(this.emailControl), Validators.required]),
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    staffType: new FormControl(null, Validators.required)
  });

  public shouldReload: boolean;
  public errorMessage: string;

  public matSpinnerOptions: MatProgressButtonOptions = {
    active: false,
    text: this.data.action === 'add' ? 'Add Staff' : 'Update Staff Type',
    raised: true,
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate',
    fullWidth: true
  };

  constructor(
    public appSyncService: AppSyncService,
    @Inject(MAT_DIALOG_DATA) public data: StaffActionData,
    private dialogRef: MatDialogRef<StaffActionsModalComponent>,
    private clinicSetupService: ClinicSetupService
  ) {
    if (data.staff) {
      this.staffFormGroup.disable();
      this.staffFormGroup.controls.email.setValue(this.data.staff.email);
      this.staffFormGroup.controls.firstName.setValue(this.data.staff.firstName);
      this.staffFormGroup.controls.lastName.setValue(this.data.staff.lastName);
      this.staffFormGroup.controls.staffType.setValue(this.data.staff.staffType);

      this.staffFormGroup.controls.staffType.enable();
    }
  }

  ngOnInit() {}

  staffAction() {
    this.staffFormGroup.markAllAsTouched();
    if (this.staffFormGroup.valid) {
      this.errorMessage = '';

      this.matSpinnerOptions.active = true;

      this.staffFormGroup.disable();

      let staffAction$: Observable<any>;

      if (this.data.action === 'add') {
        staffAction$ = this.addStaff();
      } else {
        staffAction$ = this.updateStaffType();
      }

      staffAction$.subscribe(staffActionMutation => {
        if (staffActionMutation.errors && staffActionMutation.errors.length > 0) {
          this.errorMessage = staffActionMutation.errors[0].message;
          if (this.data.action === 'add' && this.errorMessage.includes('no license')) {
            this.shouldReload = true;
          }
        } else {
          this.errorMessage = '';
          this.dialogRef.close(true);
        }
        this.staffFormGroup.enable();
        this.matSpinnerOptions.active = false;
      });
    }
  }

  enableFormGroup() {
    if (this.data.action === 'add') {
      this.staffFormGroup.enable();
    } else {
      this.staffFormGroup.controls.staffType.enable();
    }
  }

  addStaff(): Observable<any> {
    const createStaffInput: AddStaffMutationVariables = {
      ...this.staffFormGroup.value,
      clinicId: this.clinicSetupService.clinicId,
      clinicSetupId: this.clinicSetupService.clinicSetupId
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(addStaff),
          variables: createStaffInput,
          errorPolicy: 'all'
        });
      })
    );
  }

  updateStaffType(): Observable<any> {
    const updateStaffTypeInput: UpdateStaffTypeMutationVariables = {
      id: this.data.staff.id,
      clinicId: this.clinicSetupService.clinicId,
      staffType: this.staffFormGroup.controls.staffType.value,
      clinicSetupId: this.clinicSetupService.clinicSetupId
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(updateStaffType),
          variables: updateStaffTypeInput,
          errorPolicy: 'all'
        });
      })
    );
  }
}
