import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { icons } from '../models/svg-icons.model';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.registerIcons();
  }

  registerIcons() {
    for (const [key, value] of Object.entries(icons)) {
      this.iconRegistry.addSvgIconLiteral(key, this.sanitizer.bypassSecurityTrustHtml(value));
    }
  }
}
