import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

export interface QrCodeModalComponentData {
  qrCodePaths: string | string[];
  modalTitles?: string | string[];
  buttonText?: string;
}

@Component({
  selector: 'csi-multi-qr-code-modal',
  templateUrl: './multi-qr-code-modal.component.html',
  styleUrls: ['./multi-qr-code-modal.component.css']
})
export class MultiQrCodeModalComponent implements OnInit {
  codes: string[] = [];
  titles: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<MultiQrCodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QrCodeModalComponentData
  ) {}

  static open(dialog: MatDialog, data: QrCodeModalComponentData) {
    return dialog.open(MultiQrCodeModalComponent, { data });
  }

  ngOnInit() {
    this.codes = Array.isArray(this.data.qrCodePaths)
      ? this.data.qrCodePaths
      : safelyParse(this.data.qrCodePaths);
    this.titles = Array.isArray(this.data.modalTitles)
      ? this.data.modalTitles
      : safelyParse(this.data.modalTitles);
  }

  onCopy(index: number) {
    navigator.clipboard.writeText(this.codes[index]);
  }

  downloadQrCode(qrCode) {
    const svg = qrCode.qrcElement.nativeElement.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.getContext('2d').drawImage(img, 0, 0);
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'qrCode.png';
      link.href = url;
      link.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  }
}

function safelyParse(input?: string | string[]): string[] {
  if (!input) return [];
  if (Array.isArray(input)) return input;
  try {
    return JSON.parse(input);
  } catch {
    return [input];
  }
}
