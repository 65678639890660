export type RegionLabel = 'Province' | 'State';
export type LocationLabel = 'Country' | RegionLabel;

export interface Locations {
  list: Location[];
  label: LocationLabel;
}

export interface Location {
  synonyms: string[];
  name: string;
}

export enum HealthCardMode {
  provinceAndNumber = 'province and number',
  numberOnly = 'number only',
  noHealthCard = 'disable all'
}

export const LocationHealthCardModeMap = {
  CA: HealthCardMode.provinceAndNumber,
  GB: HealthCardMode.numberOnly,
  US: HealthCardMode.noHealthCard,
  CL: HealthCardMode.numberOnly
};
