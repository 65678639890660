/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubscription = /* GraphQL */ `
  mutation CreateSubscription(
    $clinicId: String!
    $name: String!
    $email: String!
    $cardToken: String!
    $customerId: String
  ) {
    createSubscription(
      clinicId: $clinicId
      name: $name
      email: $email
      cardToken: $cardToken
      customerId: $customerId
    ) {
      subscriptionId
      customerId
      lastDigitsOfCard
      nextPaymentDate
      nameOnCard
      subscriptionStatus
    }
  }
`;
export const updatePaymentInformation = /* GraphQL */ `
  mutation UpdatePaymentInformation(
    $customerId: String!
    $clinicId: String!
    $name: String!
    $cardToken: String!
  ) {
    updatePaymentInformation(
      customerId: $customerId
      clinicId: $clinicId
      name: $name
      cardToken: $cardToken
    ) {
      subscriptionId
      customerId
      lastDigitsOfCard
      nextPaymentDate
      nameOnCard
      subscriptionStatus
    }
  }
`;
export const reactivateSubscription = /* GraphQL */ `
  mutation ReactivateSubscription(
    $clinicId: String!
    $customerId: String
    $subscriptionId: String
  ) {
    reactivateSubscription(
      clinicId: $clinicId
      customerId: $customerId
      subscriptionId: $subscriptionId
    ) {
      subscriptionId
      customerId
      lastDigitsOfCard
      nextPaymentDate
      nameOnCard
      subscriptionStatus
    }
  }
`;
export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription($clinicId: String!, $subscriptionId: String!) {
    cancelSubscription(clinicId: $clinicId, subscriptionId: $subscriptionId) {
      subscriptionId
      customerId
      lastDigitsOfCard
      nextPaymentDate
      nameOnCard
      subscriptionStatus
    }
  }
`;
export const addStaff = /* GraphQL */ `
  mutation AddStaff(
    $clinicId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $staffType: String!
    $clinicSetupId: String
  ) {
    addStaff(
      clinicId: $clinicId
      firstName: $firstName
      lastName: $lastName
      email: $email
      staffType: $staffType
      clinicSetupId: $clinicSetupId
    )
  }
`;
export const updateStaffType = /* GraphQL */ `
  mutation UpdateStaffType(
    $id: String!
    $staffType: StaffType!
    $clinicId: String!
    $clinicSetupId: String
  ) {
    updateStaffType(
      id: $id
      staffType: $staffType
      clinicId: $clinicId
      clinicSetupId: $clinicSetupId
    )
  }
`;
export const deactivateStaff = /* GraphQL */ `
  mutation DeactivateStaff($staffId: String!) {
    deactivateStaff(staffId: $staffId)
  }
`;
export const reactivateStaff = /* GraphQL */ `
  mutation ReactivateStaff($staffId: String!) {
    reactivateStaff(staffId: $staffId)
  }
`;
export const createClinic = /* GraphQL */ `
  mutation CreateClinic($input: CreateClinicInput!) {
    createClinic(input: $input) {
      id
      name
      address
      province
      country
      city
      postalCode
      phone
      fax
      logo {
        bucket
        key
        region
        fileName
        metadata
      }
      doctors {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      emrIntegration {
        fax {
          enabled
          faxNumber
        }
      }
      stripeInformation {
        subscriptionId
        customerId
        lastDigitsOfCard
        nextPaymentDate
        nameOnCard
        subscriptionStatus
      }
      clinicSetup {
        id
        clinicSetupClinicId
        textFavorites {
          nextToken
        }
        treatmentTemplates {
          nextToken
        }
        doctorLicenses
        layouts {
          id
          name
          alwaysExpand
          customLayout
          symptomSettings
        }
        interactionsSetup
        defaultQuestionnaireLanguage
        defaultPdfLanguage
        temperatureUnit
        questionnaireExpiry
        welcomeMessageConfig
        emailNotificationsConfig
        hiddenTextFavorites
        hiddenTreatmentTemplates
        textFavoriteConfig
        createdAt
        updatedAt
      }
      agreedToTermsOfService
      referralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      linkedReferralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      clinicLinkedReferralCenterId
      clinicFormTemplates {
        items {
          id
          schema
          formTemplateClinicId
          createdAt
          updatedAt
        }
        nextToken
      }
      hasDryEyePortalAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateClinic = /* GraphQL */ `
  mutation UpdateClinic($input: UpdateClinicInput!) {
    updateClinic(input: $input) {
      id
      name
      address
      province
      country
      city
      postalCode
      phone
      fax
      logo {
        bucket
        key
        region
        fileName
        metadata
      }
      doctors {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      emrIntegration {
        fax {
          enabled
          faxNumber
        }
      }
      stripeInformation {
        subscriptionId
        customerId
        lastDigitsOfCard
        nextPaymentDate
        nameOnCard
        subscriptionStatus
      }
      clinicSetup {
        id
        clinicSetupClinicId
        textFavorites {
          nextToken
        }
        treatmentTemplates {
          nextToken
        }
        doctorLicenses
        layouts {
          id
          name
          alwaysExpand
          customLayout
          symptomSettings
        }
        interactionsSetup
        defaultQuestionnaireLanguage
        defaultPdfLanguage
        temperatureUnit
        questionnaireExpiry
        welcomeMessageConfig
        emailNotificationsConfig
        hiddenTextFavorites
        hiddenTreatmentTemplates
        textFavoriteConfig
        createdAt
        updatedAt
      }
      agreedToTermsOfService
      referralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      linkedReferralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      clinicLinkedReferralCenterId
      clinicFormTemplates {
        items {
          id
          schema
          formTemplateClinicId
          createdAt
          updatedAt
        }
        nextToken
      }
      hasDryEyePortalAccess
      createdAt
      updatedAt
    }
  }
`;
export const createClinicSetup = /* GraphQL */ `
  mutation CreateClinicSetup($input: CreateClinicSetupInput!) {
    createClinicSetup(input: $input) {
      id
      clinicSetupClinicId
      textFavorites {
        items {
          id
          shortcut
          text
          type
          textFavoriteTreatmentTemplateId
          textFavoriteClinicId
          copiedTextFavoriteId
          createdAt
          updatedAt
        }
        nextToken
      }
      treatmentTemplates {
        items {
          id
          friendlyId
          otcEyeDrops
          rxEyeDrops
          procedures
          exercises
          treatmentTemplateClinicId
          copiedTreatmentTemplateId
          createdAt
          updatedAt
        }
        nextToken
      }
      doctorLicenses
      layouts {
        id
        name
        medicalHistoryLayout {
          show
          hide
        }
        signsLayout {
          show
          hide
        }
        alwaysExpand
        customLayout
        symptomSettings
      }
      interactionsSetup
      defaultQuestionnaireLanguage
      defaultPdfLanguage
      temperatureUnit
      questionnaireExpiry
      welcomeMessageConfig
      emailNotificationsConfig
      hiddenTextFavorites
      hiddenTreatmentTemplates
      textFavoriteConfig
      createdAt
      updatedAt
    }
  }
`;
export const updateClinicSetup = /* GraphQL */ `
  mutation UpdateClinicSetup($input: UpdateClinicSetupInput!) {
    updateClinicSetup(input: $input) {
      id
      clinicSetupClinicId
      textFavorites {
        items {
          id
          shortcut
          text
          type
          textFavoriteTreatmentTemplateId
          textFavoriteClinicId
          copiedTextFavoriteId
          createdAt
          updatedAt
        }
        nextToken
      }
      treatmentTemplates {
        items {
          id
          friendlyId
          otcEyeDrops
          rxEyeDrops
          procedures
          exercises
          treatmentTemplateClinicId
          copiedTreatmentTemplateId
          createdAt
          updatedAt
        }
        nextToken
      }
      doctorLicenses
      layouts {
        id
        name
        medicalHistoryLayout {
          show
          hide
        }
        signsLayout {
          show
          hide
        }
        alwaysExpand
        customLayout
        symptomSettings
      }
      interactionsSetup
      defaultQuestionnaireLanguage
      defaultPdfLanguage
      temperatureUnit
      questionnaireExpiry
      welcomeMessageConfig
      emailNotificationsConfig
      hiddenTextFavorites
      hiddenTreatmentTemplates
      textFavoriteConfig
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      properties {
        doctorId
        status {
          read
          flag
        }
      }
      type
      typeId
      content
      notificationClinicId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      properties {
        doctorId
        status {
          read
          flag
        }
      }
      type
      typeId
      content
      notificationClinicId
      createdAt
      updatedAt
    }
  }
`;
export const updateIntakeForm = /* GraphQL */ `
  mutation UpdateIntakeForm($input: UpdateIntakeFormInput!) {
    UpdateIntakeForm(input: $input) {
      id
      response
      createdAt
      intakeFormClinicId
      intakeFormPatientId
      statusId
      status
      lastRead
      updatedAt
    }
  }
`;
export const createInteraction = /* GraphQL */ `
  mutation CreateInteraction($input: CreateInteractionInput!) {
    createInteraction(input: $input) {
      id
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      type
      status
      comments
      createdAt
      updatedAt
      surgeryType
      appointmentDate
      attemptFailed
      communicationMethod
      interactionClinicId
    }
  }
`;
export const updateInteraction = /* GraphQL */ `
  mutation UpdateInteraction($input: UpdateInteractionInput!) {
    updateInteraction(input: $input) {
      id
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      type
      status
      comments
      createdAt
      updatedAt
      surgeryType
      appointmentDate
      attemptFailed
      communicationMethod
      interactionClinicId
    }
  }
`;
export const createTextFavorite = /* GraphQL */ `
  mutation CreateTextFavorite($input: CreateTextFavoriteInput!) {
    createTextFavorite(input: $input) {
      id
      shortcut
      text
      type
      treatmentTemplate {
        id
        friendlyId
        otcEyeDrops
        rxEyeDrops
        procedures
        exercises
        treatmentTemplateClinicId
        copiedTreatmentTemplateId
        createdAt
        updatedAt
      }
      textFavoriteTreatmentTemplateId
      textFavoriteClinicId
      copiedTextFavoriteId
      createdAt
      updatedAt
    }
  }
`;
export const updateTextFavorite = /* GraphQL */ `
  mutation UpdateTextFavorite($input: UpdateTextFavoriteInput!) {
    updateTextFavorite(input: $input) {
      id
      shortcut
      text
      type
      treatmentTemplate {
        id
        friendlyId
        otcEyeDrops
        rxEyeDrops
        procedures
        exercises
        treatmentTemplateClinicId
        copiedTreatmentTemplateId
        createdAt
        updatedAt
      }
      textFavoriteTreatmentTemplateId
      textFavoriteClinicId
      copiedTextFavoriteId
      createdAt
      updatedAt
    }
  }
`;
export const deleteTextFavorite = /* GraphQL */ `
  mutation DeleteTextFavorite($input: DeleteTextFavoriteInput!) {
    deleteTextFavorite(input: $input) {
      id
      shortcut
      text
      type
      treatmentTemplate {
        id
        friendlyId
        otcEyeDrops
        rxEyeDrops
        procedures
        exercises
        treatmentTemplateClinicId
        copiedTreatmentTemplateId
        createdAt
        updatedAt
      }
      textFavoriteTreatmentTemplateId
      textFavoriteClinicId
      copiedTextFavoriteId
      createdAt
      updatedAt
    }
  }
`;
export const createTreatmentTemplate = /* GraphQL */ `
  mutation CreateTreatmentTemplate($input: CreateTreatmentTemplateInput!) {
    createTreatmentTemplate(input: $input) {
      id
      friendlyId
      otcEyeDrops
      rxEyeDrops
      procedures
      exercises
      treatmentTemplateClinicId
      copiedTreatmentTemplateId
      createdAt
      updatedAt
    }
  }
`;
export const updateTreatmentTemplate = /* GraphQL */ `
  mutation UpdateTreatmentTemplate($input: UpdateTreatmentTemplateInput!) {
    updateTreatmentTemplate(input: $input) {
      id
      friendlyId
      otcEyeDrops
      rxEyeDrops
      procedures
      exercises
      treatmentTemplateClinicId
      copiedTreatmentTemplateId
      createdAt
      updatedAt
    }
  }
`;
export const deleteTreatmentTemplate = /* GraphQL */ `
  mutation DeleteTreatmentTemplate($input: DeleteTreatmentTemplateInput!) {
    deleteTreatmentTemplate(input: $input) {
      id
      friendlyId
      otcEyeDrops
      rxEyeDrops
      procedures
      exercises
      treatmentTemplateClinicId
      copiedTreatmentTemplateId
      createdAt
      updatedAt
    }
  }
`;
export const createMassClinic = /* GraphQL */ `
  mutation CreateMassClinic($input: CreateMassClinicInput!) {
    createMassClinic(input: $input) {
      id
      massClinicClinicId
      date
      massClinicPatients {
        items {
          id
          massClinicPatientClinicId
          isCancelled
          createdAt
          updatedAt
        }
        nextToken
      }
      isCancelled
      createdAt
      updatedAt
    }
  }
`;
export const updateMassClinic = /* GraphQL */ `
  mutation UpdateMassClinic($input: UpdateMassClinicInput!) {
    updateMassClinic(input: $input) {
      id
      massClinicClinicId
      date
      massClinicPatients {
        items {
          id
          massClinicPatientClinicId
          isCancelled
          createdAt
          updatedAt
        }
        nextToken
      }
      isCancelled
      createdAt
      updatedAt
    }
  }
`;
export const createMassClinicPatient = /* GraphQL */ `
  mutation CreateMassClinicPatient($input: CreateMassClinicPatientInput!) {
    createMassClinicPatient(input: $input) {
      id
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      massClinicPatientClinicId
      massClinic {
        id
        massClinicClinicId
        date
        massClinicPatients {
          nextToken
        }
        isCancelled
        createdAt
        updatedAt
      }
      isCancelled
      createdAt
      updatedAt
      questionnaireRequest {
        id
        email
        mobile
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        massClinicPatient {
          id
          massClinicPatientClinicId
          isCancelled
          createdAt
          updatedAt
        }
        assessment {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        questionnaireRequestPatientId
        type
        response {
          answers
          scores
        }
        country
        consentsToPrivacyForm
        consentSource
        consentsToResearch
        completedAt
        createdAt
        updatedAt
        nonModifiableData
        language
        config
      }
    }
  }
`;
export const updateMassClinicPatient = /* GraphQL */ `
  mutation UpdateMassClinicPatient($input: UpdateMassClinicPatientInput!) {
    updateMassClinicPatient(input: $input) {
      id
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      massClinicPatientClinicId
      massClinic {
        id
        massClinicClinicId
        date
        massClinicPatients {
          nextToken
        }
        isCancelled
        createdAt
        updatedAt
      }
      isCancelled
      createdAt
      updatedAt
      questionnaireRequest {
        id
        email
        mobile
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        massClinicPatient {
          id
          massClinicPatientClinicId
          isCancelled
          createdAt
          updatedAt
        }
        assessment {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        questionnaireRequestPatientId
        type
        response {
          answers
          scores
        }
        country
        consentsToPrivacyForm
        consentSource
        consentsToResearch
        completedAt
        createdAt
        updatedAt
        nonModifiableData
        language
        config
      }
    }
  }
`;
export const createStaffClinicReference = /* GraphQL */ `
  mutation CreateStaffClinicReference(
    $input: CreateStaffClinicReferenceInput!
  ) {
    createStaffClinicReference(input: $input) {
      id
      staffId
      clinicId
      clinicOwnerId
      staffTypes
      createdAt
      updatedAt
    }
  }
`;
export const deleteStaffClinicReference = /* GraphQL */ `
  mutation DeleteStaffClinicReference(
    $input: DeleteStaffClinicReferenceInput!
  ) {
    deleteStaffClinicReference(input: $input) {
      id
      staffId
      clinicId
      clinicOwnerId
      staffTypes
      createdAt
      updatedAt
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor($input: CreateDoctorInput!) {
    createDoctor(input: $input) {
      id
      firstName
      lastName
      email
      emailVerified
      agreedToTerms
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      cognitoGroups
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      practitionerId
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      assessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      inboundAssessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deactivated
      staffType
      clinicLicenses
      cameraAssessmentRequest
      ownedClinics
      allowMultipleClinics
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor($input: UpdateDoctorInput!) {
    updateDoctor(input: $input) {
      id
      firstName
      lastName
      email
      emailVerified
      agreedToTerms
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      cognitoGroups
      patients {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
      practitionerId
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      assessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      inboundAssessmentRequests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deactivated
      staffType
      clinicLicenses
      cameraAssessmentRequest
      ownedClinics
      allowMultipleClinics
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      id
      firstName
      lastName
      email
      emailVerified
      phone
      agreedToTerms
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      patientClinicId
      dateOfBirth
      healthCard {
        province
        number
        country
      }
      gender
      genderOther
      address
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      consentsToResearch
      consentsToPrivacyForm
      consentSource
      createdAt
      updatedAt
      linkedReferralPatientId
      referralSource
      nonModifiableData
      interactions {
        items {
          id
          type
          status
          comments
          createdAt
          updatedAt
          surgeryType
          appointmentDate
          attemptFailed
          communicationMethod
          interactionClinicId
        }
        nextToken
      }
      lastInteractionAt
      isColdLead
      fitzpatrickImage {
        bucket
        key
        region
        fileName
        metadata
      }
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
        nextToken
      }
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      id
      firstName
      lastName
      email
      emailVerified
      phone
      agreedToTerms
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      patientClinicId
      dateOfBirth
      healthCard {
        province
        number
        country
      }
      gender
      genderOther
      address
      assessments {
        items {
          id
          assessmentClinicId
          type
          body
          locked
          createdAt
          updatedAt
        }
        nextToken
      }
      consentsToResearch
      consentsToPrivacyForm
      consentSource
      createdAt
      updatedAt
      linkedReferralPatientId
      referralSource
      nonModifiableData
      interactions {
        items {
          id
          type
          status
          comments
          createdAt
          updatedAt
          surgeryType
          appointmentDate
          attemptFailed
          communicationMethod
          interactionClinicId
        }
        nextToken
      }
      lastInteractionAt
      isColdLead
      fitzpatrickImage {
        bucket
        key
        region
        fileName
        metadata
      }
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
        nextToken
      }
    }
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment($input: CreateAssessmentInput!) {
    createAssessment(input: $input) {
      id
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      assessmentClinicId
      requests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      locked
      createdAt
      updatedAt
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
        nextToken
      }
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    updateAssessment(input: $input) {
      id
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      assessmentClinicId
      requests {
        items {
          id
          type
          response
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      locked
      createdAt
      updatedAt
      questionnaireRequests {
        items {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
        nextToken
      }
    }
  }
`;
export const createReferralCenter = /* GraphQL */ `
  mutation CreateReferralCenter($input: CreateReferralCenterInput!) {
    createReferralCenter(input: $input) {
      id
      name
      logo {
        bucket
        key
        region
        fileName
        metadata
      }
      referralCenterClinicId
      diseaseAreas
      notificationRecipients
      referralForms {
        name
        file {
          bucket
          key
          region
          fileName
          metadata
        }
        type
      }
      agreement
      copaymentEmbedLink
      educationEmbedLink
      advancedReferralCenter
      createdAt
      updatedAt
    }
  }
`;
export const updateReferralCenter = /* GraphQL */ `
  mutation UpdateReferralCenter($input: UpdateReferralCenterInput!) {
    updateReferralCenter(input: $input) {
      id
      name
      logo {
        bucket
        key
        region
        fileName
        metadata
      }
      referralCenterClinicId
      diseaseAreas
      notificationRecipients
      referralForms {
        name
        file {
          bucket
          key
          region
          fileName
          metadata
        }
        type
      }
      agreement
      copaymentEmbedLink
      educationEmbedLink
      advancedReferralCenter
      createdAt
      updatedAt
    }
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      id
      referralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      referringClinicId
      referralCenterClinicId
      referralReferralCenterId
      referralPatientId
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      priority
      openedAt
      status
      referralCenterResponse {
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        comment
        responseTime
      }
      comment
      feedbackHistory
      createdAt
      updatedAt
      type
      parentReferralId
    }
  }
`;
export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral($input: UpdateReferralInput!) {
    updateReferral(input: $input) {
      id
      referralCenter {
        id
        name
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        referralCenterClinicId
        diseaseAreas
        notificationRecipients
        referralForms {
          name
          type
        }
        agreement
        copaymentEmbedLink
        educationEmbedLink
        advancedReferralCenter
        createdAt
        updatedAt
      }
      referringClinicId
      referralCenterClinicId
      referralReferralCenterId
      referralPatientId
      body
      attachments {
        bucket
        key
        region
        fileName
        metadata
      }
      priority
      openedAt
      status
      referralCenterResponse {
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        comment
        responseTime
      }
      comment
      feedbackHistory
      createdAt
      updatedAt
      type
      parentReferralId
    }
  }
`;
export const createAssessmentRequest = /* GraphQL */ `
  mutation CreateAssessmentRequest($input: CreateAssessmentRequestInput!) {
    createAssessmentRequest(input: $input) {
      id
      type
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      recipient {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      assessment {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      response
      priority
      createdAt
      updatedAt
    }
  }
`;
export const updateAssessmentRequest = /* GraphQL */ `
  mutation UpdateAssessmentRequest($input: UpdateAssessmentRequestInput!) {
    updateAssessmentRequest(input: $input) {
      id
      type
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      recipient {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      assessment {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      response
      priority
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionnaireRequest = /* GraphQL */ `
  mutation CreateQuestionnaireRequest(
    $input: CreateQuestionnaireRequestInput!
  ) {
    createQuestionnaireRequest(input: $input) {
      id
      email
      mobile
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      massClinicPatient {
        id
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        massClinicPatientClinicId
        massClinic {
          id
          massClinicClinicId
          date
          isCancelled
          createdAt
          updatedAt
        }
        isCancelled
        createdAt
        updatedAt
        questionnaireRequest {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
      }
      assessment {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      questionnaireRequestPatientId
      type
      response {
        answers
        scores
        consent {
          consentsToPrivacyForm
          consentsToResearch
          agreedToTerms
          consentSource
        }
      }
      country
      consentsToPrivacyForm
      consentSource
      consentsToResearch
      completedAt
      createdAt
      updatedAt
      nonModifiableData
      language
      config
    }
  }
`;
export const createReminder = /* GraphQL */ `
  mutation CreateReminder($input: CreateReminderInput!) {
    createReminder(input: $input) {
      id
      reminderPatientId
      reminderClinicId
      reminderStaffId
      clinicName
      doctorName
      patientName
      nextReminderDate
      expirationDate
      numberOfReminders
      email
      phone
      reminderType
      config
      createdAt
      updatedAt
    }
  }
`;
export const updateReminder = /* GraphQL */ `
  mutation UpdateReminder($input: UpdateReminderInput!) {
    updateReminder(input: $input) {
      id
      reminderPatientId
      reminderClinicId
      reminderStaffId
      clinicName
      doctorName
      patientName
      nextReminderDate
      expirationDate
      numberOfReminders
      email
      phone
      reminderType
      config
      createdAt
      updatedAt
    }
  }
`;
export const deleteReminder = /* GraphQL */ `
  mutation DeleteReminder($input: DeleteReminderInput!) {
    deleteReminder(input: $input) {
      id
      reminderPatientId
      reminderClinicId
      reminderStaffId
      clinicName
      doctorName
      patientName
      nextReminderDate
      expirationDate
      numberOfReminders
      email
      phone
      reminderType
      config
      createdAt
      updatedAt
    }
  }
`;
export const createIntakeForm = /* GraphQL */ `
  mutation CreateIntakeForm($input: CreateIntakeFormInput!) {
    CreateIntakeForm(input: $input) {
      id
      response
      createdAt
      intakeFormClinicId
      intakeFormPatientId
      statusId
      status
      lastRead
      updatedAt
    }
  }
`;
export const updateQuestionnaireRequest = /* GraphQL */ `
  mutation UpdateQuestionnaireRequest(
    $input: UpdateQuestionnaireRequestInput!
  ) {
    updateQuestionnaireRequest(input: $input) {
      id
      email
      mobile
      doctor {
        id
        firstName
        lastName
        email
        emailVerified
        agreedToTerms
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        cognitoGroups
        patients {
          nextToken
        }
        practitionerId
        assessments {
          nextToken
        }
        assessmentRequests {
          nextToken
        }
        inboundAssessmentRequests {
          nextToken
        }
        createdAt
        updatedAt
        deactivated
        staffType
        clinicLicenses
        cameraAssessmentRequest
        ownedClinics
        allowMultipleClinics
      }
      clinic {
        id
        name
        address
        province
        country
        city
        postalCode
        phone
        fax
        logo {
          bucket
          key
          region
          fileName
          metadata
        }
        doctors {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patients {
          nextToken
        }
        stripeInformation {
          subscriptionId
          customerId
          lastDigitsOfCard
          nextPaymentDate
          nameOnCard
          subscriptionStatus
        }
        clinicSetup {
          id
          clinicSetupClinicId
          doctorLicenses
          interactionsSetup
          defaultQuestionnaireLanguage
          defaultPdfLanguage
          temperatureUnit
          questionnaireExpiry
          welcomeMessageConfig
          emailNotificationsConfig
          hiddenTextFavorites
          hiddenTreatmentTemplates
          textFavoriteConfig
          createdAt
          updatedAt
        }
        agreedToTermsOfService
        referralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        linkedReferralCenter {
          id
          name
          referralCenterClinicId
          diseaseAreas
          notificationRecipients
          agreement
          copaymentEmbedLink
          educationEmbedLink
          advancedReferralCenter
          createdAt
          updatedAt
        }
        clinicLinkedReferralCenterId
        clinicFormTemplates {
          nextToken
        }
        hasDryEyePortalAccess
        createdAt
        updatedAt
      }
      massClinicPatient {
        id
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        massClinicPatientClinicId
        massClinic {
          id
          massClinicClinicId
          date
          isCancelled
          createdAt
          updatedAt
        }
        isCancelled
        createdAt
        updatedAt
        questionnaireRequest {
          id
          email
          mobile
          questionnaireRequestPatientId
          type
          country
          consentsToPrivacyForm
          consentSource
          consentsToResearch
          completedAt
          createdAt
          updatedAt
          nonModifiableData
          language
          config
        }
      }
      assessment {
        id
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        patient {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          gender
          genderOther
          address
          consentsToResearch
          consentsToPrivacyForm
          consentSource
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          lastInteractionAt
          isColdLead
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        assessmentClinicId
        requests {
          nextToken
        }
        type
        body
        attachments {
          bucket
          key
          region
          fileName
          metadata
        }
        locked
        createdAt
        updatedAt
        questionnaireRequests {
          nextToken
        }
      }
      patient {
        id
        firstName
        lastName
        email
        emailVerified
        phone
        agreedToTerms
        doctor {
          id
          firstName
          lastName
          email
          emailVerified
          agreedToTerms
          cognitoGroups
          practitionerId
          createdAt
          updatedAt
          deactivated
          staffType
          clinicLicenses
          cameraAssessmentRequest
          ownedClinics
          allowMultipleClinics
        }
        clinic {
          id
          name
          address
          province
          country
          city
          postalCode
          phone
          fax
          agreedToTermsOfService
          clinicLinkedReferralCenterId
          hasDryEyePortalAccess
          createdAt
          updatedAt
        }
        patientClinicId
        dateOfBirth
        healthCard {
          province
          number
          country
        }
        gender
        genderOther
        address
        assessments {
          nextToken
        }
        consentsToResearch
        consentsToPrivacyForm
        consentSource
        createdAt
        updatedAt
        linkedReferralPatientId
        referralSource
        nonModifiableData
        interactions {
          nextToken
        }
        lastInteractionAt
        isColdLead
        fitzpatrickImage {
          bucket
          key
          region
          fileName
          metadata
        }
        questionnaireRequests {
          nextToken
        }
      }
      questionnaireRequestPatientId
      type
      response {
        answers
        scores
        consent {
          consentsToPrivacyForm
          consentsToResearch
          agreedToTerms
          consentSource
        }
      }
      country
      consentsToPrivacyForm
      consentSource
      consentsToResearch
      completedAt
      createdAt
      updatedAt
      nonModifiableData
      language
      config
    }
  }
`;
