import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { gql } from 'graphql-tag';
// import * as zlib from 'zlib';
import * as pako from 'pako';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetFilteredDataQuery, Priority, ReferralType } from 'src/API';
import { ReferralService } from 'src/app/referral/referral.service';
import { SchemaService } from 'src/app/shared/symptoms/services/schema/schema.service';
import { getFilteredData } from 'src/graphql/queries';
import { FilterType, Gender, GetFilteredDataQueryVariables } from './../../API';
import { AppSyncService } from './../core/appsync.service';
import { ClinicSetupService } from './../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import {
  FilterFieldSchema,
  FilterFieldType,
  FilterGroup,
  commonFilterFieldConfig
} from './filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  dataGroupFilterFieldMap: FilterFieldSchema = {
    assessment: {
      createdAt: commonFilterFieldConfig.calender('Created At'),
      updatedAt: commonFilterFieldConfig.calender('Updated At'),
      ...Object.keys(this.schemaService.symptomMap).reduce((acc, key) => {
        const fieldConfig = commonFilterFieldConfig.sign(this.schemaService.symptomMap, key);
        return {
          ...acc,
          [key]: fieldConfig
        };
      }, {}),
      conditions: {
        label: 'Conditions',
        operators: ['contains'],
        typeConfig: {
          type: 'checkbox',
          options: {
            ...this.schemaService.checkboxConditionMap,
            ...this.schemaService.medicalConditionMap,
            ...this.schemaService.environmentalSymptomMap
          }
        }
      },
      ...Object.entries(this.schemaService.radioConditionMap).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            label: value.name,
            operators: ['equals'],
            typeConfig: {
              type: 'dropdown',
              options: value.values
            }
          }
        }),
        {}
      )
    },
    referral: {
      createdAt: commonFilterFieldConfig.calender('Created At'),
      'patient.firstName': commonFilterFieldConfig.text('Patient First Name'),
      'patient.lastName': commonFilterFieldConfig.text('Patient Last Name'),
      'doctor.firstName': commonFilterFieldConfig.text('Doctor First Name'),
      'doctor.lastName': commonFilterFieldConfig.text('Doctor Last Name'),
      'clinic.name': commonFilterFieldConfig.text('Referring Clinic Name'),
      status: {
        label: 'Status',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: this.referralService.referralStatus.reduce(
            (acc, status) => ({ ...acc, status }),
            {}
          )
        }
      },
      priority: {
        label: 'Priority',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: Object.keys(Priority).reduce(
            (acc, priority) => ({ ...acc, [priority]: priority.split('_').join(' ') }),
            {}
          )
        }
      },
      diseaseArea: commonFilterFieldConfig.text('Disease Area'),
      feedbackHistory: commonFilterFieldConfig.text('Feedback History'),
      type: {
        label: 'Referral Type',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: Object.keys(ReferralType).reduce(
            (acc, referralType) => ({
              ...acc,
              [referralType]: this.referralService.referralTypeFriendlyMap[referralType]
            }),
            {}
          )
        }
      }
    },
    patient: {
      firstName: commonFilterFieldConfig.text('First Name'),
      lastName: commonFilterFieldConfig.text('Health Card'),
      phone: commonFilterFieldConfig.text('Phone'),
      email: commonFilterFieldConfig.text('Email'),
      dateOfBirth: commonFilterFieldConfig.calender('Date Of Birth'),
      gender: {
        label: 'Referral Type',
        operators: ['equals', 'notEqual'],
        typeConfig: {
          type: FilterFieldType.dropdown,
          options: Object.keys(Gender).reduce((acc, gender) => ({ ...acc, [gender]: gender }), {})
        }
      },
      createdAt: commonFilterFieldConfig.calender('Added On')
    }
  };

  public filterTypeCachedFilterMap: {
    [k in FilterType]: { data: { [key: string]: any } };
  } = {
    patient: { data: {} },
    assessment: { data: {} },
    referral: { data: {} },
    doctor: { data: {} },
    intakeForm: { data: {} },
    questionnaireRequest: { data: {} }
  };

  public lowerKeyMap = {};

  constructor(
    private schemaService: SchemaService,
    private referralService: ReferralService,
    private appsyncService: AppSyncService,
    private clinicSetupService: ClinicSetupService
  ) {}

  queryFilterData(type: FilterType, filter: FilterGroup[]): Observable<any[]> {
    const fetchAllData = Object.keys(this.filterTypeCachedFilterMap[type].data).length <= 0;

    return this.appsyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(getFilteredData),
          variables: {
            type,
            filter: JSON.stringify({ fetch: fetchAllData ? 'all' : 'updates' }),
            clinicId: this.clinicSetupService.clinicId
          } as GetFilteredDataQueryVariables,
          fetchPolicy: 'network-only'
        })
      ),
      map((result: ApolloQueryResult<GetFilteredDataQuery>) =>
        JSON.parse(
          pako.inflate(JSON.parse(result.data.getFilteredData).data, {
            to: 'string'
          })
        )
      ),
      map((data: any[]) => {
        data.forEach(item => {
          if (item && item.id) {
            this.filterTypeCachedFilterMap[type].data[item.id] = item;
          } else {
            console.log('ERROR', item);
          }
        });
        // console.log(
        //   'filter query',
        //   [...Object.values(this.filterTypeCachedFilterMap[type].data)],
        //   filter
        // );
        if (filter) {
          return this.filterData(
            [...Object.values(this.filterTypeCachedFilterMap[type].data)],
            filter
          );
        }

        return [...Object.values(this.filterTypeCachedFilterMap[type].data)];
      })
    );
  }

  filterData(dataItems, filter) {
    return dataItems.filter(item => {
      const e = this.evaluateFilter(item, filter);
      return e;
    });
  }

  evaluateFilter(item, filter) {
    if (!filter || !filter.length) {
      return true;
    }

    const stack = [...filter];
    let filtersMatched = false;
    while (stack.length > 0) {
      const filterGroup = stack.pop();

      if (filterGroup.field) {
        const partialKey = filterGroup.field.toLowerCase();
        filtersMatched = Object.keys(item).some(itemKey => {
          this.lowerKeyMap[itemKey] = this.lowerKeyMap[itemKey] || itemKey.toLowerCase();
          return (
            this.lowerKeyMap[itemKey].endsWith(partialKey) &&
            this.evaluateCondition(item[itemKey], filterGroup)
          );
        });
        return filtersMatched;
      } else if (filterGroup.type && filterGroup.filters) {
        // For "and", all sub-filters must match
        // For "or", at least one sub-filter must match
        let subFiltersMatch = false;

        for (const subFilter of filterGroup.filters) {
          if (this.evaluateFilter(item, [subFilter])) {
            subFiltersMatch = true;
            if (filterGroup.type === 'or') {
              break; // Exit early for "or"
            }
          } else if (filterGroup.type === 'and') {
            subFiltersMatch = false; // Reset for "and"
            break;
          }
        }

        if (subFiltersMatch) {
          return true;
        }
      }
    }
    return false;
  }

  evaluateCondition(actualValue, condition) {
    try {
      if (!actualValue) {
        return false;
      }

      let { operator, searchValue } = condition;
      searchValue = searchValue.trim();

      if (
        ['greaterThan', 'lessThan', 'greaterThanOrEqualTo', 'lessThanOrEqualTo'].includes(operator)
      ) {
        actualValue = new Date(actualValue).getTime() || parseInt(actualValue);
        searchValue = new Date(searchValue).getTime() || parseInt(searchValue);

        if (!actualValue || !searchValue) {
          return false;
        }
      }

      switch (operator) {
        case 'equals':
          return actualValue === searchValue;
        case 'contains':
          return actualValue.includes(searchValue);
        case 'notEqual':
          return actualValue !== searchValue;
        case 'notContains':
          return !actualValue.includes(searchValue);
        case 'startsWith':
          return actualValue.startsWith(searchValue);
        case 'endsWith':
          return actualValue.endsWith(searchValue);
        case 'greaterThan':
          return actualValue > searchValue;
        case 'lessThan':
          return actualValue < searchValue;
        case 'greaterThanOrEqualTo':
          return actualValue >= searchValue;
        case 'lessThanOrEqualTo':
          return actualValue <= searchValue;
        default:
          console.error(`Unsupported operator: ${operator}`);
          return false;
      }
    } catch (e) {
      console.log('Error', e);
      return false;
    }
  }
}
