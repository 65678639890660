import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import {
  FalseOcularSurfaceDiseaseCondition,
  InterpretationConditions,
  InterpretationView
} from './interpretation.model';
import { InterpretationService } from './interpretation.service';

interface InterpretationData {
  patientData: any;
  impressionsControl: FormControl;
}

@Component({
  selector: 'csi-interpretation',
  templateUrl: './interpretation.component.html',
  styleUrls: ['./interpretation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterpretationComponent {
  @ViewChild('interpretationEl', { static: false }) interpretationEl: ElementRef;
  public trueOcularSurfaceDiseaseEvidence: string[];
  public falseOcularSurfaceDiseaseEvidence: string[];
  public falseOcularSurfaceDiseaseCondition: FalseOcularSurfaceDiseaseCondition;
  public interpretationView: InterpretationView = 'text';
  public hasDryEyeMimickers = false;
  public isCopied: boolean;
  public modalView = true;
  public lang: string = this.translocoService.getActiveLang();
  public translateObject = this.translocoService.getTranslation(
    this.translocoService.getActiveLang()
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InterpretationData,
    public translocoService: TranslocoService,
    private interpretationService: InterpretationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isOcularSurfaceDisease();
  }

  isOcularSurfaceDisease() {
    let falseOSDSignsWithoutSymptoms: string[];
    let falseOSDSymptomsWithoutSigns: string[];
    this.trueOcularSurfaceDiseaseEvidence = this.interpretationService.getEvidenceForConditionsThatMatch(
      this.data.patientData,
      InterpretationConditions.trueOcularSurfaceDisease
    );
    falseOSDSignsWithoutSymptoms = this.interpretationService.getEvidenceForConditionsThatMatch(
      this.data.patientData,
      InterpretationConditions.falseOcularSurfaceDisease.signsWithoutSymptoms
    );
    falseOSDSymptomsWithoutSigns = this.interpretationService.getEvidenceForConditionsThatMatch(
      this.data.patientData,
      InterpretationConditions.falseOcularSurfaceDisease.symptomsWithoutSigns
    );

    if (falseOSDSymptomsWithoutSigns.length > 0) {
      this.falseOcularSurfaceDiseaseCondition = 'Symptoms without Signs';
      this.falseOcularSurfaceDiseaseEvidence = falseOSDSymptomsWithoutSigns;
    } else if (falseOSDSignsWithoutSymptoms.length > 0) {
      this.falseOcularSurfaceDiseaseCondition = 'Signs without Symptoms';
      this.falseOcularSurfaceDiseaseEvidence = falseOSDSignsWithoutSymptoms;
    }
  }

  public onInterpretationViewChange(newInterpretationView: InterpretationView) {
    this.interpretationView = newInterpretationView;
  }

  public setHasDryEyeMimickers(hasDryEyeMimickers) {
    this.hasDryEyeMimickers = hasDryEyeMimickers;
    setTimeout(() => this.changeDetectorRef.markForCheck());
  }

  public copyToImpressions() {
    this.modalView = false;
    this.changeDetectorRef.detectChanges();
    this.data.impressionsControl.setValue(
      this.data.impressionsControl.value + this.interpretationEl.nativeElement.innerHTML
    );
    this.isCopied = true;
    this.modalView = true;
    this.changeDetectorRef.detectChanges();
  }

  public changeLanguage(lang = 'en') {
    this.translocoService.load(lang).subscribe(() => {
      this.translocoService.setActiveLang(lang);
      this.translateObject = this.translocoService.getTranslation(
        this.translocoService.getActiveLang()
      );
    });
  }
}
