export interface Invoice {
  id?: string;
  referenceId: string;
  status: PatientInvoiceStatus;
  createdAt: string;
  treatmentPrices: PatientInvoiceTreatmentPrice[];
  patientInvoiceClinicId: string;
  patientInvoicePatientId: string;
  discount: number;
  discountType: PatientInvoiceDiscountType;
  comment: string;
  dueDate: string;
}

export interface PatientInvoiceTreatmentPrice {
  treatmentId: string;
  price: number;
  units: number;
}

export enum PatientInvoiceDiscountType {
  percent = 'percent',
  flat = 'flat'
}

export enum PatientInvoiceStatus {
  draft = 'draft',
  pending = 'pending',
  completed = 'completed',
}
