import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, forkJoin, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { deleteTreatmentConfig } from 'src/graphql/mutations';
import { listTreatmentConfigByClinicIdSortedByUpdatedAt as listTreatmentConfigQuery } from 'src/graphql/queries';
import { AppSyncService } from '../core/appsync.service';
import { PricingItem } from './pricing-item';

const createTreatmentConfig = gql`
  mutation CreateTreatmentConfig($input: CreateTreatmentConfigInput!) {
    createTreatmentConfig(input: $input) {
      id
      treatmentId
      price
      createdAt
      updatedAt
    }
  }
`;

const listTreatmentConfigByClinicIdSortedByUpdatedAt = gql`
  ${listTreatmentConfigQuery}
`;

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  constructor(private http: HttpClient, private appSyncService: AppSyncService) {}
  getTreatmentJSON(): Observable<PricingItem[]> {
    return this.http.get<PricingItem[]>('/assets/treatments.json');
  }
  public createTreatmentConfig(input: any): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: createTreatmentConfig,
          variables: { input }
        })
      ),
      map((result: any) => result.data.createTreatmentConfig)
    );
  }
  public updateTreatmentConfig(input: any): Observable<any> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql`
            mutation UpdateTreatmentConfig($input: UpdateTreatmentConfigInput!) {
              updateTreatmentConfig(input: $input) {
                id
                treatmentId
                image {
                  bucket
                  key
                  region
                  fileName
                  metadata
                }
                description
                price
                treatmentConfigClinicId
                createdAt
                updatedAt
              }
            }
          `,
          variables: { input }
        })
      ),
      map(result => result.data.updateTreatmentConfig)
    );
  }
  public saveAllTreatmentConfigs(inputs: any[]): Observable<any[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        const ops = inputs.map(input =>
          from(
            client.mutate({
              mutation: createTreatmentConfig,
              variables: { input }
            })
          ).pipe(map((res: any) => res.data.createTreatmentConfig))
        );
        return forkJoin(ops);
      })
    );
  }

  //this method lists all the treatment configs for a clinic, which includes prices of treatments
  listTreatmentConfigsForClinic(clinicId: string): Observable<any[]> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query<any>({
          query: listTreatmentConfigByClinicIdSortedByUpdatedAt,
          variables: { treatmentConfigClinicId: clinicId },
          fetchPolicy: 'no-cache'
        })
      ),
      map((result: any) => result.data.listTreatmentConfigByClinicIdSortedByUpdatedAt.items)
    );
  }
  public deleteTreatmentConfig(id: string): Observable<any> {
    const input = { id };
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(deleteTreatmentConfig),
          variables: { input }
        })
      ),
      map(result => result.data.deleteTreatmentConfig)
    );
  }
}
