import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { forkJoin, Subject } from 'rxjs';
import { IntakeForm, MassClinic, Patient } from 'src/API';
import { AssessmentService } from 'src/app/core/api/assessment.service';
import { ClinicService } from 'src/app/core/api/clinic.service';
import { compareDateStrings, parseDateToString } from 'src/app/core/api/date.util';
import { ModelUtils } from 'src/app/core/api/model-utils';
import { PatientList, PatientService } from 'src/app/core/api/patient.service';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { SideNavActionsService } from 'src/app/core/api/sidenav-actions.service';
import { FilterGroup, MatchType } from 'src/app/filter/filter.model';
import { FilterService } from 'src/app/filter/filter.service';
import { IntakeFormService } from 'src/app/intake-form/intake-form.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { InboxService } from 'src/app/logged-in-navbar/inbox/inbox.service';
import { PatientsPaginatorService } from 'src/app/patients/patients-dashboard/patients-paginator.service';
import { FilterType } from './../../../API';

@Component({
  selector: 'csi-widgets-view',
  templateUrl: './widgets-view.component.html',
  styleUrls: ['./widgets-view.component.scss'],
  providers: [TitleCasePipe]
})
export class WidgetsViewComponent implements OnInit {
  isLoading = true;
  intakeForms: IntakeForm[];
  public patients$: Subject<Partial<Patient>[]> = new Subject<Partial<Patient>[]>();
  massClinics: MassClinic;
  staff: any[] = [];
  notifications: any[] = [];
  unviewedNotifications: any[] = [];
  assessments: any[] = [];
  patientDisplayedColumns: string[] = ['name', 'lastUpdated'];
  intakeFormDisplayedColumns: string[] = ['name', 'date', 'results'];
  staffDisplayedColumns: string[] = ['name', 'position', 'email'];
  assessmentsDisplayedColumns: string[] = ['name', 'type', 'createdAt'];
  recentlyCompletedQuestionnaireDisplayedColumns: string[] = [
    'name',
    'questionnaireType',
    'completedAt'
  ];
  questionnairesPendingCompletionDisplayedColumns: string[] = [
    'name',
    'questionnaireType',
    'createdAt'
  ];
  recentlyCompletedQuestionnaires: any[] = [];
  questionnairesPendingCompletion: any[] = [];

  public readonly lastAssessmentKey = 'lastAssessment';

  copyToClipboard(email: string): void {
    const textarea = document.createElement('textarea');

    textarea.value = email;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand('copy');

    document.body.removeChild(textarea);
  }

  constructor(
    private clinicSetupService: ClinicSetupService,
    private intakeFormService: IntakeFormService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private clinicService: ClinicService,
    private inboxService: InboxService,
    private patientService: PatientService,
    private patientsPaginatorService: PatientsPaginatorService,
    public questionnaireRequestService: QuestionnaireRequestService,
    public sideNavActionsService: SideNavActionsService,
    private assessmentService: AssessmentService,
    public filterService: FilterService
  ) {}

  ngOnInit() {
    this.getStaff();
    this.getIntakeForms();
    this.getPatients();
    this.getAssessments();
    this.getQuestionnairesPendingCompletion();
    this.getRecentlyCompletedQuestionnaires();
    this.isLoading = false;
  }

  ngOnDestroy() {}

  private getIntakeForms() {
    this.intakeFormService.getIntakeForms(this.clinicSetupService.clinicId).subscribe(data => {
      this.intakeForms = data.reduce((acc, intakeForm) => {
        let response =
          typeof intakeForm.response === 'string'
            ? JSON.parse(intakeForm.response)
            : intakeForm.response;
        if (intakeForm.response['questionnaire'] && intakeForm.response['questionnaire'].response) {
          response = {
            ...response,
            scores: intakeForm.response['questionnaire'].response.scores
          };
          intakeForm = {
            ...intakeForm,
            response
          };
        } else {
          let scores =
            typeof intakeForm.response === 'string'
              ? JSON.stringify(intakeForm['response']['scores'])
              : JSON.stringify(intakeForm['response']['scores']);
          let newResponse = { ...response, scores };
          intakeForm = { ...intakeForm, response: newResponse };
        }
        if (intakeForm.response['patientInformation']) {
          const patientInformation = intakeForm.response['patientInformation'];
          const answers = { patientInformation };
          let response =
            typeof intakeForm.response === 'string'
              ? JSON.parse(intakeForm.response)
              : intakeForm.response;
          const newResponse = { ...response, answers };
          intakeForm = { ...intakeForm, response: newResponse };
        }

        acc.push(intakeForm);

        return acc;
      }, []);
    });
  }

  private capitalizeName(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  private getPatients() {
    this.patientsPaginatorService
      .loadNextPatients(0)
      .pipe(untilDestroyed(this))
      .subscribe((patients: PatientList) => {
        patients.items.forEach(patient => {
          patient.firstName = this.capitalizeName(patient.firstName);
          patient.lastName = this.capitalizeName(patient.lastName);
        });
        this.setLastAssessment(patients.items);
        this.patients$.next(patients.items);
      });

    // this.patients$.subscribe(patients => {
    //   console.log(patients);
    // });
  }

  private getQuestionnairesPendingCompletion() {
    this.filterService
      .queryFilterData(FilterType.questionnaireRequest, [
        this.generateFilterGroup(
          'createdAt',
          this.clinicSetupService.clinicSetupFormGroup.controls.questionnaireExpiry.value || 30
        )
      ])
      .subscribe(questionnaires => {
        questionnaires.sort((a, b) => compareDateStrings(a.createdAt, b.createdAt));
        const requests = questionnaires
          .filter(q => !q.completedAt)
          .map(val => this.questionnaireRequestService.getQuestionnaireRequest(val.id));
        forkJoin(requests).subscribe(
          responses => {
            this.questionnairesPendingCompletion = responses.map((val, i) => {
              return {
                ...val,
                questionnaireRequestPatientId: questionnaires[i].questionnaireRequestPatientId
              };
            });
          },
          error => {
            console.error('Error:', error);
          }
        );
      });
  }

  private getRecentlyCompletedQuestionnaires() {
    this.filterService
      .queryFilterData(FilterType.questionnaireRequest, [
        this.generateFilterGroup('completedAt', 30)
      ])
      .subscribe(questionnaires => {
        questionnaires.sort((a, b) => compareDateStrings(b.completedAt, a.completedAt));
        const requests = questionnaires.map(val =>
          this.questionnaireRequestService.getQuestionnaireRequest(val.id)
        );
        forkJoin(requests).subscribe(
          responses => {
            this.recentlyCompletedQuestionnaires = responses.map((val, i) => {
              return {
                ...val,
                questionnaireRequestPatientId: questionnaires[i].questionnaireRequestPatientId
              };
            });
          },
          error => {
            console.error('Error:', error);
          }
        );
      });
  }

  private generateFilterGroup(field: string, timeFrameInDays: number): FilterGroup {
    const dateToday = new Date();

    // Calculate the date for two weeks ago
    const startDate = new Date();
    startDate.setDate(dateToday.getDate() - timeFrameInDays * 2);

    return {
      type: MatchType.and,
      filters: [
        {
          field,
          operator: 'greaterThanOrEqualTo',
          searchValue: startDate.toISOString()
        },
        {
          field,
          operator: 'lessThanOrEqualTo',
          searchValue: dateToday.toISOString()
        }
      ]
    };
  }

  private getAssessments() {
    const dateToday = new Date();
    const startDate = new Date();
    startDate.setDate(dateToday.getDate() - 90);

    this.assessmentService
      .listAssessmentsByDateRange([parseDateToString(startDate), parseDateToString(dateToday)])
      .subscribe(
        data => {
          this.assessments = data
            .map(assessment => {
              const parsedBody = JSON.parse(assessment.body);
              const method =
                parsedBody && parsedBody.dryEyeForm && parsedBody.dryEyeForm.assessmentMethod;

              const friendlyType =
                this.assessmentService.encounterMethodFriendlyName[method] || method;
              return { ...assessment, body: parsedBody, type: friendlyType };
            })
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        },
        error => {
          this.assessments = [];
        }
      );
  }

  private getStaff() {
    this.clinicService.getClinicStaff(this.clinicSetupService.clinicId).subscribe(data => {
      const order = { null: 1, Doctor: 2, Tech: 3, Receptionist: 4 };
      this.staff = data
        .filter(staff => !staff.deactivated)
        .sort((a, b) => (order[a.staffType] || 5) - (order[b.staffType] || 5));
    });
  }

  private getNotifications() {
    this.inboxService.loadNotifications().subscribe(data => {
      const currentDoctor = this.inboxService.loggedInDoctor;
      // let notification = data[0].concat(data[1].concat(data[2]));
      let notification = data[1];
      this.notifications = notification
        .map(item => ({
          ...item,
          content: JSON.parse(item.content)
        }))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.notifications.forEach(n => {
        if (!n.properties.some(p => p.doctorId === currentDoctor)) {
          this.unviewedNotifications.push(n);
        }
      });
    });
  }

  private setLastAssessment(patients: Partial<Patient>[]) {
    patients.forEach(patient => {
      patient[this.lastAssessmentKey] = ModelUtils.sortByDate(
        patient.assessments.items,
        'createdAt'
      )[0];
    });
  }

  // onIntakeFormSelect(intakeForm: IntakeForm) {
  //   this.intakeFormService.currentIntakeForm = intakeForm;
  //   this.router.navigate([intakeForm.id], {
  //     relativeTo: this.activatedRoute
  //   });
  // }
}
