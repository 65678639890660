import { Injectable } from '@angular/core'
import { API } from 'aws-amplify'
import { from } from 'rxjs'
import { createTreatmentPackage, deleteTreatmentPackage } from 'src/graphql/mutations'
import { listTreatmentPackageByClinicId } from 'src/graphql/queries'

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  getPackages(clinicId: string) {
    return from(API.graphql({ query: listTreatmentPackageByClinicId, variables: { treatmentPackageClinicId: clinicId } }))
  }
  createPackage(input: any) {
    return from(API.graphql({ query: createTreatmentPackage, variables: { input } }))
  }
  deletePackage(input: any) {
    return from(API.graphql({ query: deleteTreatmentPackage, variables: { input } }))
  }
}