import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TreatmentPickerModule } from 'src/app/treatment-picker/treatment-picker.module';
import { ConsultFormsModule } from '../shared/consult-forms/consult-forms.module';
import { PatientModalComponent } from '../shared/consult-forms/patient-modal/patient-modal.component';
import { SharedModule } from '../shared/shared.module';
import { AttachmentsModule } from './../shared/attachments/attachments.module';
import { ChangeClinicModalComponent } from './change-clinic-modal/change-clinic-modal.component';
import { AccountAccessInstructionsModalComponent } from './clinic-setup-modal/all-doctors-table/account-access-instructions-modal/account-access-instructions-modal.component';
import { AllDoctorsTableComponent } from './clinic-setup-modal/all-doctors-table/all-doctors-table.component';
import { StaffActionsModalComponent } from './clinic-setup-modal/all-doctors-table/staff-actions-modal/staff-actions-modal.component';
import { StaffFormComponent } from './clinic-setup-modal/all-doctors-table/staff-form/staff-form.component';
import { ClinicSetupModalComponent } from './clinic-setup-modal/clinic-setup-modal.component';
import { CustomMessageComponent } from './clinic-setup-modal/custom-message/custom-message.component';
import { CustomizableWelcomeNotificationComponent } from './clinic-setup-modal/customizable-welcome-notification/customizable-welcome-notification.component';
import { LanguageSelectComponent } from './clinic-setup-modal/language-select/language-select.component';
import { CustomLayoutComponent } from './clinic-setup-modal/layouts/custom-layout/custom-layout.component';
import { LayoutsComponent } from './clinic-setup-modal/layouts/layouts.component';
import { MedicalHistoryLayoutComponent } from './clinic-setup-modal/layouts/medical-history-layout/medical-history-layout.component';
import { CanHideSignPipe } from './clinic-setup-modal/layouts/signs-layout/can-hide-sign.pipe';
import { SignsLayoutComponent } from './clinic-setup-modal/layouts/signs-layout/signs-layout.component';
import { QuestionnaireNotificationComponent } from './clinic-setup-modal/questionnaire-notification/questionnaire-notification.component';
import { TextFavoriteModule } from './clinic-setup-modal/text-favorite/text-favorite.module';
import { TreatmentPickViewerComponent } from './clinic-setup-modal/treatment-template/treatment-pick-viewer/treatment-pick-viewer.component';
import { TreatmentTemplateActionsModalComponent } from './clinic-setup-modal/treatment-template/treatment-template-actions-modal/treatment-template-actions-modal.component';
import { TreatmentTemplateViewModalComponent } from './clinic-setup-modal/treatment-template/treatment-template-view-modal/treatment-template-view-modal.component';
import { TreatmentTemplateComponent } from './clinic-setup-modal/treatment-template/treatment-template.component';
import { InboxModalComponent } from './inbox/inbox-modal/inbox-modal.component';
import { NotificationCardComponent } from './inbox/notification-card/notification-card.component';
import { NotificationContentDisplayComponent } from './inbox/notification-content-display/notification-content-display.component';
import { InteractionStatusSetupActionsModalComponent } from './interactions-setup-modal/interaction-status-setup-actions-modal/interaction-status-setup-actions-modal.component';
import { InteractionsSetupModalComponent } from './interactions-setup-modal/interactions-setup-modal.component';
import { LoggedInNavbarComponent } from './logged-in-navbar.component';
import { MyProfileModalComponent } from './my-profile-modal/my-profile-modal.component';
import { QuestionnaireSetupComponent } from './questionnaire-setup/questionnaire-setup.component';
import { ReferralCenterSetupModalComponent } from './referral-center-setup-modal/referral-center-setup-modal.component';
import { ReferralFormsSetupActionsModalComponent } from './referral-center-setup-modal/referral-forms-setup/referral-forms-setup-actions-modal/referral-forms-setup-actions-modal.component';
import { ReferralFormsSetupComponent } from './referral-center-setup-modal/referral-forms-setup/referral-forms-setup.component';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { QuestionnaireReminderComponent } from './clinic-setup-modal/questionnaire-reminder/questionnaire-reminder.component';
import { ReminderListPipe } from './clinic-setup-modal/questionnaire-reminder/reminder-list.pipe';

@NgModule({
  declarations: [
    UserDropdownComponent,
    LoggedInNavbarComponent,
    MyProfileModalComponent,
    ClinicSetupModalComponent,
    AllDoctorsTableComponent,
    StaffFormComponent,
    StaffActionsModalComponent,
    AccountAccessInstructionsModalComponent,
    SignsLayoutComponent,
    TreatmentTemplateComponent,
    TreatmentTemplateActionsModalComponent,
    CanHideSignPipe,
    MedicalHistoryLayoutComponent,
    ReferralCenterSetupModalComponent,
    ReferralFormsSetupComponent,
    ReferralFormsSetupActionsModalComponent,
    LayoutsComponent,
    ChangeClinicModalComponent,
    InteractionStatusSetupActionsModalComponent,
    InteractionsSetupModalComponent,
    CustomLayoutComponent,
    InboxModalComponent,
    NotificationCardComponent,
    LanguageSelectComponent,
    NotificationContentDisplayComponent,
    QuestionnaireSetupComponent,
    CustomizableWelcomeNotificationComponent,
    TreatmentTemplateViewModalComponent,
    TreatmentPickViewerComponent,
    CustomMessageComponent,
    QuestionnaireNotificationComponent,
    QuestionnaireReminderComponent,
    ReminderListPipe
  ],
  entryComponents: [
    MyProfileModalComponent,
    PatientModalComponent,
    TreatmentTemplateViewModalComponent,
    ClinicSetupModalComponent,
    StaffActionsModalComponent,
    AccountAccessInstructionsModalComponent,
    TreatmentTemplateActionsModalComponent,
    ReferralCenterSetupModalComponent,
    ReferralFormsSetupActionsModalComponent,
    ChangeClinicModalComponent,
    InteractionStatusSetupActionsModalComponent,
    InteractionsSetupModalComponent,
    InboxModalComponent
  ],
  imports: [
    ConsultFormsModule,
    SharedModule,
    TextFavoriteModule,
    TreatmentPickerModule,
    AttachmentsModule,
    ReactiveFormsModule
  ],
  exports: [LoggedInNavbarComponent],
  providers: [CanHideSignPipe]
})
export class LoggedInNavbarModule {}
