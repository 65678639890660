import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IntakeFormViewModule } from '../intake-form/intake-form-view/intake-form-view/intake-form-view.module';
import { AttachmentsModule } from '../shared/attachments/attachments.module';
import { SharedAngularMaterialModule } from '../shared/shared-angular-material/shared-angular-material.module';
import { SharedPipesModule } from '../shared/shared-pipes/shared-pipes.module';
import { SharedModule } from '../shared/shared.module';
import { FilterModule } from './../filter/filter.module';
import { ImportMassClinicPatientsComponent } from './patient-import-wizard-modal/import-mass-clinic-patients/import-mass-clinic-patients.component';
import { ImportWizardCheckExistingPatientsComponent } from './patient-import-wizard-modal/import-wizard-check-existing-patients/import-wizard-check-existing-patients.component';
import { ImportWizardDragAndDropComponent } from './patient-import-wizard-modal/import-wizard-drag-and-drop/import-wizard-drag-and-drop.component';
import { ImportWizardFileReceivedComponent } from './patient-import-wizard-modal/import-wizard-file-received/import-wizard-file-received.component';
import { ImportWizardSendQuestionnairesComponent } from './patient-import-wizard-modal/import-wizard-send-questionnaires/import-wizard-send-questionnaires.component';
import { PatientImportWizardModalComponent } from './patient-import-wizard-modal/patient-import-wizard-modal.component';
import { PatientSummaryComponent } from './patient-summary/patient-summary.component';
import { InteractionActionsModalComponent } from './patient/interactions/interaction-actions-modal/interaction-actions-modal.component';
import { InteractionExpansionPanelComponent } from './patient/interactions/interaction-expansion-panel/interaction-expansion-panel.component';
import { InteractionTableComponent } from './patient/interactions/interaction-table/interaction-table.component';
import { InteractionsComponent } from './patient/interactions/interactions.component';
import { PatientCardComponent } from './patient/patient-card/patient-card.component';
import { PatientDeleteDialogComponent } from './patient/patient-card/patient-delete-dialog/patient-delete-dialog.component';
import { PatientChartSeriesTooltipComponent } from './patient/patient-chart/patient-chart-series-tooltip/patient-chart-series-tooltip.component';
import { PatientChartTooltipComponent } from './patient/patient-chart/patient-chart-tooltip/patient-chart-tooltip.component';
import { PatientChartComponent } from './patient/patient-chart/patient-chart.component';
import { OpenPatientFileModalDirective } from './patient/patient-file/open-patient-file-modal.directive';
import { PatientFileModalComponent } from './patient/patient-file/patient-file-modal/patient-file-modal.component';
import { PatientComponent } from './patient/patient.component';
import { MultiQrCodeModalComponent } from './patients-dashboard/multi-qr-code-modal/multi-qr-code-modal.component';
import { PatientsDashboardComponent } from './patients-dashboard/patients-dashboard.component';
import { PatientsPaginatorService } from './patients-dashboard/patients-paginator.service';

@NgModule({
  declarations: [
    PatientComponent,
    PatientCardComponent,
    PatientsDashboardComponent,
    PatientChartComponent,
    PatientChartTooltipComponent,
    PatientChartSeriesTooltipComponent,
    PatientImportWizardModalComponent,
    ImportWizardDragAndDropComponent,
    ImportWizardFileReceivedComponent,
    ImportWizardSendQuestionnairesComponent,
    ImportWizardCheckExistingPatientsComponent,
    PatientDeleteDialogComponent,
    ImportMassClinicPatientsComponent,
    InteractionsComponent,
    InteractionActionsModalComponent,
    InteractionTableComponent,
    PatientSummaryComponent,
    InteractionExpansionPanelComponent,
    PatientFileModalComponent,
    MultiQrCodeModalComponent,
    OpenPatientFileModalDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    AttachmentsModule,
    SharedAngularMaterialModule,
    NgxChartsModule,
    SharedPipesModule,
    SharedModule,
    FilterModule,
    IntakeFormViewModule
  ],
  exports: [
    PatientImportWizardModalComponent,
    PatientsDashboardComponent,
    PatientCardComponent,
    PatientSummaryComponent,
    PatientFileModalComponent,
    OpenPatientFileModalDirective
  ],
  entryComponents: [
    PatientImportWizardModalComponent,
    PatientDeleteDialogComponent,
    InteractionActionsModalComponent,
    PatientFileModalComponent,
    MultiQrCodeModalComponent
  ],
  providers: [DatePipe, PatientsPaginatorService, PatientSummaryComponent]
})
export class PatientsModule {}
