import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Treatment } from 'src/app/core/remote-json/treatments/treatments.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import {
  TreatmentTemplate,
  TreatmentTemplateService
} from 'src/app/logged-in-navbar/clinic-setup-modal/treatment-template/treatment-template.service';
import { PackageCustomizerComponent } from 'src/app/pricing/package-customizer/package-customizer.component';
import { PackageFormGroup } from 'src/app/pricing/packages/package.model';

@Component({
  selector: 'csi-create-package-modal',
  templateUrl: './create-package-modal.component.html',
  styleUrls: ['./create-package-modal.component.scss']
})
export class CreatePackageModalComponent implements OnInit {
  packageForm: PackageFormGroup;
  templateSearchControl: FormControl;
  allTreatments: Treatment[] = [];
  selectedTreatments: (Treatment & { units: number })[] = [];
  templates: TreatmentTemplate[] = [];

  constructor(
    private dialogRef: MatDialogRef<CreatePackageModalComponent>,
    private dialog: MatDialog,
    private clinicSetupService: ClinicSetupService,
    private treatmentTemplateService: TreatmentTemplateService
  ) {
    this.packageForm = new PackageFormGroup();
    this.templateSearchControl = new FormControl('');
  }

  ngOnInit(): void {
    this.packageForm.patchValue({
      id: '',
      name: '',
      icdCode: ''
    });
    this.loadAllTreatments();
    this.loadTreatmentTemplates();
  }

  loadAllTreatments(): void {
      }

  loadTreatmentTemplates(): void {
    const clinicId = this.clinicSetupService.clinicId;
    this.treatmentTemplateService.getAllTreatmentTemplates(clinicId, null).subscribe(templates => {
      this.templates = templates;
    });
  }

  onSelectedTreatmentsChange(updated: (Treatment & { units: number })[]): void {
    this.selectedTreatments = updated;
  }

  get filteredTemplates(): TreatmentTemplate[] {
    var search = this.templateSearchControl.value;
    if (!search || !search.trim()) {
      return this.templates;
    }
    var lower = search.toLowerCase();
    return this.templates.filter(t => t.friendlyId && t.friendlyId.toLowerCase().includes(lower));
  }

  openTemplateModal(template: TreatmentTemplate): void {
    const combined = [
      ...(((template.otcEyeDrops || []) as unknown) as Treatment[]),
      ...(((template.rxEyeDrops || []) as unknown) as Treatment[]),
      ...(((template.procedures || []) as unknown) as Treatment[]),
      ...(((template.exercises || []) as unknown) as Treatment[])
    ].map(t => ({ ...t, units: 1 }));

    this.dialog
      .open(PackageCustomizerComponent, {
        width: '70%',
        data: {
          availableTreatments: this.allTreatments,
          selectedTreatments: combined
        }
      })
      .afterClosed()
      .subscribe(result => {
        if (result && result.selectedTreatments) {
          // ...
        }
      });
  }

  save(): void {
    if (this.packageForm.get('treatments')) {
      this.packageForm.get('treatments').setValue(this.selectedTreatments);
    }
    const newPackage = this.packageForm.value;
    this.dialogRef.close(newPackage);
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
