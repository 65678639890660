import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/api/navigation.service';

@Component({
  selector: 'csi-container-with-sidebar',
  templateUrl: './container-with-sidebar.component.html',
  styleUrls: ['./container-with-sidebar.component.css']
})
export class ContainerWithSidebarComponent implements OnInit {
  constructor(public navigationService: NavigationService) {}

  ngOnInit() {}
}
