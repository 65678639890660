import { Injectable } from '@angular/core';
import { identity, pickBy } from 'lodash-es';
import { defer, forkJoin, iif, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Clinic, ClinicService } from 'src/app/core/api/clinic.service';
import { CreateQuestionnaireRequestInput, QuestionnaireType } from '../../../../API';
import { Patient } from '../../../core/api/patient.service';
import {
  QuestionnaireRequest,
  QuestionnaireRequestService
} from '../../../core/api/questionnaire-request.service';

@Injectable({
  providedIn: 'root'
})
export class ImportWizardSendQuestionnairesService {
  constructor(
    private questionnaireRequestService: QuestionnaireRequestService,
    private clinicService: ClinicService
  ) {}

  public questionnaireInputs(
    patients: Patient[],
    questionnaireTypes: QuestionnaireType[],
    sendViaEmails: boolean[],
    sendViaPhones: boolean[],
    clinicCountry: string = ''
  ): { questionnaireReqInput: CreateQuestionnaireRequestInput; patientId: string }[] {
    const questionnaireCreations = patients
      .map((patient, index) => {
        const input: CreateQuestionnaireRequestInput & {
          id: string;
        } = this.questionnaireRequestService.createFormGroup({
          questionnaireType: questionnaireTypes[index],
          patient: {
            ...patient,
            nonModifiableData: patient.nonModifiableData
              ? JSON.stringify(patient.nonModifiableData)
              : '{}',
            ...{ clinic: { country: clinicCountry } as Clinic }
          }
        }).value;
        input.email = sendViaEmails[index] && !!input.email ? input.email : undefined;
        input.mobile = sendViaPhones[index] && !!input.mobile ? input.mobile : undefined;
        return { questionnaireReqInput: input, patientId: patient.id };
      })
      .filter(
        questionnaireReqAndPatientId =>
          !!questionnaireReqAndPatientId.questionnaireReqInput.email ||
          !!questionnaireReqAndPatientId.questionnaireReqInput.mobile
      );
    return questionnaireCreations;
  }

  public sendQuestionnaires(
    patients: Patient[],
    questionnaireTypes: QuestionnaireType[],
    sendViaEmails: boolean[],
    sendViaPhones: boolean[],
    createAssessmentAndSendQuestionnaire: boolean = true
  ): Observable<QuestionnaireRequest[]> {
    return this.clinicService.getLoggedInClinicCountry().pipe(
      mergeMap(country => {
        const questionnaireInputs = this.questionnaireInputs(
          patients,
          questionnaireTypes,
          sendViaEmails,
          sendViaPhones,
          country
        ).map(questionnaireReqAndPatientId => {
          if (createAssessmentAndSendQuestionnaire) {
            return this.questionnaireRequestService.createAssessmentAndSendQuestionnaireRequest(
              pickBy(
                questionnaireReqAndPatientId.questionnaireReqInput,
                identity
              ) as CreateQuestionnaireRequestInput,
              questionnaireReqAndPatientId.patientId
            );
          }
          return this.questionnaireRequestService.createQuestionnaireRequest(
            questionnaireReqAndPatientId.questionnaireReqInput
          );
        });
        return iif(
          () => questionnaireInputs.length > 0,
          defer(() => forkJoin(...questionnaireInputs)),
          of(null)
        );
      })
    );
  }
}
