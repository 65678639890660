import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InsertTextConfig } from 'src/app/shared/custom-notification-builder/custom-notification-builder.component';

export class WelcomeMessageConfigFormGroup extends FormGroup {
  constructor() {
    super({ enabled: new FormControl(), message: new FormControl() });
  }
}

@Component({
  selector: 'csi-customizable-welcome-notification',
  templateUrl: './customizable-welcome-notification.component.html',
  styleUrls: ['./customizable-welcome-notification.component.css']
})
export class CustomizableWelcomeNotificationComponent implements OnInit {
  @Input() welcomeMessageConfigFormGroup: WelcomeMessageConfigFormGroup;
  public insertTextConfig: InsertTextConfig = {
    items: [
      {
        label: 'Patient first name',
        textToInsert: '#[patient_first_name]'
      },
      {
        label: 'Clinic name',
        textToInsert: '#[clinic_name]'
      },
      {
        label: 'Clinic address',
        textToInsert: '#[clinic_address]'
      },
      {
        label: 'Clinic phone number',
        textToInsert: '#[clinic_phone_number]'
      }
    ]
  };
  constructor() {}

  ngOnInit() {}
}
