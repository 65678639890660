import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreateTreatmentTemplateMutation,
  DeleteTreatmentTemplateMutation,
  ListTreatmentTemplateQuery,
  UpdateTreatmentTemplateInput,
  UpdateTreatmentTemplateMutation
} from 'src/API';
import { AppSyncService } from 'src/app/core/appsync.service';
import { maxAWSItemLimit } from 'src/graphql/custom_queries';
import {
  createTreatmentTemplate,
  deleteTreatmentTemplate,
  updateTreatmentTemplate
} from 'src/graphql/mutations';
import { listTreatmentTemplate } from 'src/graphql/queries';
import {
  CreateTreatmentTemplateInput,
  ListTreatmentTemplateQueryVariables
} from './../../../../API';
import {
  Treatment,
  TreatmentsService
} from './../../../core/remote-json/treatments/treatments.service';
import { ClinicSetupService } from './../clinic-setup.service';
import { TextFavoriteService } from './../text-favorite/text-favorite.service';

import { defaultTreatmentTemplateMap } from './treatment-template-model';

export interface TreatmentTemplate {
  id?: string;
  friendlyId?: string;
  otcEyeDrops?: Array<string | null> | null;
  rxEyeDrops?: Array<string | null> | null;
  procedures?: Array<string | null> | null;
  exercises?: Array<string | null> | null;
  treatmentTemplateClinicId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type ParsedTreatmentTemplateList = {
  id?: string;
  friendlyId?: string;
  otcEyeDrops?: Array<Treatment> | null;
  rxEyeDrops?: Array<Treatment> | null;
  procedures?: Array<Treatment> | null;
  exercises?: Array<Treatment> | null;
  treatmentTemplateClinicId?: string;
  createdAt?: string;
  updatedAt?: string;
}[];

export enum TreatmentTemplateTreatmentType {
  otcEyeDrops = 'otcEyeDrops',
  rxEyeDrops = 'rxEyeDrops',
  procedures = 'procedures',
  exercises = 'exercises'
}

@Injectable({
  providedIn: 'root'
})
export class TreatmentTemplateService {
  public defaultTreatmentTemplateMap = defaultTreatmentTemplateMap;

  public isLoading: boolean;
  public treatmentTemplatesFormControl = this.clinicSetupService.clinicSetupFormGroup.controls
    .treatmentTemplates;

  constructor(
    public clinicSetupService: ClinicSetupService,
    private appSyncService: AppSyncService,
    private treatmentsService: TreatmentsService,
    private textFavoriteService: TextFavoriteService
  ) {
    this.isLoading = true;
    this.getAllTreatmentTemplates(this.clinicSetupService.clinicId, null).subscribe(
      allTreatmentTemplates => {
        this.convertTreatmentKeysToTreatmentForTreatmentTemplate(allTreatmentTemplates);
        allTreatmentTemplates = [
          ...Object.values(this.defaultTreatmentTemplateMap),
          ...allTreatmentTemplates
        ];
        this.treatmentTemplatesFormControl.setValue(allTreatmentTemplates);
        this.isLoading = false;
      }
    );
  }

  convertTreatmentKeysToTreatmentForTreatmentTemplate(treatmentTemplates: TreatmentTemplate[]) {
    treatmentTemplates.forEach(treatmentTemplate => {
      Object.keys(TreatmentTemplateTreatmentType).forEach(treatmentTemplateTreatmentType => {
        const treatmentTemplateTreatmentKeys = treatmentTemplate[treatmentTemplateTreatmentType];
        if (treatmentTemplateTreatmentKeys) {
          treatmentTemplate[
            treatmentTemplateTreatmentType
          ] = this.treatmentsService.treatmentKeysToTreatments(treatmentTemplateTreatmentKeys);
        }
      });
    });
  }

  getAllTreatmentTemplates(
    clinicId: string,
    nextToken,
    treatmentTemplates: TreatmentTemplate[] = []
  ): Observable<TreatmentTemplate[]> {
    const listTreatmentTemplatesQueryVariables: ListTreatmentTemplateQueryVariables = {
      treatmentTemplateClinicId: clinicId,
      limit: maxAWSItemLimit,
      nextToken
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listTreatmentTemplate),
          variables: listTreatmentTemplatesQueryVariables
        })
      ),
      mergeMap(({ data }: { data: ListTreatmentTemplateQuery }) => {
        if (data.listTreatmentTemplate.nextToken) {
          return this.getAllTreatmentTemplates(
            clinicId,
            data.listTreatmentTemplate.nextToken,
            treatmentTemplates
          ).pipe(
            map(nextTreatmentTemplates => [
              ...treatmentTemplates,
              ...data.listTreatmentTemplate.items,
              ...nextTreatmentTemplates
            ])
          );
        }
        return of([...treatmentTemplates, ...data.listTreatmentTemplate.items]);
      })
    );
  }

  addTreatmentTemplate(input: CreateTreatmentTemplateInput): Observable<TreatmentTemplate> {
    return this.appSyncService
      .hydrated()
      .pipe(
        switchMap(client =>
          client.mutate({
            mutation: gql(createTreatmentTemplate),
            variables: { input }
          })
        )
      )
      .pipe(
        map(
          (result: { data: CreateTreatmentTemplateMutation }) => result.data.createTreatmentTemplate
        )
      );
  }

  updateTreatmentTemplate(input: UpdateTreatmentTemplateInput): Observable<TreatmentTemplate> {
    return this.appSyncService
      .hydrated()
      .pipe(
        switchMap(client =>
          client.mutate({
            mutation: gql(updateTreatmentTemplate),
            variables: { input }
          })
        )
      )
      .pipe(
        map(
          (result: { data: UpdateTreatmentTemplateMutation }) => result.data.updateTreatmentTemplate
        )
      );
  }

  deleteTreatmentTemplate(id: string): Observable<TreatmentTemplate> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({ mutation: gql(deleteTreatmentTemplate), variables: { input: { id } } })
      ),
      map(({ data }: { data: DeleteTreatmentTemplateMutation }) => {
        this.deleteFromTreatmentTemplateControl(id);
        this.textFavoriteService.resetTreatmentTemplateFromTextFavorites(id);
        return data.deleteTreatmentTemplate;
      })
    );
  }

  addToTreatmentTemplateControl(treatmentTemplate: TreatmentTemplate) {
    this.convertTreatmentKeysToTreatmentForTreatmentTemplate([treatmentTemplate]);
    this.treatmentTemplatesFormControl.setValue([
      ...(this.treatmentTemplatesFormControl.value ? this.treatmentTemplatesFormControl.value : []),
      treatmentTemplate
    ]);
  }

  updateTreatmentTemplateControl(treatmentTemplateToUpdate: TreatmentTemplate) {
    this.convertTreatmentKeysToTreatmentForTreatmentTemplate([treatmentTemplateToUpdate]);
    const currentTreatmentTemplates: TreatmentTemplate[] = this.treatmentTemplatesFormControl.value;
    const currentTreatmentTemplateIndex = currentTreatmentTemplates.findIndex(
      treatmentTemplate => treatmentTemplate.id === treatmentTemplateToUpdate.id
    );
    currentTreatmentTemplates[currentTreatmentTemplateIndex] = treatmentTemplateToUpdate;
    this.treatmentTemplatesFormControl.setValue([...currentTreatmentTemplates]);
  }

  deleteFromTreatmentTemplateControl(id: string) {
    const currentTreatmentTemplates = this.treatmentTemplatesFormControl
      .value as TreatmentTemplate[];
    const index = currentTreatmentTemplates.findIndex(template => template.id === id);
    currentTreatmentTemplates.splice(index, 1);
  }
}
