import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, zip } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Assessment } from 'src/app/core/api/assessment.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { AssessmentBody } from 'src/app/econsult/assessment-body/assessment-body.model';
import { EConsultService } from 'src/app/econsult/service/econsult.service';
import { SignsAttachmentsService } from 'src/app/encounters/signs/signs-attachments-modal/signs-attachments.service';
import { InterpretationComponent } from 'src/app/shared/interpretation/interpretation.component';
import { SymptomModes } from 'src/app/shared/symptoms/symptom.model';
import { EConsultFormGroup } from '../../../../econsult/econsult-form.model';
import { FormErrorScrollingService } from '../../../form-error-scrolling/form-error-scrolling.service';
import { RiskFactorsModalComponent } from '../../../risk-factors-report/risk-factors-modal.component';
import { DryEyeMedicalHistoryFormGroup } from '../dry-eye-medical-history-form/dry-eye-medical-history-form.model';
import { PreOpService } from '../pre-op/pre-op.service';
import { TextFavoriteType } from './../../../../../API';
import { TextFavoriteService } from './../../../../logged-in-navbar/clinic-setup-modal/text-favorite/text-favorite.service';
import { DryEyeFormGroup } from './../dry-eye-form/dry-eye-form.model';
import { DryEyeExpansionPanel, DryEyeFormsService } from './../dry-eye-forms.service';
import { DryEyePredictionService } from './../dry-eye-prediction.service';
import { DryEyeAssessmentFormGroup } from './dry-eye-assessment-form.model';
import { TreatmentFiltersCDSService } from './treatment-filters/treatment-filters-cds.service';

@Component({
  selector: 'csi-dry-eye-assessment-form',
  templateUrl: './dry-eye-assessment-form.component.html',
  styleUrls: ['./dry-eye-assessment-form.component.scss']
})
export class DryEyeAssessmentFormComponent implements OnDestroy, OnInit {
  public requiredErrors: string[];
  public normalizedErrors: string[];
  public readonly SymptomModes = SymptomModes;
  public readonly ExpansionPanel = DryEyeExpansionPanel;
  public riskFactorTitle: string;

  public dryEyePrediction: string;

  public TextFavoriteType = TextFavoriteType;

  public categoryAndSeverityMatSpinnerOptions: MatProgressButtonOptions = {
    text: 'Predict Dry Eye Type And Severity',
    active: false,
    raised: true,
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate'
  };

  public severityMatSpinnerOptions: MatProgressButtonOptions = {
    text: 'Predict Dry Eye Severity Risk',
    active: false,
    raised: true,
    buttonColor: 'primary',
    spinnerSize: 19,
    mode: 'indeterminate'
  };

  public waitingForPredict: boolean = false;

  @Input() formGroup: DryEyeAssessmentFormGroup;
  @Input() medicalHistoryFormGroup: DryEyeMedicalHistoryFormGroup;
  @Input() showImageRequiredError = false;
  @Input() showCategorySelection = false;
  @Input() showImpressionsWarning$: Observable<boolean>;
  @Input() expandAttachments: boolean;
  @Input() symptomsAndSignsFormGroup: DryEyeFormGroup;
  @Input() saveFn: () => Observable<Assessment>;

  constructor(
    public filterService: TreatmentFiltersCDSService,
    private econsultFormGroup: EConsultFormGroup,
    private formErrorScrollingService: FormErrorScrollingService,
    private matDialog: MatDialog,
    private dryEyePredictionService: DryEyePredictionService,
    private changeDetector: ChangeDetectorRef,
    public staffService: StaffService,
    private textFavoriteService: TextFavoriteService,
    public dryEyeFormsService: DryEyeFormsService,
    public preOpService: PreOpService,
    public signsAttachmentsService: SignsAttachmentsService,
    @Optional() private econsultService: EConsultService
  ) {}

  public HIDDEN_MODAL_CONFIG = {
    width: '0px',
    height: '0px',
    panelClass: 'hidden-modal',
    hasBackdrop: false
  };

  ngOnInit() {
    this.textFavoriteService.treatmentControlMap = {
      otcEyeDrops: this.formGroup.controls.recommendedEyeDrops,
      rxEyeDrops: this.formGroup.controls.recommendedRxEyeDrops,
      exercises: this.formGroup.controls.recommendedExercises,
      procedures: this.formGroup.controls.recommendedProcedures
    };
  }

  get hasImages(): boolean {
    return this.formGroup.controls.images.value && this.formGroup.controls.images.value.length > 0;
  }

  public showCDSModal() {
    this.matDialog.open(InterpretationComponent, {
      data: {
        patientData: this.flattenedCDSValues(),
        impressionsControl: this.formGroup.controls.impressions
      },
      minWidth: '60vw'
    });
  }

  public openRiskFactorsReportModal() {
    const isPreOpScreening =
      this.symptomsAndSignsFormGroup.controls.assessmentMethod.value ===
      SymptomModes.PreOpScreening;

    const dialogRef = this.matDialog.open(RiskFactorsModalComponent, {
      data: {
        medicalHistoryData: this.medicalHistoryFormGroup.value,
        impressionsControl: this.formGroup.controls.impressions,
        isPreOpScreening: isPreOpScreening
      }
    });

  }

  public scrollToFirstError() {
    this.econsultFormGroup.submitted = true;
    this.formErrorScrollingService.scrollToFirstInvalidControl();
  }

  // TODO: Only pass AssessmentBody to CDS and have it use methods to get the values it wants
  // See https://gitlab.com/clarity-lv/dry-eye-frontend/issues/197#note_207435011
  private flattenedCDSValues() {
    const data: AssessmentBody = this.econsultFormGroup.getRawValue() as AssessmentBody;

    const medicalHistoryFormValues = data.medicalHistoryForm;
    const dryEyeFormValues = data.dryEyeForm;

    if (!!dryEyeFormValues && !!medicalHistoryFormValues) {
      Object.keys(medicalHistoryFormValues).forEach(key => {
        if (!!dryEyeFormValues[key] && key !== 'schemaVersion') {
          console.error(
            'Warning: Found a duplicate key used in medicalHistoryForm and dryEyeForm called `' +
              key +
              '`. This may cause CDSComponent to display incorrect values.'
          );
        }
      });
    }

    return {
      ...medicalHistoryFormValues,
      ...dryEyeFormValues
    };
  }

  predictDryEyeCategoryAndSeverity() {
    this.categoryAndSeverityMatSpinnerOptions.active = true;

    this.dryEyePredictionService
      .predictDryEyeCategoryAndSeverity()
      .pipe(
        finalize(() => (this.categoryAndSeverityMatSpinnerOptions.active = false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (result: string) => {
          this.dryEyePrediction = result;
          this.changeDetector.markForCheck();
        },
        error: (errors: string[]) => {
          this.requiredErrors = errors;
          this.changeDetector.markForCheck();
        }
      });
  }

  predictDryEyeSeverity() {
    this.severityMatSpinnerOptions.active = true;

    this.dryEyePredictionService
      .predictDryEyeSeverity()
      .pipe(
        finalize(() => (this.severityMatSpinnerOptions.active = false)),
        untilDestroyed(this)
      )
      .subscribe((result: string) => {
        this.dryEyePrediction = result;
        this.changeDetector.markForCheck();
      });
  }

  public getInterpretation(): Observable<string> {
    return this.matDialog
      .open(InterpretationComponent, {
        ...this.HIDDEN_MODAL_CONFIG,
        data: {
          patientData: this.flattenedCDSValues(),
          impressionsControl: this.formGroup.controls.impressions,
          closeAfterProcess: true
        }
      })
      .afterClosed();
  }

  public getRiskFactors(): Observable<string> {
    return this.matDialog
      .open(RiskFactorsModalComponent, {
        ...this.HIDDEN_MODAL_CONFIG,
        data: {
          medicalHistoryData: this.flattenedCDSValues(),
          impressionsControl: this.formGroup.controls.impressions,
          closeAfterProcess: true
        }
      })
      .afterClosed();
  }

  private getPrediction(): Observable<string> {
    return this.dryEyePredictionService.predictDryEyeCategoryAndSeverity().pipe(
      map(prediction => {
        const title = `<h4 style="text-decoration: underline;">Dry Eye Type And Severity AI Prediction</h4>`;
        if (this.isValidPrediction(prediction)) {
          return `
            ${title}
            <b>Prediction</b>: ${this.toTitleCase(prediction)}<br /><br />
            <b>Disclaimer:</b>
            <span>The AI prediction is solely a system recommendation based on the provided data.
            The final Dry Eye Severity must be selected by the doctor from the above choices.</span><br />
          `;
        }
        return '';
      }),
      catchError(errors => {
        this.requiredErrors = errors;

        return of('');
      }),
      finalize(() => {})
    );
  }

  private toTitleCase(str: string): string {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  private isValidPrediction(prediction: string | null | undefined): boolean {
    const invalidValues = [null, undefined, '', 'invalid', 'Invalid Data', 'invalid data', 'N/A'];
    return !invalidValues.includes(prediction);
  }

  public copyAllToImpression() {
    this.waitingForPredict = true;

    const interpretation$ = this.getInterpretation();
    const riskFactors$ = this.getRiskFactors();
    const prediction$ = this.getPrediction();

    zip(interpretation$, riskFactors$, prediction$).subscribe(
      (contents: string[]) => {
        const formattedContent = contents.filter(content => !!content).join('\n');

        const currentImpressions = this.formGroup.controls.impressions.value || '';
        this.formGroup.controls.impressions.setValue(formattedContent + currentImpressions);

        this.waitingForPredict = false;
      },
      error => {
        console.error('Error in combining data for impression:', error);
        this.waitingForPredict = false;
      }
    );
  }

  ngOnDestroy() {}
}
