import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from 'src/app/core/api/patient.service';
import { InvoiceExportComponent } from '../invoice-export/invoice-export.component';
import { InvoiceExportService } from '../invoice-export/invoice-export.service';
import { Invoice } from './invoice.model';

@Component({
  selector: 'csi-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss']
})
export class InvoiceTableComponent implements OnInit {
  @Input() invoices: Invoice[] = [];
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns = ['createdAt', 'dateSent', 'totalAmount', 'status', 'actions'];
  archivedColumns = ['status', 'createdAt', 'dateSent', 'totalAmount'];
  showArchived = false;
  dataSource!: MatTableDataSource<Invoice>;

  constructor(
    private dialog: MatDialog,
    public invoiceService: InvoiceExportService,
    private patientService: PatientService
  ) {}
  ngOnInit() {
    this.loadInvoices();
  }

  loadInvoices() {
    const patientId = this.patientService.patient.id;
    this.invoiceService.listInvoicesByPatientId(patientId).subscribe(result => {
      this.dataSource = new MatTableDataSource(result);

      this.dataSource.sortingDataAccessor = (invoice, property) => {
        if (property === 'status') {
          const order = { draft: 0, pending: 1, completed: 2 };
          return order[invoice.status] !== undefined ? order[invoice.status] : 99;
        }
        return (invoice as any)[property];
      };

      this.dataSource.sort = this.sort;
    });
  }
  // get activeInvoices(): Invoice[] {
  //   return this.invoices ? this.invoices.filter(inv => !inv.isArchived) : [];
  // }
  // get archivedInvoices(): Invoice[] {
  //   return this.invoices ? this.invoices.filter(inv => inv.isArchived) : [];
  // }
  // archiveInvoice(invoice: Invoice) {
  //   invoice.isArchived = true;
  //   this.invoices = [...this.invoices];
  // }
  toggleShowArchived() {
    this.showArchived = !this.showArchived;
  }
  openInvoice(invoice: Invoice) {
    const dialogRef = this.dialog.open(InvoiceExportComponent, {
      data: { invoice },
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.invoices.findIndex(inv => inv.id === result.id);
        if (index > -1) {
          this.invoices[index] = result;
          this.invoices = [...this.invoices];
        }
      }
    });
  }
  sendInvoice(invoice: Invoice) {
    // todo: implement sending
  }

  getComputedTotal(invoice: Invoice): number {
    const discount = invoice.discount || 0;
    const treatments = invoice.treatmentPrices || [];
    const subtotal = treatments.reduce((acc: number, treatment) => {
      return acc + treatment.units * treatment.price;
    }, 0);
    const tax = subtotal * 0.06;
    return subtotal - discount + tax;
  }
}
