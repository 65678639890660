import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Sort } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/typings/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CopyToEmrModalComponent } from 'src/app/copy-to-emr-modal/copy-to-emr-modal.component';
import { NavigationService } from 'src/app/core/api/navigation.service';
import { patientsDashboardSideNavSchema } from 'src/app/core/schemas/navigation-schema';
import { LocationSelectService } from 'src/app/shared/location-select/location-select.service';
import { HealthCardMode } from 'src/app/shared/location-select/location.model';
import { ModelUtils } from '../../core/api/model-utils';
import { Patient, PatientList } from '../../core/api/patient.service';
import { StaffService } from '../../core/api/staff.service';
import { PatientModalComponent } from '../../shared/consult-forms/patient-modal/patient-modal.component';
import { PatientImportWizardModalComponent } from '../patient-import-wizard-modal/patient-import-wizard-modal.component';
import { PatientsPaginatorService } from './patients-paginator.service';

@Component({
  selector: 'csi-patients-dashboard',
  templateUrl: './patients-dashboard.component.html',
  styleUrls: ['./patients-dashboard.component.scss'],
  providers: [PatientsPaginatorService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientsDashboardComponent implements OnInit, OnDestroy {
  @Input() forEreferral: boolean;

  public patients$: Subject<Partial<Patient>[]> = new Subject<Partial<Patient>[]>();
  public totalNumberOfPatients: number;
  public hasMorePatientsToLoad: boolean;
  public isLoading = true;
  public readonly lastAssessmentKey = 'lastAssessment';

  public isDragging = false;
  public readonly HealthCardMode = HealthCardMode;
  public readonly columnsToDisplay$: Observable<
    string[]
  > = this.locationSelectService.getHealthCardConfigForClinic().pipe(
    map(healthCardMode => {
      return healthCardMode === this.HealthCardMode.noHealthCard
        ? ['firstName', 'lastName', 'birthday', 'email', 'phone', 'lastEncounter']
        : ['firstName', 'lastName', 'birthday', 'healthCard', 'email', 'phone', 'lastEncounter'];
    })
  );

  public readonly pageChange: Subject<PageEvent> = new Subject<PageEvent>();
  public readonly patientsPerPage = 50;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public router: Router,
    private staffService: StaffService,
    private patientsPaginatorService: PatientsPaginatorService,
    private changeDetectorRef: ChangeDetectorRef,
    private locationSelectService: LocationSelectService,
    private matDialogService: MatDialog,
    public navigationService: NavigationService
  ) {
    this.patientsPaginatorService.setPageSize(this.patientsPerPage);
  }

  ngOnInit() {
    this.navigationService.updateSideNavSchema(patientsDashboardSideNavSchema);
    this.pageChange
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(currentPage => this.changePage(currentPage.pageIndex));
    this.pageChange.next({ pageIndex: 0, length: null, pageSize: null, previousPageIndex: null });
  }

  ngOnDestroy() {}

  // public qrCodeDialogue() {
  //   const standardPath = this.intakeFormLink + '&type=standard';
  //   const welcomePath = this.intakeFormLink + '&type=welcome';
  //   MultiQrCodeModalComponent.open(this.dialog, {
  //     qrCodePaths: JSON.stringify([standardPath, welcomePath]),
  //     modalTitles: JSON.stringify(['Standard', 'Welcome']),
  //     buttonText: 'Done'
  //   });
  // }

  public createPatient() {
    this.staffService.getLoggedInStaff().subscribe(loggedInDoctor => {
      PatientModalComponent.open(this.dialog, loggedInDoctor);
    });
  }

  public openPatientPage(patient: Patient) {
    this.forEreferral
      ? this.router.navigateByUrl(`/refer/patient/${patient.id}`)
      : this.router.navigate([`./${patient.id}`], { relativeTo: this.route });
  }

  public changePage(pageIndex: number) {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();

    this.patientsPaginatorService
      .loadNextPatients(pageIndex)
      .pipe(untilDestroyed(this))
      .subscribe((patients: PatientList) => {
        this.setLastAssessment(patients.items);
        this.patients$.next(patients.items);
        this.updateTotalNumberOfPatients();
        this.isLoading = false;
      });
  }

  public openPatientImportWizard(file?: File) {
    PatientImportWizardModalComponent.open(this.matDialogService, file);
  }

  public openCopyModal() {
    this.dialog.open(CopyToEmrModalComponent, {
      data: {
        showAssessmentList: true
      },
      width: '95vw',
      minWidth: '67rem',
      height: '92vh'
    });
  }

  public addFile(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
    if (
      event.dataTransfer &&
      event.dataTransfer.files.length !== 0 &&
      event.dataTransfer.files.item(0).name.endsWith('.csv')
    ) {
      this.openPatientImportWizard(event.dataTransfer.files.item(0));
    }
  }

  public dragover(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private updateTotalNumberOfPatients() {
    this.totalNumberOfPatients =
      this.patientsPaginatorService.totalPatientsLoaded +
      (this.patientsPaginatorService.hasMorePatientsToLoad ? 1 : 0);
    this.hasMorePatientsToLoad = this.patientsPaginatorService.hasMorePatientsToLoad;
  }

  private setLastAssessment(patients: Partial<Patient>[]) {
    patients.forEach(patient => {
      patient[this.lastAssessmentKey] = ModelUtils.sortByDate(
        patient.assessments.items,
        'createdAt'
      )[0];
    });
  }

  announceSortChange(sort: Sort, patients: Partial<Patient>[]) {
    this.patients$.next([
      ...patients.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'firstName':
            return this.compare(a.firstName, b.firstName, isAsc);
          case 'lastName':
            return this.compare(a.lastName, b.lastName, isAsc);
          case 'email':
            return this.compare(a.email, b.email, isAsc);
          case 'birthday':
            return this.compare(
              new Date(a.dateOfBirth).getTime() || null,
              new Date(b.dateOfBirth).getTime() || null,
              isAsc
            );
          case 'lastEncounter':
            return this.compare(
              new Date(a.createdAt).getTime() || null,
              new Date(b.createdAt).getTime() || null,
              isAsc
            );
        }
      })
    ]);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
