import { Component, Input, OnInit } from '@angular/core';
import { PricingItem } from '../pricing-item';

@Component({
  selector: 'csi-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.css']
})
export class PricingTableComponent implements OnInit {
  @Input() treatments: PricingItem[];

  displayedColumns: string[] = ['item', 'price'];

  ngOnInit() {
    console.log(this.treatments);
    // Update the data source whenever the treatments input changes
  }
}
