import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RemoteJsonFileService } from '../remote-json-file.service';
import { DryEyeCategory } from './dry-eye-category.model';

export type TreatmentClass = 'exercise' | 'procedure' | 'otc' | 'rx';

export interface StarIngredient {
  name: string;
  description: string;
}

export interface IndicationsRule {
  categories?: DryEyeTreatmentCategory[];
  itchiness?: boolean;
  telangiectasia?: boolean;
}

export interface DryEyeTreatmentCategory extends DryEyeCategory {
  popularChoice?: boolean;
}

export interface Treatment {
  units: number;
  price: number;
  id: string;
  name: string;
  class: TreatmentClass;
  indications?: string[];
  indicationsRule?: IndicationsRule;
  image: string;
  searchText: string;
}

export interface EyeDrop extends Treatment {
  type: string;
  manufacturer: string;
  preservatives: string[];
  starIngredients: StarIngredient[];
  contactLensCompatible: boolean;
  comments: string;
  dosage?: string;
}

export interface Therapy extends Treatment {
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class TreatmentsService extends RemoteJsonFileService {
  public idToTreatmentMap = new Map<string, Treatment>();
  private _allTreatments: Treatment[] = [];
  private _otcEyeDrops: EyeDrop[] = [];
  private _rxEyeDrops: EyeDrop[] = [];
  private _exercises: Therapy[] = [];
  private _procedures: Therapy[] = [];

  constructor(httpClient: HttpClient) {
    super(httpClient, 'assets/treatments.json');

    this.loadTreatments().subscribe();
  }

  public getTreatmentById(id: string): Observable<Treatment> {
    return this.allTreatments$.pipe(
      map(() => {
        const treatment = this.idToTreatmentMap.get(id);
        console.assert(
          treatment,
          `Could not find treatment with id of ${id}. idToTreatmentMap length is ${this.idToTreatmentMap.entries.length} and treatment list length is ${this._allTreatments.length}`
        );
        return treatment;
      })
    );
  }

  get allTreatments$(): Observable<ReadonlyArray<Treatment>> {
    return this.loadTreatments().pipe(map(() => this._allTreatments));
  }

  get otcEyeDrops$(): Observable<ReadonlyArray<EyeDrop>> {
    return this.loadTreatments().pipe(map(() => this._otcEyeDrops));
  }

  get rxEyeDrops$(): Observable<ReadonlyArray<EyeDrop>> {
    return this.loadTreatments().pipe(map(() => this._rxEyeDrops));
  }

  get exercises$(): Observable<ReadonlyArray<Therapy>> {
    return this.loadTreatments().pipe(map(() => this._exercises));
  }

  get procedures$(): Observable<ReadonlyArray<Therapy>> {
    return this.loadTreatments().pipe(map(() => this._procedures));
  }

  getIndications(eyeDrop: EyeDrop): string {
    return eyeDrop.indications && eyeDrop.indications.length > 0
      ? eyeDrop.indications.join(', ')
      : null;
  }

  getPreservatives(eyeDrop: EyeDrop): string {
    return eyeDrop.preservatives && eyeDrop.preservatives.length > 0
      ? eyeDrop.preservatives.join(', ')
      : null;
  }

  getStarIngredients(eyeDrop: EyeDrop): string {
    return eyeDrop.starIngredients
      ? eyeDrop.starIngredients.map(ingredient => ingredient.name).join(', ')
      : null;
  }

  public getTreatmentClass(id: string): TreatmentClass | undefined {
    return this.idToTreatmentMap.get(id).class;
  }

  private loadTreatments(): Observable<boolean> {
    return this.load<EyeDrop[]>(data => {
      this._allTreatments = data.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      this._allTreatments.forEach(treatment => this.idToTreatmentMap.set(treatment.id, treatment));

      this._allTreatments = this._allTreatments.map(treatment => {
        treatment.searchText = this.getSearchText(treatment);
        return treatment;
      });

      this._otcEyeDrops = this.filterTo('otc') as EyeDrop[];
      this._rxEyeDrops = this.filterTo('rx') as EyeDrop[];
      this._exercises = this.filterTo('exercise') as Therapy[];
      this._procedures = this.filterTo('procedure') as Therapy[];

      if (!environment.production) {
        this.checkIfDuplicatesExist(this._allTreatments);
        this.assertNoMissedTreatments();
      }
    });
  }

  treatmentKeysToTreatments(keys: string[]): Treatment[] {
    return keys.map(key => this._allTreatments.find(treatment => treatment.id === key));
  }

  private filterTo(className: TreatmentClass): Treatment[] {
    return this._allTreatments.filter(treatment => treatment.class === className);
  }

  private getSearchText(treatment: Treatment): string {
    let searchText: string;
    if (treatment.class === 'otc' || treatment.class === 'rx') {
      const eyeDrop = treatment as EyeDrop;
      let preservatives = this.getPreservatives(eyeDrop);
      preservatives = preservatives ? preservatives : 'preservative free';
      const starIngredients = this.getStarIngredients(eyeDrop);
      const indications = this.getIndications(eyeDrop);
      searchText = `${eyeDrop.name} ${eyeDrop.type} ${eyeDrop.manufacturer} ${preservatives} ${starIngredients} ${indications}`;
    } else {
      searchText = treatment.name;
    }
    return searchText.toLocaleLowerCase();
  }

  private checkIfDuplicatesExist(treatments: Treatment[]) {
    const allNames = new Set(),
      allIds = new Set(),
      allImages = new Set();
    treatments.forEach(treatment => {
      const name = treatment.name.toLowerCase();
      const image = treatment.image.toLowerCase();
      const id = treatment.id.toLowerCase();

      console.assert(!allNames.has(name), `Error, duplicate name found: ${name}`);
      console.assert(
        !allImages.has(image) || image === '',
        `Error, duplicate image url found: ${image}`
      );
      console.assert(!allIds.has(id), `Error, duplicate ID found: ${id}`);

      allNames.add(name);
      allImages.add(image);
      allIds.add(id);
    });
  }

  private assertNoMissedTreatments() {
    const lengthOfAllFilteredTreatments =
      this._otcEyeDrops.length +
      this._rxEyeDrops.length +
      this._procedures.length +
      this._exercises.length;
    console.assert(
      lengthOfAllFilteredTreatments === this._allTreatments.length,
      `Error, length of all filtered treatments (${lengthOfAllFilteredTreatments}) dpes not match length of all treatments (${this._allTreatments.length})`
    );
  }
}
