import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { PatientService } from 'src/app/core/api/patient.service';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { TreatmentPlanCustomizerModalComponent } from './treatment-plan-customizer-modal/treatment-plan-customizer-modal.component';
import { TreatmentPlan } from './treatment-plan-table.model';
import { TreatmentPlanService } from './treatment-plan-table.service';
@Component({
  selector: 'csi-treatment-plan-table',
  templateUrl: './treatment-plan-table.component.html',
  styleUrls: ['./treatment-plan-table.component.scss'],
  animations: [
    trigger('summaryExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TreatmentPlanTableComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [
    'dateCreated',
    'linkedAssessment',
    'numTreatments',
    'treatmentStatus',
    'actions'
  ];

  treatmentPlanIdToExpand: string | null = null;
  private isSorting = false;

  constructor(
    private dialog: MatDialog,
    private patientService: PatientService,
    public treatmentPlanService: TreatmentPlanService,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit(): void {
    this.loadTreatmentPlans();
  }

  loadTreatmentPlans(): void {
    const patientId = this.patientService.patient.id;
    this.treatmentPlanService.listTreatmentPlansByPatientId(patientId).subscribe(
      (plans: TreatmentPlan[]) => {
        plans.forEach(plan => {
          plan.linkedAssessmentName = this.treatmentPlanService.getLinkedAssessment(plan);
          plan.commentAndStatusHistory = JSON.parse(plan.commentAndStatusHistory);
        });
        this.treatmentPlanService.treatmentPlans$.next(plans);
        this.sortData({ active: 'dateCreated', direction: 'desc' });
      },
      error => {
        console.error('Error loading treatment plans:', error);
      }
    );
  }

  addTreatmentLinkedAssessment(plan: TreatmentPlan): TreatmentPlan {
    return {
      ...plan,
      treatmentSpecifications: plan.treatmentSpecifications.map(spec => ({
        ...spec
      })),
      linkedAssessmentName: this.treatmentPlanService.getLinkedAssessment(plan)
    };
  }

  sortData(sort: Sort): void {
    if (this.isSorting) return;
    this.isSorting = true;

    const currentPlans = this.treatmentPlanService.treatmentPlans$.value;
    const sortedPlans = this.sortTreatmentPlans(currentPlans, sort);

    this.treatmentPlanService.treatmentPlans$.next(sortedPlans);

    this.isSorting = false;
  }

  announceSortChange(sort: Sort): void {
    if (this.isSorting) return;
    this.sortData(sort);
  }

  sortTreatmentPlans(plans: TreatmentPlan[], sort: Sort): TreatmentPlan[] {
    return [...plans].sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dateCreated':
          return this.compareDate(a.createdAt, b.createdAt, isAsc);
        default:
          return 0;
      }
    });
  }

  compareDate(a: string, b: string, isAsc: boolean): number {
    return (new Date(a).getTime() - new Date(b).getTime()) * (isAsc ? 1 : -1);
  }

  toggleSummaryRow(plan: TreatmentPlan): void {
    this.treatmentPlanIdToExpand = this.treatmentPlanIdToExpand === plan.id ? null : plan.id;
  }

  openTreatmentPlan(plan: TreatmentPlan, event?: Event): void {
    if (event) event.stopPropagation();

    const rawAssessment = this.findLinkedAssessment(plan);
    this.dialog.open(TreatmentPlanCustomizerModalComponent, {
      data: {
        assessment: rawAssessment,
        patientId: this.patientService.patient.id,
        plan
      },
      width: '70vw',
      height: '80vh'
    });
  }

  findLinkedAssessment(plan: TreatmentPlan): any {
    return this.patientService.assessments.find(assessment => {
      try {
        const parsedBody = JSON.parse(assessment.body || '{}');
        return (
          parsedBody.assessmentForm &&
          parsedBody.assessmentForm.id === plan.treatmentPlanAssessmentId
        );
      } catch {
        return false;
      }
    });
  }

  deletePlan(plan: TreatmentPlan, event: Event): void {
    event.stopPropagation();

    this.confirmModalService
      .show(
        'Delete Treatment Plan',
        'Deleting Treatment Plan. Do you still wish to continue?',
        'Continue',
        'Cancel'
      )
      .subscribe(continueLinking => {
        if (continueLinking) {
          this.treatmentPlanService.deleteTreatmentPlan({ id: plan.id }).subscribe(
            () => {},
            error => {
              console.error('Error deleting treatment plan:', error);
            }
          );
        }
      });
  }
}
