import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Clinic } from 'src/API';
import { IntakeFormService } from 'src/app/intake-form/intake-form.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';

@Component({
  selector: 'csi-intake-form-links-modal',
  templateUrl: './intake-form-links-modal.component.html',
  styleUrls: ['./intake-form-links-modal.component.css']
})
export class IntakeFormLinksModalComponent implements OnInit {
  public intakeFormLink = `${window.location.protocol}//${window.location.host}/intake-form/${this.clinicSetupService.clinicId}?country=${this.clinicSetupService.clinic.country}`;
  public intakeFormTemplateIdToSchemaMap;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { clinic: Clinic; showEmr: boolean; isAdmin: boolean },
    private dialogRef: MatDialogRef<IntakeFormLinksModalComponent>,
    private clinicSetupService: ClinicSetupService,
    private intakeFormService: IntakeFormService
  ) {}

  ngOnInit() {
    this.intakeFormTemplateIdToSchemaMap = {
      ...this.intakeFormService.defaultIntakeFormTemplateSchemas,
      ...(this.clinicSetupService.formTemplateKeyToTemplatesMap['intake'] || []).reduce(
        (acc, formTemplate) => {
          acc[formTemplate.id] = formTemplate.schema;
          return acc;
        },
        []
      )
    };
  }

  public static open(matDialog: MatDialog) {
    return matDialog.open(IntakeFormLinksModalComponent, {
      width: '900px',
      height: '75dvh'
    });
  }
}
