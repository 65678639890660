import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TreatmentTemplateService } from '../../treatment-template/treatment-template.service';

@Component({
  selector: 'csi-treatment-template-select',
  templateUrl: './treatment-template-select.component.html',
  styleUrls: ['./treatment-template-select.component.css']
})
export class TreatmentTemplateSelectComponent implements OnInit {
  @Input() treatmentTemplateFormControl: FormControl;

  constructor(public treatmentTemplateService: TreatmentTemplateService) {}

  ngOnInit() {
  }
}
