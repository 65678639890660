import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { capitalize } from 'lodash-es';
import { Patient } from 'src/app/core/api/patient.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { EncountersRoutes } from 'src/app/encounters/encounters.routes';
import { MassClinicService } from 'src/app/mass-clinics/mass-clinic.service';

@Component({
  selector: 'csi-navigate-to-new-assessment-modal',
  templateUrl: './navigate-to-new-assessment-modal.component.html',
  styleUrls: ['./navigate-to-new-assessment-modal.component.css']
})
export class NavigateToNewAssessmentModalComponent implements OnInit {
  constructor(
    public staffService: StaffService,
    private massClinicService: MassClinicService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<NavigateToNewAssessmentModalComponent>,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  public static open(dialog: MatDialog) {
    return dialog.open<NavigateToNewAssessmentModalComponent>(
      NavigateToNewAssessmentModalComponent,
      {
        maxHeight: '90vh',
        minWidth: '60vw',
        data: {}
      }
    );
  }

  onPatientSelect(patient: Patient) {
    this.router.navigate(
      [`/patients/${patient.id}`, { outlets: { encounters: EncountersRoutes.New } }],
      {
        relativeTo: this.route.parent
      }
    );
    setTimeout(() => {
      this._snackBar.open("Let's get started on your new assessment!", '', {
        duration: 4000,
        verticalPosition: 'bottom'
      });
    }, 1000);

    this.dialogRef.close();
  }

  public createNewPatient(patientFullName: string) {
    let [firstName, lastName] = patientFullName.split(/\s+/);
    firstName = capitalize(firstName);
    lastName = capitalize(lastName);
    this.massClinicService
      .openPatientModal({ firstName, lastName })
      .afterClosed()
      .subscribe(newPatient => {
        if (newPatient) {
          this.onPatientSelect(newPatient);
        }
      });
  }
}
