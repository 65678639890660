import { Component, OnInit } from '@angular/core';
import { DynamicActionService } from 'src/app/core/api/dynamic-action.service';
import { NavigationService } from 'src/app/core/api/navigation.service';
import { DynamicActionConfig } from 'src/app/shared/models/dynamic-action.model';
import { bottomActionsSchema } from './../../../core/schemas/navigation-schema';

@Component({
  selector: 'csi-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public bottomActionsSchema = bottomActionsSchema;
  constructor(
    public dynamicActionService: DynamicActionService,
    public navigationService: NavigationService
  ) {}

  ngOnInit() {}
  ngOnDestroy(): void {}

  performAction(actionConfig: DynamicActionConfig | undefined, e?: Event) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }

    if (actionConfig) {
      this.dynamicActionService.performAction(actionConfig);
    }
  }
}
