import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySort'
})
export class ArraySortPipe implements PipeTransform {
  transform(
    array: string[],
    keyValueMap: { [key: string]: string },
    sortKey: string = null,
    keysToMoveLast: string[] = []
  ): any {
    if (keyValueMap) {
      return array.sort((a, b) => {
        if (keysToMoveLast.includes(a)) {
          return 1;
        }
        if (keysToMoveLast.includes(b)) {
          return -1;
        }
        return (sortKey ? keyValueMap[a][sortKey] : keyValueMap[a])
          .trim()
          .localeCompare((sortKey ? keyValueMap[b][sortKey] : keyValueMap[b]).trim());
      });
    }
    return array.sort();
  }
}
