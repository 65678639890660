import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { Notification } from 'src/API';
import { QuestionnaireRequestService } from 'src/app/core/api/questionnaire-request.service';
import { ReferralService } from 'src/app/referral/referral.service';
import { QuestionnaireResponseModalComponent } from 'src/app/shared/consult-forms/questionnaire-response-modal/questionnaire-response-modal.component';
import { InboxModalComponent } from '../inbox-modal/inbox-modal.component';
import { NotificationFormat } from './../inbox.model';

@Component({
  selector: 'csi-notification-content-display',
  templateUrl: './notification-content-display.component.html',
  styleUrls: ['./notification-content-display.component.css']
})
export class NotificationContentDisplayComponent implements OnInit, OnChanges {
  @Input() notification: Notification;
  public notificationContent = null;
  public NotificationFormat = NotificationFormat;
  constructor(
    public router: Router,
    private questionnaireRequestService: QuestionnaireRequestService,
    private referralService: ReferralService,
    public inboxDialogRef: MatDialogRef<InboxModalComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.notification && this.notification.content) {
      this.notificationContent = JSON.parse(this.notification.content);
    }
  }

  viewQuestionnaireResponse() {
    this.questionnaireRequestService
      .getQuestionnaireRequest(this.notificationContent.id)
      .subscribe(questionnaire => {
        QuestionnaireResponseModalComponent.open(this.dialog, questionnaire);
      });
  }

  goToReferral() {
    this.referralService.currentReferral = null;
    const url = `/refer/${this.notificationContent.id}`;
    this.router.navigateByUrl(url);
    this.inboxDialogRef.close();
  }

  goToIntakeForm() {
    const url = `/intake-forms/${this.notificationContent.id}`;
    this.router.navigateByUrl(url);
    this.inboxDialogRef.close();
  }
}
