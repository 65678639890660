import { EncountersRoutes } from 'src/app/encounters/encounters.routes';
import { SidenavActionConfig, SideNavSchema } from 'src/app/shared/models/navigation.model';

export const bottomActionsSchema: SidenavActionConfig[] = [
  {
    friendlyName: 'Clinic Setup',
    icon: { name: 'settings', type: 'matIcon' },
    action: {
      serviceName: 'SideNavActionsService',
      functionName: 'openClinicSetupModal',
      params: {}
    }
  }
];

export const mainSideNavSchema: SideNavSchema = {
  header: {
    title: 'Home',
    icon: { name: 'home', type: 'matIcon' },
    action: {
      serviceName: 'RouteToService',
      functionName: 'routeTo',
      params: { routeTo: '/home' }
    }
  },
  groups: [
    {
      actions: [
        {
          icon: { name: 'people', type: 'matIcon' },
          friendlyName: 'Patients',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/patients' }
          },
          subActionConfig: [
            {
              icon: { name: 'add', type: 'matIcon' },
              tooltip: 'New Patient',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openCreatePatientModal',
                params: {}
              }
            },
            {
              icon: { name: 'cloud_upload', type: 'matIcon' },
              tooltip: 'Import Patients',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openPatientImportWizard',
                params: {}
              }
            }
          ]
        },
        {
          icon: { name: 'description', type: 'matIcon' },
          friendlyName: 'Intake forms',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/intake-forms' }
          },
          subActionConfig: [
            {
              icon: { name: 'qr_code', type: 'matIcon' },
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openClinicSetupModal',
                params: { selectedTabLabel: 'Intake Form' }
              }
            }
          ]
        }
      ]
    },
    {
      actions: [
        {
          icon: { name: 'send', type: 'matIcon' },
          friendlyName: 'Send questionnaires',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openSendMultipleQuestionnairesDialog',
            params: {}
          }
        },
        {
          icon: { name: 'event', type: 'matIcon' },
          friendlyName: 'Mass clinic',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/mass-clinics' }
          }
        },
        {
          icon: { name: 'add_chart', type: 'matIcon' },
          friendlyName: 'New assessment',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openNavigateToNewAssessmentModal',
            params: {}
          }
        }
      ]
    }
  ]
};

export const patientsDashboardSideNavSchema: SideNavSchema = {
  header: {
    title: 'Home',
    icon: { name: 'home', type: 'matIcon' },
    action: {
      serviceName: 'RouteToService',
      functionName: 'routeTo',
      params: { routeTo: '/home' }
    }
  },
  groups: [
    {
      actions: [
        {
          icon: { name: 'people', type: 'matIcon' },
          friendlyName: 'Patients',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/patients' }
          },
          subActionConfig: [
            {
              icon: { name: 'add', type: 'matIcon' },
              tooltip: 'New Patient',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openCreatePatientModal',
                params: {}
              }
            },
            {
              icon: { name: 'cloud_upload', type: 'matIcon' },
              tooltip: 'Import Patients',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openPatientImportWizard',
                params: {}
              }
            }
          ]
        },
        {
          icon: { name: 'description', type: 'matIcon' },
          friendlyName: 'Intake forms',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/intake-forms' }
          },
          subActionConfig: [
            {
              icon: { name: 'qr_code', type: 'matIcon' },
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openClinicSetupModal',
                params: { selectedTabLabel: 'Intake Form' }
              }
            }
          ]
        }
      ]
    },
    {
      actions: [
        {
          icon: { name: 'send', type: 'matIcon' },
          friendlyName: 'Send questionnaires',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openSendMultipleQuestionnairesDialog',
            params: {}
          }
        },
        {
          icon: { name: 'file_copy', type: 'matIcon' },
          friendlyName: 'Copy to emr',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openCopyModal',
            params: {}
          }
        },
        {
          icon: { name: 'event', type: 'matIcon' },
          friendlyName: 'Mass clinic',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/mass-clinics' }
          }
        },
        {
          icon: { name: 'add_chart', type: 'matIcon' },
          friendlyName: 'New assessment',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openNavigateToNewAssessmentModal',
            params: {}
          }
        }
      ]
    }
  ]
};

export const defaultSideNavSchema: SideNavSchema = {
  header: {
    title: 'Home',
    icon: { name: 'home', type: 'matIcon' },
    action: {
      serviceName: 'RouteToService',
      functionName: 'routeTo',
      params: { routeTo: '/home' }
    }
  },
  groups: [
    {
      actions: [
        {
          icon: { name: 'people', type: 'matIcon' },
          friendlyName: 'Patients',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/patients' }
          },
          subActionConfig: [
            {
              icon: { name: 'add', type: 'matIcon' },
              tooltip: 'New Patient',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openCreatePatientModal',
                params: {}
              }
            },
            {
              icon: { name: 'cloud_upload', type: 'matIcon' },
              tooltip: 'Import Patients',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openPatientImportWizard',
                params: {}
              }
            }
          ]
        },
        {
          icon: { name: 'description', type: 'matIcon' },
          friendlyName: 'Intake forms',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/intake-forms' }
          },
          subActionConfig: [
            {
              icon: { name: 'qr_code', type: 'matIcon' },
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openClinicSetupModal',
                params: { selectedTabLabel: 'Intake Form' }
              }
            }
          ]
        }
      ]
    }
  ]
};

export const assessmentSideNavSchema: SideNavSchema = {
  ...defaultSideNavSchema,
  groups: [
    ...defaultSideNavSchema.groups,
    {
      actions: [
        {
          icon: { name: 'send', type: 'matIcon' },
          friendlyName: 'Send questionnaire',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openSendQuestionnaire',
            params: {}
          }
        }
      ]
    }
  ]
};

export const patientSideNavSchema: SideNavSchema = {
  header: {
    title: 'Home',
    icon: { name: 'home', type: 'matIcon' },
    action: {
      serviceName: 'RouteToService',
      functionName: 'routeTo',
      params: { routeTo: '/home' }
    }
  },
  groups: [
    {
      actions: [
        {
          icon: { name: 'people', type: 'matIcon' },
          friendlyName: 'Patients',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/patients' }
          },
          subActionConfig: [
            {
              icon: { name: 'add', type: 'matIcon' },
              tooltip: 'New Patient',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openCreatePatientModal',
                params: {}
              }
            },
            {
              icon: { name: 'cloud_upload', type: 'matIcon' },
              tooltip: 'Import Patients',
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openPatientImportWizard',
                params: {}
              }
            }
          ]
        },
        {
          icon: { name: 'description', type: 'matIcon' },
          friendlyName: 'Intake forms',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: '/intake-forms' }
          },
          subActionConfig: [
            {
              icon: { name: 'qr_code', type: 'matIcon' },
              action: {
                serviceName: 'SideNavActionsService',
                functionName: 'openClinicSetupModal',
                params: { selectedTabLabel: 'Intake Form' }
              }
            }
          ]
        }
      ]
    },
    {
      actions: [
        {
          icon: { name: 'send', type: 'matIcon' },
          friendlyName: 'Send questionnaire',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openSendQuestionnaire',
            params: {}
          }
        },
        {
          icon: { name: 'qr_code', type: 'matIcon' },
          friendlyName: 'Questionnaire qr code',
          action: {
            serviceName: 'SideNavActionsService',
            functionName: 'openTabletQuestionnaire',
            params: {}
          }
        },
        {
          icon: { name: 'add_chart', type: 'matIcon' },
          friendlyName: 'New assessment',
          action: {
            serviceName: 'RouteToService',
            functionName: 'routeTo',
            params: { routeTo: EncountersRoutes.New, outlet: 'encounters' }
          }
        }
      ]
    }
  ]
};
