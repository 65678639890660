import { Injectable } from '@angular/core';
import { SideNavSchema } from 'src/app/shared/models/navigation.model';
import { mainSideNavSchema } from '../schemas/navigation-schema';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public sideNavSchema = mainSideNavSchema;
  public sideNavExpanded = false;
  constructor() {}

  updateSideNavSchema(newSchema: SideNavSchema) {
    this.sideNavSchema = newSchema;
  }
}
