import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyService } from 'aws-amplify-angular';
import { ConsentFormComponent } from '../questionnaires/simple-questionnaire/consent-form/consent-form.component';
import { SharedModule } from '../shared/shared.module';
import { AssessmentService } from './api/assessment.service';
import { ClinicService } from './api/clinic.service';
import { Html2PdfService } from './api/html-2-pdf.service';
import { PatientService } from './api/patient.service';
import { QuestionnaireRequestService } from './api/questionnaire-request.service';
import { StaffService } from './api/staff.service';
import { AppSyncService } from './appsync.service';
import { AuthenticationService } from './authentication/authentication.service';
import { LogoutService } from './authentication/logout/logout.service';
import { HostingDomainProvider, SupportEmailProvider } from './domain-accessor';
import { TreatmentsService } from './remote-json/treatments/treatments.service';

@NgModule({
  entryComponents: [ConsentFormComponent],
  declarations: [ConsentFormComponent],
  imports: [CommonModule, SharedModule, BrowserAnimationsModule],
  providers: [
    AppSyncService,
    AuthenticationService,
    AmplifyService,
    AssessmentService,
    ClinicService,
    StaffService,
    ErrorHandler,
    Html2PdfService,
    PatientService,
    QuestionnaireRequestService,
    LogoutService,
    SupportEmailProvider,
    HostingDomainProvider,
    TreatmentsService
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard, see https://angular.io/guide/styleguide#style-04-12
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`
      );
    }
  }
}
