import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from 'src/app/core/api/patient.service';
import { TreatmentsService } from 'src/app/core/remote-json/treatments/treatments.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { TreatmentUnitFormGroup } from 'src/app/pricing/packages/package.model';
import { TreatmentPlan } from '../treatment-plan-table.model';
import { TreatmentPlanService } from '../treatment-plan-table.service';

export interface StatusAndComments {
  date: Date;
  status: string;
  comment: string;
}

@Component({
  selector: 'csi-treatment-plan-customizer-modal',
  templateUrl: './treatment-plan-customizer-modal.component.html',
  styleUrls: ['./treatment-plan-customizer-modal.component.scss']
})
export class TreatmentPlanCustomizerModalComponent implements OnInit {
  statusUpdateForm = new FormGroup({
    status: new FormControl(''),
    otherStatus: new FormControl(''),
    comment: new FormControl('')
  });
  statusHistory: Array<StatusAndComments> = [];
  @Input() patientId: string;
  treatmentPlans: any[] = [];

  assessment: any = {};
  notes = '';

  treatmentClassToRecommendedIdMap = {
    exercise: 'recommendedExercises',
    otc: 'recommendedEyeDrops',
    procedure: 'recommendedProcedures',
    rx: 'recommendedRxEyeDrops'
  };

  treatmentPlanSchema = {
    recommendedExercises: {
      treatmentIdAndFriendlyNameMap: {},
      friendlyLabel: 'Recommended Exercises',
      treatmentUnitFormGroups: []
    },
    recommendedEyeDrops: {
      treatmentIdAndFriendlyNameMap: {},
      friendlyLabel: 'Recommended Eye Drops',
      treatmentUnitFormGroups: []
    },
    recommendedProcedures: {
      treatmentIdAndFriendlyNameMap: {},
      friendlyLabel: 'Recommended Procedures',
      treatmentUnitFormGroups: []
    },
    recommendedRxEyeDrops: {
      treatmentIdAndFriendlyNameMap: {},
      friendlyLabel: 'Recommended Rx Eye Drops',
      treatmentUnitFormGroups: []
    }
  };
  additionalComments = new FormControl('');

  constructor(
    private treatmentPlanService: TreatmentPlanService,
    private dialogRef: MatDialogRef<TreatmentPlanCustomizerModalComponent>,
    private patientService: PatientService,
    private clinicSetupService: ClinicSetupService,
    public treatmentsService: TreatmentsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      plan: any;
      assessment: any;
      patientId: string;
    }
  ) {}

  ngOnInit() {
    this.patientId = this.data.patientId || this.patientService.patient.id;

    if (this.data.plan) {
      this.populateFormWithPlan(this.data.plan);
    } else {
      this.assessment = {
        ...this.data.assessment,
        body: JSON.parse(this.data.assessment.body || '{}')
      };

      const assessmentForm = this.assessment.body.assessmentForm || {};

      Object.entries(this.treatmentPlanSchema).forEach(
        ([recommendedClassKey, recommendedClassValue]) => {
          (assessmentForm[recommendedClassKey] || []).forEach(recommendationForClass => {
            const group = new TreatmentUnitFormGroup();
            const treatmentId = recommendationForClass.id;
            group.patchValue({
              treatmentId,
              units: recommendationForClass.units || 1
            });
            recommendedClassValue.treatmentUnitFormGroups.push(group);
            recommendedClassValue.treatmentIdAndFriendlyNameMap[
              treatmentId
            ] = this.treatmentsService.idToTreatmentMap.get(treatmentId).name;
          });
        }
      );

      this.statusHistory = [
        {
          date: new Date(),
          status: 'Created',
          comment: 'Treatment Plan created.'
        }
      ];
    }
  }

  populateFormWithPlan(plan: TreatmentPlan) {
    plan.treatmentSpecifications.forEach(spec => {
      const group = new TreatmentUnitFormGroup();
      group.patchValue({
        treatmentId: spec.treatmentId,
        units: spec.units,
        comment: spec.comment
      });

      const treatment = this.treatmentsService.idToTreatmentMap.get(spec.treatmentId);
      const treatmentClass = treatment.class;

      this.treatmentPlanSchema[
        this.treatmentClassToRecommendedIdMap[treatmentClass]
      ].treatmentUnitFormGroups.push(group);
      this.treatmentPlanSchema[
        this.treatmentClassToRecommendedIdMap[treatmentClass]
      ].treatmentIdAndFriendlyNameMap[treatment.id] = treatment.name;
    });

    try {
      if (Array.isArray(plan.commentAndStatusHistory)) {
        this.statusHistory = [...plan.commentAndStatusHistory];
      } else {
        const parsedHistory: StatusAndComments[] = JSON.parse(plan.commentAndStatusHistory);
        if (Array.isArray(parsedHistory)) {
          this.statusHistory = [...parsedHistory];
        }
      }
    } catch (e) {
      console.warn('No status and comment array', e);
    }
  }

  addStatusEntry() {
    const statusVal = this.statusUpdateForm.controls.status.value;
    const otherStatusVal = this.statusUpdateForm.controls.otherStatus.value;
    const commentVal = this.statusUpdateForm.controls.comment.value;

    const status = statusVal === 'Other' ? otherStatusVal : statusVal;

    if (status && commentVal) {
      this.statusHistory.push({
        date: new Date(),
        status: status,
        comment: commentVal
      });

      this.statusHistory = [...this.statusHistory];
      this.statusUpdateForm.reset();
    }
  }

  onSave() {
    const treatmentSpecifications = [];
    Object.values(this.treatmentPlanSchema).forEach(treatmentPlanSchemaValue => {
      treatmentPlanSchemaValue.treatmentUnitFormGroups.forEach(treatment => {
        treatmentSpecifications.push({
          treatmentId: treatment.get('treatmentId').value,
          units: treatment.get('units').value,
          comment: (treatment.get('comment') && treatment.get('comment').value) || ''
        });
      });
    });

    const input = {
      id: this.data.plan && this.data.plan.id,
      treatmentPlanClinicId: this.clinicSetupService.clinicId,
      treatmentPlanPatientId: this.patientId,
      treatmentPlanAssessmentId: this.assessment.id,
      treatmentSpecifications,
      commentAndStatusHistory: JSON.stringify(this.statusHistory)
    };

    const saveOperation = this.data.plan
      ? this.treatmentPlanService.updateTreatmentPlan(input)
      : this.treatmentPlanService.createTreatmentPlan(input);

    saveOperation.subscribe(
      result => {
        this.dialogRef.close(result);
      },
      error => {
        console.error('Error saving treatment plan:', error);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
