
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import { PatientService } from 'src/app/core/api/patient.service';
import {
  RiskFactor,
  RiskFactorDisplay,
  modifiableRiskFactors,
  nonModifiableRiskFactors,
  riskLevelToNameMap
} from './risk-factors.model';
import { RiskFactorsService } from './risk-factors.service';

@Component({
  selector: 'csi-risk-factors-modal',
  templateUrl: './risk-factors-modal.component.html',
  styleUrls: ['./risk-factors-modal.component.css']
})
export class RiskFactorsModalComponent implements OnInit {
  @ViewChild('riskFactorsEl', { static: false }) riskFactorsEl: ElementRef;
  public nonModifiableData = null;
  public isCopied = false;
  public modifiableRiskFactorsDisplay: RiskFactorDisplay[] = [];
  public nonModifiableRiskFactorsDisplay: RiskFactorDisplay[] = [];
  public riskLevelToNameMap = riskLevelToNameMap;
  public modalView = true;
  public lang: string = this.translocoService.getActiveLang();
  public translateObject = this.translocoService.getTranslation(
    this.translocoService.getActiveLang()
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public patientService: PatientService,
    public translocoService: TranslocoService,
    public riskFactorsService: RiskFactorsService,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.patientService.patient) {
      this.nonModifiableData = JSON.parse(this.patientService.patient.nonModifiableData);
    }

    this.evaluateRiskLevels(
      modifiableRiskFactors,
      this.modifiableRiskFactorsDisplay,
      this.data.medicalHistoryData
    );
    this.evaluateRiskLevels(
      nonModifiableRiskFactors,
      this.nonModifiableRiskFactorsDisplay,
      this.nonModifiableData
    );
  }

  evaluateRiskLevels(
    riskFactors: { [key: string]: RiskFactor },
    riskFactorDisplay: RiskFactorDisplay[],
    data: any
  ) {
    Object.keys(riskFactors).forEach(key => {
      if (data[key] === 0 || data[key]) {
        const medicalHistoryValue = data[key];
        let riskLevel = null;
        let contributor = null;
        let condition = null;
        riskFactors[key].indicators.some(indicator => {
          if (
            this.riskFactorsService.compareValues(
              medicalHistoryValue,
              indicator.condition,
              indicator.operator
            )
          ) {
            riskLevel = indicator.riskLevel;
            condition = indicator.condition;
            contributor = indicator.contributor ? indicator.contributor : null;
            return true;
          }
        });

        if (riskLevel) {
          riskFactorDisplay.push({
            key: key,
            condition: condition,
            name: riskFactors[key].name,
            riskLevel: riskLevel,
            contributor: contributor
          });
        }
      }
    });
    riskFactorDisplay.sort((a, b) => this.riskFactorsService.compareRiskFactorDisplay(a, b));
  }

  public copyToImpressions() {
    this.modalView = false;
    this.changeRef.detectChanges();
    this.data.impressionsControl.setValue(
      this.data.impressionsControl.value + this.riskFactorsEl.nativeElement.innerHTML
    );
    this.isCopied = true;
    this.modalView = true;
    this.changeRef.detectChanges();
  }

  public changeLanguage(lang = 'en') {
    this.translocoService.load(lang).subscribe(() => {
      this.translocoService.setActiveLang(lang);
      this.translateObject = this.translocoService.getTranslation(
        this.translocoService.getActiveLang()
      );
    });
  }

}
