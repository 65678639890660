import { Component, Inject, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from 'src/app/core/api/patient.service';
import { TreatmentPlanCustomizerModalComponent } from '../treatment-plan-customizer-modal/treatment-plan-customizer-modal.component';

export interface Assessment {
  id: string;
  name: string;
  date: string;
  recommendedTreatments?: { name: string; category?: string; units?: number }[];
}

@Component({
  selector: 'csi-select-assessment-modal',
  templateUrl: './select-assessment-modal.component.html',
  styleUrls: ['./select-assessment-modal.component.scss']
})
export class SelectAssessmentModalComponent {
  @ViewChild('assessmentList', { static: false }) assessmentList!: MatSelectionList;
  assessments: Assessment[];
  dialog: any;
  public readonly encounterMethodFriendlyName = {
    advanced: 'Gold Standard',
    silver: ' Accelerated Dry Eye Assessment ',
    preOpScreening: 'Pre-Operative Screening',
    simple: 'Screening',
    preOp: 'ASCRS Pre-Op',
    preop: 'ASCRS Pre-Op',
    treatment: 'Treatment',
    followUp: 'Follow Up'
  };

  constructor(
    private dialogRef: MatDialogRef<SelectAssessmentModalComponent>,
    public patientService: PatientService,
    @Inject(MAT_DIALOG_DATA) public data: { assessments: Assessment[] }
  ) {}

  ngOnInit() {}

  selectedAssessment: Assessment | null = null;

  onSelectionChange(event: MatSelectionListChange) {
    this.selectedAssessment = event.option.value;
    event.source.deselectAll();
    event.option._setSelected(true);
  }

  confirmSelection(selectedAssessment: Assessment) {
    this.dialogRef.close(selectedAssessment);
  }

  openTreatmentPlanCustomizerModal(selectedAssessment: Assessment) {
    this.dialog.open(TreatmentPlanCustomizerModalComponent, {
      width: '90vw',
      maxWidth: 'none',
      height: '85vh',
      panelClass: 'treatment-plan-modal',
      data: { assessment: selectedAssessment }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
