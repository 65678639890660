import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractControlsMap } from 'src/app/shared/dynamic-form-group/dynamic-form-group';

interface TreatmentUnitFormControls extends AbstractControlsMap {
  treatmentId: FormControl;
  units: FormControl;
  comment: FormControl;
}

export class TreatmentUnitFormGroup extends FormGroup {
  constructor() {
    super({
      treatmentId: new FormControl(null, Validators.required),
      units: new FormControl(null, [Validators.required, Validators.min(1)]),
      comment: new FormControl('')
    } as TreatmentUnitFormControls);
  }
}

interface PackageFormControls extends AbstractControlsMap {
  id: FormControl;
  name: FormControl;
  price: FormControl;
  treatments: FormControl;
  treatmentUnit: FormControl;
  treatmentTemplateId: FormControl;
  icdCode: FormControl;
  friendlyDescription: FormControl;
}

export class PackageFormGroup extends FormGroup {
  constructor() {
    super({
      id: new FormControl({ value: null, disabled: true }),
      name: new FormControl(null, Validators.required),
      price: new FormControl(null, [Validators.required, Validators.min(0)]),
      treatments: new FormControl([]),
      treatmentUnit: new FormControl([]),
      treatmentTemplateId: new FormControl(null),
      icdCode: new FormControl(null),
      friendlyDescription: new FormControl(null)
    } as PackageFormControls);
  }
}

interface TreatmentPricingConfigFormControls extends AbstractControlsMap {
  id: FormControl;
  treatmentId: FormControl;
  price: FormControl;
}

export class TreatmentPricingConfigFormGroup extends FormGroup {
  constructor() {
    super({
      id: new FormControl(null, Validators.required),
      treatmentId: new FormControl(null, Validators.required),
      price: new FormControl(null, [Validators.required, Validators.min(0)])
    } as TreatmentPricingConfigFormControls);
  }
}

interface TreatmentPlanFormControls extends AbstractControlsMap {
  id: FormControl;
  assessmentId: FormControl;
  TreatmentUnitFormGroup: FormControl;
  linkedAssessment: FormControl;
  numTreatments: FormControl;
  dateCreated: FormControl;
  treatmentStatus: FormControl;
}

export class TreatmentPlanFormGroup extends FormGroup {
  constructor() {
    super({
      id: new FormControl(null, Validators.required),
      assessmentId: new FormControl(null, Validators.required),
      TreatmentUnitFormGroup: new FormControl([]),
      linkedAssessment: new FormControl(null, Validators.required),
      numTreatments: new FormControl(null, [Validators.required, Validators.min(1)]),
      dateCreated: new FormControl(null, Validators.required),
      treatmentStatus: new FormControl(null, Validators.required)
    } as TreatmentPlanFormControls);
  }
}

interface InvoiceFormControls extends AbstractControlsMap {
  id: FormControl;
  referenceNumber: FormControl;
  status: FormControl;
  dateCreated: FormControl;
  dateSent: FormControl;
  totalAmount: FormControl;
  isLocked: FormControl;
  isArchived: FormControl;
  treatments: FormControl;
  clinicDiscount: FormControl;
}

export class InvoiceFormGroup extends FormGroup {
  constructor() {
    super({
      id: new FormControl(null, Validators.required),
      referenceNumber: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
      dateCreated: new FormControl(null, Validators.required),
      dateSent: new FormControl(null),
      totalAmount: new FormControl(null, [Validators.required, Validators.min(0)]),
      isLocked: new FormControl(false),
      isArchived: new FormControl(false),
      treatments: new FormControl([]),
      clinicDiscount: new FormControl(null, Validators.min(0))
    } as InvoiceFormControls);
  }
}
