import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Doctor, PatientInvoiceDiscountType } from 'src/API';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { PatientService } from '../core/api/patient.service';
import { StaffService } from '../core/api/staff.service';
import { DomainOption, HOSTING_DOMAIN_ACCESSOR } from '../core/domain-accessor';
import { TreatmentsService } from '../core/remote-json/treatments/treatments.service';
import { TreatmentPlan } from '../encounters/encounter-dashboard/treatment-plan-table/treatment-plan-table.model';
import {
  Invoice,
  PatientInvoiceStatus,
  PatientInvoiceTreatmentPrice
} from '../invoice-table/invoice.model';
import { ClinicSetupService } from '../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { PricingService } from '../pricing/pricing.service';
import { InvoiceExportService } from './invoice-export.service';

@Component({
  selector: 'csi-invoice-export',
  templateUrl: './invoice-export.component.html',
  styleUrls: ['./invoice-export.component.scss']
})
export class InvoiceExportComponent {
  taxPercentage: number = 6;
  currentInvoice: Invoice = null;
  PatientInvoiceDiscountType = PatientInvoiceDiscountType;
  PatientInvoiceStatus = PatientInvoiceStatus;
  clinicId: string;
  public loggedInStaff: Doctor;
  public siteLogo: string;
  public siteLogoSmall: string;
  isEditMode: boolean = false;

  private readonly IMAGE_LOCATIONS = {
    [DomainOption.Seema]: '../../../assets/images/landing-page/seema-logo.png',
    [DomainOption.CSI]: '../../../assets/images/landing-page/logo-here.png',
    [DomainOption.Other]: '../../../assets/images/landing-page/logo-here.png'
  };
  private readonly IMAGE_LOCATIONS_SMALL = {
    [DomainOption.Seema]: '../../../assets/images/landing-page/seema-logo-small.png',
    [DomainOption.CSI]: '../../../assets/images/landing-page/logo-small.png',
    [DomainOption.Other]: '../../../assets/images/landing-page/logo-here.png'
  };

  constructor(
    public dialogRef: MatDialogRef<InvoiceExportComponent>,
    public pricingService: PricingService,
    public patientService: PatientService,
    public clinicSetupService: ClinicSetupService,
    public invoiceExportService: InvoiceExportService,
    public treatmentsService: TreatmentsService,
    public staffService: StaffService,
    private LoadingSpinnerService: LoadingSpinnerService,
    @Inject(HOSTING_DOMAIN_ACCESSOR) private domain: DomainOption,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: Invoice; selectedPlan: TreatmentPlan }
  ) {
    this.clinicId = this.clinicSetupService.clinicId;
  }

  ngOnInit() {
    this.loggedInStaff = this.staffService.staff;

    this.initializeSiteLogo();
    if (this.data && this.data.invoice) {
      this.currentInvoice = this.data.invoice;
      this.isEditMode = true;
    } else {
      this.updateSelectedTreatmentPlansWithPrices();
      this.isEditMode = false;
    }
  }
  private updateSelectedTreatmentPlansWithPrices(): void {
    this.LoadingSpinnerService.show();
    if (!this.clinicId) {
      console.error('No clinic ID available');
      return;
    }
    this.pricingService.listTreatmentConfigsForClinic(this.clinicId).subscribe(
      configs => {
        this.data.selectedPlan.treatmentSpecifications.forEach(spec => {
          const matchedConfig = configs.find(c => c.treatmentId === spec.treatmentId);
          spec.price = matchedConfig ? matchedConfig.price : 0;
        });
        this.initializeInvoice();
        console.log('current invoice', this.currentInvoice);
        this.LoadingSpinnerService.hide();
      },
      error => console.error('Error loading treatment prices:', error)
    );
  }

  private initializeSiteLogo() {
    this.siteLogo = this.IMAGE_LOCATIONS[this.domain];
    this.siteLogoSmall = this.IMAGE_LOCATIONS_SMALL[this.domain];
  }

  initializeInvoice() {
    const treatmentPrices = this.data.selectedPlan.treatmentSpecifications
      .filter(spec => spec.price !== undefined)
      .map(spec => ({
        treatmentId: spec.treatmentId,
        price: spec.price,
        units: spec.units
      }));

    const now = new Date().toISOString();
    const defaultDue = new Date();
    defaultDue.setMonth(defaultDue.getMonth() + 1);
    const defaultDueDate = defaultDue.toISOString().split('T')[0];

    this.currentInvoice = {
      referenceId:
        'INV-' +
        Math.random()
          .toString(36)
          .substr(2, 9)
          .toUpperCase(),
      status: PatientInvoiceStatus.draft,
      createdAt: now,
      treatmentPrices: treatmentPrices as [PatientInvoiceTreatmentPrice],
      patientInvoiceClinicId: this.clinicId || '',
      patientInvoicePatientId:
        (this.patientService.patient && this.patientService.patient.id) || '',
      discount: 0,
      discountType: PatientInvoiceDiscountType.flat,
      comment: '',
      dueDate: defaultDueDate
    };
  }

  get subtotal() {
    return this.currentInvoice.treatmentPrices.reduce(
      (acc, item) => acc + item.units * item.price,
      0
    );
  }
  get tax() {
    return this.subtotal * (this.taxPercentage / 100);
  }
  get totalAmount() {
    return this.subtotal - this.currentInvoice.discount + this.tax;
  }
  send() {}

  save() {
    this.LoadingSpinnerService.show();
    const input = this.createUpdateInput();
    console.log('Invoice update input:', input);

    if (this.currentInvoice.id) {
      this.invoiceExportService.updateInvoice(input).subscribe({
        next: updatedInvoice => {
          this.dialogRef.close(updatedInvoice);
          this.LoadingSpinnerService.hide();
        },
        error: error => {
          console.error('Error updating invoice:', error);
          this.LoadingSpinnerService.hide();
        }
      });
    } else {
      this.invoiceExportService.createInvoice(input as Invoice).subscribe({
        next: createdInvoice => {
          this.dialogRef.close(createdInvoice);
          this.LoadingSpinnerService.hide();
        },
        error: error => {
          console.error('Error creating invoice:', error);
          this.LoadingSpinnerService.hide();
        }
      });
    }
  }

  private isDate(value: any): value is Date {
    return Object.prototype.toString.call(value) === '[object Date]';
  }

  private createUpdateInput() {
    const dueDate = this.isDate(this.currentInvoice.dueDate)
      ? this.currentInvoice.dueDate.toISOString().split('T')[0]
      : typeof this.currentInvoice.dueDate === 'string' && this.currentInvoice.dueDate.includes('T')
      ? this.currentInvoice.dueDate.split('T')[0]
      : this.currentInvoice.dueDate;

    return {
      id: this.currentInvoice.id,
      referenceId: this.currentInvoice.referenceId,
      status: this.currentInvoice.status,
      discount: this.currentInvoice.discount,
      discountType: this.currentInvoice.discountType,
      dueDate,
      comment: this.currentInvoice.comment,
      treatmentPrices: this.currentInvoice.treatmentPrices.map(tp => ({
        treatmentId: tp.treatmentId,
        price: tp.price,
        units: tp.units
      })),
      patientInvoiceClinicId: this.currentInvoice.patientInvoiceClinicId,
      patientInvoicePatientId: this.currentInvoice.patientInvoicePatientId
    };
  }

  updateCalculations() {
    this.currentInvoice.treatmentPrices = [...this.currentInvoice.treatmentPrices];
  }

  printInvoice() {
    const invoiceElement = document.getElementById('invoiceContent');
    if (!invoiceElement) {
      console.error('Invoice content not found.');
      return;
    }
    const printContents = invoiceElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = `<div>${printContents}</div>`;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }
}
