import { InteractionsSetupModalComponent } from './../interactions-setup-modal/interactions-setup-modal.component';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, skipWhile, switchMap } from 'rxjs/operators';
import { Doctor } from 'src/API';
import { Clinic, ClinicService } from '../../core/api/clinic.service';
import { StaffService } from '../../core/api/staff.service';
import { LogoutService } from '../../core/authentication/logout/logout.service';
import { DomainOption, HOSTING_DOMAIN_ACCESSOR } from '../../core/domain-accessor';
import { S3Object } from '../../shared/attachments/s3-attachment.service';
import { MyProfileModalComponent } from '../my-profile-modal/my-profile-modal.component';
import { getAllClinic } from './../../../graphql/custom_queries';
import { ReferralService } from './../../referral/referral.service';
import { ChangeClinicModalComponent } from './../change-clinic-modal/change-clinic-modal.component';
import { ClinicSetupModalComponent } from './../clinic-setup-modal/clinic-setup-modal.component';
import { ReferralCenterSetupModalComponent } from './../referral-center-setup-modal/referral-center-setup-modal.component';
import { PricingComponent } from 'src/app/pricing/pricing.component';


@Component({
  selector: 'csi-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDropdownComponent implements OnDestroy {
  public readonly user$: Observable<Doctor>;
  public readonly clinic$: Observable<Clinic>;
  public readonly clinicLogo$: Observable<S3Object>;

  constructor(
    private clinicService: ClinicService,
    private staffService: StaffService,
    public referralService: ReferralService,
    private dialog: MatDialog,
    private logoutService: LogoutService,
    @Inject(HOSTING_DOMAIN_ACCESSOR) private domain: DomainOption
  ) {
    this.user$ = this.staffService.watchLoggedInDoctor().pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );

    this.clinic$ = this.user$.pipe(
      skipWhile(user => !(user.clinic && user.clinic.id)),
      switchMap(user => {
        return this.clinicService.getClinic(
          { id: user.clinic.id },
          getAllClinic,
          'cache-first',
          true
        );
      })
    );

    this.clinicLogo$ = this.clinic$.pipe(
      map((clinic: Clinic) => clinic.logo),
      distinctUntilChanged()
    );
  }


  ngOnDestroy() {}

  public logout() {
    this.logoutService.logout();
  }

  public showDoctorInfoModal(user: Doctor) {
    MyProfileModalComponent.open(this.dialog, user);
  }

  public showClinicInfoModal(clinic: Clinic) {
    ClinicSetupModalComponent.open(this.dialog, clinic, this.domain !== DomainOption.Seema);
  }
  public showReferralCenterSetupModal() {
    ReferralCenterSetupModalComponent.open(this.dialog);
  }
  public showPatientTrackingModal() {
    InteractionsSetupModalComponent.open(this.dialog);
  }

  public showChangeClinicModal() {
    ChangeClinicModalComponent.open(this.dialog);
  }

  public shouldShowMyProfileItem(user: Doctor): boolean {
    return !this.staffService.isDoctorMissingInformation(user);
  }

  public showPricingModal() {
    PricingComponent.open(this.dialog);
  }
}
