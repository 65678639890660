/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AssessmentRequest = {
  __typename: "AssessmentRequest",
  id?: string,
  type?: AssessmentRequestType | null,
  doctor?: Doctor,
  clinic?: Clinic,
  recipient?: Doctor,
  assessment?: Assessment,
  response?: string | null,
  priority?: Priority | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum AssessmentRequestType {
  EConsult = "EConsult",
  EReferral = "EReferral",
}


export type Doctor = {
  __typename: "Doctor",
  id?: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string,
  emailVerified?: boolean | null,
  agreedToTerms?: boolean | null,
  clinic?: Clinic,
  cognitoGroups?: Array< string | null > | null,
  patients?: ModelPatientConnection,
  practitionerId?: string | null,
  assessments?: ModelAssessmentConnection,
  assessmentRequests?: ModelAssessmentRequestConnection,
  inboundAssessmentRequests?: ModelAssessmentRequestConnection,
  createdAt?: string | null,
  updatedAt?: string | null,
  deactivated?: boolean | null,
  staffType?: StaffType | null,
  clinicLicenses?: number | null,
  cameraAssessmentRequest?: string | null,
  ownedClinics?: string | null,
  allowMultipleClinics?: boolean | null,
};

export type User = {
  __typename: "User",
  id?: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  agreedToTerms?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type Patient = {
  __typename: "Patient",
  id?: string,
  firstName?: string,
  lastName?: string,
  email?: string | null,
  emailVerified?: boolean | null,
  phone?: string | null,
  agreedToTerms?: boolean | null,
  doctor?: Doctor,
  clinic?: Clinic,
  patientClinicId?: string | null,
  dateOfBirth?: string | null,
  healthCard?: HealthCard,
  gender?: Gender | null,
  genderOther?: string | null,
  address?: string | null,
  assessments?: ModelAssessmentConnection,
  consentsToResearch?: boolean | null,
  consentsToPrivacyForm?: boolean | null,
  consentSource?: ConsentSource | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  linkedReferralPatientId?: string | null,
  referralSource?: string | null,
  nonModifiableData?: string | null,
  interactions?: ModelInteractionConnection,
  lastInteractionAt?: string | null,
  isColdLead?: boolean | null,
  fitzpatrickImage?: S3Object,
  questionnaireRequests?: ModelQuestionnaireRequestConnection,
};

export type Clinic = {
  __typename: "Clinic",
  id?: string,
  name?: string,
  address?: string,
  province?: string | null,
  country?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string,
  fax?: string | null,
  logo?: S3Object,
  doctors?: ModelDoctorConnection,
  owner?: Doctor,
  patients?: ModelPatientConnection,
  emrIntegration?: EMRIntegrationSettings,
  stripeInformation?: StripeInformation,
  clinicSetup?: ClinicSetup,
  agreedToTermsOfService?: boolean | null,
  referralCenter?: ReferralCenter,
  linkedReferralCenter?: ReferralCenter,
  clinicLinkedReferralCenterId?: string | null,
  clinicFormTemplates?: ModelFormTemplateConnection,
  hasDryEyePortalAccess?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type S3Object = {
  __typename: "S3Object",
  bucket?: string,
  key?: string,
  region?: string,
  fileName?: string | null,
  metadata?: string | null,
};

export type ModelDoctorConnection = {
  __typename: "ModelDoctorConnection",
  items?:  Array<Doctor | null > | null,
  nextToken?: string | null,
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items?:  Array<Patient | null > | null,
  nextToken?: string | null,
};

export type EMRIntegrationSettings = {
  __typename: "EMRIntegrationSettings",
  fax?: FaxToEMRSettings,
};

export type FaxToEMRSettings = {
  __typename: "FaxToEMRSettings",
  enabled?: boolean,
  faxNumber?: string | null,
};

export type StripeInformation = {
  __typename: "StripeInformation",
  subscriptionId?: string | null,
  customerId?: string | null,
  lastDigitsOfCard?: string | null,
  nextPaymentDate?: string | null,
  nameOnCard?: string | null,
  subscriptionStatus?: string | null,
};

export type ClinicSetup = {
  __typename: "ClinicSetup",
  id?: string,
  clinicSetupClinicId?: string,
  textFavorites?: ModelTextFavoriteConnection,
  treatmentTemplates?: ModelTreatmentTemplateConnection,
  doctorLicenses?: number | null,
  layouts?:  Array<Layout | null > | null,
  interactionsSetup?: string | null,
  defaultQuestionnaireLanguage?: string | null,
  defaultPdfLanguage?: string | null,
  temperatureUnit?: TemperatureUnit | null,
  questionnaireExpiry?: number | null,
  welcomeMessageConfig?: string | null,
  emailNotificationsConfig?: string | null,
  hiddenTextFavorites?: Array< string | null > | null,
  hiddenTreatmentTemplates?: Array< string | null > | null,
  textFavoriteConfig?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelTextFavoriteConnection = {
  __typename: "ModelTextFavoriteConnection",
  items?:  Array<TextFavorite | null > | null,
  nextToken?: string | null,
};

export type TextFavorite = {
  __typename: "TextFavorite",
  id?: string,
  shortcut?: string,
  text?: string,
  type?: TextFavoriteType,
  treatmentTemplate?: TreatmentTemplate,
  textFavoriteTreatmentTemplateId?: string | null,
  textFavoriteClinicId?: string,
  copiedTextFavoriteId?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum TextFavoriteType {
  Impressions = "Impressions",
  Recommendations = "Recommendations",
  Goals = "Goals",
}


export type TreatmentTemplate = {
  __typename: "TreatmentTemplate",
  id?: string,
  friendlyId?: string,
  otcEyeDrops?: Array< string | null > | null,
  rxEyeDrops?: Array< string | null > | null,
  procedures?: Array< string | null > | null,
  exercises?: Array< string | null > | null,
  treatmentTemplateClinicId?: string,
  copiedTreatmentTemplateId?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelTreatmentTemplateConnection = {
  __typename: "ModelTreatmentTemplateConnection",
  items?:  Array<TreatmentTemplate | null > | null,
  nextToken?: string | null,
};

export type Layout = {
  __typename: "Layout",
  id?: string | null,
  name?: string | null,
  medicalHistoryLayout?: FieldStatus,
  signsLayout?: FieldStatus,
  alwaysExpand?: string | null,
  customLayout?: string | null,
  symptomSettings?: string | null,
};

export type FieldStatus = {
  __typename: "FieldStatus",
  show?: Array< string | null > | null,
  hide?: Array< string | null > | null,
};

export enum TemperatureUnit {
  celsius = "celsius",
  fahrenheit = "fahrenheit",
}


export type ReferralCenter = {
  __typename: "ReferralCenter",
  id?: string,
  name?: string | null,
  logo?: S3Object,
  referralCenterClinicId?: string,
  diseaseAreas?: Array< string | null > | null,
  notificationRecipients?: Array< string | null > | null,
  referralForms?:  Array<ReferralForm | null > | null,
  agreement?: string | null,
  copaymentEmbedLink?: string | null,
  educationEmbedLink?: string | null,
  advancedReferralCenter?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ReferralForm = {
  __typename: "ReferralForm",
  name?: string | null,
  file?: S3Object,
  type?: ReferralFormType | null,
};

export enum ReferralFormType {
  PREOP = "PREOP",
  POSTOP = "POSTOP",
}


export type ModelFormTemplateConnection = {
  __typename: "ModelFormTemplateConnection",
  items?:  Array<FormTemplate | null > | null,
  nextToken?: string | null,
};

export type FormTemplate = {
  __typename: "FormTemplate",
  id?: string,
  schema?: string | null,
  formTemplateClinicId?: string,
  clinic?: Clinic,
  createdAt?: string,
  updatedAt?: string,
};

export type HealthCard = {
  __typename: "HealthCard",
  province?: string | null,
  number?: string | null,
  country?: string | null,
};

export enum Gender {
  male = "male",
  female = "female",
  other = "other",
}


export type ModelAssessmentConnection = {
  __typename: "ModelAssessmentConnection",
  items?:  Array<Assessment | null > | null,
  nextToken?: string | null,
};

export type Assessment = {
  __typename: "Assessment",
  id?: string,
  doctor?: Doctor,
  patient?: Patient,
  clinic?: Clinic,
  assessmentClinicId?: string | null,
  requests?: ModelAssessmentRequestConnection,
  type?: AssessmentType | null,
  body?: string,
  attachments?:  Array<S3Object | null > | null,
  locked?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  questionnaireRequests?: ModelQuestionnaireRequestConnection,
};

export type ModelAssessmentRequestConnection = {
  __typename: "ModelAssessmentRequestConnection",
  items?:  Array<AssessmentRequest | null > | null,
  nextToken?: string | null,
};

export enum AssessmentType {
  Cornea = "Cornea",
  DryEye = "DryEye",
  DryEyeSpecialized = "DryEyeSpecialized",
  RefractiveSurgery = "RefractiveSurgery",
  Glaucoma = "Glaucoma",
  Retina = "Retina",
  Other = "Other",
}


export type ModelQuestionnaireRequestConnection = {
  __typename: "ModelQuestionnaireRequestConnection",
  items?:  Array<QuestionnaireRequest | null > | null,
  nextToken?: string | null,
};

export type QuestionnaireRequest = {
  __typename: "QuestionnaireRequest",
  id?: string,
  email?: string | null,
  mobile?: string | null,
  doctor?: Doctor,
  clinic?: Clinic,
  massClinicPatient?: MassClinicPatient,
  assessment?: Assessment,
  patient?: Patient,
  questionnaireRequestPatientId?: string | null,
  type?: QuestionnaireType,
  response?: QuestionnaireRequestResponse,
  country?: string | null,
  consentsToPrivacyForm?: boolean | null,
  consentSource?: ConsentSource | null,
  consentsToResearch?: boolean | null,
  completedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  nonModifiableData?: string | null,
  language?: string | null,
  config?: string | null,
};

export type MassClinicPatient = {
  __typename: "MassClinicPatient",
  id?: string,
  patient?: Patient,
  massClinicPatientClinicId?: string,
  massClinic?: MassClinic,
  isCancelled?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
  questionnaireRequest?: QuestionnaireRequest,
};

export type MassClinic = {
  __typename: "MassClinic",
  id?: string,
  massClinicClinicId?: string,
  date?: string,
  massClinicPatients?: ModelMassClinicPatientConnection,
  isCancelled?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelMassClinicPatientConnection = {
  __typename: "ModelMassClinicPatientConnection",
  items?:  Array<MassClinicPatient | null > | null,
  nextToken?: string | null,
};

export enum QuestionnaireType {
  DEQ = "DEQ",
  SPEED = "SPEED",
  OSDI = "OSDI",
  OSDIAndSPEED = "OSDIAndSPEED",
  DERFS = "DERFS",
  CDERFS = "CDERFS",
  SPEEDII = "SPEEDII",
  CUSTOM = "CUSTOM",
}


export type QuestionnaireRequestResponse = {
  __typename: "QuestionnaireRequestResponse",
  answers?: string,
  scores?: string,
  consent?: QuestionnairePatientConsent,
};

export type QuestionnairePatientConsent = {
  __typename: "QuestionnairePatientConsent",
  consentsToPrivacyForm?: boolean | null,
  consentsToResearch?: boolean | null,
  agreedToTerms?: boolean,
  consentSource?: ConsentSource,
};

export enum ConsentSource {
  Doctor = "Doctor",
  Patient = "Patient",
}


export type ModelInteractionConnection = {
  __typename: "ModelInteractionConnection",
  items?:  Array<Interaction | null > | null,
  nextToken?: string | null,
};

export type Interaction = {
  __typename: "Interaction",
  id?: string,
  patient?: Patient,
  type?: string,
  status?: string,
  comments?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  surgeryType?: string | null,
  appointmentDate?: string | null,
  attemptFailed?: boolean | null,
  communicationMethod?: string | null,
  interactionClinicId?: string,
};

export enum StaffType {
  Owner = "Owner",
  Doctor = "Doctor",
  Tech = "Tech",
  Receptionist = "Receptionist",
}


export enum Priority {
  URGENT = "URGENT",
  SEMI_URGENT = "SEMI_URGENT",
  ROUTINE = "ROUTINE",
}


export type UpdateAssessmentInput = {
  id: string,
  type?: AssessmentType | null,
  body?: string | null,
  attachments?: Array< S3ObjectInput | null > | null,
  assessmentDoctorId?: string | null,
  assessmentPatientId?: string | null,
  locked?: boolean | null,
  assessmentClinicId?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  key: string,
  region: string,
  fileName?: string | null,
  metadata?: string | null,
  localUri?: string | null,
  mimeType?: string | null,
};

export type DrugBankMedications = {
  __typename: "DrugBankMedications",
  products?:  Array<DrugBankMedication | null > | null,
};

export type DrugBankMedication = {
  __typename: "DrugBankMedication",
  _id?: string | null,
  ndc_product_codes?: Array< string | null > | null,
  dpd_codes?: Array< string | null > | null,
  ema_product_codes?: Array< string | null > | null,
  name?: string | null,
  rx_norm_prescribable_name?: string | null,
  country?: string | null,
  route?: string | null,
  dosage_form?: string | null,
  ingredients?:  Array<DrugBankActiveActiveIngredients | null > | null,
};

export type DrugBankActiveActiveIngredients = {
  __typename: "DrugBankActiveActiveIngredients",
  drugbank_id?: string | null,
  name?: string | null,
  strength?: DrugBankStrength,
};

export type DrugBankStrength = {
  __typename: "DrugBankStrength",
  unit?: string | null,
  number?: number | null,
};

export type CreateClinicInput = {
  id?: string | null,
  name: string,
  address: string,
  province?: string | null,
  country?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone: string,
  fax?: string | null,
  logo?: S3ObjectInput | null,
  emrIntegration?: EMRIntegrationSettingsInput | null,
  stripeInformation?: StripeInformationInput | null,
  agreedToTermsOfService?: boolean | null,
  clinicLinkedReferralCenterId?: string | null,
  hasDryEyePortalAccess?: boolean | null,
  clinicOwnerId?: string | null,
  clinicClinicSetupId?: string | null,
  clinicReferralCenterId?: string | null,
};

export type EMRIntegrationSettingsInput = {
  fax?: FaxToEMRSettingsInput | null,
};

export type FaxToEMRSettingsInput = {
  enabled: boolean,
  faxNumber?: string | null,
};

export type StripeInformationInput = {
  subscriptionId?: string | null,
  customerId?: string | null,
  lastDigitsOfCard?: string | null,
  nextPaymentDate?: string | null,
  nameOnCard?: string | null,
  subscriptionStatus?: string | null,
};

export type UpdateClinicInput = {
  id: string,
  name?: string | null,
  address?: string | null,
  province?: string | null,
  country?: string | null,
  city?: string | null,
  postalCode?: string | null,
  phone?: string | null,
  fax?: string | null,
  logo?: S3ObjectInput | null,
  emrIntegration?: EMRIntegrationSettingsInput | null,
  agreedToTermsOfService?: boolean | null,
  clinicOwnerId?: string | null,
  clinicClinicSetupId?: string | null,
  clinicReferralCenterId?: string | null,
  clinicLinkedReferralCenterId?: string | null,
};

export type CreateClinicSetupInput = {
  id?: string | null,
  clinicSetupClinicId: string,
  doctorLicenses?: number | null,
  layouts?: Array< LayoutInput | null > | null,
  interactionsSetup?: string | null,
  defaultQuestionnaireLanguage?: string | null,
  defaultPdfLanguage?: string | null,
  temperatureUnit?: TemperatureUnit | null,
  questionnaireExpiry?: number | null,
  welcomeMessageConfig?: string | null,
  emailNotificationsConfig?: string | null,
  hiddenTextFavorites?: Array< string | null > | null,
  hiddenTreatmentTemplates?: Array< string | null > | null,
  textFavoriteConfig?: string | null,
};

export type LayoutInput = {
  id?: string | null,
  name?: string | null,
  medicalHistoryLayout?: FieldStatusInput | null,
  signsLayout?: FieldStatusInput | null,
  alwaysExpand?: string | null,
  customLayout?: string | null,
  symptomSettings?: string | null,
};

export type FieldStatusInput = {
  show?: Array< string | null > | null,
  hide?: Array< string | null > | null,
};

export type UpdateClinicSetupInput = {
  id: string,
  clinicSetupClinicId?: string | null,
  doctorLicenses?: number | null,
  layouts?: Array< LayoutInput | null > | null,
  interactionsSetup?: string | null,
  defaultQuestionnaireLanguage?: string | null,
  defaultPdfLanguage?: string | null,
  temperatureUnit?: TemperatureUnit | null,
  questionnaireExpiry?: number | null,
  welcomeMessageConfig?: string | null,
  emailNotificationsConfig?: string | null,
  hiddenTextFavorites?: Array< string | null > | null,
  hiddenTreatmentTemplates?: Array< string | null > | null,
  textFavoriteConfig?: string | null,
};

export type UpdateNotificationInput = {
  id: string,
  properties?: Array< NotificationPropertiesInput | null > | null,
  type?: NotificationType | null,
  typeId?: string | null,
  content?: string | null,
  notificationClinicId?: string | null,
};

export type NotificationPropertiesInput = {
  doctorId: string,
  status?: NotificationStatusInput | null,
};

export type NotificationStatusInput = {
  read?: boolean | null,
  flag?: Flag | null,
};

export enum Flag {
  none = "none",
  important = "important",
  complete = "complete",
  archive = "archive",
  trash = "trash",
}


export enum NotificationType {
  system = "system",
  referral = "referral",
  questionnaire = "questionnaire",
  intake = "intake",
}


export type Notification = {
  __typename: "Notification",
  id?: string,
  properties?:  Array<NotificationProperties | null > | null,
  type?: NotificationType,
  typeId?: string | null,
  content?: string | null,
  notificationClinicId?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type NotificationProperties = {
  __typename: "NotificationProperties",
  doctorId?: string,
  status?: NotificationStatus,
};

export type NotificationStatus = {
  __typename: "NotificationStatus",
  read?: boolean | null,
  flag?: Flag | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type UpdateIntakeFormInput = {
  id: string,
  intakeFormPatientId: string,
};

export type IntakeForm = {
  __typename: "IntakeForm",
  id?: string,
  response?: string,
  createdAt?: string,
  intakeFormClinicId?: string,
  intakeFormPatientId?: string | null,
  statusId?: string | null,
  status?: IntakeFormStatus | null,
  lastRead?: string | null,
  updatedAt?: string,
};

export enum IntakeFormStatus {
  processed = "processed",
  archived = "archived",
  none = "none",
}


export type CreateInteractionInput = {
  id?: string | null,
  type: string,
  status: string,
  comments?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  surgeryType?: string | null,
  appointmentDate?: string | null,
  attemptFailed?: boolean | null,
  communicationMethod?: string | null,
  interactionClinicId: string,
  interactionPatientId: string,
};

export type UpdateInteractionInput = {
  id: string,
  type?: string | null,
  status?: string | null,
  comments?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  surgeryType?: string | null,
  appointmentDate?: string | null,
  attemptFailed?: boolean | null,
  communicationMethod?: string | null,
  interactionClinicId?: string | null,
  interactionPatientId?: string | null,
};

export type CreateTextFavoriteInput = {
  id?: string | null,
  shortcut: string,
  text: string,
  type: TextFavoriteType,
  textFavoriteTreatmentTemplateId?: string | null,
  textFavoriteClinicId: string,
  copiedTextFavoriteId?: string | null,
};

export type UpdateTextFavoriteInput = {
  id: string,
  shortcut?: string | null,
  text?: string | null,
  type?: TextFavoriteType | null,
  textFavoriteTreatmentTemplateId?: string | null,
  textFavoriteClinicId?: string | null,
  copiedTextFavoriteId?: string | null,
};

export type DeleteTextFavoriteInput = {
  id: string,
};

export type CreateTreatmentTemplateInput = {
  id?: string | null,
  friendlyId: string,
  otcEyeDrops?: Array< string | null > | null,
  rxEyeDrops?: Array< string | null > | null,
  procedures?: Array< string | null > | null,
  exercises?: Array< string | null > | null,
  treatmentTemplateClinicId: string,
  copiedTreatmentTemplateId?: string | null,
};

export type UpdateTreatmentTemplateInput = {
  id: string,
  friendlyId?: string | null,
  otcEyeDrops?: Array< string | null > | null,
  rxEyeDrops?: Array< string | null > | null,
  procedures?: Array< string | null > | null,
  exercises?: Array< string | null > | null,
  treatmentTemplateClinicId?: string | null,
  copiedTreatmentTemplateId?: string | null,
};

export type DeleteTreatmentTemplateInput = {
  id: string,
};

export type CreateMassClinicInput = {
  id?: string | null,
  massClinicClinicId: string,
  date: string,
  isCancelled?: boolean | null,
};

export type UpdateMassClinicInput = {
  id: string,
  massClinicClinicId?: string | null,
  date?: string | null,
  isCancelled?: boolean | null,
};

export type CreateMassClinicPatientInput = {
  id?: string | null,
  massClinicPatientClinicId: string,
  isCancelled?: boolean | null,
  massClinicPatientPatientId: string,
  massClinicPatientMassClinicId: string,
  massClinicPatientQuestionnaireRequestId?: string | null,
};

export type UpdateMassClinicPatientInput = {
  id: string,
  massClinicPatientClinicId?: string | null,
  isCancelled?: boolean | null,
  massClinicPatientPatientId?: string | null,
  massClinicPatientMassClinicId?: string | null,
  massClinicPatientQuestionnaireRequestId?: string | null,
};

export type CreateStaffClinicReferenceInput = {
  id?: string | null,
  staffId: string,
  clinicId: string,
  clinicOwnerId: string,
  staffTypes: Array< StaffType | null >,
};

export type StaffClinicReference = {
  __typename: "StaffClinicReference",
  id?: string,
  staffId?: string,
  clinicId?: string,
  clinicOwnerId?: string,
  staffTypes?: Array< StaffType | null >,
  createdAt?: string,
  updatedAt?: string,
};

export type DeleteStaffClinicReferenceInput = {
  id: string,
};

export type CreateDoctorInput = {
  id?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  practitionerId?: string | null,
  doctorClinicId?: string | null,
};

export type UpdateDoctorInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  practitionerId?: string | null,
  doctorClinicId?: string | null,
  agreedToTerms?: boolean | null,
  availableClinics?: string | null,
  cameraAssessmentRequest?: string | null,
  ownedClinics?: string | null,
};

export type CreatePatientInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email?: string | null,
  emailVerified?: boolean | null,
  phone?: string | null,
  agreedToTerms?: boolean | null,
  patientClinicId?: string | null,
  dateOfBirth?: string | null,
  healthCard?: HealthCardInput | null,
  gender?: Gender | null,
  genderOther?: string | null,
  address?: string | null,
  consentsToResearch?: boolean | null,
  consentsToPrivacyForm?: boolean | null,
  consentSource?: ConsentSource | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  linkedReferralPatientId?: string | null,
  referralSource?: string | null,
  nonModifiableData?: string | null,
  lastInteractionAt?: string | null,
  isColdLead?: boolean | null,
  fitzpatrickImage?: S3ObjectInput | null,
  patientDoctorId: string,
};

export type HealthCardInput = {
  province?: string | null,
  number?: string | null,
  country?: string | null,
};

export type UpdatePatientInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  emailVerified?: boolean | null,
  phone?: string | null,
  agreedToTerms?: boolean | null,
  patientClinicId?: string | null,
  dateOfBirth?: string | null,
  healthCard?: HealthCardInput | null,
  gender?: Gender | null,
  genderOther?: string | null,
  address?: string | null,
  consentsToResearch?: boolean | null,
  consentsToPrivacyForm?: boolean | null,
  consentSource?: ConsentSource | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  linkedReferralPatientId?: string | null,
  referralSource?: string | null,
  nonModifiableData?: string | null,
  lastInteractionAt?: string | null,
  isColdLead?: boolean | null,
  fitzpatrickImage?: S3ObjectInput | null,
  patientDoctorId?: string | null,
};

export type CreateAssessmentInput = {
  id?: string | null,
  type?: AssessmentType | null,
  body: string,
  assessmentDoctorId?: string | null,
  assessmentPatientId?: string | null,
  assessmentClinicId?: string | null,
  attachments?: Array< S3ObjectInput | null > | null,
  locked?: boolean | null,
};

export type CreateReferralCenterInput = {
  id?: string | null,
  name?: string | null,
  logo?: S3ObjectInput | null,
  referralCenterClinicId: string,
  diseaseAreas?: Array< string | null > | null,
  notificationRecipients?: Array< string | null > | null,
  referralForms?: Array< ReferralFormInput | null > | null,
  agreement?: string | null,
  copaymentEmbedLink?: string | null,
  educationEmbedLink?: string | null,
  advancedReferralCenter?: boolean | null,
};

export type ReferralFormInput = {
  name?: string | null,
  file?: S3ObjectInput | null,
  type?: ReferralFormType | null,
};

export type UpdateReferralCenterInput = {
  id: string,
  name?: string | null,
  logo?: S3ObjectInput | null,
  referralCenterClinicId?: string | null,
  diseaseAreas?: Array< string | null > | null,
  notificationRecipients?: Array< string | null > | null,
  referralForms?: Array< ReferralFormInput | null > | null,
  agreement?: string | null,
  copaymentEmbedLink?: string | null,
  educationEmbedLink?: string | null,
  advancedReferralCenter?: boolean | null,
};

export type CreateReferralInput = {
  id?: string | null,
  referringClinicId: string,
  referralCenterClinicId: string,
  referralReferralCenterId: string,
  referralPatientId: string,
  body: string,
  attachments?: Array< S3ObjectInput | null > | null,
  priority: Priority,
  openedAt?: string | null,
  status?: string | null,
  referralCenterResponse?: Array< ReferralResponseInput | null > | null,
  comment?: string | null,
  feedbackHistory?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: ReferralType | null,
  parentReferralId?: string | null,
};

export type ReferralResponseInput = {
  attachments?: Array< S3ObjectInput | null > | null,
  comment?: string | null,
  responseTime?: string | null,
};

export enum ReferralType {
  Referral = "Referral",
  PostProcedure = "PostProcedure",
}


export type Referral = {
  __typename: "Referral",
  id?: string,
  referralCenter?: ReferralCenter,
  referringClinicId?: string,
  referralCenterClinicId?: string,
  referralReferralCenterId?: string,
  referralPatientId?: string,
  body?: string,
  attachments?:  Array<S3Object | null > | null,
  priority?: Priority,
  openedAt?: string | null,
  status?: string | null,
  referralCenterResponse?:  Array<ReferralResponse | null > | null,
  comment?: string | null,
  feedbackHistory?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: ReferralType | null,
  parentReferralId?: string | null,
};

export type ReferralResponse = {
  __typename: "ReferralResponse",
  attachments?:  Array<S3Object | null > | null,
  comment?: string | null,
  responseTime?: string | null,
};

export type UpdateReferralInput = {
  id: string,
  referringClinicId?: string | null,
  referralCenterClinicId?: string | null,
  referralReferralCenterId?: string | null,
  referralPatientId?: string | null,
  body?: string | null,
  attachments?: Array< S3ObjectInput | null > | null,
  priority?: Priority | null,
  openedAt?: string | null,
  status?: string | null,
  referralCenterResponse?: Array< ReferralResponseInput | null > | null,
  comment?: string | null,
  feedbackHistory?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: ReferralType | null,
  parentReferralId?: string | null,
};

export type CreateAssessmentRequestInput = {
  id?: string | null,
  type?: AssessmentRequestType | null,
  response?: string | null,
  assessmentRequestDoctorId?: string | null,
  assessmentRequestClinicId?: string | null,
  assessmentRequestRecipientId?: string | null,
  assessmentRequestAssessmentId: string,
  priority?: Priority | null,
};

export type UpdateAssessmentRequestInput = {
  id: string,
  type?: AssessmentRequestType | null,
  response?: string | null,
  assessmentRequestDoctorId?: string | null,
  assessmentRequestRecipientId?: string | null,
  assessmentRequestAssessmentId?: string | null,
  assessmentRequestClinicId?: string | null,
};

export type CreateQuestionnaireRequestInput = {
  id?: string | null,
  email?: string | null,
  mobile?: string | null,
  type: QuestionnaireType,
  response?: string | null,
  country: string,
  consentsToPrivacyForm?: boolean | null,
  consentSource?: ConsentSource | null,
  consentsToResearch?: boolean | null,
  questionnaireRequestDoctorId?: string | null,
  questionnaireRequestClinicId?: string | null,
  questionnaireRequestPatientId: string,
  nonModifiableData?: string | null,
  language?: string | null,
  config?: string | null,
  completedAt?: string | null,
  questionnaireRequestMassClinicPatientId?: string | null,
  questionnaireRequestAssessmentId?: string | null,
};

export type CreateReminderInput = {
  id?: string | null,
  reminderPatientId: string,
  reminderClinicId: string,
  reminderStaffId: string,
  clinicName: string,
  doctorName: string,
  patientName: string,
  nextReminderDate: string,
  expirationDate: string,
  numberOfReminders: number,
  email?: string | null,
  phone?: string | null,
  reminderType: ReminderType,
  config: string,
};

export enum ReminderType {
  QUESTIONNAIRE = "QUESTIONNAIRE",
  MASS_CLINIC = "MASS_CLINIC",
  MEDICATION = "MEDICATION",
  CUSTOM = "CUSTOM",
}


export type Reminder = {
  __typename: "Reminder",
  id?: string,
  reminderPatientId?: string,
  reminderClinicId?: string,
  reminderStaffId?: string,
  clinicName?: string,
  doctorName?: string,
  patientName?: string,
  nextReminderDate?: string,
  expirationDate?: string,
  numberOfReminders?: number,
  email?: string | null,
  phone?: string | null,
  reminderType?: ReminderType,
  config?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateReminderInput = {
  id: string,
  reminderPatientId?: string | null,
  reminderClinicId?: string | null,
  reminderStaffId?: string | null,
  clinicName?: string | null,
  doctorName?: string | null,
  patientName?: string | null,
  nextReminderDate?: string | null,
  expirationDate?: string | null,
  numberOfReminders?: number | null,
  email?: string | null,
  phone?: string | null,
  reminderType?: ReminderType | null,
  config?: string | null,
};

export type DeleteReminderInput = {
  id: string,
};

export type CreateIntakeFormInput = {
  id?: string | null,
  response: string,
  createdAt?: string | null,
  intakeFormClinicId: string,
  intakeFormPatientId?: string | null,
  statusId?: string | null,
  status?: IntakeFormStatus | null,
  lastRead?: string | null,
};

export type UpdateQuestionnaireRequestInput = {
  id: string,
  email?: string | null,
  mobile?: string | null,
  questionnaireRequestPatientId?: string | null,
  type?: QuestionnaireType | null,
  response?: QuestionnaireRequestResponseInput | null,
  country?: string | null,
  consentsToPrivacyForm?: boolean | null,
  consentSource?: ConsentSource | null,
  consentsToResearch?: boolean | null,
  completedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  nonModifiableData?: string | null,
  language?: string | null,
  config?: string | null,
  questionnaireRequestDoctorId?: string | null,
  questionnaireRequestClinicId?: string | null,
  questionnaireRequestMassClinicPatientId?: string | null,
  questionnaireRequestAssessmentId?: string | null,
};

export type QuestionnaireRequestResponseInput = {
  answers: string,
  scores: string,
  consent: QuestionnairePatientConsentInput,
};

export type QuestionnairePatientConsentInput = {
  consentsToPrivacyForm?: boolean | null,
  consentsToResearch?: boolean | null,
  agreedToTerms: boolean,
  consentSource: ConsentSource,
};

export type PDF = {
  __typename: "PDF",
  base64?: string,
};

export type QueueFaxResult = {
  __typename: "QueueFaxResult",
  Status?: SRFaxStatus,
  Result?: string | null,
};

export enum SRFaxStatus {
  Success = "Success",
  Failed = "Failed",
}


export enum FilterType {
  patient = "patient",
  doctor = "doctor",
  referral = "referral",
  assessment = "assessment",
}


export enum CognitoGroup {
  Owner = "Owner",
  Doctor = "Doctor",
  Tech = "Tech",
  Receptionist = "Receptionist",
  DryEyeExpertPanel = "DryEyeExpertPanel",
  Seema = "Seema",
  Admin = "Admin",
  CanSendQuestionnaires = "CanSendQuestionnaires",
  CanReferToSeema = "CanReferToSeema",
  ExperimentalCDS = "ExperimentalCDS",
  CameraAccess = "CameraAccess",
}


export type Drug = {
  __typename: "Drug",
  id?: string | null,
  ndc_product_codes?: Array< string | null > | null,
  dpd_codes?: Array< string | null > | null,
  ema_product_codes?: Array< string | null > | null,
  name?: string | null,
  rx_norm_prescribable_name?: string | null,
  country?: string | null,
  routes?:  Array<DrugRoute | null > | null,
  dosage_form?: string | null,
  active_ingredients?:  Array<DrugActiveIngredients | null > | null,
};

export type DrugRoute = {
  __typename: "DrugRoute",
  route_of_administration?: string | null,
};

export type DrugActiveIngredients = {
  __typename: "DrugActiveIngredients",
  ingredient?: string | null,
  strength?: number | null,
  strength_unit?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDFilterInput | null,
  type?: ModelNotificationTypeFilterInput | null,
  typeId?: ModelIDFilterInput | null,
  content?: ModelStringFilterInput | null,
  notificationClinicId?: ModelIDFilterInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelIDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelNotificationTypeFilterInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items?:  Array<Notification | null > | null,
  nextToken?: string | null,
};

export type ModelSystemNotificationFilterInput = {
  id?: ModelIDFilterInput | null,
  date?: ModelStringFilterInput | null,
  content?: ModelStringFilterInput | null,
  and?: Array< ModelSystemNotificationFilterInput | null > | null,
  or?: Array< ModelSystemNotificationFilterInput | null > | null,
  not?: ModelSystemNotificationFilterInput | null,
};

export type ModelSystemNotificationConnection = {
  __typename: "ModelSystemNotificationConnection",
  items?:  Array<SystemNotification | null > | null,
  nextToken?: string | null,
};

export type SystemNotification = {
  __typename: "SystemNotification",
  id?: string,
  date?: string,
  content?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelIntakeFormFilterInput = {
  id?: ModelIDFilterInput | null,
  response?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  intakeFormClinicId?: ModelIDFilterInput | null,
  intakeFormPatientId?: ModelIDFilterInput | null,
  statusId?: ModelIDFilterInput | null,
  status?: ModelIntakeFormStatusFilterInput | null,
  lastRead?: ModelStringFilterInput | null,
  and?: Array< ModelIntakeFormFilterInput | null > | null,
  or?: Array< ModelIntakeFormFilterInput | null > | null,
  not?: ModelIntakeFormFilterInput | null,
};

export type ModelIntakeFormStatusFilterInput = {
  eq?: IntakeFormStatus | null,
  ne?: IntakeFormStatus | null,
};

export type ModelIntakeFormConnection = {
  __typename: "ModelIntakeFormConnection",
  items?:  Array<IntakeForm | null > | null,
  nextToken?: string | null,
};

export type ModelInteractionFilterInput = {
  id?: ModelIDFilterInput | null,
  type?: ModelStringFilterInput | null,
  status?: ModelStringFilterInput | null,
  comments?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  surgeryType?: ModelStringFilterInput | null,
  appointmentDate?: ModelStringFilterInput | null,
  attemptFailed?: ModelBooleanFilterInput | null,
  communicationMethod?: ModelStringFilterInput | null,
  interactionClinicId?: ModelIDFilterInput | null,
  and?: Array< ModelInteractionFilterInput | null > | null,
  or?: Array< ModelInteractionFilterInput | null > | null,
  not?: ModelInteractionFilterInput | null,
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelStaffClinicReferenceFilterInput = {
  id?: ModelIDFilterInput | null,
  staffId?: ModelIDFilterInput | null,
  clinicId?: ModelIDFilterInput | null,
  clinicOwnerId?: ModelIDFilterInput | null,
  staffTypes?: ModelStaffTypeListFilterInput | null,
  and?: Array< ModelStaffClinicReferenceFilterInput | null > | null,
  or?: Array< ModelStaffClinicReferenceFilterInput | null > | null,
  not?: ModelStaffClinicReferenceFilterInput | null,
};

export type ModelStaffTypeListFilterInput = {
  eq?: Array< StaffType | null > | null,
  ne?: Array< StaffType | null > | null,
  contains?: StaffType | null,
  notContains?: StaffType | null,
};

export type ModelStaffClinicReferenceConnection = {
  __typename: "ModelStaffClinicReferenceConnection",
  items?:  Array<StaffClinicReference | null > | null,
  nextToken?: string | null,
};

export type ModelDoctorFilterInput = {
  id?: ModelIDFilterInput | null,
  firstName?: ModelStringFilterInput | null,
  lastName?: ModelStringFilterInput | null,
  email?: ModelStringFilterInput | null,
  emailVerified?: ModelBooleanFilterInput | null,
  agreedToTerms?: ModelBooleanFilterInput | null,
  cognitoGroups?: ModelStringFilterInput | null,
  practitionerId?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  deactivated?: ModelBooleanFilterInput | null,
  staffType?: ModelStaffTypeFilterInput | null,
  clinicLicenses?: ModelIntFilterInput | null,
  cameraAssessmentRequest?: ModelStringFilterInput | null,
  ownedClinics?: ModelStringFilterInput | null,
  allowMultipleClinics?: ModelBooleanFilterInput | null,
  and?: Array< ModelDoctorFilterInput | null > | null,
  or?: Array< ModelDoctorFilterInput | null > | null,
  not?: ModelDoctorFilterInput | null,
};

export type ModelStaffTypeFilterInput = {
  eq?: StaffType | null,
  ne?: StaffType | null,
};

export type ModelIntFilterInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPatientFilterInput = {
  id?: ModelIDFilterInput | null,
  firstName?: ModelStringFilterInput | null,
  lastName?: ModelStringFilterInput | null,
  email?: ModelStringFilterInput | null,
  emailVerified?: ModelBooleanFilterInput | null,
  phone?: ModelStringFilterInput | null,
  agreedToTerms?: ModelBooleanFilterInput | null,
  patientClinicId?: ModelIDFilterInput | null,
  dateOfBirth?: ModelStringFilterInput | null,
  gender?: ModelGenderFilterInput | null,
  genderOther?: ModelStringFilterInput | null,
  address?: ModelStringFilterInput | null,
  consentsToResearch?: ModelBooleanFilterInput | null,
  consentsToPrivacyForm?: ModelBooleanFilterInput | null,
  consentSource?: ModelConsentSourceFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  linkedReferralPatientId?: ModelIDFilterInput | null,
  referralSource?: ModelStringFilterInput | null,
  nonModifiableData?: ModelStringFilterInput | null,
  lastInteractionAt?: ModelStringFilterInput | null,
  isColdLead?: ModelBooleanFilterInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
};

export type ModelGenderFilterInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelConsentSourceFilterInput = {
  eq?: ConsentSource | null,
  ne?: ConsentSource | null,
};

export type ModelAssessmentFilterInput = {
  id?: ModelIDFilterInput | null,
  assessmentClinicId?: ModelIDFilterInput | null,
  type?: ModelAssessmentTypeFilterInput | null,
  body?: ModelStringFilterInput | null,
  locked?: ModelBooleanFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  and?: Array< ModelAssessmentFilterInput | null > | null,
  or?: Array< ModelAssessmentFilterInput | null > | null,
  not?: ModelAssessmentFilterInput | null,
};

export type ModelAssessmentTypeFilterInput = {
  eq?: AssessmentType | null,
  ne?: AssessmentType | null,
};

export type ModelReferralCenterFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  referralCenterClinicId?: ModelIDFilterInput | null,
  diseaseAreas?: ModelStringFilterInput | null,
  notificationRecipients?: ModelStringFilterInput | null,
  agreement?: ModelStringFilterInput | null,
  copaymentEmbedLink?: ModelStringFilterInput | null,
  educationEmbedLink?: ModelStringFilterInput | null,
  advancedReferralCenter?: ModelBooleanFilterInput | null,
  and?: Array< ModelReferralCenterFilterInput | null > | null,
  or?: Array< ModelReferralCenterFilterInput | null > | null,
  not?: ModelReferralCenterFilterInput | null,
};

export type ModelReferralCenterConnection = {
  __typename: "ModelReferralCenterConnection",
  items?:  Array<ReferralCenter | null > | null,
  nextToken?: string | null,
};

export type ModelReferralFilterInput = {
  id?: ModelIDFilterInput | null,
  referringClinicId?: ModelIDFilterInput | null,
  referralCenterClinicId?: ModelIDFilterInput | null,
  referralReferralCenterId?: ModelIDFilterInput | null,
  referralPatientId?: ModelIDFilterInput | null,
  body?: ModelStringFilterInput | null,
  priority?: ModelPriorityFilterInput | null,
  openedAt?: ModelStringFilterInput | null,
  status?: ModelStringFilterInput | null,
  comment?: ModelStringFilterInput | null,
  feedbackHistory?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  type?: ModelReferralTypeFilterInput | null,
  parentReferralId?: ModelIDFilterInput | null,
  and?: Array< ModelReferralFilterInput | null > | null,
  or?: Array< ModelReferralFilterInput | null > | null,
  not?: ModelReferralFilterInput | null,
};

export type ModelPriorityFilterInput = {
  eq?: Priority | null,
  ne?: Priority | null,
};

export type ModelReferralTypeFilterInput = {
  eq?: ReferralType | null,
  ne?: ReferralType | null,
};

export type ModelReferralConnection = {
  __typename: "ModelReferralConnection",
  items?:  Array<Referral | null > | null,
  nextToken?: string | null,
};

export type ModelFormTemplateFieldLibraryFilterInput = {
  id?: ModelIDFilterInput | null,
  friendlyId?: ModelStringFilterInput | null,
  label?: ModelStringFilterInput | null,
  typeConfig?: ModelStringFilterInput | null,
  required?: ModelBooleanFilterInput | null,
  triggerGroups?: ModelStringFilterInput | null,
  and?: Array< ModelFormTemplateFieldLibraryFilterInput | null > | null,
  or?: Array< ModelFormTemplateFieldLibraryFilterInput | null > | null,
  not?: ModelFormTemplateFieldLibraryFilterInput | null,
};

export type ModelFormTemplateFieldLibraryConnection = {
  __typename: "ModelFormTemplateFieldLibraryConnection",
  items?:  Array<FormTemplateFieldLibrary | null > | null,
  nextToken?: string | null,
};

export type FormTemplateFieldLibrary = {
  __typename: "FormTemplateFieldLibrary",
  id?: string,
  friendlyId?: string,
  label?: string,
  typeConfig?: string,
  required?: boolean | null,
  triggerGroups?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelReminderFilterInput = {
  id?: ModelIDFilterInput | null,
  reminderPatientId?: ModelIDFilterInput | null,
  reminderClinicId?: ModelIDFilterInput | null,
  reminderStaffId?: ModelIDFilterInput | null,
  clinicName?: ModelStringFilterInput | null,
  doctorName?: ModelStringFilterInput | null,
  patientName?: ModelStringFilterInput | null,
  nextReminderDate?: ModelStringFilterInput | null,
  expirationDate?: ModelStringFilterInput | null,
  numberOfReminders?: ModelIntFilterInput | null,
  email?: ModelStringFilterInput | null,
  phone?: ModelStringFilterInput | null,
  reminderType?: ModelReminderTypeFilterInput | null,
  config?: ModelStringFilterInput | null,
  and?: Array< ModelReminderFilterInput | null > | null,
  or?: Array< ModelReminderFilterInput | null > | null,
  not?: ModelReminderFilterInput | null,
};

export type ModelReminderTypeFilterInput = {
  eq?: ReminderType | null,
  ne?: ReminderType | null,
};

export type ModelReminderConnection = {
  __typename: "ModelReminderConnection",
  items?:  Array<Reminder | null > | null,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelClinicFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  address?: ModelStringFilterInput | null,
  province?: ModelStringFilterInput | null,
  country?: ModelStringFilterInput | null,
  city?: ModelStringFilterInput | null,
  postalCode?: ModelStringFilterInput | null,
  phone?: ModelStringFilterInput | null,
  fax?: ModelStringFilterInput | null,
  agreedToTermsOfService?: ModelBooleanFilterInput | null,
  clinicLinkedReferralCenterId?: ModelIDFilterInput | null,
  hasDryEyePortalAccess?: ModelBooleanFilterInput | null,
  and?: Array< ModelClinicFilterInput | null > | null,
  or?: Array< ModelClinicFilterInput | null > | null,
  not?: ModelClinicFilterInput | null,
};

export type ModelClinicConnection = {
  __typename: "ModelClinicConnection",
  items?:  Array<Clinic | null > | null,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTextFavoriteFilterInput = {
  id?: ModelIDFilterInput | null,
  shortcut?: ModelStringFilterInput | null,
  text?: ModelStringFilterInput | null,
  type?: ModelTextFavoriteTypeFilterInput | null,
  textFavoriteTreatmentTemplateId?: ModelIDFilterInput | null,
  textFavoriteClinicId?: ModelIDFilterInput | null,
  copiedTextFavoriteId?: ModelStringFilterInput | null,
  and?: Array< ModelTextFavoriteFilterInput | null > | null,
  or?: Array< ModelTextFavoriteFilterInput | null > | null,
  not?: ModelTextFavoriteFilterInput | null,
};

export type ModelTextFavoriteTypeFilterInput = {
  eq?: TextFavoriteType | null,
  ne?: TextFavoriteType | null,
};

export type ModelTreatmentTemplateFilterInput = {
  id?: ModelIDFilterInput | null,
  friendlyId?: ModelStringFilterInput | null,
  otcEyeDrops?: ModelStringFilterInput | null,
  rxEyeDrops?: ModelStringFilterInput | null,
  procedures?: ModelStringFilterInput | null,
  exercises?: ModelStringFilterInput | null,
  treatmentTemplateClinicId?: ModelIDFilterInput | null,
  copiedTreatmentTemplateId?: ModelStringFilterInput | null,
  and?: Array< ModelTreatmentTemplateFilterInput | null > | null,
  or?: Array< ModelTreatmentTemplateFilterInput | null > | null,
  not?: ModelTreatmentTemplateFilterInput | null,
};

export type ModelMassClinicFilterInput = {
  id?: ModelIDFilterInput | null,
  massClinicClinicId?: ModelIDFilterInput | null,
  date?: ModelStringFilterInput | null,
  isCancelled?: ModelBooleanFilterInput | null,
  and?: Array< ModelMassClinicFilterInput | null > | null,
  or?: Array< ModelMassClinicFilterInput | null > | null,
  not?: ModelMassClinicFilterInput | null,
};

export type ModelMassClinicConnection = {
  __typename: "ModelMassClinicConnection",
  items?:  Array<MassClinic | null > | null,
  nextToken?: string | null,
};

export type ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyConditionInput = {
  eq?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  le?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  lt?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  ge?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  gt?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  between?: Array< ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null > | null,
  beginsWith?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
};

export type ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyInput = {
  status?: string | null,
  createdAt?: string | null,
};

export type ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyConditionInput = {
  eq?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  le?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  lt?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  ge?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  gt?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
  between?: Array< ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null > | null,
  beginsWith?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput | null,
};

export type ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyInput = {
  status?: string | null,
  createdAt?: string | null,
};

export type ModelDataCacheFilterInput = {
  id?: ModelIDFilterInput | null,
  data?: ModelStringFilterInput | null,
  dataCount?: ModelIntFilterInput | null,
  type?: ModelDataCacheTypeFilterInput | null,
  dataCacheClinicId?: ModelIDFilterInput | null,
  typeId?: ModelIDFilterInput | null,
  dateAndTypeId?: ModelIDFilterInput | null,
  updateId?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  sizeFull?: ModelBooleanFilterInput | null,
  and?: Array< ModelDataCacheFilterInput | null > | null,
  or?: Array< ModelDataCacheFilterInput | null > | null,
  not?: ModelDataCacheFilterInput | null,
};

export type ModelDataCacheTypeFilterInput = {
  eq?: DataCacheType | null,
  ne?: DataCacheType | null,
};

export enum DataCacheType {
  assessment = "assessment",
  patient = "patient",
  doctor = "doctor",
  referral = "referral",
}


export type ModelDataCacheConnection = {
  __typename: "ModelDataCacheConnection",
  items?:  Array<DataCache | null > | null,
  nextToken?: string | null,
};

export type DataCache = {
  __typename: "DataCache",
  id?: string,
  data?: string | null,
  dataCount?: number | null,
  type?: DataCacheType | null,
  dataCacheClinicId?: string,
  typeId?: string,
  dateAndTypeId?: string,
  updateId?: string | null,
  createdAt?: string | null,
  sizeFull?: boolean | null,
  updatedAt?: string,
};

export type GetAssessmentRequestWithDoctorQueryVariables = {
  id?: string,
};

export type GetAssessmentRequestWithDoctorQuery = {
  getAssessmentRequest?:  {
    __typename: "AssessmentRequest",
    id: string,
    type?: AssessmentRequestType | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      cognitoGroups?: Array< string | null > | null,
      practitionerId?: string | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
      } | null,
    } | null,
    recipient?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      practitionerId?: string | null,
    } | null,
    assessment:  {
      __typename: "Assessment",
      id: string,
      patient?:  {
        __typename: "Patient",
        id: string,
      } | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        practitionerId?: string | null,
        clinic?:  {
          __typename: "Clinic",
          id: string,
        } | null,
      },
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
      } | null > | null,
    },
    response?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type GetAllInfoQuery = {
  me?:  {
    __typename: "Doctor",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    emailVerified?: boolean | null,
    agreedToTerms?: boolean | null,
    staffType?: StaffType | null,
    allowMultipleClinics?: boolean | null,
    ownedClinics?: string | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        items?:  Array< {
          __typename: "Doctor",
          id: string,
          email: string,
          practitionerId?: string | null,
          firstName?: string | null,
          lastName?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      emrIntegration?:  {
        __typename: "EMRIntegrationSettings",
        fax?:  {
          __typename: "FaxToEMRSettings",
          enabled: boolean,
          faxNumber?: string | null,
        } | null,
      } | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        logo?:  {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
          fileName?: string | null,
        } | null,
        referralForms?:  Array< {
          __typename: "ReferralForm",
          name?: string | null,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
            fileName?: string | null,
          } | null,
          type?: ReferralFormType | null,
        } | null > | null,
        agreement?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        logo?:  {
          __typename: "S3Object",
          bucket: string,
          key: string,
          region: string,
          fileName?: string | null,
        } | null,
        referralForms?:  Array< {
          __typename: "ReferralForm",
          name?: string | null,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            key: string,
            region: string,
            fileName?: string | null,
          } | null,
          type?: ReferralFormType | null,
        } | null > | null,
        agreement?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        createdAt: string,
        updatedAt: string,
        layouts?:  Array< {
          __typename: "Layout",
          id?: string | null,
          name?: string | null,
          alwaysExpand?: string | null,
          signsLayout?:  {
            __typename: "FieldStatus",
            show?: Array< string | null > | null,
            hide?: Array< string | null > | null,
          } | null,
          medicalHistoryLayout?:  {
            __typename: "FieldStatus",
            show?: Array< string | null > | null,
            hide?: Array< string | null > | null,
          } | null,
          symptomSettings?: string | null,
          customLayout?: string | null,
        } | null > | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        questionnaireExpiry?: number | null,
      } | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        items?:  Array< {
          __typename: "FormTemplate",
          id: string,
          schema?: string | null,
          formTemplateClinicId: string,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      agreedToTermsOfService?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cognitoGroups?: Array< string | null > | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    practitionerId?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    assessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inboundAssessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type LockAssessmentMutationVariables = {
  input?: UpdateAssessmentInput,
};

export type LockAssessmentMutation = {
  updateAssessment?:  {
    __typename: "Assessment",
    id: string,
    locked?: boolean | null,
  } | null,
};

export type MedicationLookupCustomQueryVariables = {
  query?: string,
};

export type MedicationLookupCustomQuery = {
  searchMedications?:  {
    __typename: "DrugBankMedications",
    products?:  Array< {
      __typename: "DrugBankMedication",
      _id?: string | null,
      ndc_product_codes?: Array< string | null > | null,
      dpd_codes?: Array< string | null > | null,
      ema_product_codes?: Array< string | null > | null,
      name?: string | null,
      rx_norm_prescribable_name?: string | null,
      country?: string | null,
      route?: string | null,
      dosage_form?: string | null,
      ingredients?:  Array< {
        __typename: "DrugBankActiveActiveIngredients",
        drugbank_id?: string | null,
        name?: string | null,
        strength?:  {
          __typename: "DrugBankStrength",
          number?: number | null,
          unit?: string | null,
        } | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type getQuestionnaireRequestWithResponseAndCompletedAtQueryVariables = {
  id?: string,
};

export type getQuestionnaireRequestWithResponseAndCompletedAtQuery = {
  getQuestionnaireRequest?:  {
    __typename: "QuestionnaireRequest",
    completedAt?: string | null,
    response?:  {
      __typename: "QuestionnaireRequestResponse",
      answers: string,
      scores: string,
    } | null,
    config?: string | null,
  } | null,
};

export type GetAllClinicQueryVariables = {
  id?: string,
};

export type GetAllClinicQuery = {
  getClinic?:  {
    __typename: "Clinic",
    id: string,
    name: string,
    address: string,
    province?: string | null,
    country?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone: string,
    fax?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      items?:  Array< {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    owner?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
    } | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    emrIntegration?:  {
      __typename: "EMRIntegrationSettings",
      fax?:  {
        __typename: "FaxToEMRSettings",
        enabled: boolean,
        faxNumber?: string | null,
      } | null,
    } | null,
    clinicSetup?:  {
      __typename: "ClinicSetup",
      id: string,
      clinicSetupClinicId: string,
      createdAt: string,
      updatedAt: string,
      layouts?:  Array< {
        __typename: "Layout",
        id?: string | null,
        name?: string | null,
        alwaysExpand?: string | null,
        signsLayout?:  {
          __typename: "FieldStatus",
          show?: Array< string | null > | null,
          hide?: Array< string | null > | null,
        } | null,
        medicalHistoryLayout?:  {
          __typename: "FieldStatus",
          show?: Array< string | null > | null,
          hide?: Array< string | null > | null,
        } | null,
        symptomSettings?: string | null,
      } | null > | null,
      defaultQuestionnaireLanguage?: string | null,
      defaultPdfLanguage?: string | null,
      welcomeMessageConfig?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAllAssessmentInfoQueryVariables = {
  id?: string,
};

export type GetAllAssessmentInfoQuery = {
  getAssessment?:  {
    __typename: "Assessment",
    id: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
    },
    clinic?:  {
      __typename: "Clinic",
      id: string,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
    } | null,
    assessmentClinicId?: string | null,
    type?: AssessmentType | null,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    locked?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        config?: string | null,
        response?:  {
          __typename: "QuestionnaireRequestResponse",
          answers: string,
          scores: string,
          consent:  {
            __typename: "QuestionnairePatientConsent",
            consentsToPrivacyForm?: boolean | null,
            consentsToResearch?: boolean | null,
            agreedToTerms: boolean,
            consentSource: ConsentSource,
          },
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSubscriptionMutationVariables = {
  clinicId?: string,
  name?: string,
  email?: string,
  cardToken?: string,
  customerId?: string | null,
};

export type CreateSubscriptionMutation = {
  createSubscription?:  {
    __typename: "StripeInformation",
    subscriptionId?: string | null,
    customerId?: string | null,
    lastDigitsOfCard?: string | null,
    nextPaymentDate?: string | null,
    nameOnCard?: string | null,
    subscriptionStatus?: string | null,
  } | null,
};

export type UpdatePaymentInformationMutationVariables = {
  customerId?: string,
  clinicId?: string,
  name?: string,
  cardToken?: string,
};

export type UpdatePaymentInformationMutation = {
  updatePaymentInformation?:  {
    __typename: "StripeInformation",
    subscriptionId?: string | null,
    customerId?: string | null,
    lastDigitsOfCard?: string | null,
    nextPaymentDate?: string | null,
    nameOnCard?: string | null,
    subscriptionStatus?: string | null,
  } | null,
};

export type ReactivateSubscriptionMutationVariables = {
  clinicId?: string,
  customerId?: string | null,
  subscriptionId?: string | null,
};

export type ReactivateSubscriptionMutation = {
  reactivateSubscription?:  {
    __typename: "StripeInformation",
    subscriptionId?: string | null,
    customerId?: string | null,
    lastDigitsOfCard?: string | null,
    nextPaymentDate?: string | null,
    nameOnCard?: string | null,
    subscriptionStatus?: string | null,
  } | null,
};

export type CancelSubscriptionMutationVariables = {
  clinicId?: string,
  subscriptionId?: string,
};

export type CancelSubscriptionMutation = {
  cancelSubscription?:  {
    __typename: "StripeInformation",
    subscriptionId?: string | null,
    customerId?: string | null,
    lastDigitsOfCard?: string | null,
    nextPaymentDate?: string | null,
    nameOnCard?: string | null,
    subscriptionStatus?: string | null,
  } | null,
};

export type AddStaffMutationVariables = {
  clinicId?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  staffType?: string,
  clinicSetupId?: string | null,
};

export type AddStaffMutation = {
  addStaff?: boolean | null,
};

export type UpdateStaffTypeMutationVariables = {
  id?: string,
  staffType?: StaffType,
  clinicId?: string,
  clinicSetupId?: string | null,
};

export type UpdateStaffTypeMutation = {
  updateStaffType?: boolean | null,
};

export type DeactivateStaffMutationVariables = {
  staffId?: string,
};

export type DeactivateStaffMutation = {
  deactivateStaff?: boolean | null,
};

export type ReactivateStaffMutationVariables = {
  staffId?: string,
};

export type ReactivateStaffMutation = {
  reactivateStaff?: boolean | null,
};

export type CreateClinicMutationVariables = {
  input?: CreateClinicInput,
};

export type CreateClinicMutation = {
  createClinic?:  {
    __typename: "Clinic",
    id: string,
    name: string,
    address: string,
    province?: string | null,
    country?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone: string,
    fax?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      items?:  Array< {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    owner?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    emrIntegration?:  {
      __typename: "EMRIntegrationSettings",
      fax?:  {
        __typename: "FaxToEMRSettings",
        enabled: boolean,
        faxNumber?: string | null,
      } | null,
    } | null,
    stripeInformation?:  {
      __typename: "StripeInformation",
      subscriptionId?: string | null,
      customerId?: string | null,
      lastDigitsOfCard?: string | null,
      nextPaymentDate?: string | null,
      nameOnCard?: string | null,
      subscriptionStatus?: string | null,
    } | null,
    clinicSetup?:  {
      __typename: "ClinicSetup",
      id: string,
      clinicSetupClinicId: string,
      textFavorites?:  {
        __typename: "ModelTextFavoriteConnection",
        nextToken?: string | null,
      } | null,
      treatmentTemplates?:  {
        __typename: "ModelTreatmentTemplateConnection",
        nextToken?: string | null,
      } | null,
      doctorLicenses?: number | null,
      layouts?:  Array< {
        __typename: "Layout",
        id?: string | null,
        name?: string | null,
        alwaysExpand?: string | null,
        customLayout?: string | null,
        symptomSettings?: string | null,
      } | null > | null,
      interactionsSetup?: string | null,
      defaultQuestionnaireLanguage?: string | null,
      defaultPdfLanguage?: string | null,
      temperatureUnit?: TemperatureUnit | null,
      questionnaireExpiry?: number | null,
      welcomeMessageConfig?: string | null,
      emailNotificationsConfig?: string | null,
      hiddenTextFavorites?: Array< string | null > | null,
      hiddenTreatmentTemplates?: Array< string | null > | null,
      textFavoriteConfig?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    agreedToTermsOfService?: boolean | null,
    referralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    linkedReferralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clinicLinkedReferralCenterId?: string | null,
    clinicFormTemplates?:  {
      __typename: "ModelFormTemplateConnection",
      items?:  Array< {
        __typename: "FormTemplate",
        id: string,
        schema?: string | null,
        formTemplateClinicId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    hasDryEyePortalAccess?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClinicMutationVariables = {
  input?: UpdateClinicInput,
};

export type UpdateClinicMutation = {
  updateClinic?:  {
    __typename: "Clinic",
    id: string,
    name: string,
    address: string,
    province?: string | null,
    country?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone: string,
    fax?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      items?:  Array< {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    owner?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    emrIntegration?:  {
      __typename: "EMRIntegrationSettings",
      fax?:  {
        __typename: "FaxToEMRSettings",
        enabled: boolean,
        faxNumber?: string | null,
      } | null,
    } | null,
    stripeInformation?:  {
      __typename: "StripeInformation",
      subscriptionId?: string | null,
      customerId?: string | null,
      lastDigitsOfCard?: string | null,
      nextPaymentDate?: string | null,
      nameOnCard?: string | null,
      subscriptionStatus?: string | null,
    } | null,
    clinicSetup?:  {
      __typename: "ClinicSetup",
      id: string,
      clinicSetupClinicId: string,
      textFavorites?:  {
        __typename: "ModelTextFavoriteConnection",
        nextToken?: string | null,
      } | null,
      treatmentTemplates?:  {
        __typename: "ModelTreatmentTemplateConnection",
        nextToken?: string | null,
      } | null,
      doctorLicenses?: number | null,
      layouts?:  Array< {
        __typename: "Layout",
        id?: string | null,
        name?: string | null,
        alwaysExpand?: string | null,
        customLayout?: string | null,
        symptomSettings?: string | null,
      } | null > | null,
      interactionsSetup?: string | null,
      defaultQuestionnaireLanguage?: string | null,
      defaultPdfLanguage?: string | null,
      temperatureUnit?: TemperatureUnit | null,
      questionnaireExpiry?: number | null,
      welcomeMessageConfig?: string | null,
      emailNotificationsConfig?: string | null,
      hiddenTextFavorites?: Array< string | null > | null,
      hiddenTreatmentTemplates?: Array< string | null > | null,
      textFavoriteConfig?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    agreedToTermsOfService?: boolean | null,
    referralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    linkedReferralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clinicLinkedReferralCenterId?: string | null,
    clinicFormTemplates?:  {
      __typename: "ModelFormTemplateConnection",
      items?:  Array< {
        __typename: "FormTemplate",
        id: string,
        schema?: string | null,
        formTemplateClinicId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    hasDryEyePortalAccess?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClinicSetupMutationVariables = {
  input?: CreateClinicSetupInput,
};

export type CreateClinicSetupMutation = {
  createClinicSetup?:  {
    __typename: "ClinicSetup",
    id: string,
    clinicSetupClinicId: string,
    textFavorites?:  {
      __typename: "ModelTextFavoriteConnection",
      items?:  Array< {
        __typename: "TextFavorite",
        id: string,
        shortcut: string,
        text: string,
        type: TextFavoriteType,
        textFavoriteTreatmentTemplateId?: string | null,
        textFavoriteClinicId: string,
        copiedTextFavoriteId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    treatmentTemplates?:  {
      __typename: "ModelTreatmentTemplateConnection",
      items?:  Array< {
        __typename: "TreatmentTemplate",
        id: string,
        friendlyId: string,
        otcEyeDrops?: Array< string | null > | null,
        rxEyeDrops?: Array< string | null > | null,
        procedures?: Array< string | null > | null,
        exercises?: Array< string | null > | null,
        treatmentTemplateClinicId: string,
        copiedTreatmentTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    doctorLicenses?: number | null,
    layouts?:  Array< {
      __typename: "Layout",
      id?: string | null,
      name?: string | null,
      medicalHistoryLayout?:  {
        __typename: "FieldStatus",
        show?: Array< string | null > | null,
        hide?: Array< string | null > | null,
      } | null,
      signsLayout?:  {
        __typename: "FieldStatus",
        show?: Array< string | null > | null,
        hide?: Array< string | null > | null,
      } | null,
      alwaysExpand?: string | null,
      customLayout?: string | null,
      symptomSettings?: string | null,
    } | null > | null,
    interactionsSetup?: string | null,
    defaultQuestionnaireLanguage?: string | null,
    defaultPdfLanguage?: string | null,
    temperatureUnit?: TemperatureUnit | null,
    questionnaireExpiry?: number | null,
    welcomeMessageConfig?: string | null,
    emailNotificationsConfig?: string | null,
    hiddenTextFavorites?: Array< string | null > | null,
    hiddenTreatmentTemplates?: Array< string | null > | null,
    textFavoriteConfig?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClinicSetupMutationVariables = {
  input?: UpdateClinicSetupInput,
};

export type UpdateClinicSetupMutation = {
  updateClinicSetup?:  {
    __typename: "ClinicSetup",
    id: string,
    clinicSetupClinicId: string,
    textFavorites?:  {
      __typename: "ModelTextFavoriteConnection",
      items?:  Array< {
        __typename: "TextFavorite",
        id: string,
        shortcut: string,
        text: string,
        type: TextFavoriteType,
        textFavoriteTreatmentTemplateId?: string | null,
        textFavoriteClinicId: string,
        copiedTextFavoriteId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    treatmentTemplates?:  {
      __typename: "ModelTreatmentTemplateConnection",
      items?:  Array< {
        __typename: "TreatmentTemplate",
        id: string,
        friendlyId: string,
        otcEyeDrops?: Array< string | null > | null,
        rxEyeDrops?: Array< string | null > | null,
        procedures?: Array< string | null > | null,
        exercises?: Array< string | null > | null,
        treatmentTemplateClinicId: string,
        copiedTreatmentTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    doctorLicenses?: number | null,
    layouts?:  Array< {
      __typename: "Layout",
      id?: string | null,
      name?: string | null,
      medicalHistoryLayout?:  {
        __typename: "FieldStatus",
        show?: Array< string | null > | null,
        hide?: Array< string | null > | null,
      } | null,
      signsLayout?:  {
        __typename: "FieldStatus",
        show?: Array< string | null > | null,
        hide?: Array< string | null > | null,
      } | null,
      alwaysExpand?: string | null,
      customLayout?: string | null,
      symptomSettings?: string | null,
    } | null > | null,
    interactionsSetup?: string | null,
    defaultQuestionnaireLanguage?: string | null,
    defaultPdfLanguage?: string | null,
    temperatureUnit?: TemperatureUnit | null,
    questionnaireExpiry?: number | null,
    welcomeMessageConfig?: string | null,
    emailNotificationsConfig?: string | null,
    hiddenTextFavorites?: Array< string | null > | null,
    hiddenTreatmentTemplates?: Array< string | null > | null,
    textFavoriteConfig?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input?: UpdateNotificationInput,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    properties?:  Array< {
      __typename: "NotificationProperties",
      doctorId: string,
      status?:  {
        __typename: "NotificationStatus",
        read?: boolean | null,
        flag?: Flag | null,
      } | null,
    } | null > | null,
    type: NotificationType,
    typeId?: string | null,
    content?: string | null,
    notificationClinicId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input?: DeleteNotificationInput,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    properties?:  Array< {
      __typename: "NotificationProperties",
      doctorId: string,
      status?:  {
        __typename: "NotificationStatus",
        read?: boolean | null,
        flag?: Flag | null,
      } | null,
    } | null > | null,
    type: NotificationType,
    typeId?: string | null,
    content?: string | null,
    notificationClinicId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIntakeFormMutationVariables = {
  input?: UpdateIntakeFormInput,
};

export type UpdateIntakeFormMutation = {
  UpdateIntakeForm?:  {
    __typename: "IntakeForm",
    id: string,
    response: string,
    createdAt: string,
    intakeFormClinicId: string,
    intakeFormPatientId?: string | null,
    statusId?: string | null,
    status?: IntakeFormStatus | null,
    lastRead?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateInteractionMutationVariables = {
  input?: CreateInteractionInput,
};

export type CreateInteractionMutation = {
  createInteraction?:  {
    __typename: "Interaction",
    id: string,
    patient:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    type: string,
    status: string,
    comments?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    surgeryType?: string | null,
    appointmentDate?: string | null,
    attemptFailed?: boolean | null,
    communicationMethod?: string | null,
    interactionClinicId: string,
  } | null,
};

export type UpdateInteractionMutationVariables = {
  input?: UpdateInteractionInput,
};

export type UpdateInteractionMutation = {
  updateInteraction?:  {
    __typename: "Interaction",
    id: string,
    patient:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    type: string,
    status: string,
    comments?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    surgeryType?: string | null,
    appointmentDate?: string | null,
    attemptFailed?: boolean | null,
    communicationMethod?: string | null,
    interactionClinicId: string,
  } | null,
};

export type CreateTextFavoriteMutationVariables = {
  input?: CreateTextFavoriteInput,
};

export type CreateTextFavoriteMutation = {
  createTextFavorite?:  {
    __typename: "TextFavorite",
    id: string,
    shortcut: string,
    text: string,
    type: TextFavoriteType,
    treatmentTemplate?:  {
      __typename: "TreatmentTemplate",
      id: string,
      friendlyId: string,
      otcEyeDrops?: Array< string | null > | null,
      rxEyeDrops?: Array< string | null > | null,
      procedures?: Array< string | null > | null,
      exercises?: Array< string | null > | null,
      treatmentTemplateClinicId: string,
      copiedTreatmentTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    textFavoriteTreatmentTemplateId?: string | null,
    textFavoriteClinicId: string,
    copiedTextFavoriteId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTextFavoriteMutationVariables = {
  input?: UpdateTextFavoriteInput,
};

export type UpdateTextFavoriteMutation = {
  updateTextFavorite?:  {
    __typename: "TextFavorite",
    id: string,
    shortcut: string,
    text: string,
    type: TextFavoriteType,
    treatmentTemplate?:  {
      __typename: "TreatmentTemplate",
      id: string,
      friendlyId: string,
      otcEyeDrops?: Array< string | null > | null,
      rxEyeDrops?: Array< string | null > | null,
      procedures?: Array< string | null > | null,
      exercises?: Array< string | null > | null,
      treatmentTemplateClinicId: string,
      copiedTreatmentTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    textFavoriteTreatmentTemplateId?: string | null,
    textFavoriteClinicId: string,
    copiedTextFavoriteId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTextFavoriteMutationVariables = {
  input?: DeleteTextFavoriteInput,
};

export type DeleteTextFavoriteMutation = {
  deleteTextFavorite?:  {
    __typename: "TextFavorite",
    id: string,
    shortcut: string,
    text: string,
    type: TextFavoriteType,
    treatmentTemplate?:  {
      __typename: "TreatmentTemplate",
      id: string,
      friendlyId: string,
      otcEyeDrops?: Array< string | null > | null,
      rxEyeDrops?: Array< string | null > | null,
      procedures?: Array< string | null > | null,
      exercises?: Array< string | null > | null,
      treatmentTemplateClinicId: string,
      copiedTreatmentTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    textFavoriteTreatmentTemplateId?: string | null,
    textFavoriteClinicId: string,
    copiedTextFavoriteId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTreatmentTemplateMutationVariables = {
  input?: CreateTreatmentTemplateInput,
};

export type CreateTreatmentTemplateMutation = {
  createTreatmentTemplate?:  {
    __typename: "TreatmentTemplate",
    id: string,
    friendlyId: string,
    otcEyeDrops?: Array< string | null > | null,
    rxEyeDrops?: Array< string | null > | null,
    procedures?: Array< string | null > | null,
    exercises?: Array< string | null > | null,
    treatmentTemplateClinicId: string,
    copiedTreatmentTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTreatmentTemplateMutationVariables = {
  input?: UpdateTreatmentTemplateInput,
};

export type UpdateTreatmentTemplateMutation = {
  updateTreatmentTemplate?:  {
    __typename: "TreatmentTemplate",
    id: string,
    friendlyId: string,
    otcEyeDrops?: Array< string | null > | null,
    rxEyeDrops?: Array< string | null > | null,
    procedures?: Array< string | null > | null,
    exercises?: Array< string | null > | null,
    treatmentTemplateClinicId: string,
    copiedTreatmentTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTreatmentTemplateMutationVariables = {
  input?: DeleteTreatmentTemplateInput,
};

export type DeleteTreatmentTemplateMutation = {
  deleteTreatmentTemplate?:  {
    __typename: "TreatmentTemplate",
    id: string,
    friendlyId: string,
    otcEyeDrops?: Array< string | null > | null,
    rxEyeDrops?: Array< string | null > | null,
    procedures?: Array< string | null > | null,
    exercises?: Array< string | null > | null,
    treatmentTemplateClinicId: string,
    copiedTreatmentTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMassClinicMutationVariables = {
  input?: CreateMassClinicInput,
};

export type CreateMassClinicMutation = {
  createMassClinic?:  {
    __typename: "MassClinic",
    id: string,
    massClinicClinicId: string,
    date: string,
    massClinicPatients?:  {
      __typename: "ModelMassClinicPatientConnection",
      items?:  Array< {
        __typename: "MassClinicPatient",
        id: string,
        massClinicPatientClinicId: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isCancelled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMassClinicMutationVariables = {
  input?: UpdateMassClinicInput,
};

export type UpdateMassClinicMutation = {
  updateMassClinic?:  {
    __typename: "MassClinic",
    id: string,
    massClinicClinicId: string,
    date: string,
    massClinicPatients?:  {
      __typename: "ModelMassClinicPatientConnection",
      items?:  Array< {
        __typename: "MassClinicPatient",
        id: string,
        massClinicPatientClinicId: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isCancelled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMassClinicPatientMutationVariables = {
  input?: CreateMassClinicPatientInput,
};

export type CreateMassClinicPatientMutation = {
  createMassClinicPatient?:  {
    __typename: "MassClinicPatient",
    id: string,
    patient:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    massClinicPatientClinicId: string,
    massClinic:  {
      __typename: "MassClinic",
      id: string,
      massClinicClinicId: string,
      date: string,
      massClinicPatients?:  {
        __typename: "ModelMassClinicPatientConnection",
        nextToken?: string | null,
      } | null,
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isCancelled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    questionnaireRequest?:  {
      __typename: "QuestionnaireRequest",
      id: string,
      email?: string | null,
      mobile?: string | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      massClinicPatient?:  {
        __typename: "MassClinicPatient",
        id: string,
        massClinicPatientClinicId: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessment?:  {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      questionnaireRequestPatientId?: string | null,
      type: QuestionnaireType,
      response?:  {
        __typename: "QuestionnaireRequestResponse",
        answers: string,
        scores: string,
      } | null,
      country?: string | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      consentsToResearch?: boolean | null,
      completedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      nonModifiableData?: string | null,
      language?: string | null,
      config?: string | null,
    } | null,
  } | null,
};

export type UpdateMassClinicPatientMutationVariables = {
  input?: UpdateMassClinicPatientInput,
};

export type UpdateMassClinicPatientMutation = {
  updateMassClinicPatient?:  {
    __typename: "MassClinicPatient",
    id: string,
    patient:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    massClinicPatientClinicId: string,
    massClinic:  {
      __typename: "MassClinic",
      id: string,
      massClinicClinicId: string,
      date: string,
      massClinicPatients?:  {
        __typename: "ModelMassClinicPatientConnection",
        nextToken?: string | null,
      } | null,
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isCancelled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    questionnaireRequest?:  {
      __typename: "QuestionnaireRequest",
      id: string,
      email?: string | null,
      mobile?: string | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      massClinicPatient?:  {
        __typename: "MassClinicPatient",
        id: string,
        massClinicPatientClinicId: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessment?:  {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      questionnaireRequestPatientId?: string | null,
      type: QuestionnaireType,
      response?:  {
        __typename: "QuestionnaireRequestResponse",
        answers: string,
        scores: string,
      } | null,
      country?: string | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      consentsToResearch?: boolean | null,
      completedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      nonModifiableData?: string | null,
      language?: string | null,
      config?: string | null,
    } | null,
  } | null,
};

export type CreateStaffClinicReferenceMutationVariables = {
  input?: CreateStaffClinicReferenceInput,
};

export type CreateStaffClinicReferenceMutation = {
  createStaffClinicReference?:  {
    __typename: "StaffClinicReference",
    id: string,
    staffId: string,
    clinicId: string,
    clinicOwnerId: string,
    staffTypes: Array< StaffType | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStaffClinicReferenceMutationVariables = {
  input?: DeleteStaffClinicReferenceInput,
};

export type DeleteStaffClinicReferenceMutation = {
  deleteStaffClinicReference?:  {
    __typename: "StaffClinicReference",
    id: string,
    staffId: string,
    clinicId: string,
    clinicOwnerId: string,
    staffTypes: Array< StaffType | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDoctorMutationVariables = {
  input?: CreateDoctorInput,
};

export type CreateDoctorMutation = {
  createDoctor?:  {
    __typename: "Doctor",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    emailVerified?: boolean | null,
    agreedToTerms?: boolean | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cognitoGroups?: Array< string | null > | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    practitionerId?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    assessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inboundAssessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deactivated?: boolean | null,
    staffType?: StaffType | null,
    clinicLicenses?: number | null,
    cameraAssessmentRequest?: string | null,
    ownedClinics?: string | null,
    allowMultipleClinics?: boolean | null,
  } | null,
};

export type UpdateDoctorMutationVariables = {
  input?: UpdateDoctorInput,
};

export type UpdateDoctorMutation = {
  updateDoctor?:  {
    __typename: "Doctor",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    emailVerified?: boolean | null,
    agreedToTerms?: boolean | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cognitoGroups?: Array< string | null > | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    practitionerId?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    assessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inboundAssessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deactivated?: boolean | null,
    staffType?: StaffType | null,
    clinicLicenses?: number | null,
    cameraAssessmentRequest?: string | null,
    ownedClinics?: string | null,
    allowMultipleClinics?: boolean | null,
  } | null,
};

export type CreatePatientMutationVariables = {
  input?: CreatePatientInput,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    emailVerified?: boolean | null,
    phone?: string | null,
    agreedToTerms?: boolean | null,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    patientClinicId?: string | null,
    dateOfBirth?: string | null,
    healthCard?:  {
      __typename: "HealthCard",
      province?: string | null,
      number?: string | null,
      country?: string | null,
    } | null,
    gender?: Gender | null,
    genderOther?: string | null,
    address?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    consentsToResearch?: boolean | null,
    consentsToPrivacyForm?: boolean | null,
    consentSource?: ConsentSource | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    linkedReferralPatientId?: string | null,
    referralSource?: string | null,
    nonModifiableData?: string | null,
    interactions?:  {
      __typename: "ModelInteractionConnection",
      items?:  Array< {
        __typename: "Interaction",
        id: string,
        type: string,
        status: string,
        comments?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        surgeryType?: string | null,
        appointmentDate?: string | null,
        attemptFailed?: boolean | null,
        communicationMethod?: string | null,
        interactionClinicId: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    lastInteractionAt?: string | null,
    isColdLead?: boolean | null,
    fitzpatrickImage?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input?: UpdatePatientInput,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    emailVerified?: boolean | null,
    phone?: string | null,
    agreedToTerms?: boolean | null,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    patientClinicId?: string | null,
    dateOfBirth?: string | null,
    healthCard?:  {
      __typename: "HealthCard",
      province?: string | null,
      number?: string | null,
      country?: string | null,
    } | null,
    gender?: Gender | null,
    genderOther?: string | null,
    address?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    consentsToResearch?: boolean | null,
    consentsToPrivacyForm?: boolean | null,
    consentSource?: ConsentSource | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    linkedReferralPatientId?: string | null,
    referralSource?: string | null,
    nonModifiableData?: string | null,
    interactions?:  {
      __typename: "ModelInteractionConnection",
      items?:  Array< {
        __typename: "Interaction",
        id: string,
        type: string,
        status: string,
        comments?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        surgeryType?: string | null,
        appointmentDate?: string | null,
        attemptFailed?: boolean | null,
        communicationMethod?: string | null,
        interactionClinicId: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    lastInteractionAt?: string | null,
    isColdLead?: boolean | null,
    fitzpatrickImage?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAssessmentMutationVariables = {
  input?: CreateAssessmentInput,
};

export type CreateAssessmentMutation = {
  createAssessment?:  {
    __typename: "Assessment",
    id: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    patient?:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assessmentClinicId?: string | null,
    requests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    type?: AssessmentType | null,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    locked?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAssessmentMutationVariables = {
  input?: UpdateAssessmentInput,
};

export type UpdateAssessmentMutation = {
  updateAssessment?:  {
    __typename: "Assessment",
    id: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    patient?:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assessmentClinicId?: string | null,
    requests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    type?: AssessmentType | null,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    locked?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReferralCenterMutationVariables = {
  input?: CreateReferralCenterInput,
};

export type CreateReferralCenterMutation = {
  createReferralCenter?:  {
    __typename: "ReferralCenter",
    id: string,
    name?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    referralCenterClinicId: string,
    diseaseAreas?: Array< string | null > | null,
    notificationRecipients?: Array< string | null > | null,
    referralForms?:  Array< {
      __typename: "ReferralForm",
      name?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      type?: ReferralFormType | null,
    } | null > | null,
    agreement?: string | null,
    copaymentEmbedLink?: string | null,
    educationEmbedLink?: string | null,
    advancedReferralCenter?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReferralCenterMutationVariables = {
  input?: UpdateReferralCenterInput,
};

export type UpdateReferralCenterMutation = {
  updateReferralCenter?:  {
    __typename: "ReferralCenter",
    id: string,
    name?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    referralCenterClinicId: string,
    diseaseAreas?: Array< string | null > | null,
    notificationRecipients?: Array< string | null > | null,
    referralForms?:  Array< {
      __typename: "ReferralForm",
      name?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      type?: ReferralFormType | null,
    } | null > | null,
    agreement?: string | null,
    copaymentEmbedLink?: string | null,
    educationEmbedLink?: string | null,
    advancedReferralCenter?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReferralMutationVariables = {
  input?: CreateReferralInput,
};

export type CreateReferralMutation = {
  createReferral?:  {
    __typename: "Referral",
    id: string,
    referralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    referringClinicId: string,
    referralCenterClinicId: string,
    referralReferralCenterId: string,
    referralPatientId: string,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    priority: Priority,
    openedAt?: string | null,
    status?: string | null,
    referralCenterResponse?:  Array< {
      __typename: "ReferralResponse",
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      comment?: string | null,
      responseTime?: string | null,
    } | null > | null,
    comment?: string | null,
    feedbackHistory?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: ReferralType | null,
    parentReferralId?: string | null,
  } | null,
};

export type UpdateReferralMutationVariables = {
  input?: UpdateReferralInput,
};

export type UpdateReferralMutation = {
  updateReferral?:  {
    __typename: "Referral",
    id: string,
    referralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    referringClinicId: string,
    referralCenterClinicId: string,
    referralReferralCenterId: string,
    referralPatientId: string,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    priority: Priority,
    openedAt?: string | null,
    status?: string | null,
    referralCenterResponse?:  Array< {
      __typename: "ReferralResponse",
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      comment?: string | null,
      responseTime?: string | null,
    } | null > | null,
    comment?: string | null,
    feedbackHistory?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: ReferralType | null,
    parentReferralId?: string | null,
  } | null,
};

export type CreateAssessmentRequestMutationVariables = {
  input?: CreateAssessmentRequestInput,
};

export type CreateAssessmentRequestMutation = {
  createAssessmentRequest?:  {
    __typename: "AssessmentRequest",
    id: string,
    type?: AssessmentRequestType | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    recipient?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    assessment:  {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    response?: string | null,
    priority?: Priority | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateAssessmentRequestMutationVariables = {
  input?: UpdateAssessmentRequestInput,
};

export type UpdateAssessmentRequestMutation = {
  updateAssessmentRequest?:  {
    __typename: "AssessmentRequest",
    id: string,
    type?: AssessmentRequestType | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    recipient?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    assessment:  {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    response?: string | null,
    priority?: Priority | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateQuestionnaireRequestMutationVariables = {
  input?: CreateQuestionnaireRequestInput,
};

export type CreateQuestionnaireRequestMutation = {
  createQuestionnaireRequest?:  {
    __typename: "QuestionnaireRequest",
    id: string,
    email?: string | null,
    mobile?: string | null,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    massClinicPatient?:  {
      __typename: "MassClinicPatient",
      id: string,
      patient:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      },
      massClinicPatientClinicId: string,
      massClinic:  {
        __typename: "MassClinic",
        id: string,
        massClinicClinicId: string,
        date: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      questionnaireRequest?:  {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null,
    } | null,
    assessment?:  {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    questionnaireRequestPatientId?: string | null,
    type: QuestionnaireType,
    response?:  {
      __typename: "QuestionnaireRequestResponse",
      answers: string,
      scores: string,
      consent:  {
        __typename: "QuestionnairePatientConsent",
        consentsToPrivacyForm?: boolean | null,
        consentsToResearch?: boolean | null,
        agreedToTerms: boolean,
        consentSource: ConsentSource,
      },
    } | null,
    country?: string | null,
    consentsToPrivacyForm?: boolean | null,
    consentSource?: ConsentSource | null,
    consentsToResearch?: boolean | null,
    completedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nonModifiableData?: string | null,
    language?: string | null,
    config?: string | null,
  } | null,
};

export type CreateReminderMutationVariables = {
  input?: CreateReminderInput,
};

export type CreateReminderMutation = {
  createReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReminderMutationVariables = {
  input?: UpdateReminderInput,
};

export type UpdateReminderMutation = {
  updateReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReminderMutationVariables = {
  input?: DeleteReminderInput,
};

export type DeleteReminderMutation = {
  deleteReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIntakeFormMutationVariables = {
  input?: CreateIntakeFormInput,
};

export type CreateIntakeFormMutation = {
  CreateIntakeForm?:  {
    __typename: "IntakeForm",
    id: string,
    response: string,
    createdAt: string,
    intakeFormClinicId: string,
    intakeFormPatientId?: string | null,
    statusId?: string | null,
    status?: IntakeFormStatus | null,
    lastRead?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionnaireRequestMutationVariables = {
  input?: UpdateQuestionnaireRequestInput,
};

export type UpdateQuestionnaireRequestMutation = {
  updateQuestionnaireRequest?:  {
    __typename: "QuestionnaireRequest",
    id: string,
    email?: string | null,
    mobile?: string | null,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    massClinicPatient?:  {
      __typename: "MassClinicPatient",
      id: string,
      patient:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      },
      massClinicPatientClinicId: string,
      massClinic:  {
        __typename: "MassClinic",
        id: string,
        massClinicClinicId: string,
        date: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      questionnaireRequest?:  {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null,
    } | null,
    assessment?:  {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    questionnaireRequestPatientId?: string | null,
    type: QuestionnaireType,
    response?:  {
      __typename: "QuestionnaireRequestResponse",
      answers: string,
      scores: string,
      consent:  {
        __typename: "QuestionnairePatientConsent",
        consentsToPrivacyForm?: boolean | null,
        consentsToResearch?: boolean | null,
        agreedToTerms: boolean,
        consentSource: ConsentSource,
      },
    } | null,
    country?: string | null,
    consentsToPrivacyForm?: boolean | null,
    consentSource?: ConsentSource | null,
    consentsToResearch?: boolean | null,
    completedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nonModifiableData?: string | null,
    language?: string | null,
    config?: string | null,
  } | null,
};

export type MeQuery = {
  me?:  {
    __typename: "Doctor",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    emailVerified?: boolean | null,
    agreedToTerms?: boolean | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cognitoGroups?: Array< string | null > | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    practitionerId?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    assessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inboundAssessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deactivated?: boolean | null,
    staffType?: StaffType | null,
    clinicLicenses?: number | null,
    cameraAssessmentRequest?: string | null,
    ownedClinics?: string | null,
    allowMultipleClinics?: boolean | null,
  } | null,
};

export type HtmlToPdfQueryVariables = {
  htmlInBase64?: string,
};

export type HtmlToPdfQuery = {
  htmlToPDF?:  {
    __typename: "PDF",
    base64: string,
  } | null,
};

export type QueueFaxQueryVariables = {
  to?: string,
  title?: string,
  htmlInBase64?: string,
};

export type QueueFaxQuery = {
  queueFax?:  {
    __typename: "QueueFaxResult",
    Status: SRFaxStatus,
    Result?: string | null,
  } | null,
};

export type SearchMedicationsQueryVariables = {
  query?: string,
};

export type SearchMedicationsQuery = {
  searchMedications?:  {
    __typename: "DrugBankMedications",
    products?:  Array< {
      __typename: "DrugBankMedication",
      _id?: string | null,
      ndc_product_codes?: Array< string | null > | null,
      dpd_codes?: Array< string | null > | null,
      ema_product_codes?: Array< string | null > | null,
      name?: string | null,
      rx_norm_prescribable_name?: string | null,
      country?: string | null,
      route?: string | null,
      dosage_form?: string | null,
      ingredients?:  Array< {
        __typename: "DrugBankActiveActiveIngredients",
        drugbank_id?: string | null,
        name?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetDryEyePredictionQueryVariables = {
  input?: string | null,
};

export type GetDryEyePredictionQuery = {
  getDryEyePrediction?: string | null,
};

export type GetFilteredDataQueryVariables = {
  type?: FilterType,
  clinicId?: string,
  filter?: string,
};

export type GetFilteredDataQuery = {
  getFilteredData?: string | null,
};

export type DummyS3ObjectQueryQuery = {
  dummyS3ObjectQuery?:  {
    __typename: "S3Object",
    bucket: string,
    key: string,
    region: string,
    fileName?: string | null,
    metadata?: string | null,
  } | null,
};

export type DummyCognitoGroupQuery = {
  dummyCognitoGroup?: CognitoGroup | null,
};

export type DrugLookupQueryVariables = {
  query?: string,
};

export type DrugLookupQuery = {
  drugLookup?:  Array< {
    __typename: "Drug",
    id?: string | null,
    ndc_product_codes?: Array< string | null > | null,
    dpd_codes?: Array< string | null > | null,
    ema_product_codes?: Array< string | null > | null,
    name?: string | null,
    rx_norm_prescribable_name?: string | null,
    country?: string | null,
    routes?:  Array< {
      __typename: "DrugRoute",
      route_of_administration?: string | null,
    } | null > | null,
    dosage_form?: string | null,
    active_ingredients?:  Array< {
      __typename: "DrugActiveIngredients",
      ingredient?: string | null,
      strength?: number | null,
      strength_unit?: string | null,
    } | null > | null,
  } | null > | null,
};

export type GetClinicQueryVariables = {
  id?: string,
};

export type GetClinicQuery = {
  getClinic?:  {
    __typename: "Clinic",
    id: string,
    name: string,
    address: string,
    province?: string | null,
    country?: string | null,
    city?: string | null,
    postalCode?: string | null,
    phone: string,
    fax?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      items?:  Array< {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    owner?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    emrIntegration?:  {
      __typename: "EMRIntegrationSettings",
      fax?:  {
        __typename: "FaxToEMRSettings",
        enabled: boolean,
        faxNumber?: string | null,
      } | null,
    } | null,
    stripeInformation?:  {
      __typename: "StripeInformation",
      subscriptionId?: string | null,
      customerId?: string | null,
      lastDigitsOfCard?: string | null,
      nextPaymentDate?: string | null,
      nameOnCard?: string | null,
      subscriptionStatus?: string | null,
    } | null,
    clinicSetup?:  {
      __typename: "ClinicSetup",
      id: string,
      clinicSetupClinicId: string,
      textFavorites?:  {
        __typename: "ModelTextFavoriteConnection",
        nextToken?: string | null,
      } | null,
      treatmentTemplates?:  {
        __typename: "ModelTreatmentTemplateConnection",
        nextToken?: string | null,
      } | null,
      doctorLicenses?: number | null,
      layouts?:  Array< {
        __typename: "Layout",
        id?: string | null,
        name?: string | null,
        alwaysExpand?: string | null,
        customLayout?: string | null,
        symptomSettings?: string | null,
      } | null > | null,
      interactionsSetup?: string | null,
      defaultQuestionnaireLanguage?: string | null,
      defaultPdfLanguage?: string | null,
      temperatureUnit?: TemperatureUnit | null,
      questionnaireExpiry?: number | null,
      welcomeMessageConfig?: string | null,
      emailNotificationsConfig?: string | null,
      hiddenTextFavorites?: Array< string | null > | null,
      hiddenTreatmentTemplates?: Array< string | null > | null,
      textFavoriteConfig?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    agreedToTermsOfService?: boolean | null,
    referralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    linkedReferralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clinicLinkedReferralCenterId?: string | null,
    clinicFormTemplates?:  {
      __typename: "ModelFormTemplateConnection",
      items?:  Array< {
        __typename: "FormTemplate",
        id: string,
        schema?: string | null,
        formTemplateClinicId: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    hasDryEyePortalAccess?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetNotificationQueryVariables = {
  id?: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    properties?:  Array< {
      __typename: "NotificationProperties",
      doctorId: string,
      status?:  {
        __typename: "NotificationStatus",
        read?: boolean | null,
        flag?: Flag | null,
      } | null,
    } | null > | null,
    type: NotificationType,
    typeId?: string | null,
    content?: string | null,
    notificationClinicId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      properties?:  Array< {
        __typename: "NotificationProperties",
        doctorId: string,
      } | null > | null,
      type: NotificationType,
      typeId?: string | null,
      content?: string | null,
      notificationClinicId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemNotificationsQueryVariables = {
  filter?: ModelSystemNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemNotificationsQuery = {
  listSystemNotifications?:  {
    __typename: "ModelSystemNotificationConnection",
    items?:  Array< {
      __typename: "SystemNotification",
      id: string,
      date: string,
      content?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetIntakeFormQueryVariables = {
  id?: string,
};

export type GetIntakeFormQuery = {
  getIntakeForm?:  {
    __typename: "IntakeForm",
    id: string,
    response: string,
    createdAt: string,
    intakeFormClinicId: string,
    intakeFormPatientId?: string | null,
    statusId?: string | null,
    status?: IntakeFormStatus | null,
    lastRead?: string | null,
    updatedAt: string,
  } | null,
};

export type ListIntakeFormsQueryVariables = {
  filter?: ModelIntakeFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntakeFormsQuery = {
  listIntakeForms?:  {
    __typename: "ModelIntakeFormConnection",
    items?:  Array< {
      __typename: "IntakeForm",
      id: string,
      response: string,
      createdAt: string,
      intakeFormClinicId: string,
      intakeFormPatientId?: string | null,
      statusId?: string | null,
      status?: IntakeFormStatus | null,
      lastRead?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetInteractionQueryVariables = {
  id?: string,
};

export type GetInteractionQuery = {
  getInteraction?:  {
    __typename: "Interaction",
    id: string,
    patient:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    type: string,
    status: string,
    comments?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    surgeryType?: string | null,
    appointmentDate?: string | null,
    attemptFailed?: boolean | null,
    communicationMethod?: string | null,
    interactionClinicId: string,
  } | null,
};

export type ListInteractionsQueryVariables = {
  filter?: ModelInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInteractionsQuery = {
  listInteractions?:  {
    __typename: "ModelInteractionConnection",
    items?:  Array< {
      __typename: "Interaction",
      id: string,
      patient:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      },
      type: string,
      status: string,
      comments?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      surgeryType?: string | null,
      appointmentDate?: string | null,
      attemptFailed?: boolean | null,
      communicationMethod?: string | null,
      interactionClinicId: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMassClinicQueryVariables = {
  id?: string,
};

export type GetMassClinicQuery = {
  getMassClinic?:  {
    __typename: "MassClinic",
    id: string,
    massClinicClinicId: string,
    date: string,
    massClinicPatients?:  {
      __typename: "ModelMassClinicPatientConnection",
      items?:  Array< {
        __typename: "MassClinicPatient",
        id: string,
        massClinicPatientClinicId: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isCancelled?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetMassClinicPatientQueryVariables = {
  id?: string,
};

export type GetMassClinicPatientQuery = {
  getMassClinicPatient?:  {
    __typename: "MassClinicPatient",
    id: string,
    patient:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    massClinicPatientClinicId: string,
    massClinic:  {
      __typename: "MassClinic",
      id: string,
      massClinicClinicId: string,
      date: string,
      massClinicPatients?:  {
        __typename: "ModelMassClinicPatientConnection",
        nextToken?: string | null,
      } | null,
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    isCancelled?: boolean | null,
    createdAt: string,
    updatedAt: string,
    questionnaireRequest?:  {
      __typename: "QuestionnaireRequest",
      id: string,
      email?: string | null,
      mobile?: string | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      massClinicPatient?:  {
        __typename: "MassClinicPatient",
        id: string,
        massClinicPatientClinicId: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessment?:  {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      questionnaireRequestPatientId?: string | null,
      type: QuestionnaireType,
      response?:  {
        __typename: "QuestionnaireRequestResponse",
        answers: string,
        scores: string,
      } | null,
      country?: string | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      consentsToResearch?: boolean | null,
      completedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      nonModifiableData?: string | null,
      language?: string | null,
      config?: string | null,
    } | null,
  } | null,
};

export type GetStaffClinicReferenceQueryVariables = {
  id?: string,
};

export type GetStaffClinicReferenceQuery = {
  getStaffClinicReference?:  {
    __typename: "StaffClinicReference",
    id: string,
    staffId: string,
    clinicId: string,
    clinicOwnerId: string,
    staffTypes: Array< StaffType | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStaffClinicReferencesQueryVariables = {
  filter?: ModelStaffClinicReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffClinicReferencesQuery = {
  listStaffClinicReferences?:  {
    __typename: "ModelStaffClinicReferenceConnection",
    items?:  Array< {
      __typename: "StaffClinicReference",
      id: string,
      staffId: string,
      clinicId: string,
      clinicOwnerId: string,
      staffTypes: Array< StaffType | null >,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDoctorQueryVariables = {
  id?: string,
};

export type GetDoctorQuery = {
  getDoctor?:  {
    __typename: "Doctor",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    emailVerified?: boolean | null,
    agreedToTerms?: boolean | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cognitoGroups?: Array< string | null > | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    practitionerId?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    assessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inboundAssessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deactivated?: boolean | null,
    staffType?: StaffType | null,
    clinicLicenses?: number | null,
    cameraAssessmentRequest?: string | null,
    ownedClinics?: string | null,
    allowMultipleClinics?: boolean | null,
  } | null,
};

export type ListDoctorsQueryVariables = {
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDoctorsQuery = {
  listDoctors?:  {
    __typename: "ModelDoctorConnection",
    items?:  Array< {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPatientQueryVariables = {
  id?: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    emailVerified?: boolean | null,
    phone?: string | null,
    agreedToTerms?: boolean | null,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    patientClinicId?: string | null,
    dateOfBirth?: string | null,
    healthCard?:  {
      __typename: "HealthCard",
      province?: string | null,
      number?: string | null,
      country?: string | null,
    } | null,
    gender?: Gender | null,
    genderOther?: string | null,
    address?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    consentsToResearch?: boolean | null,
    consentsToPrivacyForm?: boolean | null,
    consentSource?: ConsentSource | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    linkedReferralPatientId?: string | null,
    referralSource?: string | null,
    nonModifiableData?: string | null,
    interactions?:  {
      __typename: "ModelInteractionConnection",
      items?:  Array< {
        __typename: "Interaction",
        id: string,
        type: string,
        status: string,
        comments?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        surgeryType?: string | null,
        appointmentDate?: string | null,
        attemptFailed?: boolean | null,
        communicationMethod?: string | null,
        interactionClinicId: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    lastInteractionAt?: string | null,
    isColdLead?: boolean | null,
    fitzpatrickImage?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items?:  Array< {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAssessmentQueryVariables = {
  id?: string,
};

export type GetAssessmentQuery = {
  getAssessment?:  {
    __typename: "Assessment",
    id: string,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    patient?:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    assessmentClinicId?: string | null,
    requests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    type?: AssessmentType | null,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    locked?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionnaireRequests?:  {
      __typename: "ModelQuestionnaireRequestConnection",
      items?:  Array< {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAssessmentsQueryVariables = {
  filter?: ModelAssessmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssessmentsQuery = {
  listAssessments?:  {
    __typename: "ModelAssessmentConnection",
    items?:  Array< {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetReferralCenterQueryVariables = {
  id?: string,
};

export type GetReferralCenterQuery = {
  getReferralCenter?:  {
    __typename: "ReferralCenter",
    id: string,
    name?: string | null,
    logo?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null,
    referralCenterClinicId: string,
    diseaseAreas?: Array< string | null > | null,
    notificationRecipients?: Array< string | null > | null,
    referralForms?:  Array< {
      __typename: "ReferralForm",
      name?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      type?: ReferralFormType | null,
    } | null > | null,
    agreement?: string | null,
    copaymentEmbedLink?: string | null,
    educationEmbedLink?: string | null,
    advancedReferralCenter?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReferralCentersQueryVariables = {
  filter?: ModelReferralCenterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralCentersQuery = {
  listReferralCenters?:  {
    __typename: "ModelReferralCenterConnection",
    items?:  Array< {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetReferralQueryVariables = {
  id?: string,
};

export type GetReferralQuery = {
  getReferral?:  {
    __typename: "Referral",
    id: string,
    referralCenter?:  {
      __typename: "ReferralCenter",
      id: string,
      name?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      referralCenterClinicId: string,
      diseaseAreas?: Array< string | null > | null,
      notificationRecipients?: Array< string | null > | null,
      referralForms?:  Array< {
        __typename: "ReferralForm",
        name?: string | null,
        type?: ReferralFormType | null,
      } | null > | null,
      agreement?: string | null,
      copaymentEmbedLink?: string | null,
      educationEmbedLink?: string | null,
      advancedReferralCenter?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    referringClinicId: string,
    referralCenterClinicId: string,
    referralReferralCenterId: string,
    referralPatientId: string,
    body: string,
    attachments?:  Array< {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
      fileName?: string | null,
      metadata?: string | null,
    } | null > | null,
    priority: Priority,
    openedAt?: string | null,
    status?: string | null,
    referralCenterResponse?:  Array< {
      __typename: "ReferralResponse",
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      comment?: string | null,
      responseTime?: string | null,
    } | null > | null,
    comment?: string | null,
    feedbackHistory?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: ReferralType | null,
    parentReferralId?: string | null,
  } | null,
};

export type ListReferralsQueryVariables = {
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsQuery = {
  listReferrals?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAssessmentRequestQueryVariables = {
  id?: string,
};

export type GetAssessmentRequestQuery = {
  getAssessmentRequest?:  {
    __typename: "AssessmentRequest",
    id: string,
    type?: AssessmentRequestType | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    recipient?:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    } | null,
    assessment:  {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    },
    response?: string | null,
    priority?: Priority | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type GetFormTemplateQueryVariables = {
  id?: string,
};

export type GetFormTemplateQuery = {
  getFormTemplate?:  {
    __typename: "FormTemplate",
    id: string,
    schema?: string | null,
    formTemplateClinicId: string,
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFormTemplateFieldLibraryQueryVariables = {
  filter?: ModelFormTemplateFieldLibraryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormTemplateFieldLibraryQuery = {
  listFormTemplateFieldLibrary?:  {
    __typename: "ModelFormTemplateFieldLibraryConnection",
    items?:  Array< {
      __typename: "FormTemplateFieldLibrary",
      id: string,
      friendlyId: string,
      label: string,
      typeConfig: string,
      required?: boolean | null,
      triggerGroups?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetReminderQueryVariables = {
  id?: string,
};

export type GetReminderQuery = {
  getReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRemindersQueryVariables = {
  filter?: ModelReminderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRemindersQuery = {
  listReminders?:  {
    __typename: "ModelReminderConnection",
    items?:  Array< {
      __typename: "Reminder",
      id: string,
      reminderPatientId: string,
      reminderClinicId: string,
      reminderStaffId: string,
      clinicName: string,
      doctorName: string,
      patientName: string,
      nextReminderDate: string,
      expirationDate: string,
      numberOfReminders: number,
      email?: string | null,
      phone?: string | null,
      reminderType: ReminderType,
      config: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListClinicsByLinkedReferralCenterQueryVariables = {
  clinicLinkedReferralCenterId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicsByLinkedReferralCenterQuery = {
  listClinicsByLinkedReferralCenter?:  {
    __typename: "ModelClinicConnection",
    items?:  Array< {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByClinicIdQueryVariables = {
  notificationClinicId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByClinicIdQuery = {
  listNotificationsByClinicId?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      properties?:  Array< {
        __typename: "NotificationProperties",
        doctorId: string,
      } | null > | null,
      type: NotificationType,
      typeId?: string | null,
      content?: string | null,
      notificationClinicId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByTypeIdQueryVariables = {
  typeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByTypeIdQuery = {
  listNotificationsByTypeId?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      properties?:  Array< {
        __typename: "NotificationProperties",
        doctorId: string,
      } | null > | null,
      type: NotificationType,
      typeId?: string | null,
      content?: string | null,
      notificationClinicId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListIntakeFormByClinicIdSortedByCreatedAtQueryVariables = {
  intakeFormClinicId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIntakeFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntakeFormByClinicIdSortedByCreatedAtQuery = {
  listIntakeFormByClinicIdSortedByCreatedAt?:  {
    __typename: "ModelIntakeFormConnection",
    items?:  Array< {
      __typename: "IntakeForm",
      id: string,
      response: string,
      createdAt: string,
      intakeFormClinicId: string,
      intakeFormPatientId?: string | null,
      statusId?: string | null,
      status?: IntakeFormStatus | null,
      lastRead?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListIntakeFormByPatientIdSortedByCreatedAtQueryVariables = {
  intakeFormPatientId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIntakeFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntakeFormByPatientIdSortedByCreatedAtQuery = {
  listIntakeFormByPatientIdSortedByCreatedAt?:  {
    __typename: "ModelIntakeFormConnection",
    items?:  Array< {
      __typename: "IntakeForm",
      id: string,
      response: string,
      createdAt: string,
      intakeFormClinicId: string,
      intakeFormPatientId?: string | null,
      statusId?: string | null,
      status?: IntakeFormStatus | null,
      lastRead?: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTextFavoritesQueryVariables = {
  textFavoriteClinicId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextFavoriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTextFavoritesQuery = {
  listTextFavorites?:  {
    __typename: "ModelTextFavoriteConnection",
    items?:  Array< {
      __typename: "TextFavorite",
      id: string,
      shortcut: string,
      text: string,
      type: TextFavoriteType,
      treatmentTemplate?:  {
        __typename: "TreatmentTemplate",
        id: string,
        friendlyId: string,
        otcEyeDrops?: Array< string | null > | null,
        rxEyeDrops?: Array< string | null > | null,
        procedures?: Array< string | null > | null,
        exercises?: Array< string | null > | null,
        treatmentTemplateClinicId: string,
        copiedTreatmentTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      textFavoriteTreatmentTemplateId?: string | null,
      textFavoriteClinicId: string,
      copiedTextFavoriteId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTreatmentTemplateQueryVariables = {
  treatmentTemplateClinicId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTreatmentTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTreatmentTemplateQuery = {
  listTreatmentTemplate?:  {
    __typename: "ModelTreatmentTemplateConnection",
    items?:  Array< {
      __typename: "TreatmentTemplate",
      id: string,
      friendlyId: string,
      otcEyeDrops?: Array< string | null > | null,
      rxEyeDrops?: Array< string | null > | null,
      procedures?: Array< string | null > | null,
      exercises?: Array< string | null > | null,
      treatmentTemplateClinicId: string,
      copiedTreatmentTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMassClinicByClinicIdSortedByDateQueryVariables = {
  massClinicClinicId?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMassClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMassClinicByClinicIdSortedByDateQuery = {
  listMassClinicByClinicIdSortedByDate?:  {
    __typename: "ModelMassClinicConnection",
    items?:  Array< {
      __typename: "MassClinic",
      id: string,
      massClinicClinicId: string,
      date: string,
      massClinicPatients?:  {
        __typename: "ModelMassClinicPatientConnection",
        nextToken?: string | null,
      } | null,
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListStaffClinicReferenceByStaffIdQueryVariables = {
  staffId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffClinicReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffClinicReferenceByStaffIdQuery = {
  listStaffClinicReferenceByStaffId?:  {
    __typename: "ModelStaffClinicReferenceConnection",
    items?:  Array< {
      __typename: "StaffClinicReference",
      id: string,
      staffId: string,
      clinicId: string,
      clinicOwnerId: string,
      staffTypes: Array< StaffType | null >,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListStaffClinicReferenceByClinicIdQueryVariables = {
  clinicId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffClinicReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffClinicReferenceByClinicIdQuery = {
  listStaffClinicReferenceByClinicId?:  {
    __typename: "ModelStaffClinicReferenceConnection",
    items?:  Array< {
      __typename: "StaffClinicReference",
      id: string,
      staffId: string,
      clinicId: string,
      clinicOwnerId: string,
      staffTypes: Array< StaffType | null >,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByLinkedReferralPatientIdQueryVariables = {
  linkedReferralPatientId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByLinkedReferralPatientIdQuery = {
  listPatientsByLinkedReferralPatientId?:  {
    __typename: "ModelPatientConnection",
    items?:  Array< {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPatientsByClinicIdSortedByLastInteractionAtQueryVariables = {
  patientClinicId?: string | null,
  lastInteractionAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsByClinicIdSortedByLastInteractionAtQuery = {
  listPatientsByClinicIdSortedByLastInteractionAt?:  {
    __typename: "ModelPatientConnection",
    items?:  Array< {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListAssessmentsByClinicIdCreatedAtQueryVariables = {
  assessmentClinicId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssessmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssessmentsByClinicIdCreatedAtQuery = {
  listAssessmentsByClinicIdCreatedAt?:  {
    __typename: "ModelAssessmentConnection",
    items?:  Array< {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByReferralCenterIdSortedByCreatedAtQueryVariables = {
  referralReferralCenterId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByReferralCenterIdSortedByCreatedAtQuery = {
  listReferralsByReferralCenterIdSortedByCreatedAt?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByReferralCenterIdSortedByStatusAndCreatedAtQueryVariables = {
  referralReferralCenterId?: string | null,
  statusCreatedAt?: ModelReferralByReferralCenterIdSortedByStatusAndCreatedAtCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByReferralCenterIdSortedByStatusAndCreatedAtQuery = {
  listReferralsByReferralCenterIdSortedByStatusAndCreatedAt?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByReferringClinicIdSortedByStatusAndCreatedAtQueryVariables = {
  referringClinicId?: string | null,
  statusCreatedAt?: ModelReferralByReferringClinicIdSortedByStatusAndCreatedAtCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByReferringClinicIdSortedByStatusAndCreatedAtQuery = {
  listReferralsByReferringClinicIdSortedByStatusAndCreatedAt?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByReferringClinicIdSortedByCreatedAtQueryVariables = {
  referringClinicId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByReferringClinicIdSortedByCreatedAtQuery = {
  listReferralsByReferringClinicIdSortedByCreatedAt?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByPatientIdSortedByCreatedAtQueryVariables = {
  referralPatientId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByPatientIdSortedByCreatedAtQuery = {
  listReferralsByPatientIdSortedByCreatedAt?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByParentIdSortedByCreatedAtQueryVariables = {
  parentReferralId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByParentIdSortedByCreatedAtQuery = {
  listReferralsByParentIdSortedByCreatedAt?:  {
    __typename: "ModelReferralConnection",
    items?:  Array< {
      __typename: "Referral",
      id: string,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      referringClinicId: string,
      referralCenterClinicId: string,
      referralReferralCenterId: string,
      referralPatientId: string,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      priority: Priority,
      openedAt?: string | null,
      status?: string | null,
      referralCenterResponse?:  Array< {
        __typename: "ReferralResponse",
        comment?: string | null,
        responseTime?: string | null,
      } | null > | null,
      comment?: string | null,
      feedbackHistory?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: ReferralType | null,
      parentReferralId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListByTypeIdQueryVariables = {
  typeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCacheFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListByTypeIdQuery = {
  listByTypeId?:  {
    __typename: "ModelDataCacheConnection",
    items?:  Array< {
      __typename: "DataCache",
      id: string,
      data?: string | null,
      dataCount?: number | null,
      type?: DataCacheType | null,
      dataCacheClinicId: string,
      typeId: string,
      dateAndTypeId: string,
      updateId?: string | null,
      createdAt?: string | null,
      sizeFull?: boolean | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListByDateAndTypeIdQueryVariables = {
  dateAndTypeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCacheFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListByDateAndTypeIdQuery = {
  listByDateAndTypeId?:  {
    __typename: "ModelDataCacheConnection",
    items?:  Array< {
      __typename: "DataCache",
      id: string,
      data?: string | null,
      dataCount?: number | null,
      type?: DataCacheType | null,
      dataCacheClinicId: string,
      typeId: string,
      dateAndTypeId: string,
      updateId?: string | null,
      createdAt?: string | null,
      sizeFull?: boolean | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListByUpdateIdQueryVariables = {
  updateId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCacheFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListByUpdateIdQuery = {
  listByUpdateId?:  {
    __typename: "ModelDataCacheConnection",
    items?:  Array< {
      __typename: "DataCache",
      id: string,
      data?: string | null,
      dataCount?: number | null,
      type?: DataCacheType | null,
      dataCacheClinicId: string,
      typeId: string,
      dateAndTypeId: string,
      updateId?: string | null,
      createdAt?: string | null,
      sizeFull?: boolean | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionnaireRequestQueryVariables = {
  id?: string,
};

export type GetQuestionnaireRequestQuery = {
  getQuestionnaireRequest?:  {
    __typename: "QuestionnaireRequest",
    id: string,
    email?: string | null,
    mobile?: string | null,
    doctor:  {
      __typename: "Doctor",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      emailVerified?: boolean | null,
      agreedToTerms?: boolean | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      cognitoGroups?: Array< string | null > | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      practitionerId?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      assessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      inboundAssessmentRequests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      deactivated?: boolean | null,
      staffType?: StaffType | null,
      clinicLicenses?: number | null,
      cameraAssessmentRequest?: string | null,
      ownedClinics?: string | null,
      allowMultipleClinics?: boolean | null,
    },
    clinic:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    massClinicPatient?:  {
      __typename: "MassClinicPatient",
      id: string,
      patient:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      },
      massClinicPatientClinicId: string,
      massClinic:  {
        __typename: "MassClinic",
        id: string,
        massClinicClinicId: string,
        date: string,
        isCancelled?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      isCancelled?: boolean | null,
      createdAt: string,
      updatedAt: string,
      questionnaireRequest?:  {
        __typename: "QuestionnaireRequest",
        id: string,
        email?: string | null,
        mobile?: string | null,
        questionnaireRequestPatientId?: string | null,
        type: QuestionnaireType,
        country?: string | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        consentsToResearch?: boolean | null,
        completedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        nonModifiableData?: string | null,
        language?: string | null,
        config?: string | null,
      } | null,
    } | null,
    assessment?:  {
      __typename: "Assessment",
      id: string,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      patient?:  {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      assessmentClinicId?: string | null,
      requests?:  {
        __typename: "ModelAssessmentRequestConnection",
        nextToken?: string | null,
      } | null,
      type?: AssessmentType | null,
      body: string,
      attachments?:  Array< {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null > | null,
      locked?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      emailVerified?: boolean | null,
      phone?: string | null,
      agreedToTerms?: boolean | null,
      doctor:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      },
      clinic:  {
        __typename: "Clinic",
        id: string,
        name: string,
        address: string,
        province?: string | null,
        country?: string | null,
        city?: string | null,
        postalCode?: string | null,
        phone: string,
        fax?: string | null,
        agreedToTermsOfService?: boolean | null,
        clinicLinkedReferralCenterId?: string | null,
        hasDryEyePortalAccess?: boolean | null,
        createdAt: string,
        updatedAt: string,
      },
      patientClinicId?: string | null,
      dateOfBirth?: string | null,
      healthCard?:  {
        __typename: "HealthCard",
        province?: string | null,
        number?: string | null,
        country?: string | null,
      } | null,
      gender?: Gender | null,
      genderOther?: string | null,
      address?: string | null,
      assessments?:  {
        __typename: "ModelAssessmentConnection",
        nextToken?: string | null,
      } | null,
      consentsToResearch?: boolean | null,
      consentsToPrivacyForm?: boolean | null,
      consentSource?: ConsentSource | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      linkedReferralPatientId?: string | null,
      referralSource?: string | null,
      nonModifiableData?: string | null,
      interactions?:  {
        __typename: "ModelInteractionConnection",
        nextToken?: string | null,
      } | null,
      lastInteractionAt?: string | null,
      isColdLead?: boolean | null,
      fitzpatrickImage?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      questionnaireRequests?:  {
        __typename: "ModelQuestionnaireRequestConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    questionnaireRequestPatientId?: string | null,
    type: QuestionnaireType,
    response?:  {
      __typename: "QuestionnaireRequestResponse",
      answers: string,
      scores: string,
      consent:  {
        __typename: "QuestionnairePatientConsent",
        consentsToPrivacyForm?: boolean | null,
        consentsToResearch?: boolean | null,
        agreedToTerms: boolean,
        consentSource: ConsentSource,
      },
    } | null,
    country?: string | null,
    consentsToPrivacyForm?: boolean | null,
    consentSource?: ConsentSource | null,
    consentsToResearch?: boolean | null,
    completedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    nonModifiableData?: string | null,
    language?: string | null,
    config?: string | null,
  } | null,
};

export type PublicDrugLookupQueryVariables = {
  query?: string,
};

export type PublicDrugLookupQuery = {
  publicDrugLookup?: string | null,
};

export type OnUpdatedDoctorSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdatedDoctorSubscription = {
  onUpdatedDoctor?:  {
    __typename: "Doctor",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    emailVerified?: boolean | null,
    agreedToTerms?: boolean | null,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      name: string,
      address: string,
      province?: string | null,
      country?: string | null,
      city?: string | null,
      postalCode?: string | null,
      phone: string,
      fax?: string | null,
      logo?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
        fileName?: string | null,
        metadata?: string | null,
      } | null,
      doctors?:  {
        __typename: "ModelDoctorConnection",
        nextToken?: string | null,
      } | null,
      owner?:  {
        __typename: "Doctor",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        emailVerified?: boolean | null,
        agreedToTerms?: boolean | null,
        cognitoGroups?: Array< string | null > | null,
        practitionerId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        deactivated?: boolean | null,
        staffType?: StaffType | null,
        clinicLicenses?: number | null,
        cameraAssessmentRequest?: string | null,
        ownedClinics?: string | null,
        allowMultipleClinics?: boolean | null,
      } | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      stripeInformation?:  {
        __typename: "StripeInformation",
        subscriptionId?: string | null,
        customerId?: string | null,
        lastDigitsOfCard?: string | null,
        nextPaymentDate?: string | null,
        nameOnCard?: string | null,
        subscriptionStatus?: string | null,
      } | null,
      clinicSetup?:  {
        __typename: "ClinicSetup",
        id: string,
        clinicSetupClinicId: string,
        doctorLicenses?: number | null,
        interactionsSetup?: string | null,
        defaultQuestionnaireLanguage?: string | null,
        defaultPdfLanguage?: string | null,
        temperatureUnit?: TemperatureUnit | null,
        questionnaireExpiry?: number | null,
        welcomeMessageConfig?: string | null,
        emailNotificationsConfig?: string | null,
        hiddenTextFavorites?: Array< string | null > | null,
        hiddenTreatmentTemplates?: Array< string | null > | null,
        textFavoriteConfig?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      agreedToTermsOfService?: boolean | null,
      referralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      linkedReferralCenter?:  {
        __typename: "ReferralCenter",
        id: string,
        name?: string | null,
        referralCenterClinicId: string,
        diseaseAreas?: Array< string | null > | null,
        notificationRecipients?: Array< string | null > | null,
        agreement?: string | null,
        copaymentEmbedLink?: string | null,
        educationEmbedLink?: string | null,
        advancedReferralCenter?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clinicLinkedReferralCenterId?: string | null,
      clinicFormTemplates?:  {
        __typename: "ModelFormTemplateConnection",
        nextToken?: string | null,
      } | null,
      hasDryEyePortalAccess?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    cognitoGroups?: Array< string | null > | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items?:  Array< {
        __typename: "Patient",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        emailVerified?: boolean | null,
        phone?: string | null,
        agreedToTerms?: boolean | null,
        patientClinicId?: string | null,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        genderOther?: string | null,
        address?: string | null,
        consentsToResearch?: boolean | null,
        consentsToPrivacyForm?: boolean | null,
        consentSource?: ConsentSource | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        linkedReferralPatientId?: string | null,
        referralSource?: string | null,
        nonModifiableData?: string | null,
        lastInteractionAt?: string | null,
        isColdLead?: boolean | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    practitionerId?: string | null,
    assessments?:  {
      __typename: "ModelAssessmentConnection",
      items?:  Array< {
        __typename: "Assessment",
        id: string,
        assessmentClinicId?: string | null,
        type?: AssessmentType | null,
        body: string,
        locked?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    assessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    inboundAssessmentRequests?:  {
      __typename: "ModelAssessmentRequestConnection",
      items?:  Array< {
        __typename: "AssessmentRequest",
        id: string,
        type?: AssessmentRequestType | null,
        response?: string | null,
        priority?: Priority | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    deactivated?: boolean | null,
    staffType?: StaffType | null,
    clinicLicenses?: number | null,
    cameraAssessmentRequest?: string | null,
    ownedClinics?: string | null,
    allowMultipleClinics?: boolean | null,
  } | null,
};

export type OnCreateReminderSubscription = {
  onCreateReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReminderSubscription = {
  onUpdateReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReminderSubscription = {
  onDeleteReminder?:  {
    __typename: "Reminder",
    id: string,
    reminderPatientId: string,
    reminderClinicId: string,
    reminderStaffId: string,
    clinicName: string,
    doctorName: string,
    patientName: string,
    nextReminderDate: string,
    expirationDate: string,
    numberOfReminders: number,
    email?: string | null,
    phone?: string | null,
    reminderType: ReminderType,
    config: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
