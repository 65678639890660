import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/core/api/patient.service';
import { patientSideNavSchema } from 'src/app/core/schemas/navigation-schema';
import { InvoiceExportComponent } from 'src/app/invoice-export/invoice-export.component';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { EConsultFormGroup } from '../../econsult/econsult-form.model';
import { TabletQuestionnaireModalComponent } from '../../shared/tablet-questionnaire-modal/tablet-questionnaire-modal.component';
import { EncountersRoutes } from '../encounters.routes';
import { AssessmentService } from './../../core/api/assessment.service';
import { NavigationService } from './../../core/api/navigation.service';
import { SendQuestionnaireDialogComponent } from './../../shared/send-questionnaire-dialog/send-questionnaire-dialog.component';
import {
  EncountersCompareModalComponent,
  EncountersToCompare
} from './../encounters-compare-modal/encounters-compare-modal.component';
import { SelectAssessmentModalComponent } from './treatment-plan-table/select-assessment-modal/select-assessment-modal.component';
import { SelectTreatmentPlanModalComponent } from './treatment-plan-table/select-treatment-plan-modal/select-treatment-plan-modal.component';
import { TreatmentPlanCustomizerModalComponent } from './treatment-plan-table/treatment-plan-customizer-modal/treatment-plan-customizer-modal.component';
import { TreatmentPlan } from './treatment-plan-table/treatment-plan-table.model';
import { TreatmentPlanService } from './treatment-plan-table/treatment-plan-table.service';
@Component({
  selector: 'csi-encounter-dashboard',
  templateUrl: './encounter-dashboard.component.html',
  styleUrls: ['./encounter-dashboard.component.scss'],
  providers: [AssessmentService, EConsultFormGroup]
})
export class EncounterDashboardComponent implements OnInit {
  EncountersRoutes = EncountersRoutes;
  assessmentIdToExpand: string;
  assessmentRowHoveredId: string;
  encountersToCompare: EncountersToCompare = [];
  treatmentPicker: any;
  isLoading: boolean;
  invoices: any[] = [];
  treatmentPlans: TreatmentPlan[] = [];
  assessments = [];
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public assessmentService: AssessmentService,
    public patientService: PatientService,
    public loadingSpinnerService: LoadingSpinnerService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    public formGroup: EConsultFormGroup,
    private treatmentPlanService: TreatmentPlanService
  ) {}
  ngOnInit() {
    this.navigationService.updateSideNavSchema(patientSideNavSchema);
    this.assessments = this.patientService.assessments.map(raw => {
      const parsed = JSON.parse(raw.body || '{}').assessmentForm || {};
      return {
        id: parsed.id || '',
        name: parsed.name || 'Untitled',
        date: parsed.date || '',
        recommendedTreatments: parsed.recommendedTreatments || []
      };
    });
    if (
      this.patientService.patient &&
      this.patientService.patient.clinic &&
      this.patientService.patient.clinic.id
    ) {
      const filter = { treatmentPlanClinicId: { eq: this.patientService.patient.clinic.id } };
      this.treatmentPlanService.treatmentPlans$.subscribe(treatmentPlan => {
        this.treatmentPlans = treatmentPlan;
      });
    }
  }
  openTabletQuestionnaire() {
    TabletQuestionnaireModalComponent.open(this.dialog, null, this.patientService.patient)
      .afterClosed()
      .subscribe(questionnaireRequestGenerated => {
        if (questionnaireRequestGenerated) {
          this.patientService.questionnaires = [
            questionnaireRequestGenerated,
            ...this.patientService.questionnaires
          ];
        }
      });
  }
  openSendQuestionnaire() {
    SendQuestionnaireDialogComponent.open(
      this.dialog,
      null,
      this.patientService.patient,
      {},
      null,
      true
    )
      .afterClosed()
      .subscribe(questionnaireRequestsSent => {
        if (questionnaireRequestsSent) {
          if (!Array.isArray(questionnaireRequestsSent)) {
            questionnaireRequestsSent = [questionnaireRequestsSent];
          }
          this.patientService.questionnaires = [
            ...questionnaireRequestsSent,
            ...this.patientService.questionnaires
          ];
        }
      });
  }
  openEncountersCompare() {
    EncountersCompareModalComponent.open(this.dialog, this.encountersToCompare);
  }
  routeToNewEncounter() {
    this.router.navigate([{ outlets: { encounters: EncountersRoutes.New } }], {
      relativeTo: this.route.parent
    });
  }
  openSelectAssessmentModal() {
    const dialogRef = this.dialog.open(SelectAssessmentModalComponent, {
      data: { assessments: this.patientService.assessments },
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe(selectedAssessment => {
      if (selectedAssessment) {
        this.openTreatmentPlanCustomizer(selectedAssessment);
      }
    });
  }
  openTreatmentPlanCustomizer(selectedAssessment: any) {
    const dialogRef = this.dialog.open(TreatmentPlanCustomizerModalComponent, {
      data: { assessment: selectedAssessment },
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  openSelectTreatmentModal() {
    const dialogRef = this.dialog.open(SelectTreatmentPlanModalComponent, {
      data: { treatmentPlans: this.treatmentPlans },
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe((selectedPlan: TreatmentPlan) => {
      if (selectedPlan) {
        const dialogRef = this.dialog.open(InvoiceExportComponent, {
          data: { invoice: '', selectedPlan: selectedPlan },
          width: '70vw'
        });

        dialogRef.afterClosed().subscribe(result => {});
      }
    });
  }

  openInvoice(invoice: any) {
    const dialogRef = this.dialog.open(InvoiceExportComponent, {
      data: { invoice },
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.invoices.findIndex(inv => inv.id === result.id);
        if (index > -1) {
          this.invoices[index] = result;
          this.invoices = [...this.invoices];
        }
      }
    });
  }
}
