import { filter } from 'lodash-es';
// import { includes } from 'lodash-es/includes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { TextFavorite } from 'src/API';
import { ClinicSetupService } from '../../clinic-setup.service';
import { ConfirmModalService } from './../../../../shared/confirm-modal/confirm-modal.service';
import { TextFavoriteSetupActionsModalComponent } from './../text-favorite-setup-actions-modal/text-favorite-setup-actions-modal.component';
import { TextFavoriteService } from './../text-favorite.service';

@Component({
  selector: 'csi-text-favorite-setup',
  templateUrl: './text-favorite-setup.component.html',
  styleUrls: ['./text-favorite-setup.component.css']
})
export class TextFavoriteSetupComponent implements OnInit {
  @ViewChild('favoriteTable', { read: ElementRef, static: false }) table: ElementRef;

  @Input() isSaving: boolean;
  @Input() textFavoritesFormControl: FormControl;

  public filterControl: FormControl = new FormControl();

  public filteredFavorites: TextFavorite[] = this.clinicSetupService.textFavorites;

  displayedColumns: string[] = ['shortcut', 'text', 'type', 'friendlyId'];

  public duplicateShortcut: boolean;

  constructor(
    public clinicSetupService: ClinicSetupService,
    public textFavoriteService: TextFavoriteService,
    private dialog: MatDialog,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    if (this.clinicSetupService.isClinicAdmin) {
      this.displayedColumns.push('action');
    }

    this.filteredFavorites = this.textFavoritesFormControl.value;

    this.textFavoritesFormControl.valueChanges.subscribe(value => {
      this.filteredFavorites = value;
      this.filterControl.setValue('');
    });
    this.filterControl.valueChanges.pipe(debounceTime(100)).subscribe((filter: string) => {
      if (!filter.trim()) {
        this.filteredFavorites = [...this.textFavoritesFormControl.value || []];
        return;
      }

      const lowerCaseFilter = filter.toLowerCase();
      this.filteredFavorites = [
        ...this.textFavoritesFormControl.value.filter(
          textFavorite =>
            textFavorite.shortcut.toLowerCase().includes(lowerCaseFilter) ||
            textFavorite.text.toLowerCase().includes(lowerCaseFilter) ||
            (textFavorite.treatmentTemplate &&
              textFavorite.treatmentTemplate.friendlyId &&
              textFavorite.treatmentTemplate.friendlyId.toLowerCase().includes(lowerCaseFilter))
        )
      ];
    });
  }

  deleteTextFavorite(textFavoriteId: string) {
    this.confirmModalService
      .show(
        'Delete Text Favorite',
        'Are you sure you want to delete this text favorite? This action cannot be done.',
        'Delete Text Favorite',
        'Cancel'
      )
      .pipe(
        mergeMap(confirmed => {
          if (confirmed) {
            this.isSaving = true;
            return this.textFavoriteService.deleteTextFavorite(textFavoriteId);
          }
          return of(null);
        })
      )
      .subscribe(result => {
        if (result) {
          this.isSaving = false;
          const indexOfFavorite = this.findIndexOfFavorite(textFavoriteId);
          const arrayWithAllElements = [...this.textFavoritesFormControl.value];
          arrayWithAllElements.splice(indexOfFavorite, 1);
          this.textFavoritesFormControl.markAsDirty();
          this.textFavoritesFormControl.setValue(arrayWithAllElements);
        }
      });
  }

  findIndexOfFavorite(textFavoriteId: string): number {
    const textFavoritesFormControlValue: TextFavorite[] = [this.textFavoritesFormControl.value];

    if (!textFavoritesFormControlValue) {
      return -1;
    }
    return textFavoritesFormControlValue.findIndex(
      textFavorite => textFavorite.id === textFavoriteId
    );
  }

  textFavoriteAction(textFavorite: TextFavorite, action: 'add' | 'update') {
    this.dialog.open(TextFavoriteSetupActionsModalComponent, {
      width: '75vw',
      data: { textFavorite, action }
    });
  }
}
