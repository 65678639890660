export function parseDateToString(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${
    date.getDate() < 10 ? '0' : ''
  }${date.getDate()}`;
}

export function isWithinTimeFrameInDays(dateA: Date, dateB: Date, timeFrameInDays: number) {
  const oneDayInMilliseconds = 86400000;
  const timeDifferenceInMilliseconds = Math.abs(dateB.getTime() - dateA.getTime());
  return timeDifferenceInMilliseconds <= oneDayInMilliseconds * timeFrameInDays;
}

export function compareDateStrings(a: string, b: string) {
  return new Date(a).getTime() - new Date(b).getTime();
}
