import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
import { TreatmentTemplate } from './../../../../API';
import { ClinicSetupService } from './../clinic-setup.service';
import { TreatmentTemplateActionsModalComponent } from './treatment-template-actions-modal/treatment-template-actions-modal.component';
import { TreatmentTemplateService } from './treatment-template.service';

interface TreatmentTemplateHeader {
  columnDef: string;
  label: string;
  displayLength?: boolean;
}

@Component({
  selector: 'csi-treatment-template',
  templateUrl: './treatment-template.component.html',
  styleUrls: ['./treatment-template.component.css']
})
export class TreatmentTemplateComponent implements OnInit {
  public treatmentTemplateControl = this.treatmentTemplateService.treatmentTemplatesFormControl;

  public filterControl: FormControl = new FormControl();

  public filteredTreatment: TreatmentTemplate[];
  public tableHeaders: TreatmentTemplateHeader[] = [
    { columnDef: 'friendlyId', label: 'Treatment Template Id' },
    { columnDef: 'otcEyeDrops', label: 'Number of OTC', displayLength: true },
    { columnDef: 'rxEyeDrops', label: 'Number of RX', displayLength: true },
    { columnDef: 'exercises', label: 'Number of Exercises', displayLength: true },
    { columnDef: 'procedures', label: 'Number of Procedures', displayLength: true }
  ];
  public displayedColumns = this.tableHeaders.map(tableHeader => tableHeader.columnDef);

  public isSaving: boolean;

  constructor(
    public dialog: MatDialog,
    public treatmentTemplateService: TreatmentTemplateService,
    public clinicSetupService: ClinicSetupService,
    public confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    if (this.clinicSetupService.isClinicAdmin) {
      this.displayedColumns.push('actions');
    }
    this.filteredTreatment = [...(this.treatmentTemplateControl.value || [])];
    this.treatmentTemplateControl.valueChanges.subscribe(value => {
      this.filteredTreatment = [...(value || [])];

      this.filterControl.setValue('');
    });

    this.filterControl.valueChanges.pipe(debounceTime(100)).subscribe((filter: string) => {
      if (!filter.trim()) {
        this.filteredTreatment = [...(this.treatmentTemplateControl.value || [])];
        return;
      }

      const lowerCaseFilter = filter.toLowerCase();
      this.filteredTreatment = [
        ...this.treatmentTemplateControl.value.filter(
          textFavorite =>
            textFavorite &&
            textFavorite.friendlyId &&
            textFavorite.friendlyId.toLowerCase().includes(lowerCaseFilter)
        )
      ];
    });
  }

  deleteTreatmentTemplate(id: string, index: number) {
    this.confirmModalService
      .show(
        'Delete Treatment Template',
        'Are you sure you want to delete this Treatment Template? This action cannot be done.',
        'Delete Treatment Template',
        'Cancel'
      )
      .pipe(
        mergeMap(confirmed => {
          if (confirmed) {
            this.isSaving = true;
            return this.treatmentTemplateService.deleteTreatmentTemplate(id);
          }
          return of(null);
        })
      )
      .subscribe(result => {
        if (result) {
          this.isSaving = false;
          this.filterControl.setValue(this.filterControl.value);
        }
      });
  }

  openTreatmentTemplateActionsModal(
    action: 'add' | 'update',
    treatmentTemplate: TreatmentTemplate = null,
    isView = false
  ) {
    this.dialog.open(TreatmentTemplateActionsModalComponent, {
      width: '75vw',
      data: { action, treatmentTemplate, isView }
    });
  }
}
