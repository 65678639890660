import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedAngularMaterialModule } from '../shared-angular-material/shared-angular-material.module';
import { CountrySelectComponent } from './country-select/country-select.component';
import { LocationSelectComponent } from './location-select.component';
import { RegionSelectComponent } from './region-select/region-select.component';

@NgModule({
  declarations: [CountrySelectComponent, RegionSelectComponent, LocationSelectComponent],
  imports: [CommonModule, NgSelectModule, SharedAngularMaterialModule, ReactiveFormsModule],
  exports: [CountrySelectComponent, RegionSelectComponent, LocationSelectComponent]
})
export class LocationSelectModule {}
