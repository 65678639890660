export type Operator = '>' | '<' | '=' | '>=' | '<=' | '!=' | '!empty' | 'includes';
export type RiskLevel = 'none' | 'low' | 'medium' | 'high';

export interface Indicator {
  condition: any;
  operator: Operator;
  riskLevel: RiskLevel;
  contributor?: string;
}

export interface RiskFactor {
  name: string;
  indicators: Indicator[];
}

export interface RiskFactorDisplay {
  key: string,
  condition: string;
  name: string;
  contributor?: string;
  riskLevel: string;
}

export const riskLevelToNameMap: { [key: string]: string } = {
  none: 'No known risk',
  low: 'Low risk',
  medium: 'Medium risk',
  high: 'High risk'
};

export const riskLevelToNumberMap: { [key: string]: number } = {
  none: 0,
  low: 1,
  medium: 2,
  high: 3
};

// Keys should match nonModifiableData
export const nonModifiableRiskFactors: { [key: string]: RiskFactor } = {
  ethnicity: {
    name: 'Ethnicity: ',
    indicators: [
      {
        condition: 'south-asian',
        contributor: 'south-asian',
        operator: '=',
        riskLevel: 'medium'
      },
      {
        condition: 'east-asian',
        contributor: 'east-asian',
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  female: {
    name: 'Gender: ',
    indicators: [
      {
        condition: true,
        contributor: 'female',
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  childBirth: {
    name: 'Given birth to a child',
    indicators: [
      {
        condition: 'yes',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  lasikOrPRK: {
    name: 'Previous Lasik or PRK surgery',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  diabetesHistory: {
    name: 'Family history of diabetes',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  highCholestrolHistory: {
    name: 'Family history of high cholesterol',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  autoImmuneDiseaseHistory: {
    name: 'Family history of autoimmune disease',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  rosaceaHistory: {
    name: 'Family history of rosacea',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  cataractsHistory: {
    name: 'Family history of cataracts',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  sickBuildingSyndrome: {
    name: 'Past diagnosis of sick building syndrome (SBS)',
    indicators: [
      {
        condition: 'yes',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  styeOrChalazion: {
    name: '',
    indicators: [
      {
        condition: 'multiple',
        contributor: 'Past occurrence of a stye or chalazion',
        operator: '=',
        riskLevel: 'low'
      },
      {
        condition: 'once',
        contributor: 'History of stye or chalazion',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  education: {
    name: 'Education: university or higher',
    indicators: [
      {
        condition: 'university',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  sjorgenSyndrome: {
    name: "Past diagnosis of Sjogren's syndrome",
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'high'
      }
    ]
  },
  anxiety: {
    name: 'Past diagnosis of anxiety',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  acne: {
    name: 'Past diagnosis of acne',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  diabetes: {
    name: 'Past diagnosis of diabetes',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  lupus: {
    name: 'Past diagnosis of lupus',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  arthritis: {
    name: 'Past diagnosis of arthritis',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  osteoporosis: {
    name: 'Past diagnosis of osteoporosis',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  thyroid: {
    name: 'Past diagnosis of thyroid disease',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  radiotherapy: {
    name: 'Past use of radiotherapy',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  pterygium: {
    name: 'Past diagnosis of pterygium',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  demodexSkinInfection: {
    name: 'Past diagnosis of demodex skin infection',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  androgenDeficiency: {
    name: 'Past diagnosis of androgen deficiency',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  accutaneTreatment: {
    name: 'Past accutane treatment',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  eczema: {
    name: 'Past diagnosis of eczema',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  seborrheicDermatitis: {
    name: 'Past diagnosis of seborrheic dermatitis',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  cataractSurgery: {
    name: 'Previous cataract surgery',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  hematopoieticStemCellTransplantation: {
    name: 'Hematopoietic stem cells transplanted',
    indicators: [
      {
        condition: 'yes',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  }
};

// Keys should match Medical History form group
export const modifiableRiskFactors: { [key: string]: RiskFactor } = {
  smoking: {
    name: 'Smoking',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  vaping: {
    name: 'Vaping',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  pregnant: {
    name: 'Pregnant',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  faceCareAndMakeUpProductsFreeText: {
    name: 'Face care/Make-up products',
    indicators: [
      {
        condition: '',
        operator: '!empty',
        riskLevel: 'low'
      }
    ]
  },
  contactLens: {
    name: 'Contact lens wear',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  skinProductEyeIrritation: {
    name: 'Skin products or make-up eye irritation',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  eyeDryness: {
    name: 'Currently experiencing eye dryness',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  eyeRedness: {
    name: 'Currently experiencing eye redness',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  eyeFatigue: {
    name: 'Currently experiencing eye fatigue',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  eyeItchiness: {
    name: 'Currently experiencing eye itching',
    indicators: [
      {
        condition: 10,
        operator: '>=',
        riskLevel: 'low'
      }
    ]
  },
  eyeMucous: {
    name: 'Currently experiencing stringy mucous in or around eyes',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  eyeTearingQuantity: {
    name: '',
    indicators: [
      {
        contributor: 'Experiences eye tearing in large quantities',
        condition: 70,
        operator: '>=',
        riskLevel: 'low'
      },
      {
        contributor: 'Experiences no eye tearing at all',
        condition: 0,
        operator: '=',
        riskLevel: 'high'
      }
    ]
  },
  visionAffected: {
    name: 'Currently experiencing fluctuating vision',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  currentContactLensDiscomfort: {
    name: 'Currently experiencing contact lens discomfort',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  crustyOrDischarge: {
    name: 'Currently experiencing crusty eyes or eye discharge',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  enlargedGlands: {
    name: 'Currently experiencing enlarged glands',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  eyesSwollen: {
    name: 'Currently experiencing swollen eyelids',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  symptomsRednessGreaterInOneEye: {
    name: 'Currently experiencing symptoms/redness greater in one eye',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  urbanAreaTimePerWeekFreeText: {
    name: 'Spends time in urban areas',
    indicators: [
      {
        condition: 0,
        operator: '>',
        riskLevel: 'low'
      }
    ]
  },
  alcohol: {
    name: 'Drinks alcoholic beverages',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  caffeinatedDrinksPerDayFreeText: {
    name: 'Drinks caffeinated beverages',
    indicators: [
      {
        condition: 0,
        operator: '>',
        riskLevel: 'low'
      }
    ]
  },
  waterPerDayFreeText: {
    name: 'Poor water consumption: ',
    indicators: [
      {
        condition: 4,
        contributor: 'less than 4 glasses of water per day',
        operator: '<',
        riskLevel: 'low'
      }
    ]
  },
  screenTime: {
    name: 'Screen time per day: ',
    indicators: [
      {
        condition: 'group4',
        contributor: '> 6 hours',
        operator: '=',
        riskLevel: 'medium'
      },
      {
        condition: 'group3',
        contributor: '3 - 6 hours',
        operator: '=',
        riskLevel: 'low'
      },
      {
        condition: 'group2',
        contributor: '1 - 3 hours',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  sleepPerNight: {
    name: 'Sleep per night: ',
    indicators: [
      {
        condition: 4,
        contributor: '< 4 hours',
        operator: '<',
        riskLevel: 'medium'
      },
      {
        condition: 8,
        contributor: '4 - 8 hours',
        operator: '<',
        riskLevel: 'low'
      }
    ]
  },
  sleepingPosition: {
    name: 'Sleeping position: ',
    indicators: [
      {
        condition: 'stomach',
        contributor: 'on the stomach',
        operator: '=',
        riskLevel: 'medium'
      },
      {
        condition: 'side',
        contributor: 'on the side',
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  timeSpentOutdoorsFreeText: {
    name: 'Time spent outdoors: ',
    indicators: [
      {
        condition: 4,
        contributor: '> 4 hours',
        operator: '>',
        riskLevel: 'low'
      }
    ]
  },
  exercise: {
    name: 'Exercise per week: ',
    indicators: [
      {
        condition: 2,
        contributor: '< 2 hours',
        operator: '<',
        riskLevel: 'low'
      }
    ]
  },
  stressLevel: {
    name: 'Stress level: ',
    indicators: [
      {
        condition: 70,
        contributor: 'mediun',
        operator: '>',
        riskLevel: 'medium'
      },
      {
        condition: 30,
        contributor: 'medium',
        operator: '>',
        riskLevel: 'low'
      },
      {
        condition: 0,
        contributor: 'low',
        operator: '>',
        riskLevel: 'low'
      }
    ]
  },
  healthQuality: {
    name: 'Health quality: ',
    indicators: [
      {
        condition: 50,
        contributor: 'poor',
        operator: '<',
        riskLevel: 'low'
      }
    ]
  },
  seasonalAllergies: {
    name: 'Seasonal allergies',
    indicators: [
      {
        condition: 'none',
        operator: '=',
        riskLevel: 'none'
      },
      {
        condition: '',
        operator: '!empty',
        riskLevel: 'medium'
      }
    ]
  },
  drugAllergies: {
    name: 'Drug allergies',
    indicators: [
      {
        condition: 'none',
        operator: '=',
        riskLevel: 'none'
      },
      {
        condition: '',
        operator: '!empty',
        riskLevel: 'medium'
      }
    ]
  },
  vitaminDeficiency: {
    name: 'Past diagnosis of vitamin deficiency',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  sexSteroidDeficiency: {
    name: 'Past diagnosis of sex steroid deficiency',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  difficultySwallowingFood: {
    name: 'Dry mouth and/or difficulty swallowing food',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  cityHumidityLevel: {
    name: 'Humidity: ',
    indicators: [
      {
        condition: 5,
        contributor: 'low - normal',
        operator: '<=',
        riskLevel: 'low'
      }
    ]
  },
  cityPollutionLevel: {
    name: 'Pollution: ',
    indicators: [
      {
        condition: 5,
        contributor: 'normal - high',
        operator: '>=',
        riskLevel: 'low'
      }
    ]
  },
  eyeRubbingFrequency: {
    name: 'Eye rubbing frequency: ',
    indicators: [
      {
        condition: 'moderate',
        contributor: 'once a day',
        operator: '=',
        riskLevel: 'low'
      },
      {
        condition: 'extreme',
        contributor: 'several times a day',
        operator: '=',
        riskLevel: 'medium'
      }
    ]
  },
  throatIrritation: {
    name: 'Frequent throat irritation',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  breathingDifficulty: {
    name: 'Frequent breathing difficulties',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  chestTightness: {
    name: 'Frequent tightness in the chest',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  runnyNose: {
    name: 'Frequent runny nose',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  allergySymptoms: {
    name: 'Frequent allergy-like symptoms, such as sneezing',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  noseBurningSensation: {
    name: 'Frequent burning sensation in the nose',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  skinDrynessAndItchiness: {
    name: 'Frequent dry, itchy skin, rashes',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  headaches: {
    name: 'Frequent headaches',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  dizziness: {
    name: 'Frequent dizziness',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  difficultyConcentrating: {
    name: 'Frequent difficulty concentrating',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  forgetfulness: {
    name: 'Frequent forgetfulness',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  fatigue: {
    name: 'Frequent fatigue',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  irritability: {
    name: 'Frequent irritability',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  nausea: {
    name: 'Frequent nausea',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  bodyAches: {
    name: 'Frequent body aches',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  fever: {
    name: 'Frequent fever',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  },
  chills: {
    name: 'Frequent chills',
    indicators: [
      {
        condition: true,
        operator: '=',
        riskLevel: 'low'
      }
    ]
  }
};
