import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatCheckboxChange,
  MatDialog,
  MatDialogRef,
  MatSelect,
  MatSelectChange,
  MatSnackBar
} from '@angular/material';
import { capitalize } from 'lodash-es';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionnaireType } from 'src/API';
import { Patient } from 'src/app/core/api/patient.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { MassClinicService } from 'src/app/mass-clinics/mass-clinic.service';
import { ImportWizardSendQuestionnairesService } from 'src/app/patients/patient-import-wizard-modal/import-wizard-send-questionnaires/import-wizard-send-questionnaires.service';
import { PatientFormControlType } from '../patient-form/patient-form.model';

class SendMethodsFormGroup extends FormGroup {
  controls: { byPhone: FormArray; byEmail: FormArray };

  constructor(fb: FormBuilder, initialValues: boolean[]) {
    super({
      byPhone: fb.array(initialValues),
      byEmail: fb.array(initialValues)
    });
  }

  add(phone, email) {
    this.controls.byPhone.push(new FormControl(phone));
    this.controls.byEmail.push(new FormControl(email));
  }
}

@Component({
  selector: 'csi-send-multiple-questionnaires-dialog',
  templateUrl: './send-multiple-questionnaires-dialog.component.html',
  styleUrls: ['./send-multiple-questionnaires-dialog.component.css']
})
export class SendMultipleQuestionnairesDialogComponent implements OnInit {
  @Output() completed: EventEmitter<null> = new EventEmitter();
  @ViewChild('matSelect', { static: false }) masterMatSelect: MatSelect;

  public patientsToSendTo: Patient[] = [];
  public readonly columnsToDisplay = [
    PatientFormControlType.FirstName,
    PatientFormControlType.LastName,
    PatientFormControlType.Phone,
    PatientFormControlType.Email,
    PatientFormControlType.DateOfBirth,
    'questionnaireType',
    'sendToPhone',
    'sendToEmail'
  ];
  protected typesToSend: FormArray;
  protected sendMethods: SendMethodsFormGroup;
  protected sendToPhoneIndeterminate = false;
  protected sendToEmailIndeterminate = false;
  public matSpinnerOptions: MatProgressButtonOptions;
  protected readonly sendToPhoneControl = this.fb.control(true);
  protected readonly sendToEmailControl = this.fb.control(true);
  protected readonly QuestionnaireType = Object.keys(QuestionnaireType)
    .filter(key => key !== QuestionnaireType.CUSTOM)
    .map(key => QuestionnaireType[key]);

  public alreadyExists = false;
  public noContactInformation = false;

  constructor(
    private fb: FormBuilder,
    private importWizardSendQuestionnairesService: ImportWizardSendQuestionnairesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SendMultipleQuestionnairesDialogComponent>,
    public staffService: StaffService,
    private massClinicService: MassClinicService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.patientsToSendTo = this.data.patients;
    this.typesToSend = this.fb.array([
      ...this.patientsToSendTo.map(() => QuestionnaireType.DEQ as string)
    ]);
    this.sendMethods = new SendMethodsFormGroup(
      this.fb,
      this.patientsToSendTo.map(() => true)
    );

    this.matSpinnerOptions = {
      active: false,
      text:
        'Send Questionnaires to ' +
        this.numberOfQuestionnairesToSend +
        ' Patient' +
        (this.numberOfQuestionnairesToSend === 1 ? '' : 's'),
      raised: true,
      buttonColor: 'primary',
      spinnerSize: 19,
      disabled: this.numberOfQuestionnairesToSend <= 0,
      mode: 'indeterminate'
    };

    this.sendMethods.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.matSpinnerOptions.text =
        'Send Questionnaires to ' +
        this.numberOfQuestionnairesToSend +
        ' Patient' +
        (this.numberOfQuestionnairesToSend === 1 ? '' : 's');
      this.matSpinnerOptions.disabled = this.numberOfQuestionnairesToSend <= 0;
    });
  }

  ngOnDestroy() {}

  public static open(matDialog: MatDialog, patients: Patient[] = []) {
    return matDialog.open<SendMultipleQuestionnairesDialogComponent>(
      SendMultipleQuestionnairesDialogComponent,
      {
        maxHeight: '90vh',
        minWidth: '60vw',
        disableClose: true,
        data: { patients }
      }
    );
  }
  protected get numberOfQuestionnairesToSend(): number {
    return this.importWizardSendQuestionnairesService.questionnaireInputs(
      this.patientsToSendTo,
      this.typesToSend.value,
      this.sendMethods.controls.byEmail.value,
      this.sendMethods.controls.byPhone.value
    ).length;
  }

  protected setAllQuestionnaireTypesTo(change: MatSelectChange) {
    this.masterMatSelect.value = undefined;
    this.typesToSend.controls.forEach(control => control.setValue(change.value));
  }

  protected setAllSendMethodsTo(change: MatCheckboxChange) {
    if (change.source.name === 'phone') {
      this.sendMethods.controls.byPhone.controls.forEach(control =>
        control.setValue(change.checked)
      );
    } else if (change.source.name === 'email') {
      this.sendMethods.controls.byEmail.controls.forEach(control =>
        control.setValue(change.checked)
      );
    }
  }

  protected updateMasterCheckboxes(checkboxType: 'phone' | 'email') {
    let formValues: boolean[], checkbox: FormControl;
    if (checkboxType === 'phone') {
      formValues = this.sendMethods.controls.byPhone.value;
      checkbox = this.sendToPhoneControl;
      this.sendToPhoneIndeterminate = formValues.some(val => !!val) && formValues.some(val => !val);
    } else {
      formValues = this.sendMethods.controls.byEmail.value;
      checkbox = this.sendToEmailControl;
      this.sendToEmailIndeterminate = formValues.some(val => !!val) && formValues.some(val => !val);
    }

    if (formValues.every(val => !!val)) {
      checkbox.setValue(true);
    } else if (formValues.every(val => !val)) {
      checkbox.setValue(false);
    }
  }

  public createNewPatient(patientFullName: string) {
    let [firstName, lastName] = patientFullName.split(/\s+/);
    firstName = capitalize(firstName);
    lastName = capitalize(lastName);
    this.massClinicService
      .openPatientModal({ firstName, lastName })
      .afterClosed()
      .subscribe(newPatientToAddToMassClinic => {
        if (newPatientToAddToMassClinic) {
          this.patientsToSendTo = [...this.patientsToSendTo, newPatientToAddToMassClinic];
          this.typesToSend.push(new FormControl(QuestionnaireType.DEQ as string));
          this.sendMethods.add(true, true);
        }
      });
  }

  onPatientSelect(patient: Patient) {
    if (patient) {
      this.alreadyExists = false;
      this.checkIfContactInformationExists(patient).subscribe(editedPatient => {
        this.noContactInformation = !editedPatient.email && !editedPatient.phone;
        if (!this.noContactInformation) {
          if (!this.patientExists(editedPatient)) {
            this.patientsToSendTo = [...this.patientsToSendTo, editedPatient];
            this.typesToSend.push(new FormControl(QuestionnaireType.DEQ as string));
            this.sendMethods.add(true, true);
          } else {
            this.alreadyExists = true;
          }
        }
      });
    }
  }

  public patientExists(patient: Patient) {
    if (this.patientsToSendTo.includes(patient)) {
      return true;
    }
    return false;
  }

  checkIfContactInformationExists(patient: Patient): Observable<Patient> {
    if (patient.email || patient.phone) {
      return of(patient);
    }
    return this.massClinicService
      .openPatientModal(patient)
      .afterClosed()
      .pipe(map(editedPatient => editedPatient));
  }
  public sendQuestionnaires() {
    this.disableAllButtons();
    this.importWizardSendQuestionnairesService
      .sendQuestionnaires(
        this.patientsToSendTo,
        this.typesToSend.value,
        this.sendMethods.controls.byEmail.value,
        this.sendMethods.controls.byPhone.value,
        false
      )
      .subscribe(() => {
        this.completed.emit(null);
        this._snackBar.open('Questionnaire(s) sent successfully!', '', {
          duration: 4000,
          verticalPosition: 'bottom'
        });
        this.dialogRef.close();
      });
  }

  private disableAllButtons() {
    this.matSpinnerOptions.active = true;
    this.sendMethods.disable();
    this.typesToSend.disable();
    this.sendToEmailControl.disable();
    this.sendToPhoneControl.disable();
  }
}
