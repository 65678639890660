import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CreateReminderInput,
  CreateReminderMutation,
  QuestionnaireRequest,
  ReminderType
} from 'src/API';
import { ReminderConfig } from 'src/app/core/api/questionnaire-request.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { AppSyncService } from 'src/app/core/appsync.service';
import { ReminderFormGroup } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup-form.model';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { createReminder } from 'src/graphql/mutations';

export type ReminderRequest = CreateReminderMutation['createReminder'];
// csi-dry-eye-assessment-form

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  constructor(
    private appSyncService: AppSyncService,
    public staffService: StaffService,
    private clinicSetupService: ClinicSetupService
  ) {}

  public createQuestionnaireReminder(
    createReminderRequestInput: Partial<ReminderRequest>
  ): Observable<ReminderRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createReminder),
          variables: { input: createReminderRequestInput }
        })
      ),
      map((result: ApolloQueryResult<CreateReminderMutation>) => result.data.createReminder)
    );
  }

  public createReminder(questionnaire: QuestionnaireRequest) {
    if (!this.clinicSetupService.clinicSetupFormGroup.controls.reminderConfig.controls.enabled.value) return of(null);
    const clinicReminderSchedule = {
      reminderScheduleList: this.reminderSchedule(
        this.clinicSetupService.clinicSetupFormGroup.controls.reminderConfig
      ),
      numberOfReminder: this.clinicSetupService.clinicSetupFormGroup.controls.reminderConfig
        .controls.numberOfReminder.value
    };
    const reminder = this.createReminderRequest(
      this.clinicSetupService.clinicSetupFormGroup.controls.reminderConfig,
      questionnaire,
      clinicReminderSchedule
    );
    return this.createQuestionnaireReminder(reminder);
  }

  private createReminderRequest(
    reminderFormGroup: ReminderFormGroup,
    questionnaire: QuestionnaireRequest,
    clinicReminderSchedule
  ): CreateReminderInput {
    const todayDate = new Date();
    const daysUntilExpirationInMill =
      this.clinicSetupService.clinicSetupFormGroup.value.questionnaireExpiry * 1000 * 60 * 60 * 24;
    const expirationDate = new Date(todayDate.getTime() + daysUntilExpirationInMill);
    const { nextReminderDate, reminderScheduleList } = this.getNextReminderDate(
      expirationDate,
      reminderFormGroup
    );
//phone number are null please change it questionnaire.mobile to enable text messages
    return {
      clinicName: questionnaire.clinic.name,
      sourceId: questionnaire.id,
      phone: null,
      email: questionnaire.email,
      expirationDate: `${expirationDate.toISOString().split('T')[0]}`,
      nextReminderDate: nextReminderDate.toISOString().split('T')[0],
      config: JSON.stringify(
        this.reminderConfig(
          questionnaire.type,
          '1',
          reminderFormGroup,
          questionnaire.id,
          expirationDate,
          reminderScheduleList
        )
      ),
      patientName: `${questionnaire.patient.firstName} ${questionnaire.patient.lastName}`,
      reminderClinicId: this.clinicSetupService.clinicId,
      reminderPatientId: questionnaire.patient.id,
      reminderStaffId: this.staffService.staff.id,
      reminderType: ReminderType.QUESTIONNAIRE,
      doctorName: `${this.staffService.staff.firstName} ${this.staffService.staff.lastName}`,
      numberOfReminders: reminderFormGroup.controls.numberOfReminder.value
    };
  }

  private reminderConfig(
    questionTypes: string,
    reminderFrequency: string,
    reminderFormGroup: ReminderFormGroup,
    questionnaireId: string,
    expirationDate: Date,
    reminderScheduleList
  ) {
    const config: ReminderConfig = {
      body: {
        textType: 'Html',
        message: `Please complete the <a href=${
          window.location.origin
        }/questionnaires/${questionnaireId}> ${questionTypes}</a>
         questionnaire by the ${expirationDate.toLocaleDateString()}`
      },
      frequency: reminderFrequency,
      reminderScheduleList: reminderScheduleList
    };
    return config;
  }

  private reminderSchedule(reminderFormGroup: ReminderFormGroup) {
    let currentIndex = 1;
    const listOfReminderSchedule = [];
    while (reminderFormGroup.controls.numberOfReminder.value >= currentIndex) {
      listOfReminderSchedule.push(parseInt(reminderFormGroup.controls[currentIndex].value, 10));
      currentIndex++;
    }

    return listOfReminderSchedule.reverse();
  }

  private getNextReminderDate(expirationDate: Date, reminderFormGroup) {
    const reminderScheduleList = this.reminderSchedule(reminderFormGroup);
    const reminderScheduleLength = reminderScheduleList.length;
    const nextReminderInMillieSecond =
      reminderScheduleList[reminderScheduleLength - 1] * (1000 * 60 * 60 * 24);
    const nextReminderDate = new Date(expirationDate.getTime() - nextReminderInMillieSecond);
    reminderScheduleList.pop();
    return { nextReminderDate, reminderScheduleList };
  }
}
