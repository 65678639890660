import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CopyToEmrModalComponent } from 'src/app/copy-to-emr-modal/copy-to-emr-modal.component';
import { ClinicService } from 'src/app/core/api/clinic.service';
import { EncountersRoutes } from 'src/app/encounters/encounters.routes';
import { ClinicSetupModalComponent } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup-modal.component';
import { PatientImportWizardModalComponent } from 'src/app/patients/patient-import-wizard-modal/patient-import-wizard-modal.component';
import { PatientModalComponent } from 'src/app/shared/consult-forms/patient-modal/patient-modal.component';
import { IntakeFormLinksModalComponent } from 'src/app/shared/intake-form-links-modal/intake-form-links-modal.component';
import { NavigateToNewAssessmentModalComponent } from 'src/app/shared/navigate-to-new-assessment-modal/navigate-to-new-assessment-modal.component';
import { SendMultipleQuestionnairesDialogComponent } from 'src/app/shared/send-multiple-questionnaires-dialog/send-multiple-questionnaires-dialog.component';
import { SendQuestionnaireDialogComponent } from 'src/app/shared/send-questionnaire-dialog/send-questionnaire-dialog.component';
import { TabletQuestionnaireModalComponent } from 'src/app/shared/tablet-questionnaire-modal/tablet-questionnaire-modal.component';
import { DomainOption, HOSTING_DOMAIN_ACCESSOR } from '../domain-accessor';
import { PatientService } from './patient.service';
import { StaffService } from './staff.service';

@Injectable({
  providedIn: 'root'
})
export class SideNavActionsService {
  constructor(
    private dialog: MatDialog,
    private staffService: StaffService,
    private patientService: PatientService,
    public router: Router,
    public route: ActivatedRoute,
    private clinicService: ClinicService,
    @Inject(HOSTING_DOMAIN_ACCESSOR) private domain: DomainOption
  ) {}

  public openCreatePatientModal() {
    this.staffService.getLoggedInStaff().subscribe(loggedInDoctor => {
      PatientModalComponent.open(this.dialog, loggedInDoctor);
    });
  }

  public openPatientImportWizard() {
    PatientImportWizardModalComponent.open(this.dialog, null);
  }

  openTabletQuestionnaire() {
    TabletQuestionnaireModalComponent.open(this.dialog, null, this.patientService.patient)
      .afterClosed()
      .subscribe(questionnaireRequestGenerated => {
        if (questionnaireRequestGenerated) {
          this.patientService.questionnaires = [
            questionnaireRequestGenerated,
            ...this.patientService.questionnaires
          ];
        }
      });
  }

  openSendQuestionnaire() {
    SendQuestionnaireDialogComponent.open(
      this.dialog,
      null,
      this.patientService.patient,
      {},
      null,
      true
    )
      .afterClosed()
      .subscribe(questionnaireRequestsSent => {
        if (questionnaireRequestsSent) {
          if (!Array.isArray(questionnaireRequestsSent)) {
            questionnaireRequestsSent = [questionnaireRequestsSent];
          }
          this.patientService.questionnaires = [
            ...questionnaireRequestsSent,
            ...this.patientService.questionnaires
          ];
        }
      });
  }

  public openSendMultipleQuestionnairesDialog() {
    SendMultipleQuestionnairesDialogComponent.open(this.dialog);
  }

  public openNavigateToNewAssessmentModal() {
    NavigateToNewAssessmentModalComponent.open(this.dialog);
  }

  routeToNewEncounter() {
    this.router.navigate([{ outlets: { encounters: EncountersRoutes.New } }], {
      relativeTo: this.route.parent
    });
  }

  public openCopyModal() {
    this.dialog.open(CopyToEmrModalComponent, {
      data: {
        showAssessmentList: true
      },
      width: '95vw',
      minWidth: '67rem',
      height: '92vh'
    });
  }

  public openIntakeFormLinksModal() {
    IntakeFormLinksModalComponent.open(this.dialog);
  }

  public openClinicSetupModal({ selectedTabLabel }: any) {
    this.clinicService.clinic$.subscribe(clinic => {
      ClinicSetupModalComponent.open(
        this.dialog,
        clinic,
        this.domain !== DomainOption.Seema,
        {},
        selectedTabLabel || ''
      );
    });
  }
}
