import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ApolloQueryResult, FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  AssessmentType,
  CreateQuestionnaireRequestInput,
  CreateQuestionnaireRequestMutation,
  CreateReminderMutation,
  GetQuestionnaireRequestQuery,
  ListQuestionnaireRequestsByClinicIdSortedByCreatedAtQuery,
  MassClinicPatient,
  ModelSortDirection,
  QuestionnaireType,
  UpdateQuestionnaireRequestInput,
  UpdateQuestionnaireRequestMutation
} from 'src/API';
import { CustomValidator } from 'src/app/intake-form/intake-form-validators';

import { fitzpatrick } from 'src/app/form-template/form-field-template-library';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { createQuestionnaireRequest, createReminder } from 'src/graphql/mutations';
import { listQuestionnaireRequestsByClinicIdSortedByCreatedAt } from 'src/graphql/queries';
import { AssessmentBody } from '../../econsult/assessment-body/assessment-body.model';
import { SymptomModes } from '../../shared/symptoms/symptom.model';
import { AppSyncService } from '../appsync.service';
import { UpdateMassClinicPatientInput } from './../../../API';
import { getQuestionnaireRequestWithResponse } from './../../../graphql/custom_queries';
import { Assessment, AssessmentService } from './assessment.service';
import { Patient } from './patient.service';
import { ReminderService } from 'src/app/shared/services/reminder.service';

export interface QuestionnaireWithStatus extends Partial<QuestionnaireRequest> {
  status: 'Completed' | 'Expired' | 'Incomplete';
}

export enum FitzpatrickType {
  type1 = 'Type I',
  type2 = 'Type II',
  type3 = 'Type III',
  type4 = 'Type IV',
  type5 = 'Type V',
  type6 = 'Type VI'
}

export enum QuestionnaireScoreType {
  FITZPATRICK = 'FITZPATRICK'
}

export type QuestionnaireRequest = CreateQuestionnaireRequestMutation['createQuestionnaireRequest'];
export type ReminderRequest = CreateReminderMutation['createReminder'];

export interface SendQuestionnaireDialogData {
  patient: Partial<Patient>;
  questionnaireType: QuestionnaireType;
  massClinicPatient?: MassClinicPatient;
  questionnaireRequest?: QuestionnaireRequest;
  multipleQuestionnairesSelectable?: boolean;
}

export interface TabletQuestionnaireModalData {
  patient: any; // Partial<Patient> not working for some reason
  questionnaireType: QuestionnaireType;
  questionnaireRequest?: QuestionnaireRequest;
}

export interface ReminderConfig {
  body: {
    textType: 'Html';
    message: string;
  };
  [key: string]: unknown;
}
export type ReminderSchedule = {
  disabled: boolean;
  friendlyName: string;
  name: string;
};

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireRequestService {
  private static SendQuestionnaireValidator(group: FormGroup): ValidationErrors {
    return !!group.value.mobile || !!group.value.email
      ? null
      : { noDeliveryMethod: 'Please select a delivery method.' };
  }

  public QuestionnaireType = QuestionnaireType;

  public questionnairesWithStatus: QuestionnaireWithStatus[];

  public formTemplateLibraryQuestionnaires: { [key in QuestionnaireType]?: any } = {
    [QuestionnaireType.FITZPATRICK]: fitzpatrick
  };

  public questionnaireTypeToFriendlyNameMap: { [key in QuestionnaireType]?: string } = {
    [QuestionnaireType.DEQ]: 'DEQ',
    [QuestionnaireType.OSDI]: 'OSDI',
    [QuestionnaireType.SPEED]: 'SPEED',
    [QuestionnaireType.SPEEDII]: 'SPEED II (Includes Speed)',
    [QuestionnaireType.OSDIAndSPEED]: 'OSDI and SPEED',
    [QuestionnaireType.DERFS]: 'DERFS',
    [QuestionnaireType.CDERFS]: 'CDERFS',
    [QuestionnaireType.FITZPATRICK]: 'FITZPATRICK',
    [QuestionnaireType.CUSTOM]: 'CUSTOM'
  };

  public readonly questionnaireHeaderMap = {
    createdAt: 'Sent On',
    type: 'Type',
    completedAt: 'Completed At',
    status: 'Status',
    score: 'Score',
    assessment: 'Linked Assessment Date'
  };

  public readonly questionnaireHeaderKeys = Object.keys(this.questionnaireHeaderMap);

  private twoWeeksBack = this.getDaysBackInMilliSeconds(14);
  public readonly questionnaireHeaderKeysWithActionsKey = [
    ...this.questionnaireHeaderKeys,
    'actions'
  ];

  private readonly questionnaireRequestPublicFields = `
    fragment QuestionnaireRequestPublicFields on QuestionnaireRequest {
      id
      type
      completedAt
      country
      consentsToResearch
      consentsToPrivacyForm
      consentSource
      createdAt
      response {
        answers
        scores
      }
      nonModifiableData
      language
      config
    }
  `;

  private readonly getQuestionnaireRequestLimited = `
    query GetQuestionnaireRequest($id: ID!) {
      getQuestionnaireRequest(id: $id) {
        ...QuestionnaireRequestPublicFields
      }
    }
    ${this.questionnaireRequestPublicFields}
  `;

  private readonly updateQuestionnaireRequestLimited = `
    mutation UpdateQuestionnaireRequest($input: UpdateQuestionnaireRequestInput!) {
      updateQuestionnaireRequest(input: $input) {
        ...QuestionnaireRequestPublicFields
      }
    }
    ${this.questionnaireRequestPublicFields}
  `;

  private createQuestionnaireRequestAndUpdateMassClinicPatientMutation = /* GraphQL */ `
    mutation UpdateMassClinicPatientAfterQuestionnaireRequestCreation(
      $createQuestionnaireRequestInput: CreateQuestionnaireRequestInput!
      $updateMassClinicPatientInput: UpdateMassClinicPatientInput!
    ) {
      createQuestionnaireRequest(input: $createQuestionnaireRequestInput) {
        id
        createdAt
        type
      }
      updateMassClinicPatient(input: $updateMassClinicPatientInput) {
        id
        questionnaireRequest {
          id
        }
      }
    }
  `;

  private updateQuestionnaireRequestMutation = /* GraphQL */ `
    mutation UpdateQuestionnaireRequest($input: UpdateQuestionnaireRequestInput!) {
      updateQuestionnaireRequest(input: $input) {
        id
        email
        mobile
        assessment {
          id
          createdAt
          updatedAt
        }
        questionnaireRequestPatientId
        type
        response {
          answers
          scores
          consent {
            consentsToPrivacyForm
            consentsToResearch
            agreedToTerms
            consentSource
          }
        }
        country
        consentsToPrivacyForm
        consentSource
        consentsToResearch
        completedAt
        createdAt
        updatedAt
      }
    }
  `;

  constructor(
    private appSyncService: AppSyncService,
    private formBuilder: FormBuilder,
    private reminderService: ReminderService,
    private assessmentService: AssessmentService
  ) {}

  public createFormGroup(data: SendQuestionnaireDialogData) {
    return this.formBuilder.group(
      {
        email: [data.patient.email, [Validators.email, Validators.required]],
        mobile: [data.patient.phone, Validators.required],
        type: data.multipleQuestionnairesSelectable
          ? data.questionnaireType
          : [data.questionnaireType, Validators.required],
        questionnaireRequestPatientId: data.patient.id,
        country: data.patient.clinic.country,
        consentsToPrivacyForm: data.patient.consentsToPrivacyForm,
        consentSource: data.patient.consentSource,
        consentsToResearch: data.patient.consentsToResearch,
        nonModifiableData: data.patient.nonModifiableData
      },
      { validators: QuestionnaireRequestService.SendQuestionnaireValidator }
    );
  }


  public createFormGroupForQuestionnaireTypes() {
    return this.formBuilder.group({
      ...this.questionnaireHeaderKeys.map(key => {
        return { [key]: null };
      })
    });
  }

  public createTabletQuestionnaireFormGroup(data: TabletQuestionnaireModalData) {
    return this.formBuilder.group(
      {
        type: [data.questionnaireType, Validators.required],
        questionnaireRequestPatientId: data.patient.id,
        country: data.patient.clinic.country,
        consentsToPrivacyForm: data.patient.consentsToPrivacyForm,
        consentSource: data.patient.consentSource,
        consentsToResearch: data.patient.consentsToResearch
      },
      {}
    );
  }

  public createQuestionnaireRequest(
    input: CreateQuestionnaireRequestInput
  ): Observable<QuestionnaireRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createQuestionnaireRequest),
          variables: { input: input }
        })
      ),
      map(
        (result: ApolloQueryResult<CreateQuestionnaireRequestMutation>) =>
          result.data.createQuestionnaireRequest
      ),tap((questionnaire) => this.reminderService.createReminder(questionnaire).subscribe())
    );
  }

  public createQuestionnaireRequestAndUpdateMassClinicPatient(
    createQuestionnaireRequestInput: CreateQuestionnaireRequestInput,
    updateMassClinicPatientInput: UpdateMassClinicPatientInput
  ): Observable<QuestionnaireRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(this.createQuestionnaireRequestAndUpdateMassClinicPatientMutation),
          variables: { createQuestionnaireRequestInput, updateMassClinicPatientInput }
        })
      ),
      map(
        (
          result: ApolloQueryResult<{
            createQuestionnaireRequest: QuestionnaireRequest;
            UpdateMassClinicPatientInput: MassClinicPatient;
          }>
        ) => result.data.createQuestionnaireRequest
      )
    );
  }

  public createQuestionnaireReminder(
    createReminderRequestInput: Partial<ReminderRequest>
  ): Observable<ReminderRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createReminder),
          variables: { input: createReminderRequestInput }
        })
      ),
      map((result: ApolloQueryResult<CreateReminderMutation>) => result.data.createReminder)
    );
  }

  public createAssessmentAndSendQuestionnaireRequest(
    questionnaireRequestInput: CreateQuestionnaireRequestInput,
    patientId: string
  ): Observable<QuestionnaireRequest> {
    return this.createAssessmentForSendingQuestionnaire(questionnaireRequestInput.type, patientId)
      .pipe(
        tap(
          (assessment: Assessment) =>
            (questionnaireRequestInput.questionnaireRequestAssessmentId = assessment.id)
        )
      )
      .pipe(switchMap(() => this.createQuestionnaireRequest(questionnaireRequestInput)));
  }

  public getQuestionnaireRequest(
    id: string,
    limitToPatientView = true
  ): Observable<QuestionnaireRequest> {
    const query = limitToPatientView
      ? this.getQuestionnaireRequestLimited
      : getQuestionnaireRequestWithResponse;

    return this.appSyncService.hydrated(limitToPatientView).pipe(
      switchMap(client =>
        client.query({
          query: gql(query),
          variables: { id: id }
        })
      ),
      map(
        (result: ApolloQueryResult<GetQuestionnaireRequestQuery>) =>
          result.data.getQuestionnaireRequest
      )
    );
  }

  public getQuestionnaireRequestsCreatedBetweenDateRange(
    questionnaireRequestClinicId: string,
    dateRange: [string, string],
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Observable<Partial<QuestionnaireRequest[]>> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listQuestionnaireRequestsByClinicIdSortedByCreatedAt),
          variables: {
            questionnaireRequestClinicId,
            sortDirection: ModelSortDirection.DESC,
            createdAt: { between: dateRange }
          },
          fetchPolicy
        })
      ),

      map(
        (result: ApolloQueryResult<ListQuestionnaireRequestsByClinicIdSortedByCreatedAtQuery>) =>
          result.data.listQuestionnaireRequestsByClinicIdSortedByCreatedAt
            .items as QuestionnaireRequest[]
      )
    );
  }

  // public getQuestionnaireRequestsCreatedBetweenDateRange(
  //   questionnaireRequestClinicId: string,
  //   dateRange: [string, string],
  //   fetchPolicy: FetchPolicy = 'cache-first',
  //   nextToken: string | null = null,
  //   limit = Number.POSITIVE_INFINITY
  // ): Observable<Partial<QuestionnaireRequest[]>> {
  //   limit = limit > maxAWSItemLimit && limit !== Number.POSITIVE_INFINITY ? maxAWSItemLimit : limit;
  //   let allItems = [];
  //   return this.appSyncService.hydrated().pipe(
  //     switchMap(client =>
  //       client.query({
  //         query: gql(listQuestionnaireRequestsByClinicIdSortedByCreatedAt),
  //         variables: {
  //           questionnaireRequestClinicId,
  //           sortDirection: ModelSortDirection.DESC,
  //           createdAt: { between: dateRange }
  //         },
  //         fetchPolicy
  //       })
  //     ),
  //     switchMap(
  //       (result: ApolloQueryResult<ListQuestionnaireRequestsByClinicIdSortedByCreatedAtQuery>) => {
  //         console.log('query', result.data.listQuestionnaireRequestsByClinicIdSortedByCreatedAt);
  //         const {
  //           items,
  //           nextToken: newToken
  //         } = result.data.listQuestionnaireRequestsByClinicIdSortedByCreatedAt;

  //         allItems = [...allItems, ...items]; // Accumulate results
  //         nextToken = newToken; // Update nextToken for next request
  //         if (nextToken) {
  //           return this.getQuestionnaireRequestsCreatedBetweenDateRange(
  //             questionnaireRequestClinicId,
  //             dateRange,
  //             fetchPolicy,
  //             nextToken,
  //             limit
  //           );
  //         } else {
  //           return of(allItems as QuestionnaireRequest[]);
  //         }
  //       }
  //     )
  //   );
  // }

  public updateQuestionnaireType(
    questionnaireRequest: QuestionnaireRequest,
    type: QuestionnaireType
  ): Observable<QuestionnaireRequest> {
    const {
      id,
      questionnaireRequestPatientId,
      country,
      email,
      mobile,
      consentsToPrivacyForm,
      consentSource,
      consentsToResearch
    } = questionnaireRequest;
    const updateQuestionnaireRequestInput: UpdateQuestionnaireRequestInput = {
      id,
      questionnaireRequestPatientId,
      country,
      type,
      email,
      mobile,
      consentsToPrivacyForm,
      consentSource,
      consentsToResearch
    };

    if (questionnaireRequest.massClinicPatient) {
      updateQuestionnaireRequestInput.questionnaireRequestMassClinicPatientId =
        questionnaireRequest.massClinicPatient.id;
    }

    return this.updateQuestionnaireRequest(updateQuestionnaireRequestInput);
  }

  public updateQuestionnaireRequest(
    input: UpdateQuestionnaireRequestInput,
    limitToPatientView = true
  ): Observable<QuestionnaireRequest> {
    const mutation = limitToPatientView
      ? this.updateQuestionnaireRequestLimited
      : this.updateQuestionnaireRequestMutation;
    return this.appSyncService.hydrated(limitToPatientView).pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(mutation),
          variables: { input: input }
        })
      ),
      map(
        (result: ApolloQueryResult<UpdateQuestionnaireRequestMutation>) =>
          result.data.updateQuestionnaireRequest
      )
    );
  }

  private createAssessmentForSendingQuestionnaire(
    questionnaireType: QuestionnaireType,
    patientId: string
  ): Observable<Assessment> {
    const defaultNewAssessmentType: AssessmentType = AssessmentType.DryEyeSpecialized;
    const body: Partial<AssessmentBody> = {
      assessmentType: defaultNewAssessmentType,
      dryEyeForm: {
        assessmentMethod: this.getSymptomModeForQuestionnaireType(questionnaireType)
      }
    };
    return this.assessmentService.createAssessment(defaultNewAssessmentType, body, null, patientId);
  }

  private getSymptomModeForQuestionnaireType(type: QuestionnaireType) {
    switch (type) {
      case QuestionnaireType.DEQ:
        return SymptomModes.Simple;
      default:
        return SymptomModes.Advanced;
    }
  }

  public setQuestionnaireStatus(
    questionnaire: Partial<QuestionnaireRequest>
  ): QuestionnaireWithStatus {
    const createdAtDate = new Date(questionnaire.createdAt).getTime();

    if (questionnaire.completedAt) {
      return { ...questionnaire, status: 'Completed' };
    }
    if (!questionnaire.completedAt && createdAtDate - this.twoWeeksBack <= 0) {
      return { ...questionnaire, status: 'Expired' };
    }
    return { ...questionnaire, status: 'Incomplete' };
  }

  getDaysBackInMilliSeconds(days: number): number {
    const oneDayInMilliSeconds = 86400000;
    const daysInMilliSeconds = days * oneDayInMilliSeconds;

    const dateToday = new Date();
    dateToday.setHours(0);
    dateToday.setMinutes(0);
    dateToday.setMilliseconds(0);

    return dateToday.getTime() - daysInMilliSeconds;
  }

  public isAtRisk(scores: string): boolean {
    const deqThreshold = 5;
    const osdiThreshold = 13;
    const speedThreshold = 5;
    const speedIIThreshold = 5;
    const fitzpatrickThreshold = 25;

    scores = JSON.parse(scores);
    const isPositive =
      scores[QuestionnaireType.DEQ] > deqThreshold ||
      scores[QuestionnaireType.OSDI] >= osdiThreshold ||
      scores[QuestionnaireType.SPEED] >= speedThreshold ||
      scores[QuestionnaireType.SPEEDII] >= speedIIThreshold ||
      scores[QuestionnaireType.FITZPATRICK] <= fitzpatrickThreshold;

    return isPositive;
  }

  public getCategorizedScore(questionnaireType: string, score: number): string {
    let category = '';
    switch (questionnaireType) {
      case QuestionnaireScoreType.FITZPATRICK: {
        category = this.getFitzpatrickType(score);
        break;
      }
    }
    return category;
  }

  public getFitzpatrickType(fitzpatrickScore: number) {
    if (fitzpatrickScore <= 7) {
      return FitzpatrickType.type1;
    } else if (fitzpatrickScore <= 16) {
      return FitzpatrickType.type2;
    } else if (fitzpatrickScore <= 25) {
      return FitzpatrickType.type3;
    } else if (fitzpatrickScore <= 30) {
      return FitzpatrickType.type4;
    } else if (fitzpatrickScore <= 36) {
      return FitzpatrickType.type5;
    } else if (fitzpatrickScore <= 40) {
      return FitzpatrickType.type6;
    }
    return '';
  }

  public getLastCompletedLinkedQuestionnaireByType(
    linkedQuestionnaires: any[],
    lastCompletedLinkedQuestionnaireByTypeMap: any = {}
  ) {
    let linkedQuestionnairesByType: {
      [key: string]: QuestionnaireRequest[];
    } = this.getQuestionnaireTypeToQuestionnairesMap(linkedQuestionnaires);

    lastCompletedLinkedQuestionnaireByTypeMap = Object.keys(linkedQuestionnairesByType).reduce(
      (acc, questionnaireType) => {
        const lastCompletedQuestionnaire = this.getLastCompletedQuestionnaire(
          linkedQuestionnairesByType[questionnaireType]
        );
        if (lastCompletedQuestionnaire) {
          acc[questionnaireType] = lastCompletedQuestionnaire;
        } else {
          acc[questionnaireType] = lastCompletedLinkedQuestionnaireByTypeMap[questionnaireType];
        }
        return acc;
      },
      {}
    );
    return lastCompletedLinkedQuestionnaireByTypeMap;
  }

  public getLastCompletedQuestionnairesByScore(
    questionnaires: any[],
    lastCompletedQuestionnaireByScoreMap: any = {}
  ) {
    let questionnaireScoreTypeToQuestionnairesMap: {
      [key: string]: QuestionnaireRequest[];
    } = this.getQuestionnaireScoreTypeToQuestionnairesMap(questionnaires);
    let newLastCompletedQuestionnaireByScoreMap = Object.keys(
      questionnaireScoreTypeToQuestionnairesMap
    ).reduce((acc, key) => {
      if (lastCompletedQuestionnaireByScoreMap[key]) {
        questionnaireScoreTypeToQuestionnairesMap[key].push(
          lastCompletedQuestionnaireByScoreMap[key]
        );
      }
      const lastCompletedQuestionnaire = this.getLastCompletedQuestionnaire(
        questionnaireScoreTypeToQuestionnairesMap[key]
      );
      if (lastCompletedQuestionnaire) {
        acc[key] = lastCompletedQuestionnaire;
      } else {
        acc[key] = lastCompletedQuestionnaireByScoreMap[key];
      }
      return acc;
    }, {});
    return { ...lastCompletedQuestionnaireByScoreMap, ...newLastCompletedQuestionnaireByScoreMap };
  }

  public getQuestionnaireScoreTypeToQuestionnairesMap(questionnaires: QuestionnaireRequest[]) {
    let questionnaireScoreTypeToQuestionnairesMap: {
      [key: string]: QuestionnaireRequest[];
    } = {};
    for (let questionnaire of questionnaires) {
      const scores = JSON.parse(questionnaire.response.scores);
      Object.keys(scores).forEach(key => {
        questionnaireScoreTypeToQuestionnairesMap[key]
          ? questionnaireScoreTypeToQuestionnairesMap[key].push(questionnaire)
          : (questionnaireScoreTypeToQuestionnairesMap[key] = [questionnaire]);
      });
    }
    return questionnaireScoreTypeToQuestionnairesMap;
  }

  public getLastCompletedQuestionnaire(questionnaires: any[]) {
    if (!questionnaires) return null;
    const mostRecentDate = new Date(
      Math.max.apply(
        null,
        questionnaires.map(item => {
          return new Date(item.completedAt);
        })
      )
    );
    const mostRecentObject = questionnaires.filter(item => {
      const d = new Date(item.completedAt);
      return d.getTime() === mostRecentDate.getTime();
    })[0];
    return mostRecentObject;
  }

  public getQuestionnaireTypeToQuestionnairesMap(questionnaires: any[]) {
    let questionnaireTypeToQuestionnairesMap = {};
    for (let questionnaire of questionnaires) {
      let questionnaireType = this.getQuestionnaireType(questionnaire);
      questionnaireTypeToQuestionnairesMap[questionnaireType]
        ? questionnaireTypeToQuestionnairesMap[questionnaireType].push(questionnaire)
        : (questionnaireTypeToQuestionnairesMap[questionnaireType] = [questionnaire]);
    }
    return questionnaireTypeToQuestionnairesMap;
  }

  public getQuestionnaireType(questionnaire: any) {
    if (questionnaire.type === QuestionnaireType.CUSTOM) {
      try {
        return (questionnaire.config as any) instanceof Object
          ? questionnaire.config['schema']['abbreviation']
          : JSON.parse(questionnaire.config).schema.abbreviation;
      } catch {
        return QuestionnaireType.CUSTOM;
      }
    }
    return questionnaire.type;
  }
}
