export const treatmentTemplateValuesMap = {
  ['aque-mod']: {
    friendlyId: 'aque-mod',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo-gel', 'systane-ultra-high-performance'],
    rxEyeDrops: ['restasis-and-restasis-multidose'],
    procedures: ['punctal-plugs'],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['aque-mild']: {
    friendlyId: 'aque-mild',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['systane-original']
  },
  ['evap-mod']: {
    friendlyId: 'evap-mod',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['ocusoft-retaine'],
    procedures: [
      'lipiflow-thermal-pulsation-treatment',
      'inmode-radiofrequency-therapy-(rf)',
      'intense-pulsed-light-therapy-(ipl)'
    ],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  ['mix-mild']: {
    friendlyId: 'mix-mild',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['systane-balance-restorative'],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['mix-mod']: {
    friendlyId: 'mix-mild',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['mgd', 'biodrop-md-plus', 'prn-omega-softgel'],
    rxEyeDrops: ['cequa'],
    procedures: [
      'intense-pulsed-light-therapy-(ipl)',
      'lipiflow-thermal-pulsation-treatment',
      'inmode-radiofrequency-therapy-(rf)'
    ],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['evap-sev']: {
    friendlyId: 'evap-sev',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['ocusoft-retaine'],
    rxEyeDrops: ['autologous-serum'],
    procedures: [
      'intraductal-probing',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)'
    ],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['aque-sev']: {
    friendlyId: 'aque-sev',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['systane-bion-tears'],
    rxEyeDrops: ['cequa', 'autologous-serum'],
    procedures: ['punctal-plugs']
  },
  ['evap-mild']: {
    friendlyId: 'evap-mild',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['systane-balance-restorative', 'hylo-dual-dry-eye-and-allergy-relief'],
    procedures: ['lipiflow-thermal-pulsation-treatment', 'miboflo'],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  ['mix-severe']: {
    friendlyId: 'mix-severe',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['ocusoft-retaine'],
    rxEyeDrops: ['cequa', 'autologous-serum'],
    procedures: ['intense-pulsed-light-therapy-(ipl)', 'inmode-radiofrequency-therapy-(rf)'],
    exercises: ['warm-compresses', 'blinking-exercises']
  },
  ['mild-aqueous']: {
    friendlyId: 'mild-aqueous',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['i-drop-pur-gel'],
    rxEyeDrops: [],
    procedures: ['punctal-plugs'],
    exercises: ['blinking-exercises']
  },
  ['moderate-aqueous']: {
    friendlyId: 'moderate-aqeueous',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['i-drop-pur-gel'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
    procedures: ['punctal-plugs', 'boston-sight-scleral', 'scleral-contact-lens','itear'],
    exercises: ['blinking-exercises']
  },
  ['severe-aqueous']: {
    friendlyId: 'severe-aqueous',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['i-drop-pur-gel', 'hylo', 'tranquileyes'],
    rxEyesDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum', 'prp'],
    procedures: [
      'punctal-plugs',
      'amniotic-membrane-transplantation',
      'scleral-lenses',
      'boston-sight-scleral'
    ],
    exercises: ['blinking-exercises', 'warm-compresses']
  },
  ['mild-mixed']: {
    friendlyId: 'mild-mixed',
    __typename: 'TreatmentTemplate',
    otcEyeDrops: ['mgd', 'prn-omega-softgel'],
    rxEyesDrops: [],
    procedures: ['miboflo'],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },
  ['moderate-mixed']: {
    friendlyId: 'moderate-mixed',
    __typename: 'moderate-mixed',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'punctal-plugs','itear'
    ],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },
  ['severe-mixed']: {
    friendlyId: 'severe-mixed',
    __typename: 'severe-mixed',
    otcEyeDrops: [
      'mgd',
      'hylo-dual',
      'ocunox',
      'prn-omega-softgel',
      'tranquileyes'
    ],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum','prp'],
    procedures: [
      'blephex-(microblepharoexfoliation)',
      'intense-pulsed-light-therapy-(ipl)',
      'inmode-radiofrequency-therapy-(rf)',
      'punctal-plugs',
      'amniotic-membrane-transplantation',
      'boston-sight-scleral',
      'intraductal-probing',
      'itear'
    ],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },

  ['mild-evaporative']: {
    friendlyId: 'mild-evaporative',
    __typename: 'mild-evaporative',
    otcEyeDrops: ['mgd','hylo-dual','systane-balance-restorative','prn-omega-softgel'],
    rxEyeDrops: [''],
    procedures: ['miboflo'],
    exercises: ['warm-compresses','blinking-exercises','visual-hygiene']
  },
  ['moderate-evaporative']: {
    friendlyId: 'moderate-evaporative',
    __typename: 'moderate-evaporative',
    otcEyeDrops: ['mgd','hylo-dual','prn-omega-softgel'],
    rxEyeDrops: ['restasis-and-restasis-multidose','cequa','xiidra','autologous-serum'],
    procedures: ['blephex-(microblepharoexfoliation)','intense-pulsed-light-therapy-(ipl)','inmode-radiofrequency-therapy-(rf)'],
    exercises: ['warm-compresses', 'blinking-exercises', 'visual-hygiene']
  },

  ['severe-evaporative']: {
    friendlyId: 'severe-evaporative',
    __typename: 'severe-evaporative',
    otcEyeDrops: ['mgd','hylo-dual','ocunox','prn-omega-softgel','tranquileyes'],
    rxEyeDrops: ['restasis-and-restasis-multidose','cequa','xiidra','autologous-serum','prp'],
    procedures: ['blephex-(microblepharoexfoliation)','intense-pulsed-light-therapy-(ipl)','inmode-radiofrequency-therapy-(rf)','punctal-plugs','amniotic-membrane-transplantation','boston-sight-scleral','intraductal-probing','itear'],
    exercises: ['warm-compresses','blinking-exercises','visual-hygiene']
  },

  ['neurotrophic-stage-1']: {
    friendlyId: 'neurotrophic-stage-1',
    __typename: 'neurotrophic-stage-1',
    otcEyeDrops: ['i-drop-pur-gel'],
    rxEyeDrops: ['restasis-and-restasis-multidose','cequa','xiidra','autologous-serum','prp'],
    procedures: [''],
    exercises: ['blinking-exercises','glad-press-seal']
  },
  ['neurotrophic-stage-2']: {
    friendlyId: 'neurotrophic-stage-2',
    __typename: 'neurotrophic-stage-2',
    otcEyeDrops: ['i-drop-pur-gel','tranquileyes'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum',
      'bandage-contact-lens', 'amniotic-membrane-transplantation', 'vigamox','prp'],
    procedures: ['tarsorraphy','tape-tarsorrhaphy'],
    exercises: ['blinking-exercises','tape-tarsorrhaphy']
  },
  ['neurotrophic-stage-3']: {
    friendlyId: 'neurotrophic-stage-3',
    __typename: 'neurotrophic-stage-3',
    otcEyeDrops: ['i-drop-pur-gel','tranquileyes','ocunox'],
    rxEyeDrops: ['restasis-and-restasis-multidose', 'cequa', 'xiidra', 'autologous-serum',
    'bandage-contact-lens', 'amniotic-membrane-transplantation', 'vigamox' , 'topical-n-acetylcysteine','doxycycline','prp'],
    procedures: ['tarsorraphy','tape-tarsorrhaphy'],
    exercises: ['blinking-exercises','tape-tarsorrhaphy']
  },

  ['contact-lens-induced-keratopathy']: {
    friendlyId: 'contact-lens-induced-keratopathy',
    __typename: 'contact-lens-induced-keratopathy',
    otcEyeDrops: ['hylo'],
    rxEyeDrops: ['lotemax'],
    procedures: [''],
    exercises: ['']
  },

  ['slk']: {
    friendlyId: 'slk',
    __typename: 'slk',
    otcEyeDrops: ['i-drop-pur-gel','tranquileyes'],
    rxEyeDrops: ['lotemax','cequa'],
    procedures: ['bandage-contact-lens'],
    exercises: ['']
  },

  ['corneal-toxicity']: {
    friendlyId: 'corneal-toxicity',
    __typename: 'corneal-toxicity',
    otcEyeDrops: ['refresh-optive-pf','systane-bion-tears'],
    rxEyeDrops: ['autologous-serum','prednisolone-sodium-phosphate-minums','prp'],
    procedures: ['bandage-contact-lens'],
    exercises: ['warm-compresses','blinking-exercises','visual-hygiene']
  },

  ['trichiasis']: {
    friendlyId: 'trichiasis',
    __typename: 'trichiasis',
    otcEyeDrops: ['i-drop-pur-gel','hylo-gel'],
    rxEyeDrops: ['vigamox'],
    procedures: ['eyelash-apilation-cauterization'],
    exercises: ['']
  },

  ['lagophthalmos']: {
    friendlyId: 'lagophthalmos',
    __typename: 'lagophthalmos',
    otcEyeDrops: ['i-drop-pur-gel','hylo','ocunox','tranquileyes'],
    rxEyeDrops: [''],
    procedures: ['tarsorraphy',], // I didn't know what add for eyelid surgery (Other)
    exercises: ['glad-press-seal','tape-tarsorrhaphy']
  },

  ['bacterial-blepharokeratoconjunctivitis-(bkc)']: {
    friendlyId: 'bacterial-blepharokeratoconjunctivitis',
    __typename: 'bacterial-blepharokeratoconjunctivitis',
    otcEyeDrops: ['i-drop-pur-gel','hylo','prn-omega-softgel','systane-lid-wipes','i-lid-n-lash-plus'],
    rxEyeDrops: ['tobradex-drops','vigamox','fucithalmic-viscous'],
    procedures: ['blephex-(microblepharoexfoliation)'],
    exercises: ['warm-compresses']
  },

  ['exposure-keratopathy']: {
    friendlyId: 'exposure-keratopathy',
    __typename: 'exposure-keratopathy',
    otcEyeDrops: ['i-drop-pur-gel','hylo-gel','ocunox','prn-omega-softgel','tranquileyes','sleeptite-sleeprite'],
    rxEyeDrops: ['erythromycin-ointment'],
    procedures: ['punctal-plugs','bandage-contact-lens','boston-sight-scleral','tape-tarsorrhaphy','tarsorraphy'],
    exercises: ['visual-hygiene','glad-press-seal',]
  },

  ['gpc/vkc']: {
    friendlyId: 'gpc/vkc',
    __typename: 'gpc/vkc',
    otcEyeDrops: ['hylo-dual', 'thealoz-duo'],
    //added restasis-and-restasis-multidose fot restasis.
    rxEyeDrops: ['pataday','prednisolone-sodium-phosphate-minums','alrex','restasis-and-restasis-multidose','cequa','xiidra'], // no Zaditor,
    procedures: [''],
    exercises: ['cool-compresses']// I dont't see the discontune contact lense.
  },

  ['allergic-conjunctivitis']: {
    friendlyId: 'allergic-conjunctivitis',
    __typename: 'allergic-conjunctivitis',
    otcEyeDrops: ['hylo-dual',],
    //prednisolone-sodium-phosphate-minums for prednisolone minuims added restasis-and-restasis-multidose fot restasis.
    rxEyeDrops: ['pataday','prednisolone-sodium-phosphate-minums','alrex','restasis-and-restasis-multidose','cequa','xiidra'], // Zaditor doesn't exist
    procedures: [''],
    exercises: ['cool-compresses']
  },

  ['viral/bacterial-conjunctivitis']: {
    friendlyId: 'viral/bacterial-conjunctivitis',
    __typename: 'viral/bacterial-conjunctivitis',
    otcEyeDrops: ['systane-bion-tears'],
    rxEyeDrops: ['vigamox','erythromycin-ointment'],
    procedures: [''],
    exercises: ['cool-compresses']
  },

  ['bacterial-blepharitis']: {
    friendlyId: 'bacterial-blepharitis',
    __typename: 'bacterial-blepharitis',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel', 'i-lid-n-lash-plus'],

    rxEyeDrops: ['erythromycin-ointment','fucithalmic-viscous','blephamide-drop','blephamide-ointment'],
    procedures: ['blephex-(microblepharoexfoliation)','low-level-light-therapy'],
    exercises: ['warm-compresses']
  },

  ['demodex-blepharitis']: {
    friendlyId: 'demodex-blepharitis',
    __typename: 'demodex-blepharitis',
    otcEyeDrops: ['mgd', 'hylo-dual', 'prn-omega-softgel', 'i-lid-n-lash-plus'],
    rxEyeDrops: ['restasis-and-restasis-multidose','xiidra','cequa','doxycycline'],
    procedures: ['blephex-(microblepharoexfoliation)','low-level-light-therapy','miboflo','lipiflow-thermal-pulsation-treatment','intense-pulsed-light-therapy-(ipl)'],
    exercises: ['warm-compresses']
  },

  ['epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion']: {
    friendlyId: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
    __typename: 'epithelial-basement-membrane-dystrophy/recurrent-corneal-erosion',
    otcEyeDrops: ['thealoz-duo','sleeptite-sleeprite'], //Muro 128 ointment at night time.
    rxEyeDrops: [''],
    procedures: ['bandage-contact-lens','epithelial-debridement','phototheraputic-keratectomy'],
    exercises: ['glad-press-seal','warm-compresses']
  },

  ['medication-induced']: {
    friendlyId: 'medication-induced',
    __typename: 'medication-induced',
    otcEyeDrops: ['systane-bion-tears'],
    rxEyeDrops: ['autologous-serum','prp'],
    procedures: [''],
    exercises: ['warm-compresses','blinking-exercises']
  },

  ['corneal-neuralgia']: {
    friendlyId: 'corneal-neuralgia',
    __typename: 'corneal-neuralgia',
    otcEyeDrops: ['i-drop-pur-gel','hylo','ocunox'],
    rxEyeDrops: ['lotemax','restasis-and-restasis-multidose','cequa','xiidra','autologous-serum','prp'], //still missing gabapentin
    procedures: ['bandage-contact-lens','boston-sight-scleral','intense-pulsed-light-therapy-(ipl)','inmode-radiofrequency-therapy-(rf)','lipiflow-thermal-pulsation-treatment','miboflo'],
    exercises:['warm-compresses','blinking-exercises']
  },

  ['conjunctivochalasis-mild']: {
    friendlyId: 'conjunctivochalasis-mild',
    __typename: 'conjunctivochalasis-mild',
    otcEyeDrops: ['i-drop-pur-gel','thealoz-duo','prn-omega-softgel'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['']
  },
  ['conjunctivochalasis-moderate']: {
    friendlyId: 'conjunctivochalasis-moderate',
    __typename: 'conjunctivochalasis-moderate',
    otcEyeDrops: ['i-drop-pur-gel','thealoz-duo','prn-omega-softgel'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['warm-compresses']
  },
  ['conjunctivochalasis-severe']: {
    friendlyId: 'conjunctivochalasis-severe',
    __typename: 'conjunctivochalasis-severe',
    otcEyeDrops: ['i-drop-pur-gel','thealoz-duo','prn-omega-softgel'],
    rxEyeDrops: ['alrex','prednisolone-sodium-phosphate-minums'],
    procedures: [''],
    exercises: ['warm-compresses']
  },

  ['floppy-eyelid-syndrome-mild']: {
    friendlyId: 'floppy-eyelid-syndrome-mild',
    __typename: 'floppy-eyelid-syndrome-mild',
    otcEyeDrops: ['i-drop-pur-gel','hylo-gel','ocunox'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['']
  },
  ['floppy-eyelid-syndrome-moderate']: {
    friendlyId: 'floppy-eyelid-syndrome-moderate',
    __typename: 'floppy-eyelid-syndrome-moderate',
    otcEyeDrops: ['i-drop-pur-gel','hylo-gel','ocunox','sleeptite-sleeprite'],
    rxEyeDrops: [''],
    procedures: [''],
    exercises: ['glad-press-seal',]
  },
  ['floppy-eyelid-syndrome-severe']: {
    friendlyId: 'floppy-eyelid-syndrome-severe',
    __typename: 'floppy-eyelid-syndrome-severe',
    otcEyeDrops: ['i-drop-pur-gel','hylo-gel','ocunox'],
    rxEyeDrops: ['restasis-and-restasis-multidose','xiidra','cequa','prednisolone-sodium-phosphate-minums','tobradex-ointment'],
    procedures: [''],//eyelid-surgery(other)
    exercises: ['glad-press-seal']
  },

  ['mechanical-(entropion,distichiasis,tarsal-entropion)']: {
    friendlyId: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
    __typename: 'mechanical-(entropion,distichiasis,tarsal-entropion)',
    otcEyeDrops: ['i-drop-pur-gel','hylo-gel','ocunox'],
    rxEyeDrops: ['erythromycin-ointment'],
    procedures: ['bandage-contact-lens','eyelash-apilation-cauterization'],//eyelid-surgery(other) need to be added.
    exercises: ['']
  },

  ['lid-wiper-epitheliopathy']: {
    friendlyId: 'lid-wiper-epitheliopathy',
    __typename: 'lid-wiper-epitheliopathy',
    otcEyeDrops: ['mgd','hylo-dual','tranquileyes'],
    rxEyeDrops: ['alrex','prednisolone-sodium-phosphate-minums','lotemax','cequa','autologous-serum','prp'],
    procedures: ['punctal-plugs', 'intense-pulsed-light-therapy-(ipl)', 'inmode-radiofrequency-therapy-(rf)', 'miboflo', 'lipiflow-thermal-pulsation-treatment','itear'],
    exercises: ['warm-compresses','blinking-exercises']
  },
};


