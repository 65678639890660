import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteToService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  routeTo({ routeTo, redirect, outlet }: any): Observable<any> {
    const redirectTo = (redirect && this.route.snapshot.queryParams['redirectTo']) || '';

    if (outlet) {
      const currentUrl = this.router.url;
      const newUrl = `${currentUrl}/(${outlet}:${routeTo +
        (redirectTo ? '?redirectTo=' + redirectTo : '')})`;
      return from(this.router.navigateByUrl(newUrl));
    }

    return from(
      this.router.navigateByUrl(`${routeTo + (redirectTo ? '?redirectTo=' + redirectTo : '')}`)
    );
  }
}
