import { Injectable, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService } from 'src/app/filter/filter.service';
import { IntakeFormService } from 'src/app/intake-form/intake-form.service';
import { DynamicActionConfig } from 'src/app/shared/models/dynamic-action.model';
import { AssessmentService } from './assessment.service';
import { ClinicService } from './clinic.service';
import { PatientService } from './patient.service';
import { QuestionnaireRequestService } from './questionnaire-request.service';
import { RouteToService } from './route-to.service';
import { SideNavActionsService } from './sidenav-actions.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicActionService {
  private serviceTokenMap: { [key: string]: Type<any> } = {
    RouteToService,
    PatientService,
    ClinicService,
    SideNavActionsService,
    IntakeFormService,
    AssessmentService,
    QuestionnaireRequestService,
    FilterService
  };

  constructor(private injector: Injector) {}

  performAction({ serviceName, functionName, params }: DynamicActionConfig): Observable<any> {
    const serviceToken = this.serviceTokenMap[serviceName];
    if (!serviceToken) {
      throw new Error(
        'Service token is not included, ensure it is included in the DynamicActionService'
      );
    }
    return this.injector.get(serviceToken)[functionName](params);
  }

  performAction2(serviceName, functionName, params): Observable<any> {
    const serviceToken = this.serviceTokenMap[serviceName];
    if (!serviceToken) {
      throw new Error(
        'Service token is not included, ensure it is included in the DynamicActionService'
      );
    }
    return this.injector.get(serviceToken)[functionName](...params);
  }
}
