import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { TextFavorite, TextFavoriteType } from 'src/API';
import { TextFavoriteService } from './../text-favorite.service';
import { noSort } from 'src/app/core/api/model-utils';

@Component({
  selector: 'csi-text-favorite-select',
  templateUrl: './text-favorite-select.component.html',
  styleUrls: ['./text-favorite-select.component.css']
})
export class TextFavoriteSelectComponent implements OnInit {
  public filterControl: FormControl = new FormControl();
  public favoritesForType: TextFavorite[] = [];
  public filteredFavorites: TextFavorite[] = [];
  public selectedFavoritesMap: Record<string, TextFavorite> = {};
  public selectedFavorites: TextFavorite[] = [];

  public matSelectHeaders: { [key: string]: { name: string; conditionToShow: TextFavoriteType } } = {
    shortCut: { name: 'Shortcut', conditionToShow: TextFavoriteType.Recommendations },
    treatMentTemplateID: {
      name: 'Treatment Template ID',
      conditionToShow: TextFavoriteType.Recommendations
    },
    Text: { name: 'Text', conditionToShow: TextFavoriteType.Recommendations }
  };

  public TextFavoriteType = TextFavoriteType;

  constructor(
    private textFavoriteService: TextFavoriteService,
    @Inject(MAT_DIALOG_DATA) public textFavoriteType: TextFavoriteType
  ) {
    this.favoritesForType = this.textFavoriteService.getTextFavoritesForType(this.textFavoriteType);
    this.updateFilteredFavorites();

    this.filterControl.valueChanges.pipe(debounceTime(100)).subscribe((filter: string) => {
      this.updateFilteredFavorites(filter);
    });
  }

  ngOnInit() {}

  addSelectedFavorites(selectedFavorite: TextFavorite) {
    if (this.selectedFavoritesMap[selectedFavorite.shortcut]) {
      delete this.selectedFavoritesMap[selectedFavorite.shortcut];
    } else {
      this.selectedFavoritesMap[selectedFavorite.shortcut] = selectedFavorite;
    }
    this.selectedFavorites = Object.values(this.selectedFavoritesMap);
  }

  private updateFilteredFavorites(filter: string = ''): void {
    const lowerCaseFilter = filter.toLowerCase();

    const userCreatedFavorites = this.favoritesForType.filter(favorite => favorite.createdAt);

    const otherFavorites = this.favoritesForType.filter(favorite => !favorite.createdAt);

    const filteredUserCreatedFavorites = userCreatedFavorites.filter(
      favorite =>
        (favorite.shortcut && favorite.shortcut.toLowerCase().includes(lowerCaseFilter)) ||
        (favorite.text && favorite.text.toLowerCase().includes(lowerCaseFilter)) ||
        (favorite.treatmentTemplate &&
          favorite.treatmentTemplate.friendlyId &&
          favorite.treatmentTemplate.friendlyId.toLowerCase().includes(lowerCaseFilter))
    );

    const filteredOtherFavorites = otherFavorites.filter(
      favorite =>
        (favorite.shortcut && favorite.shortcut.toLowerCase().includes(lowerCaseFilter)) ||
        (favorite.text && favorite.text.toLowerCase().includes(lowerCaseFilter)) ||
        (favorite.treatmentTemplate &&
          favorite.treatmentTemplate.friendlyId &&
          favorite.treatmentTemplate.friendlyId.toLowerCase().includes(lowerCaseFilter))
    );

    this.filteredFavorites = [...filteredUserCreatedFavorites, ...filteredOtherFavorites];
  }
  noSort = noSort;
}
