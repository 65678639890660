import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, forkJoin, of } from 'rxjs';
import {
  Treatment,
  TreatmentsService
} from 'src/app/core/remote-json/treatments/treatments.service';
import { ClinicSetupService } from '../logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { TreatmentPricingConfigFormGroup } from './packages/package.model';
import { PricingService } from './pricing.service';

@Component({
  selector: 'csi-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  treatmentGroups: {
    label: string;
    priced: Treatment[];
    nonPriced: Treatment[];
    originalPriced: Treatment[];
    originalNonPriced: Treatment[];
  }[] = [];
  isLoading = true;
  pricingConfigs: { [treatmentId: number]: TreatmentPricingConfigFormGroup } = {};
  constructor(
    private treatmentsService: TreatmentsService,
    private pricingService: PricingService,
    private clinicSetupService: ClinicSetupService,
    public dialogRef: MatDialogRef<PricingComponent>
  ) {}
  static open(dialog: MatDialog): MatDialogRef<PricingComponent> {
    return dialog.open(PricingComponent, { width: '80%', height: '85dvh' });
  }
  ngOnInit(): void {
    this.loadTreatments();
  }
  loadTreatments(): void {
    this.isLoading = true;
    const clinicId = this.clinicSetupService.clinicId;
    combineLatest([
      this.treatmentsService.allTreatments$,
      this.pricingService.listTreatmentConfigsForClinic(clinicId)
    ]).subscribe(([allTreatments, existingConfigs]) => {
      this.treatmentGroups = [
        {
          label: 'RX',
          priced: [],
          nonPriced: allTreatments.filter(t => t.class === 'rx'),
          originalPriced: [],
          originalNonPriced: allTreatments.filter(t => t.class === 'rx')
        },
        {
          label: 'OTC',
          priced: [],
          nonPriced: allTreatments.filter(t => t.class === 'otc'),
          originalPriced: [],
          originalNonPriced: allTreatments.filter(t => t.class === 'otc')
        },
        {
          label: 'Procedures',
          priced: [],
          nonPriced: allTreatments.filter(t => t.class === 'procedure'),
          originalPriced: [],
          originalNonPriced: allTreatments.filter(t => t.class === 'procedure')
        },
        {
          label: 'Exercises',
          priced: [],
          nonPriced: allTreatments.filter(t => t.class === 'exercise'),
          originalPriced: [],
          originalNonPriced: allTreatments.filter(t => t.class === 'exercise')
        }
      ];
      existingConfigs.forEach(config => {
        const treatmentId = String(config.treatmentId);
        const foundTreatment = allTreatments.find(t => t.id === treatmentId);
        if (!foundTreatment) return;
        foundTreatment.price = Number(config.price) || 0;
        const groupLabel = this.getGroupLabel(foundTreatment.class);
        const group = this.treatmentGroups.find(g => g.label === groupLabel);
        if (!group) return;
        group.nonPriced = group.nonPriced.filter(t => t.id !== foundTreatment.id);
        group.originalNonPriced = group.originalNonPriced.filter(t => t.id !== foundTreatment.id);
        group.priced.push(foundTreatment);
        group.originalPriced.push(foundTreatment);
        this.pricingConfigs[foundTreatment.id] = new TreatmentPricingConfigFormGroup();
        this.pricingConfigs[foundTreatment.id].patchValue({
          id: config.id,
          treatmentId: foundTreatment.id,
          price: foundTreatment.price,
          units: 1
        });
      });
      this.isLoading = false;
    });
  }
  private getGroupLabel(tClass: string): string {
    switch (tClass) {
      case 'rx':
        return 'RX';
      case 'otc':
        return 'OTC';
      case 'procedure':
        return 'Procedures';
      case 'exercise':
        return 'Exercises';
      default:
        return '';
    }
  }
  drop(event: CdkDragDrop<Treatment[]>, groupLabel: string): void {
    const group = this.treatmentGroups.find(g => g.label === groupLabel);
    if (!group) return;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  moveToPriced(item: Treatment, groupLabel: string): void {
    const group = this.treatmentGroups.find(g => g.label === groupLabel);
    if (group) {
      const index = group.nonPriced.indexOf(item);
      if (index > -1) {
        group.nonPriced.splice(index, 1);
        item.price = 0;
        group.priced.push(item);
        group.originalNonPriced = group.originalNonPriced.filter(t => t.id !== item.id);
        group.originalPriced.push(item);
        if (!this.pricingConfigs[item.id]) {
          this.pricingConfigs[item.id] = new TreatmentPricingConfigFormGroup();
        }
        this.pricingConfigs[item.id].patchValue({
          treatmentId: item.id,
          price: item.price,
          units: 1
        });
      }
    }
  }
  moveToNonPriced(item: Treatment, groupLabel: string): void {
    const group = this.treatmentGroups.find(g => g.label === groupLabel);
    if (!group) return;
    const index = group.priced.indexOf(item);
    if (index > -1) {
      group.priced.splice(index, 1);
      item.price = 0;
      group.nonPriced.push(item);
      group.priced = [...group.priced];
      group.nonPriced = [...group.nonPriced];
      group.originalPriced = group.originalPriced.filter(t => t.id !== item.id);
      group.originalNonPriced.push(item);
      const formGroup = this.pricingConfigs[item.id];
      const savedId = formGroup && formGroup.value ? formGroup.value.id : null;
      if (savedId) {
        this.pricingService.deleteTreatmentConfig(savedId).subscribe(
          () => console.log(`TreatmentConfig ${savedId} deleted from server`),
          error => console.error('Error deleting TreatmentConfig', error)
        );
      }
      delete this.pricingConfigs[item.id];
    }
  }
  filterPriced(filterValue: string, groupLabel: string): void {
    const group = this.treatmentGroups.find(g => g.label === groupLabel);
    if (group) {
      const searchTerm = filterValue.trim().toLowerCase();
      group.priced = group.originalPriced.filter(item =>
        item.name.toLowerCase().includes(searchTerm)
      );
    }
  }
  filterNonPriced(filterValue: string, groupLabel: string): void {
    const group = this.treatmentGroups.find(g => g.label === groupLabel);
    if (group) {
      const searchTerm = filterValue.trim().toLowerCase();
      group.nonPriced = group.originalNonPriced.filter(item =>
        item.name.toLowerCase().includes(searchTerm)
      );
    }
  }
  onSave(): void {
    const allFormValues = Object.values(this.pricingConfigs).map(fg => fg.value);
    const newItems = allFormValues.filter(item => !item.id);
    const updatedItems = allFormValues.filter(item => item.id);
    const clinicId = this.clinicSetupService.clinicId;
    const newConfigs = newItems.map(item => ({
      treatmentId: String(item.treatmentId),
      price: String(item.price),
      treatmentConfigClinicId: clinicId
    }));
    const newConfigs$ =
      newConfigs.length > 0 ? this.pricingService.saveAllTreatmentConfigs(newConfigs) : of([]);
    const updateConfigs$ =
      updatedItems.length > 0
        ? forkJoin(
            updatedItems.map(item =>
              this.pricingService.updateTreatmentConfig({
                id: item.id,
                treatmentId: String(item.treatmentId),
                price: String(item.price),
                treatmentConfigClinicId: clinicId
              })
            )
          )
        : of([]);
    forkJoin([newConfigs$, updateConfigs$]).subscribe(
      ([newResults, updateResults]) => {
        console.log('Successfully saved new treatment configs:', newResults);
        console.log('Successfully updated treatment configs:', updateResults);
        this.dialogRef.close();
      },
      error => {
        console.error('Error saving treatment configs:', error);
      }
    );
  }
}
