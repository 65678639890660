import { CardBottomColor } from './dashboard-number-card/dashboard-number-card.component';

export enum TimeFrameInDays {
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90,
  YEAR = 365,
  ALL = 365 * 10
}

export interface StatisticsConfig {
  serviceName: string;
  functionName: string;
  params: any;
  title: string;
  cardBottomColor: CardBottomColor;
  filterBy: string;
  timeFrameSelection: TimeFrameInDays[];
}

export interface DashboardNumberCardStatConfig {
  label: string;
  subtitle: string;
  stat: string;
  statChange: number;
  statPercentChanged: number;
}

export interface DashboardNumberCardConfig {
  title: string;
  cardBottomColor: CardBottomColor;
  multiStatConfig: DashboardNumberCardStatConfig[];
}

export const timeFrameToCardConfigMap: { [key in TimeFrameInDays]: any } = {
  [TimeFrameInDays.WEEK]: {
    label: '1W',
    subtitle: 'vs previous week'
  },
  [TimeFrameInDays.MONTH]: {
    label: '1M',
    subtitle: 'vs previous month'
  },
  [TimeFrameInDays.QUARTER]: {
    label: '3M',
    subtitle: 'vs previous 3 months'
  },
  [TimeFrameInDays.YEAR]: {
    label: '1Y',
    subtitle: 'vs previous year'
  },
  [TimeFrameInDays.ALL]: {
    label: 'ALL',
    subtitle: 'All time'
  }
};
