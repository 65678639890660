import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AbsoluteValuePipe } from 'src/app/shared/shared-pipes/absolute-value.pipe';
import { DashboardNumberCardStatConfig } from '../dashboard.model';

export type CardBottomColor = 'none' | 'blue' | 'green' | 'purple' | 'red' | 'orange';

@Component({
  selector: 'csi-dashboard-number-card',
  templateUrl: './dashboard-number-card.component.html',
  styleUrls: ['./dashboard-number-card.component.css'],
  providers: [AbsoluteValuePipe]
})
export class DashboardNumberCardComponent implements OnInit {
  @Input() title: string;
  @Input() cardBottomColor: CardBottomColor = 'none';
  @Input() multiStatConfig: DashboardNumberCardStatConfig[] = [];

  public hideStatPercentChanged = [false];
  buttonToggleControl: FormControl = new FormControl(0);
  constructor() {}

  ngOnInit() {
    this.multiStatConfig.forEach((config, i) => {
      if (!Number.isFinite(config.statPercentChanged)) {
        this.hideStatPercentChanged[i] = true;
      }
    });

    if (this.multiStatConfig.length > 1) {
      this.buttonToggleControl.setValue(1); // Set default to 1 month
    }
  }
}
