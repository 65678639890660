import { Injectable } from '@angular/core';
import { gql } from 'graphql-tag';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Clinic, Doctor, FormTemplate, TemperatureUnit } from 'src/API';
import { StaffService } from 'src/app/core/api/staff.service';
import { TextFavorite, UpdateClinicSetupInput, UpdateClinicSetupMutation } from './../../../API';
import { updateClinicSetup } from './../../../graphql/mutations';
import { AppSyncService } from './../../core/appsync.service';
import { FormTemplateSchema } from './../../form-template/form-template-model';
import { InteractionsSetup } from './../interactions-setup-modal/interactions-setup.model';
import { ClinicSetupFormGroup } from './clinic-setup-form.model';
import { LayoutsService } from './layouts/layouts.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicSetupService {
  public textFavorites: TextFavorite[];
  public interactionsSetup: InteractionsSetup;
  public isClinicAdmin: boolean;
  public clinicOwnerId: string;
  public isSaving: boolean;
  public temperatureInCelsius: string | number;
  public clinicSetupTemperatureUnit: TemperatureUnit;

  public clinicStaffs: Doctor[];

  public clinicSetupFormGroup = new ClinicSetupFormGroup();

  public clinicSetupId: string;
  public clinicId: string;
  public clinic: Clinic;

  public formTemplates: (Omit<FormTemplate, 'schema'> & { schema: FormTemplateSchema })[];

  constructor(
    private staffService: StaffService,
    private layoutsService: LayoutsService,
    private appSyncService: AppSyncService
  ) {
    this.setClinicSetup(this.staffService.staff);
  }

  public setClinicSetup(doctor: Doctor) {
    if (doctor && doctor.clinic) {
      this.clinic = doctor.clinic;
      this.clinicId = doctor.clinic.id;
      this.clinicOwnerId = doctor.clinic.owner.id;
      this.isClinicAdmin = this.staffService.isClinicOwner;

      this.formTemplates = (
        (doctor.clinic.clinicFormTemplates && doctor.clinic.clinicFormTemplates.items) ||
        []
      ).map(formTemplate => {
        return { ...formTemplate, schema: JSON.parse(formTemplate.schema) as FormTemplateSchema };
      });

      this.clinicStaffs = doctor.clinic.doctors.items;
      if (doctor.clinic.clinicSetup) {
        this.clinicSetupFormGroup.patchValueFromClinicSetup(
          doctor.clinic.clinicSetup,
          doctor.clinic.owner.email
        );

        this.layoutsService.setLayouts(this.clinic.clinicSetup.layouts);
        this.clinicSetupId = doctor.clinic.clinicSetup.id;
        this.interactionsSetup = JSON.parse(doctor.clinic.clinicSetup.interactionsSetup || '{}');
        this.clinicSetupTemperatureUnit = doctor.clinic.clinicSetup.temperatureUnit;
      }
    }
  }

  public updateClinicSetup(input: UpdateClinicSetupInput): Observable<UpdateClinicSetupMutation> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.mutate({
          mutation: gql(updateClinicSetup),
          variables: { input: input }
        });
      })
    );
  }
}
