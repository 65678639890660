import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ReminderFormGroup } from '../clinic-setup-form.model';
import { ClinicSetupService } from '../clinic-setup.service';
import { ClinicService } from './../../../core/api/clinic.service';
import { reminderSelectionKeys } from './reminder.model';

type RadioConfig = {
  name: string;
  formControlName: AbstractControl;
};
type RadioButton = {
  [key in string]: {
    key: string;
    deepCopyList?: any[];
  };
};

export type Radio = {
  radioConfig: RadioConfig;
  radioButtons: RadioButton;
};
@Component({
  selector: 'csi-questionnaire-reminder',
  templateUrl: './questionnaire-reminder.component.html',
  styleUrls: ['./questionnaire-reminder.component.css']
})
export class QuestionnaireReminderComponent implements OnInit {
  public reminderFormGroup: ReminderFormGroup = this.clinicSetupService.clinicSetupFormGroup
    .controls.reminderConfig;
  public todayDate = new Date();
  public minDate = new Date();
  public maxDate = new Date(this.todayDate);

  public reminderSelectionKeys = reminderSelectionKeys;

  constructor(public clinicSetupService: ClinicSetupService, public clinicService: ClinicService) {
    this.minDate.setDate(this.todayDate.getDate() + 1);
    this.maxDate.setDate(
      this.todayDate.getDate() +
        this.clinicSetupService.clinicSetupFormGroup.value.questionnaireExpiry
    );
  }

  ngOnInit() {
    reminderSelectionKeys.forEach(key => {
      this.reminderFormGroup.addReminderControl(
        key + '',
        this.reminderFormGroup.controls.numberOfReminder
      );
    });
  }
}
