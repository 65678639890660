import { Component, Inject, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TreatmentPlan } from '../treatment-plan-table.model';

@Component({
  selector: 'csi-select-treatment-plan-modal',
  templateUrl: './select-treatment-plan-modal.component.html',
  styleUrls: ['./select-treatment-plan-modal.component.scss']
})
export class SelectTreatmentPlanModalComponent {
  @ViewChild('planList', { static: false }) planList!: MatSelectionList;
  treatmentPlans: TreatmentPlan[];
  selectedPlan: any = null;

  constructor(
    private dialogRef: MatDialogRef<SelectTreatmentPlanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { treatmentPlans: TreatmentPlan[] }
  ) {
    this.treatmentPlans = data.treatmentPlans;
  }

  onSelectionChange(event: any) {
    this.selectedPlan = event.option.value;
    event.source.deselectAll();
    event.option._setSelected(true);
  }

  confirmSelection(selectedPlan: any) {
    this.dialogRef.close(selectedPlan);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
