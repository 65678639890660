import { Component, OnInit } from '@angular/core';
import { FilterType } from 'src/API';
import { isWithinTimeFrameInDays } from '../core/api/date.util';
import { DynamicActionService } from '../core/api/dynamic-action.service';
import { NavigationService } from '../core/api/navigation.service';
import { mainSideNavSchema } from '../core/schemas/navigation-schema';
import { FilterGroup, MatchType } from '../filter/filter.model';
import { CardBottomColor } from './dashboard-number-card/dashboard-number-card.component';
import {
  DashboardNumberCardStatConfig,
  StatisticsConfig,
  TimeFrameInDays,
  timeFrameToCardConfigMap
} from './dashboard.model';

@Component({
  selector: 'csi-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public loading = true;
  cards = {};

  statsConfig: StatisticsConfig[] = [
    {
      serviceName: 'FilterService',
      functionName: 'queryFilterData',
      params: [FilterType.questionnaireRequest],
      title: 'Questionnaires Sent',
      timeFrameSelection: [
        TimeFrameInDays.WEEK,
        TimeFrameInDays.MONTH,
        TimeFrameInDays.QUARTER,
        TimeFrameInDays.YEAR,
        TimeFrameInDays.ALL
      ],
      cardBottomColor: 'blue',
      filterBy: 'createdAt'
    },
    {
      serviceName: 'FilterService',
      functionName: 'queryFilterData',
      params: [FilterType.questionnaireRequest],
      title: 'Questionnaires Completed',
      timeFrameSelection: [
        TimeFrameInDays.WEEK,
        TimeFrameInDays.MONTH,
        TimeFrameInDays.QUARTER,
        TimeFrameInDays.YEAR,
        TimeFrameInDays.ALL
      ],
      cardBottomColor: 'blue',
      filterBy: 'completedAt'
    },
    {
      serviceName: 'FilterService',
      functionName: 'queryFilterData',
      params: [FilterType.patient],
      timeFrameSelection: [
        TimeFrameInDays.WEEK,
        TimeFrameInDays.MONTH,
        TimeFrameInDays.QUARTER,
        TimeFrameInDays.YEAR,
        TimeFrameInDays.ALL
      ],
      title: 'New Patients',
      cardBottomColor: 'green',
      filterBy: 'createdAt'
    },
    {
      serviceName: 'FilterService',
      functionName: 'queryFilterData',
      params: [FilterType.assessment],
      title: 'New Assessments',
      timeFrameSelection: [
        TimeFrameInDays.WEEK,
        TimeFrameInDays.MONTH,
        TimeFrameInDays.QUARTER,
        TimeFrameInDays.YEAR,
        TimeFrameInDays.ALL
      ],
      cardBottomColor: 'orange',
      filterBy: 'createdAt'
    },
    {
      serviceName: 'FilterService',
      functionName: 'queryFilterData',
      params: [FilterType.intakeForm],
      timeFrameSelection: [
        TimeFrameInDays.WEEK,
        TimeFrameInDays.MONTH,
        TimeFrameInDays.QUARTER,
        TimeFrameInDays.YEAR,
        TimeFrameInDays.ALL
      ],
      title: 'Intake Forms Received',
      cardBottomColor: 'purple',
      filterBy: 'createdAt'
    }
  ];

  constructor(
    public navigationService: NavigationService,
    private dynamicActionService: DynamicActionService
  ) {}

  ngOnInit() {
    this.statsConfig.forEach(config => {
      this.generateStatistics(config);
    });
    this.navigationService.updateSideNavSchema(mainSideNavSchema);
  }

  private generateFilterGroup(field: string, timeFrameInDays: number): FilterGroup {
    const dateToday = new Date();

    // Calculate the date for two weeks ago
    const startDate = new Date();
    startDate.setDate(dateToday.getDate() - timeFrameInDays * 2);

    return {
      type: MatchType.and,
      filters: [
        {
          field,
          operator: 'greaterThanOrEqualTo',
          searchValue: startDate.toISOString()
        },
        {
          field,
          operator: 'lessThanOrEqualTo',
          searchValue: dateToday.toISOString()
        }
      ]
    };
  }
  public generateStatistics(config: StatisticsConfig) {
    if (config.timeFrameSelection.length === 0) {
      console.warn('time frame selection is 0');
      return;
    }
    const dateToday = new Date();

    const startDate = new Date();
    const timeFrameInDays = Math.max(...config.timeFrameSelection.map(t => t as number)) * 2;
    startDate.setDate(dateToday.getDate() - timeFrameInDays);

    this.dynamicActionService
      .performAction2(config.serviceName, config.functionName, [
        ...config.params,
        [this.generateFilterGroup(config.filterBy, timeFrameInDays)]
      ])
      .subscribe(data => {
        this.cards[config.title] = this.generateNumberCardConfig(
          data,
          config.filterBy,
          config.title,
          config.cardBottomColor,
          config.timeFrameSelection || []
        );
        this.loading = false;
      });
  }

  public generateNumberCardConfig(
    data: any[],
    filterBy: string,
    title: string,
    cardBottomColor: CardBottomColor = 'none',
    timeFrameSelection: TimeFrameInDays[] = []
  ) {
    let multiDateRangeConfig: DashboardNumberCardStatConfig[] = this.generateMultiDateRangeConfig(
      data,
      filterBy,
      timeFrameSelection
    );

    return {
      title,
      cardBottomColor,
      multiStatConfig: multiDateRangeConfig
    };
  }

  public generateMultiDateRangeConfig(
    data: any[],
    filterBy: string,
    timeFrameSelection: TimeFrameInDays[] = []
  ) {
    const dateToday = new Date();
    const startDate = new Date();
    let multiDateRangeConfig: DashboardNumberCardStatConfig[] = [];

    if (timeFrameSelection.length > 0) {
      for (let timeFrame of timeFrameSelection) {
        let dataFilteredByLastTimeFrame;
        startDate.setDate(dateToday.getDate() - timeFrame * 2);
        dataFilteredByLastTimeFrame = data.filter(value =>
          isWithinTimeFrameInDays(startDate, new Date(value[filterBy]), timeFrame)
        );

        let dateFilteredByCurrentTimeFrame;
        dateFilteredByCurrentTimeFrame = data.filter(value =>
          isWithinTimeFrameInDays(dateToday, new Date(value[filterBy]), timeFrame)
        );

        const stat = dateFilteredByCurrentTimeFrame.length;
        const statChange =
          dateFilteredByCurrentTimeFrame.length - dataFilteredByLastTimeFrame.length;
        const statPercentChanged =
          ((dateFilteredByCurrentTimeFrame.length - dataFilteredByLastTimeFrame.length) /
            dataFilteredByLastTimeFrame.length) *
          100;

        multiDateRangeConfig.push({
          label: timeFrameToCardConfigMap[timeFrame].label,
          stat,
          statChange,
          statPercentChanged,
          subtitle: timeFrameToCardConfigMap[timeFrame].subtitle
        });
      }
    }
    return multiDateRangeConfig;
  }
}
