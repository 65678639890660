import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import gql from 'graphql-tag';
import { from, Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators/map';
import { getDryEyePrediction } from './../../../../graphql/queries';
import { Patient } from './../../../core/api/patient.service';
import { AppSyncService } from './../../../core/appsync.service';
import { DryEyeFormGroup } from './dry-eye-form/dry-eye-form.model';
import { DryEyeMedicalHistoryFormGroup } from './dry-eye-medical-history-form/dry-eye-medical-history-form.model';

@Injectable({
  providedIn: 'root'
})
export class DryEyePredictionService {
  medicalHistoryFormGroup: DryEyeMedicalHistoryFormGroup;
  symptomsAndSignsFormGroup: DryEyeFormGroup;
  patient: Patient;

  constructor(private appSyncService: AppSyncService, private http: HttpClient) {}

  public predictDryEyeCategoryAndSeverity(): Observable<any> {
    const dryEyeFormCombined = {
      ...this.medicalHistoryFormGroup.value,
      ...this.symptomsAndSignsFormGroup.value
    };

    const dryEyePredictionInput: any = {
      ...dryEyeFormCombined,
      age: Math.floor((Date.now() - new Date(this.patient.dateOfBirth).getTime()) / 3.15576e10),
      gender: this.patient.gender
    };

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        return client.query({
          query: gql(getDryEyePrediction),
          variables: { input: JSON.stringify(dryEyePredictionInput) }
        });
      }),
      map(result => result.data['getDryEyePrediction'])
    );
  }

  getInputErrors(): string[] {
    const errors = [];

    if (!this.symptomsAndSignsFormGroup.controls.speed) {
      errors.push('SPEED is required but it is hidden, please enable it through the clinic setup');
    }
    if (!this.symptomsAndSignsFormGroup.controls.osdi) {
      errors.push('OSDI is required but it is hidden, please enable it through the clinic setup');
    }

    if (errors.length > 0) {
      return errors;
    }

    this.symptomsAndSignsFormGroup.controls.speed.markAsTouched();
    this.symptomsAndSignsFormGroup.controls.osdi.markAsTouched();

    if (
      !this.symptomsAndSignsFormGroup.controls.speed.value ||
      this.symptomsAndSignsFormGroup.controls.speed.value > 28
    ) {
      errors.push('Speed is required and must be less than or equal to 28');
    }
    if (
      !this.symptomsAndSignsFormGroup.controls.osdi.value ||
      this.symptomsAndSignsFormGroup.controls.osdi.value > 100
    ) {
      errors.push('Osdi is required and must be less than or equal to 100');
    }

    if (
      errors.length === 0 &&
      Object.entries(this.symptomsAndSignsFormGroup.controls).filter(
        ([key, control]) =>
          (key.includes('LEFT_') || key.includes('RIGHT_')) &&
          !key.includes('_METHOD') &&
          control.value &&
          control.valid
      ).length < 8
    ) {
      errors.push('Require more sign variables to be filled with patient data');
    }

    return (errors.length > 0 && errors) || null;
  }

  predictDryEyeSeverity(): Observable<string> {
    const bodyKeys = [
      'RIGHT_NITBUT',
      'LEFT_NITBUT',
      'RIGHT_ITBUT',
      'LEFT_ITBUT',
      'RIGHT_OSMOLARITY',
      'LEFT_OSMOLARITY',
      'LEFT_MEIBOGRAPHY',
      'RIGHT_MEIBOGRAPHY',
      'speed'
    ];

    const body = bodyKeys.reduce((acc, key) => {
      acc[key] = this.symptomsAndSignsFormGroup.controls[key].value || null;
      return acc;
    }, {});

    return from(Auth.currentSession()).pipe(
      mergeMap(res => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${res.getIdToken().getJwtToken()}`
        });
        return this.http.post(
          'https://ziicgfn8u9.execute-api.us-east-1.amazonaws.com/default/DryEyePreOpScreeningPrediction',
          JSON.stringify(body),
          { headers }
        );
      }),
      map(res => res['body'])
    );
  }
}
