import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DataProxy } from 'apollo-cache';
import { ApolloQueryResult, FetchPolicy, QueryOptions } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PatientFormGroup } from 'src/app/shared/consult-forms/patient-form/patient-form.model';
import { createPatient, createQuestionnaireRequest, updatePatient } from 'src/graphql/mutations';
import {
  CreateAssessmentMutation,
  CreatePatientInput,
  CreateQuestionnaireRequestInput,
  CreateQuestionnaireRequestMutation,
  GetPatientQuery,
  HealthCardInput,
  IntakeForm,
  ListPatientsByClinicIdSortedByLastInteractionAtQuery,
  ModelSortDirection,
  Patient as PatientInteraction,
  QuestionnaireRequest as ResponseRequest,
  UpdatePatientInput
} from '../../../API';
import { AppSyncService } from '../appsync.service';
import { CreatePatientMutation, ListPatientsByLinkedReferralPatientIdQuery } from './../../../API';
import { Assessment, AssessmentList } from './assessment.service';
import { ErrorHandlerService } from './error-handler.service';
import { QuestionnaireRequest } from './questionnaire-request.service';

export type Patient = CreatePatientMutation['createPatient'];
export interface PatientList {
  items: Patient[];
  nextToken: string;
}
export type HealthCard = HealthCardInput;
@Injectable({
  providedIn: 'root'
})
export class PatientService {
  public referralPatientId: string;
  public patient: Patient;
  public assessments: Assessment[];
  public questionnaires: QuestionnaireRequest[];
  public intakeForms: IntakeForm[];

  // We don't currently do pagination for patient assessments, this is a safe max that ui components can refer to.
  public readonly maxAssessmentsPerPatient = 50;
  public readonly maxQuestionnaireRequestsPerPatient = 50;
  public readonly maxIntakeFormsPerPatient = 50;
  public readonly maxReferralsPerPatient = 50;
  public readonly patientSource = ['Word to Mouth', 'Walk-ins', 'Google', 'Reddit'];
  public patientSummaryMap: { [key: string]: PatientInteraction } = {};

  private readonly patientBasicFieldsFragment = `
    fragment PatientBasicFields on Patient {
      firstName
      lastName
      email
      emailVerified
      agreedToTerms
      dateOfBirth
      linkedReferralPatientId
      referralSource
      healthCard {
        province
        number
      }
      clinic {
        id
        country
      }
      gender
      genderOther
      phone
      address
      consentsToResearch
      consentsToPrivacyForm
      consentSource
      createdAt
      updatedAt
      interactions(limit:20, sortDirection: DESC) {
        items {
          id
          type
          status
          comments
          createdAt
          updatedAt
          surgeryType
          appointmentDate
          attemptFailed
          communicationMethod
        }
        nextToken
      }
      nonModifiableData
    }
  `;

  private readonly patientAssessmentsFragment = `
    fragment PatientAssessments on Patient {
      assessments(limit: $assessmentsLimit, sortDirection: DESC) @skip(if: $skipAssessments) {
        items {
          id
          type
          body
          patient {
            id
            email
            phone
            firstName
            lastName
          }
          doctor {
            id
            firstName
            lastName
            email
            practitionerId
          }
          attachments {
            bucket
            key
            region
            fileName
          }
          createdAt
          updatedAt
          questionnaireRequests{
            items {
              id
              type
              response {
                answers
                scores
              }
              createdAt
              completedAt
              config
            }
          }
          requests(limit: 1) {
            items {
              id
              type
              response
            }
            nextToken
          }
        }
        nextToken
      }
    }
  `;

  private readonly patientQuestionnaireRequestsFragment = `
    fragment PatientQuestionnaireRequestsSortedByCreatedAt on Patient {
      questionnaireRequests(limit: $questionnaireRequestsLimit, sortDirection: DESC) @skip(if: $skipQuestionnaireRequests){
        items {
          id
          createdAt
          completedAt
          config
          response {
            answers
            scores
          }
          type
          updatedAt
          assessment{
            id
            createdAt
          }
        }
        nextToken
      }
    }
  `;

  private readonly patientLatestAssessmentDateQuery = gql`
    query GetPatientLatestAssessmentDate($id: ID!) {
      getPatient(id: $id) {
        id
        assessments(limit: 1, sortDirection: DESC) {
          items {
            id
            createdAt
          }
        }
      }
    }
  `;

  private readonly getPatientWithIntakeFormsQuery = gql`
    query GetPatient(
      $id: ID!
      $assessmentsLimit: Int = 0
      $skipAssessments: Boolean = true
      $questionnaireRequestsLimit: Int = 0
      $skipQuestionnaireRequests: Boolean = true
      $skipIntakeForms: Boolean = true
      $intakeFormsLimit: Int = 0
    ) {
      getPatient(id: $id) {
        id
        ...PatientBasicFields
        ...PatientAssessments
        ...PatientQuestionnaireRequestsSortedByCreatedAt
      }
      listIntakeFormByPatientIdSortedByCreatedAt(
        intakeFormPatientId: $id
        sortDirection: DESC
        limit: $intakeFormsLimit
      ) @skip(if: $skipIntakeForms) {
        items {
          createdAt
          id
          updatedAt
          response
          intakeFormPatientId
        }
        nextToken
      }
    }
    ${this.patientBasicFieldsFragment}
    ${this.patientAssessmentsFragment}
    ${this.patientQuestionnaireRequestsFragment}
  `;

  listPatientsByClinicIdSortedByLastInteractionAt = /* GraphQL */ `
    query ListPatientsByClinicIdSortedByLastInteractionAt(
      $patientClinicId: ID
      $lastInteractionAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelPatientFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPatientsByClinicIdSortedByLastInteractionAt(
        patientClinicId: $patientClinicId
        lastInteractionAt: $lastInteractionAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          firstName
          lastName
          email
          emailVerified
          phone
          agreedToTerms
          patientClinicId
          dateOfBirth
          healthCard {
            province
            number
            country
          }
          gender
          address
          createdAt
          updatedAt
          linkedReferralPatientId
          referralSource
          nonModifiableData
          interactions {
            items {
              id
              type
              status
              comments
              createdAt
              updatedAt
              surgeryType
              appointmentDate
              attemptFailed
              communicationMethod
              interactionClinicId
            }
            nextToken
          }
          lastInteractionAt
          isColdLead
        }
        nextToken
      }
    }
  `;
  constructor(
    private appSyncService: AppSyncService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  public createPatient(input: CreatePatientInput): Observable<Patient> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createPatient),
          variables: { input: input }
        })
      ),
      map(result => result.data.createPatient)
    );
  }

  public getPatientLatestAssessmentDate(id: string): Observable<string> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: this.patientLatestAssessmentDateQuery,
          variables: { id },
          fetchPolicy: 'network-only'
        })
      ),
      map(
        (result: ApolloQueryResult<any>) =>
          (result.data.getPatient.assessments.items[0] &&
            result.data.getPatient.assessments.items[0].createdAt) ||
          ''
      )
    );
  }

  public updatePatient(input: UpdatePatientInput): Observable<Patient> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(updatePatient),
          variables: { input: input }
        })
      ),
      map(result => result.data.updatePatient)
    );
  }



  public getPatient(
    id: string,
    watch: boolean = false,
    fetchPolicy: FetchPolicy = 'cache-first',
    assessmentsLimit: number = 0,
    questionnaireRequestsLimit: number = 0,
    intakeFormsLimit: number = 0
  ): Observable<Patient> {
    const skipAssessments = assessmentsLimit === 0;
    const skipQuestionnaireRequests = questionnaireRequestsLimit === 0;
    const skipIntakeForms = intakeFormsLimit === 0;

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        const queryOptions = {
          query: this.getPatientWithIntakeFormsQuery,
          variables: {
            id: id,
            assessmentsLimit,
            skipAssessments,
            questionnaireRequestsLimit,
            skipQuestionnaireRequests,
            intakeFormsLimit,
            skipIntakeForms
          },
          fetchPolicy: fetchPolicy
        };
        return watch ? client.watchQuery(queryOptions) : client.query(queryOptions);
      }),
      map((result: ApolloQueryResult<GetPatientQuery>) => result.data.getPatient)
    );
  }

  public getPatientWithIntakeForms(
    id: string,
    watch: boolean = false,
    fetchPolicy: FetchPolicy = 'cache-first',
    assessmentsLimit: number = 0,
    questionnaireRequestsLimit: number = 0,
    intakeFormsLimit: number = 0
  ): Observable<{ patient: Patient; intakeForms: IntakeForm[] }> {
    const skipAssessments = assessmentsLimit === 0;
    const skipQuestionnaireRequests = questionnaireRequestsLimit === 0;
    const skipIntakeForms = intakeFormsLimit === 0;

    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        const queryOptions = {
          query: this.getPatientWithIntakeFormsQuery,
          variables: {
            id: id,
            assessmentsLimit,
            skipAssessments,
            questionnaireRequestsLimit,
            skipQuestionnaireRequests,
            intakeFormsLimit,
            skipIntakeForms
          },
          fetchPolicy: fetchPolicy
        };
        return watch ? client.watchQuery(queryOptions) : client.query(queryOptions);
      }),
      map((result: any) => ({
        patient: result.data.getPatient,
        intakeForms:
          result.data.listIntakeFormByPatientIdSortedByCreatedAt &&
          result.data.listIntakeFormByPatientIdSortedByCreatedAt.items
      }))
    );
  }

  public getPatientQuestionnaire() {
    return this.getPatient(this.patient.id, false, 'network-only', 0, 10).pipe(
      tap(val => {
        this.questionnaires =
          val.questionnaireRequests && (val.questionnaireRequests.items as QuestionnaireRequest[]);
      })
    );
  }

  // TODO: This is similar to getPatientsForDoctor in staff.service
  // maybe rewrite as one method using generics
  // TODO: Support pagination. But I doubt in the real world a patient will have more than 50 assessments.
  // See https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/Query.html#Query.FilterExpression
  public getAssessmentsForPatient(
    patientId: string,
    watch: boolean = false,
    fetchPolicy: FetchPolicy = 'cache-first',
    limit: number = 50
  ): Observable<AssessmentList> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client => {
        const queryOptions: QueryOptions = {
          query: this.getPatientWithIntakeFormsQuery,
          variables: { id: patientId, assessmentsLimit: limit, skipAssessments: false },
          fetchPolicy: fetchPolicy,
          fetchResults: true
        };
        return watch ? client.watchQuery(queryOptions) : client.query(queryOptions);
      }),
      filter((result: ApolloQueryResult<GetPatientQuery>) => !result.loading),
      map((result: ApolloQueryResult<GetPatientQuery>) => result.data.getPatient.assessments),
      tap((results: AssessmentList) => {
        if (results.nextToken) {
          console.warn(
            `Patient has more than ${limit} assessments. Consider increasing the limit or using pagination.`
          );
        }
      })
    );
  }

  public updateAssessmentsForPatient(
    proxy: DataProxy,
    mutationResultData: CreateAssessmentMutation,
    patientId: string
  ) {
    try {
      const queryOptions = {
        id: this.appSyncService.dataIdFromObject({
          __typename: 'Patient',
          id: patientId
        }),
        variables: { assessmentsLimit: this.maxAssessmentsPerPatient, skipAssessments: false },
        fragment: gql(this.patientAssessmentsFragment)
      };
      const data: GetPatientQuery['getPatient'] = proxy.readFragment<GetPatientQuery['getPatient']>(
        queryOptions
      );
      data.assessments.items = [...data.assessments.items, mutationResultData.createAssessment];
      proxy.writeFragment({
        ...queryOptions,
        data: {
          ...data
        }
      });
    } catch (e) {
      if (e.message && e.message.startsWith("Can't find field assessments")) {
        // variables in readQuery must match an existing query, else an exception is thrown, this is expected in case of usage through
        // econsults.seemaeye.com, so ignore
      } else {
        this.errorHandlerService.handleError(e);
      }
    }
  }

  public getPatientBasedOnLinkedReferralPatientId(
    linkedReferralPatientId: string
  ): Observable<Patient> {
    const listPatientsByLinkedReferralPatientIdWithInteractions = /* GraphQL */ `
      query ListPatientsByLinkedReferralPatientId(
        $linkedReferralPatientId: ID
        $sortDirection: ModelSortDirection
        $filter: ModelPatientFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listPatientsByLinkedReferralPatientId(
          linkedReferralPatientId: $linkedReferralPatientId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            firstName
            lastName
            email
            phone
            dateOfBirth
            healthCard {
              province
              number
              country
            }
            gender
            address
            linkedReferralPatientId
            referralSource
            interactions {
              items {
                id
                type
                status
                comments
                createdAt
                updatedAt
                surgeryType
                appointmentDate
                attemptFailed
                communicationMethod
                interactionClinicId
              }
              nextToken
            }
          }
          nextToken
        }
      }
    `;

    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(listPatientsByLinkedReferralPatientIdWithInteractions),
          variables: {
            linkedReferralPatientId: linkedReferralPatientId,
            limit: 1
          }
        })
      ),
      map(
        (result: ApolloQueryResult<ListPatientsByLinkedReferralPatientIdQuery>) =>
          result.data.listPatientsByLinkedReferralPatientId.items &&
          result.data.listPatientsByLinkedReferralPatientId.items[0]
      )
    );
  }
  public listPatientsSortedByLastInteraction(
    patientClinicId: string,
    nextToken: string = null,
    limit: number = 10
  ): Observable<{ items?: Patient[]; nextToken?: string }> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.query({
          query: gql(this.listPatientsByClinicIdSortedByLastInteractionAt),
          variables: {
            patientClinicId,
            limit,
            sortDirection: ModelSortDirection.DESC,
            nextToken
          }
        })
      ),
      map(
        (result: ApolloQueryResult<ListPatientsByClinicIdSortedByLastInteractionAtQuery>) =>
          result.data.listPatientsByClinicIdSortedByLastInteractionAt
      )
    );
  }

  public createLinkedPatientIntakeQuestionnaire(
    input: CreateQuestionnaireRequestInput
  ): Observable<ResponseRequest> {
    return this.appSyncService.hydrated().pipe(
      switchMap(client =>
        client.mutate({
          mutation: gql(createQuestionnaireRequest),
          variables: { input: input }
        })
      ),
      map(
        (result: ApolloQueryResult<CreateQuestionnaireRequestMutation>) =>
          result.data.createQuestionnaireRequest
      )
    );
  }
  /***ASk Pujan If this can be done without the query or query need to created. */
  private getPatientSummariesBasedOnStatus(
    status: string = '',
    dateRange: string[] = [],
    nextToken: string = null,
    limit: number
  ): Observable<{ items?: Patient[]; nextToken?: string }> {
    return;
  }
  public contactValidator(control: AbstractControl): ValidationErrors | null {
    const patientFormGroup = control as PatientFormGroup;

    const emailControl = patientFormGroup.controls.email;
    const phoneControl = patientFormGroup.controls.phone;
    const isEmailValid = emailControl.value && emailControl.valid;
    const isPhoneValid = phoneControl.value && phoneControl.valid;

    const isContactInvalid = !isEmailValid && !isPhoneValid;
    const emailErrors = emailControl.errors ? emailControl.errors : {};
    const phoneErrors = phoneControl.errors ? phoneControl.errors : {};

    if (isContactInvalid) {
      emailErrors['invalidContact'] = true;
      emailControl.setErrors(emailErrors);

      phoneErrors['invalidContact'] = true;
      phoneControl.setErrors(phoneErrors);
    } else {
      delete emailErrors.invalidContact;
      delete phoneErrors.invalidContact;

      emailControl.setErrors(Object.keys(emailErrors).length === 0 ? null : emailErrors);
      phoneControl.setErrors(Object.keys(phoneErrors).length === 0 ? null : phoneErrors);
    }

    return isContactInvalid ? { invalidContact: true } : null;
  }
}
