import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { MarkdownModule } from 'ngx-markdown';
import { WebcamModule } from 'ngx-webcam';
import { CopyToEmrModalComponent } from '../copy-to-emr-modal/copy-to-emr-modal.component';
import { CopyToEmrComponent } from '../copy-to-emr/copy-to-emr.component';
import { IntakeFormViewComponent } from '../intake-form/intake-form-view/intake-form-view.component';
import { QuestionStepComponent } from '../questionnaires/simple-questionnaire/question-step/question-step.component';
import { SpeedIIComponent } from '../questionnaires/speedII/speed-ii.component';
import { ChooseDoctorFromClinicComponent } from '../shared/choose-doctor-from-clinic/choose-doctor-from-clinic.component';
import { SendQuestionnaireDialogComponent } from '../shared/send-questionnaire-dialog/send-questionnaire-dialog.component';
import { DerfsComponent } from './../questionnaires/derfs/derfs.component';
import { MarkdownNoPTagsDirective } from './../questionnaires/markdown-no-p-tags.directive';
import { TranslocoRootModule } from './../transloco-root.module';
import { BirthdayPickerFormFieldComponent } from './birthday-picker/birthday-picker-form-field/birthday-picker-form-field.component';
import { BirthdayPickerComponent } from './birthday-picker/birthday-picker.component';
import { CheckboxAndInputComponent } from './checkbox-and-input/checkbox-and-input.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { LinkQuestionnaireModalComponent } from './consult-forms/dry-eye-forms/dry-eye-medical-history-form/link-assessment-to-questionnaire-modal/link-assessment-to-questionnaire-modal.component';
import { CustomNotificationBuilderComponent } from './custom-notification-builder/custom-notification-builder.component';
import { CsiContenteditableDirective } from './directives/contenteditable.directive';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard/copy-to-clipboard.directive';
import { ForceSelectableTextDirective } from './directives/force-selectable-text/force-selectable-text.directive';
import { NgSelectHighlightFirstDirective } from './directives/ng-select/ng-select-highlight-first/ng-select-highlight-first.directive';
import { TabAfterNgSelectOnTabDirective } from './directives/ng-select/tab-after-ng-select-on-tab/tab-after-ng-select-on-tab.directive';
import { PdfExportDonutChartComponent } from './dry-eye-pdf-export/pdf-export-donut-chart/pdf-export-donut-chart.component';
import { EnlargeableMediaComponent } from './enlargeable-media/enlargeable-media.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { CheckboxQuestionComponent } from './form-fields/checkbox-question/checkbox-question.component';
import { MultipleChoiceQuestionComponent } from './form-fields/multiple-choice-question/multiple-choice-question.component';
import { GenderComponent } from './gender/gender.component';
import { HealthcardFormFieldComponent } from './healthcard-form-field/healthcard-form-field.component';
import { InfoPopoverModule } from './info-popover/info-popover.module';
import { ListViewAndAddComponent } from './list-view-and-add/list-view-and-add.component';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { LocationSelectModule } from './location-select/location-select.module';
import { CSIMatFormFieldGroupModule } from './mat-form-field-group/mat-form-field-group.module';
import { MatSpinnerButtonComponent } from './mat-spinner-button/mat-spinner-button.component';
import { MatTableColumnOrderComponent } from './mat-table-column-order/mat-table-column-order.component';
import { MediaSelectionModalComponent } from './media-selection-modal/media-selection-modal.component';
import { NewPasswordInputComponent } from './new-password-input/new-password-input.component';
import { NoResultsMessageComponent } from './no-results-message/no-results-message.component';
import { OverlayComponent } from './overlay/overlay.component';
import { PatientLookupComponent } from './patient-lookup/patient-lookup.component';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { QrCodeModalComponent } from './qr-code-modal/qr-code-modal.component';
import { QuestionnaireSelectComponent } from './questionnaire-select/questionnaire-select.component';
import { ResponseFormComponent } from './response-form/response-form.component';
import { RiskFactorsModalComponent } from './risk-factors-report/risk-factors-modal.component';
import { SharedAngularMaterialModule } from './shared-angular-material/shared-angular-material.module';
import { AlwaysShowTicksDirective } from './shared-angular-material/slider-directives/always-show-ticks.directive';
import { LabeledMatSliderComponent } from './shared-angular-material/slider-directives/labeled-mat-slider/labeled-mat-slider.component';
import { MatVerticalStepperScrollerDirective } from './shared-angular-material/stepper-directives/mat-vertical-stepper-scroller.directive';
import { SharedNgxBootstrapModule } from './shared-ngx-bootstrap/shared-ngx-bootstrap.module';
import { SharedPipesModule } from './shared-pipes/shared-pipes.module';
import { ShowHideDragDropComponent } from './show-hide-drag-drop/show-hide-drag-drop.component';
import { DynamicBindingDirective } from './symptoms/directives/dynamic-binding.directive';
import { TabletQuestionnaireModalComponent } from './tablet-questionnaire-modal/tablet-questionnaire-modal.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { VerificationInputComponent } from './verification-input/verification-input.component';
@NgModule({
  declarations: [
    EnlargeableMediaComponent,
    BirthdayPickerComponent,
    TabAfterNgSelectOnTabDirective,
    NgSelectHighlightFirstDirective,
    CopyToClipboardDirective,
    PatientLookupComponent,
    BirthdayPickerFormFieldComponent,
    HealthcardFormFieldComponent,
    ForceSelectableTextDirective,
    SendQuestionnaireDialogComponent,
    TermsOfUseComponent,
    ResponseFormComponent,
    ChooseDoctorFromClinicComponent,
    CheckboxAndInputComponent,
    PhoneNumberInputComponent,
    NewPasswordInputComponent,
    VerificationInputComponent,
    MarkdownNoPTagsDirective,
    MatSpinnerButtonComponent,
    TermsOfServiceComponent,
    QuestionnaireSelectComponent,
    ListViewAndAddComponent,
    CsiContenteditableDirective,
    DynamicBindingDirective,
    DerfsComponent,
    MatTableColumnOrderComponent,
    GenderComponent,
    MultipleChoiceQuestionComponent,
    CheckboxQuestionComponent,
    RiskFactorsModalComponent,
    CheckboxGroupComponent,
    TabletQuestionnaireModalComponent,
    QrCodeModalComponent,
    LinkQuestionnaireModalComponent,
    OverlayComponent,
    SpeedIIComponent,
    QuestionStepComponent,
    CopyToEmrModalComponent,
    CopyToEmrComponent,
    MediaSelectionModalComponent,
    ShowHideDragDropComponent,
    LabeledMatSliderComponent,
    MatVerticalStepperScrollerDirective,
    AlwaysShowTicksDirective,
    PdfExportDonutChartComponent,
    FormFieldComponent,
    NoResultsMessageComponent,
    IntakeFormViewComponent,
    CustomNotificationBuilderComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    ReactiveFormsModule,
    SharedAngularMaterialModule,
    SharedPipesModule,
    RouterModule,
    CSIMatFormFieldGroupModule,
    PdfViewerModule,
    InfoPopoverModule,
    LocationSelectModule,
    MarkdownModule.forRoot(),
    ClipboardModule,
    WebcamModule,
    QRCodeModule,
    TranslocoRootModule,
    MatStepperModule
  ],
  exports: [
    EnlargeableMediaComponent,
    BirthdayPickerComponent,
    CopyToClipboardDirective,
    TabAfterNgSelectOnTabDirective,
    NgSelectHighlightFirstDirective,
    FontAwesomeModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    LoadingSpinnerModule,
    SharedNgxBootstrapModule,
    NgSelectModule,
    NgOptionHighlightModule,
    SharedAngularMaterialModule,
    InfoPopoverModule,
    SharedPipesModule,
    PatientLookupComponent,
    CSIMatFormFieldGroupModule,
    BirthdayPickerFormFieldComponent,
    HealthcardFormFieldComponent,
    ForceSelectableTextDirective,
    SendQuestionnaireDialogComponent,
    TermsOfUseComponent,
    TermsOfServiceComponent,
    ResponseFormComponent,
    ChooseDoctorFromClinicComponent,
    CheckboxAndInputComponent,
    PdfViewerModule,
    LocationSelectModule,
    PhoneNumberInputComponent,
    NewPasswordInputComponent,
    VerificationInputComponent,
    MarkdownNoPTagsDirective,
    MatSpinnerButtonComponent,
    QuestionnaireSelectComponent,
    ListViewAndAddComponent,
    ClipboardModule,
    CsiContenteditableDirective,
    DynamicBindingDirective,
    WebcamModule,
    DerfsComponent,
    MatTableColumnOrderComponent,
    GenderComponent,
    MultipleChoiceQuestionComponent,
    CheckboxQuestionComponent,
    LinkQuestionnaireModalComponent,
    OverlayComponent,
    CheckboxGroupComponent,
    DragDropModule,
    SpeedIIComponent,
    QuestionStepComponent,
    ShowHideDragDropComponent,
    TranslocoRootModule,
    LabeledMatSliderComponent,
    MatVerticalStepperScrollerDirective,
    AlwaysShowTicksDirective,
    PdfExportDonutChartComponent,
    NoResultsMessageComponent,
    IntakeFormViewComponent,
    QRCodeModule,
    CustomNotificationBuilderComponent,
    CopyToEmrModalComponent,
    CopyToEmrComponent
  ],
  entryComponents: [
    SendQuestionnaireDialogComponent,
    RiskFactorsModalComponent,
    TabletQuestionnaireModalComponent,
    QrCodeModalComponent,
    LinkQuestionnaireModalComponent,
    CopyToEmrModalComponent,
    CopyToEmrComponent,
    MediaSelectionModalComponent,
    IntakeFormViewComponent
  ],
  providers: []
})
export class SharedModule {}
