import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DashboardNumberCardComponent } from './dashboard-number-card/dashboard-number-card.component';
import { DashboardComponent } from './dashboard.component';
import { WidgetsViewComponent } from './widgets-view/widgets-view.component';
import { QuestionnaireRiskPipe } from './questionnaire-risk.pipe';

@NgModule({
  declarations: [DashboardComponent, DashboardNumberCardComponent, WidgetsViewComponent, QuestionnaireRiskPipe],
  imports: [CommonModule, SharedModule],
  exports: [DashboardComponent],
  entryComponents: [],
  providers: [DatePipe]
})
export class DashboardModule {}
