import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  Treatment,
  TreatmentsService
} from 'src/app/core/remote-json/treatments/treatments.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import {
  TreatmentTemplate,
  TreatmentTemplateService
} from 'src/app/logged-in-navbar/clinic-setup-modal/treatment-template/treatment-template.service';
import { PackageFormGroup } from '../packages/package.model';
import { PackagesService } from '../packages/packages.service';

@Component({
  selector: 'csi-package-customizer',
  templateUrl: './package-customizer.component.html',
  styleUrls: ['./package-customizer.component.scss']
})
export class PackageCustomizerComponent implements OnInit {
  @Input() availableTreatments: Array<Treatment & { units: number; price?: number }> = [];
  @Input() selectedTreatments: Array<Treatment & { units: number; price?: number }> = [];
  @Output() selectedTreatmentsChange = new EventEmitter<
    (Treatment & { units: number; price?: number })[]
  >();
  packageForm: PackageFormGroup = new PackageFormGroup();
  searchTerm = '';
  templateSearchControl = new FormControl('');
  templates: TreatmentTemplate[] = [];
  constructor(
    private treatmentsService: TreatmentsService,
    private clinicSetupService: ClinicSetupService,
    private treatmentTemplateService: TreatmentTemplateService,
    private packagesService: PackagesService,
    @Optional() public dialogRef?: MatDialogRef<PackageCustomizerComponent>
  ) {}
  ngOnInit(): void {
    this.packageForm.patchValue({ id: '', name: '', icdCode: '', price: null, treatments: [] });
    this.treatmentsService.allTreatments$.subscribe(all => {
      this.availableTreatments = all.map(t => ({ ...t, units: 1, price: t.price || 0 }));
    });
    this.loadTreatmentTemplates();
  }
  loadTreatmentTemplates(): void {
    const clinicId = this.clinicSetupService.clinicId;
    this.treatmentTemplateService.getAllTreatmentTemplates(clinicId, null).subscribe(templates => {
      this.templates = templates;
    });
  }
  get filteredAvailableTreatments() {
    if (!this.searchTerm.trim()) {
      return this.availableTreatments;
    }
    const lower = this.searchTerm.toLowerCase();
    return this.availableTreatments.filter(t => t.name.toLowerCase().includes(lower));
  }
  get filteredTemplates(): TreatmentTemplate[] {
    const search = this.templateSearchControl.value;
    if (!search || !search.trim()) {
      return this.templates;
    }
    const lower = search.toLowerCase();
    return this.templates.filter(t => t.friendlyId && t.friendlyId.toLowerCase().includes(lower));
  }
  drop(event: CdkDragDrop<Treatment[]>): void {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
  }
  moveToSelected(t: Treatment & { units: number; price?: number }) {
    const idx = this.availableTreatments.indexOf(t);
    if (idx > -1) {
      this.availableTreatments.splice(idx, 1);
      t.price = t.price || 0;
      this.selectedTreatments.push(t);
    }
  }
  moveToAvailable(t: Treatment & { units: number; price?: number }) {
    const idx = this.selectedTreatments.indexOf(t);
    if (idx > -1) {
      this.selectedTreatments.splice(idx, 1);
      this.availableTreatments.push(t);
    }
  }
  openTemplate(template: TreatmentTemplate): void {
    const parseTreatmentArray = (arr: any): Treatment[] => {
      if (!Array.isArray(arr)) return [];
      return arr.filter((x: any) => typeof x === 'object') as Treatment[];
    };
    const combined = [
      ...parseTreatmentArray(template.otcEyeDrops),
      ...parseTreatmentArray(template.rxEyeDrops),
      ...parseTreatmentArray(template.procedures),
      ...parseTreatmentArray(template.exercises)
    ].map(t => ({ ...t, units: t.units || 1, price: t.price || 0 }));
    combined.forEach(tc => {
      const idx = this.availableTreatments.findIndex(a => a.id === tc.id);
      if (idx > -1) {
        this.availableTreatments.splice(idx, 1);
      }
      this.selectedTreatments.push(tc);
    });
  }
  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close(null);
    }
  }
  saveDialog(): void {
    const formValue = this.packageForm.value;
    formValue.treatments = this.selectedTreatments;
    const input = {
      name: formValue.name,
      friendlyDescription: '',
      price: formValue.price.toString(),
      treatmentSpecifications: formValue.treatments.map(t => ({
        treatmentId: t.id,
        units: t.units,
        comment: t.comment || ''
      })),
      treatmentTemplateId: '',
      icdCode: formValue.icdCode,
      treatmentPackageClinicId: this.clinicSetupService.clinicId
    };
    this.packagesService.createPackage(input).subscribe((result: any) => {
      if (this.dialogRef) {
        this.dialogRef.close(result.data.createTreatmentPackage);
      }
    });
  }
}
