import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { PackageCustomizerComponent } from '../package-customizer/package-customizer.component';
import { PackagesService } from './packages.service';

@Component({
  selector: 'csi-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  packages: any[] = [];
  constructor(
    private dialog: MatDialog,
    private packagesService: PackagesService,
    private clinicSetupService: ClinicSetupService
  ) {}
  ngOnInit(): void {
    this.loadPackages();
  }
  loadPackages(): void {
    const clinicId = this.clinicSetupService.clinicId;
    this.packagesService.getPackages(clinicId).subscribe((result: any) => {
      this.packages = result.data.listTreatmentPackageByClinicId.items;
    });
  }
  openCreatePackage(): void {
    const dialogRef = this.dialog.open(PackageCustomizerComponent, {
      width: '1000px',
      height: '85dvh'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.packages = [...this.packages, result];
      }
    });
  }
  deletePackage(pkg: any): void {
    this.packagesService.deletePackage({ id: pkg.id }).subscribe((result: any) => {
      this.packages = this.packages.filter(p => p.id !== pkg.id);
    });
  }
}
