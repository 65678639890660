import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

interface WeatherFormControls {
  temp: FormControl;
  tempUnit: FormControl;
  humidity: FormControl;
}

export class WeatherFormGroup extends FormGroup {
  public controls: WeatherFormControls & { [key: string]: AbstractControl };

  constructor() {
    super({
      temp: new FormControl(),
      tempUnit: new FormControl(),
      humidity: new FormControl()
    });
  }
}
